.payment-info {

  .container {
    max-width: 1240px;
    padding: 0 1rem;
  }

  &__wrap {
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .title-block {
    &__content {
      margin-top: .25rem;
      font-weight: 500;
    }
  }

  .pills-payment-info-nav {
    .nav-link {
      display: flex;
      justify-content: space-between;
      width: 17rem;
      padding: 1rem;
      font-size: $btn-font-size;
      color: $gray-900;
      background-color: $white;

      &.active,
      &.focus {
        color: $orange;
        border-left: 4px solid $orange;
        box-shadow: none;
      }

      i {
        margin-top: 1px;
      }
    }
  }

  .select {
    margin-bottom: 2.5rem;
  }

  .pills-top-menu {
    &-payment-tab {
      .form-select {
        margin-bottom: 2.5rem;
      }
    }
  }

  .info {
    h2,
    h5 {
      margin-bottom: 2rem;
    }

    h3 {
      margin-bottom: 1.5rem;
    }

    h6 {
      margin-bottom: .5rem;
    }

    ul {
      padding-left: 1.5rem;
    }

    .location-container {
      padding: 1.5rem;
      background-color: $gray-100;

      &__location {
        cursor: pointer;

        .border-bottom {
          border-bottom: 1px dotted $gray-900;
        }
      }
    }

    .payment-lists {
      display: flex;
      flex-direction: column;
      margin: 1.5rem 0;

      li {
        &:not(:last-of-type) {
          padding-bottom: .5rem;
        }
      }

      @include media-breakpoint-up(xl) {
        flex-direction: row;

        &__block {
          width: 50%;
        }

        ul {
          margin-bottom: 0;
        }
      }
    }

    .payment-notes {
      padding-top: 1.5rem;
      font-size: $fs-m;
      color: $gray-700;
      border-top: 1px solid $gray-300;

      &__list {
        margin-bottom: 0;

        li {
          &:not(:last-of-type) {
            padding-bottom: 1.5rem;
          }
        }
      }
    }

    .payment-options {
      &__desc {
        margin-top: 2.5rem;

        &-header {
          img {
            width: 3rem;
            margin-right: .5rem;
          }

          .logos {
            margin-bottom: 1.5rem;
          }
        }

        &-content {
          li {
            &:not(:last-of-type) {
              padding-bottom: 1rem;
            }
          }
        }

        &-note {
          font-size: $fs-m;
          color: $gray-700;
        }
      }
    }
  }
}
