[class^="icn-"],
[class*=" icn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  // stylelint-disable-next-line
  font-family: "EvrazIcon" !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: never;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icn-close-color::before {
  color: #e32112;
  content: "\e904";
}
.icn-info-color::before {
  color: #ed7817;
  content: "\e905";
}
.icn-percent-color::before {
  color: #e32112;
  content: "\e906";
}
.icn-phone-color::before {
  color: #4a8f40;
  content: "\e907";
}
.icn-question-color::before {
  color: #b1b2b2;
  content: "\e908";
}
.icn-success-color::before {
  color: #4a8f40;
  content: "\e909";
}
.icn-warning-color::before {
  color: #fab82e;
  content: "\e90a";
}
.icn-phone::before {
  content: "\e90b";
}
.icn-wallet::before {
  content: "\e90c";
}
.icn-wishlist::before {
  content: "\e90e";
}
.icn-wishlist-delete::before {
  content: "\e90f";
}
.icn-user-bold::before {
  content: "\e910";
}
.icn-user::before {
  content: "\e948";
}
.icn-trash::before {
  content: "\e901";
}
.icn-upload::before {
  content: "\e912";
}
.icn-small-plus::before {
  content: "\e913";
}
.icn-sort::before {
  content: "\e914";
}
.icn-success-circle::before {
  content: "\e94a";
}
.icn-warranty::before {
  content: "\e947";
}
.icn-success-circle-bold::before {
  content: "\e915";
}
.icn-warning-circle::before {
  content: "\e90d";
}
.icn-close-circle::before {
  content: "\e938";
}
.icn-info-circle::before {
  content: "\e92b";
}
.icn-question-circle-bold::before {
  content: "\e91c";
}
.icn-question-circle::before {
  content: "\e94d";
}
.icn-close::before {
  content: "\e939";
}
.icn-small-close::before {
  content: "\e917";
}
.icn-shipping::before {
  content: "\e916";
}
.icn-search::before {
  content: "\e919";
}
.icn-saw-bold::before {
  content: "\e91a";
}
.icn-saw::before {
  content: "\e94b";
}
.icn-reload::before {
  content: "\e91b";
}
.icn-pin::before {
  content: "\e91d";
}
.icn-plus::before {
  content: "\e91e";
}
.icn-print::before {
  content: "\e91f";
}
.icn-minus::before {
  content: "\e920";
}
.icn-options::before {
  content: "\e921";
}
.icn-logout::before {
  content: "\e923";
}
.icn-mail::before {
  content: "\e924";
}
.icn-map::before {
  content: "\e925";
}
.icn-kebab::before {
  content: "\e926";
}
.icn-lightning::before {
  content: "\e927";
}
.icn-location::before {
  content: "\e928";
}
.icn-grid::before {
  content: "\e929";
}
.icn-hamburger::before {
  content: "\e92a";
}
.icn-geoposition::before {
  content: "\e92c";
}
.icn-filter::before {
  content: "\e92d";
}
.icn-file-data::before {
  content: "\e92e";
}
.icn-file-attach::before {
  content: "\e92f";
}
.icn-file::before {
  content: "\e930";
}
.icn-edit::before {
  content: "\e931";
}
.icn-eye-closed::before {
  content: "\e932";
}
.icn-eye-open::before {
  content: "\e933";
}
.icn-crane::before {
  content: "\e934";
}
.icn-delivery::before {
  content: "\e935";
}
.icn-download::before {
  content: "\e936";
}
.icn-comment-dots::before {
  content: "\e937";
}
.icn-clock::before {
  content: "\e93a";
}
.icn-cursor::before {
  content: "\e93b";
}
.icn-chevron-right::before {
  content: "\e93c";
}
.icn-chevron-up::before {
  content: "\e93d";
}
.icn-chevron-left::before {
  content: "\e93e";
}
.icn-check::before {
  content: "\e93f";
}
.icn-chevron-down::before {
  content: "\e940";
}
.icn-cart::before {
  content: "\e941";
}
.icn-arrow-left::before {
  content: "\e942";
}
.icn-arrow-right::before {
  content: "\e943";
}
.icn-calendar::before {
  content: "\e944";
}
.icn-bars::before {
  content: "\e945";
}
.icn-pdf::before {
  content: "\e950";
}
.icn-xls::before {
  content: "\e946";
}
.icn-trolley::before {
  content: "\e949";
}
.icn-request::before {
  content: "\e94c";
}
.icn-pipes::before {
  content: "\e94e";
}
.icn-pipe::before {
  content: "\e94f";
}
.icn-operator::before {
  content: "\e918";
}
.icn-offer::before {
  content: "\e952";
}
.icn-nail::before {
  content: "\e911";
}
.icn-layer::before {
  content: "\e954";
}
.icn-guillotine::before {
  content: "\e955";
}
.icn-gas::before {
  content: "\e956";
}
.icn-fittings::before {
  content: "\e957";
}
.icn-claim::before {
  content: "\e958";
}
.icn-calc-delivery::before {
  content: "\e959";
}
.icn-book::before {
  content: "\e95a";
}
.icn-min::before {
  content: "\e922";
}
.icn-big-truck::before {
  content: "\e902";
}
.icn-medium-truck::before {
  content: "\e903";
}
.icn-small-truck::before {
  content: "\e951";
}
.icn-crane-truck::before {
  content: "\e900";
}
.icn-calc::before {
  content: "\e953";
}
.icn-truck::before {
  content: "\e95b";
}
.icn-price::before {
  content: "\e95c";
}
.icn-paper::before {
  content: "\e95d";
}
.icn-small-clock::before {
  content: "\e95e";
}
.icn-metiz::before {
  content: "\e95f";
}
.icn-bars-big::before {
  content: "\e960";
}
.icn-credit-card::before {
  content: "\e961";
}
.icn-doc::before {
  content: "\e962";
}
.icn-jpg::before {
  content: "\e963";
}
.icn-pptx::before {
  content: "\e964";
}
