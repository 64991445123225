// The dropdown wrapper (`<div>`)
.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;

  // Generate the caret automatically
  @include caret();
}

.dropdown-arrow {
  display: inline-block;

  .dropdown-toggle[aria-expanded="true"] & {
    transform: rotate(180deg);
  }
}

// The dropdown menu
.dropdown-menu {
  @include font-size($dropdown-font-size);
  @include border-radius($dropdown-border-radius);
  @include box-shadow($dropdown-box-shadow);

  position: absolute;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  min-width: $dropdown-min-width;
  padding: 0;
  margin: 0; // Override default margin of ul
  color: $dropdown-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;

  &[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: $dropdown-spacer;
  }

  li:first-child a {
    padding-top: 21px;
  }

  li:last-child a {
    padding-bottom: 21px;
  }
}

// scss-docs-start responsive-breakpoints
// We deliberately hardcode the `bs-` prefix because we check
// this custom property in JS to determine Popper's positioning

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .dropdown-menu#{$infix}-start {
      --bs-position: start;

      &[data-bs-popper] {
        right: auto;
        left: 0;
      }
    }

    .dropdown-menu#{$infix}-end {
      --bs-position: end;

      &[data-bs-popper] {
        right: 0;
        left: auto;
      }
    }
  }
}
// scss-docs-end responsive-breakpoints

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(up);
  }
}

.dropend {
  .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(end);
    &::after {
      vertical-align: 0;
    }
  }
}

.dropstart {
  .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(start);
    &::before {
      vertical-align: 0;
    }
  }
}


// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  height: 0;
  margin: $dropdown-divider-margin-y 0;
  overflow: hidden;
  border-top: 1px solid $dropdown-divider-bg;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: 17px 16px;
  clear: both;
  font-weight: $font-weight-normal;
  line-height: 1rem;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  text-decoration: if($link-decoration == none, null, none);
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s

  // Prevent dropdown overflow if there's no padding
  // See https://github.com/twbs/bootstrap/pull/27703
  @if $dropdown-padding-y == 0 {
    &:first-child {
      @include border-top-radius($dropdown-inner-border-radius);
    }

    &:last-child {
      @include border-bottom-radius($dropdown-inner-border-radius);
    }
  }

  &:hover,
  &:focus {
    color: $dropdown-link-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    @include gradient-bg($gray-100);
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    pointer-events: none;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
  }
}

.dropdown-menu.show {
  display: block;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: $dropdown-header-padding;
  margin-bottom: 0; // for use with heading elements
  @include font-size($font-size-sm);
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}

// Dark dropdowns
.dropdown-menu-dark {
  color: $dropdown-dark-color;
  background-color: $dropdown-dark-bg;
  border-color: $dropdown-dark-border-color;
  @include box-shadow($dropdown-dark-box-shadow);

  .dropdown-item {
    color: $dropdown-dark-link-color;

    &:hover,
    &:focus {
      color: $dropdown-dark-link-hover-color;
      @include gradient-bg($dropdown-dark-link-hover-bg);
    }

    &.active,
    &:active {
      color: $dropdown-dark-link-active-color;
      @include gradient-bg($dropdown-dark-link-active-bg);
    }

    &.disabled,
    &:disabled {
      color: $dropdown-dark-link-disabled-color;
    }
  }

  .dropdown-divider {
    border-color: $dropdown-dark-divider-bg;
  }

  .dropdown-item-text {
    color: $dropdown-dark-link-color;
  }

  .dropdown-header {
    color: $dropdown-dark-header-color;
  }
}

.dropdown-filter {
  margin-right: 12px;

  &__counter {
    display: none;
    padding: 0 .3rem;
    font-size: $fs-s;
    color: $white;
    background-color: $secondary;
    border-radius: 100%;

    &_visible {
      display: inline;
    }
  }

  .dropdown-toggle {
    padding: .6rem 1rem .6rem .6rem;
    font-size: $fs-l;
    font-weight: $font-weight-normal;
    color: $dark;
    text-transform: initial;
    border: 1px solid $border-color;

    i {
      padding-left: .75rem;
      vertical-align: middle;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    min-width: 13.5rem;
    padding: 1.5rem 0;
    filter: drop-shadow(0 2px 16px rgba(0, 0, 0, .25));
    border-color: transparent;

    &::after {
      position: absolute;
      top: -8px;
      left: 50%;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0 .5rem .5rem;
      border-bottom-color: #fff;
      transform: translateX(-50%);
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .5rem 1.5rem;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        margin-bottom: .25rem;
      }

      &:hover {
        background-color: transparent;
      }

      .form-check {
        margin-bottom: 0;
      }

      .form-check-input[type="checkbox"]:hover {
        border-color: rgba($gray-400, .25);
      }

      &__count {
        font-size: $fs-m;
        color: $gray-600;
      }
    }

    .dropdown-line {
      width: 100%;
      height: 1px;
      background-color: $border-color;
    }

    .dropdown-btn {
      display: block;
      width: calc(100% - 3rem);
      padding: 11px 42px;
      margin: 1.5rem auto 0;
      font-size: $fs-m;
      font-weight: $font-weight-medium;
    }
  }
}

