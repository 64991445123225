.article-page {
  margin-bottom: 40px;

  @include media-breakpoint-up(md) {
    margin-bottom: 80px;
  }

  &__content-wrapper {
    max-width: 648px;
    margin: 16px auto 32px;

    img {
      @include size(100%, 354px);
      object-fit: cover;
      margin-bottom: 24px;
    }
  }

  p + p {
    margin-top: 10px;
  }

  h1 {
    margin-bottom: 24px;
    line-height: 44px;
  }

  h2 {
    margin-bottom: 16px;
  }

  &__date {
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: $fs-m;
    color: $gray-700;
  }

  &__back-link {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $gray-700;
    text-decoration: none;

    &:hover {
      color: $gray-700;
      text-decoration: none;
    }

    i {
      font-size: 20px;
    }

    span {
      padding-left: 8px;
    }
  }

  &__news-item {
    color: unset;
    text-decoration: none;

    &:hover {
      color: $orange;
      text-decoration: none;
    }

    img {
      @include size (100%, 170px);
      object-fit: cover;
    }
  }

  &__news-date {
    padding: 16px 0 10px;
    font-size: $fs-m;
    color: $gray-700;
  }

}
