.checkout-popup {
  padding: 40px;

  .popup-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
  }

  .popup-wrapper {
    margin-bottom: 30px;
  }
}

.popup-empty-basket {
  &__text {
    font-size: $fs-l;

    &:first-child {
      margin-bottom: 10px;
    }
  }

  @include media-breakpoint-up(lg) {
    .modal-dialog {
      width: 568px;
    }
  }
}

.popup-quick-order {
  @include media-breakpoint-up(lg) {
    .modal-dialog {
      width: 568px;
    }
  }

  &__consent {
    font-size: $fs-m;
  }
}

.popup-item-info {
  &__name {
    font-size: $fs-m;
    font-weight: 500;

    @include media-breakpoint-up(lg) {
      font-size: $fs-l;
    }
  }

  &__length {
    margin-top: 4px;
    font-size: $fs-m;
    color: $gray-700;

    @include media-breakpoint-up(md) {
      font-size: $fs-l;
    }
  }

  &__type {
    margin-left: 4px;
    font-size: $fs-m;
    font-weight: 500;

    &--mobile {
      font-family: $font-family-base;
      font-size: $fs-m;
      color: $gray-700;

      @include media-breakpoint-up(md) {
        font-size: $fs-l;
      }
    }
  }

  &__quantity {
    font-weight: 500;
  }
}

.popup-error {
  .modal-content {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }

  .modal-footer {
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      margin: 28px 70px 74px;
    }
  }

  img {
    @include size(64px);
    margin-bottom: 28px;
  }

  @include media-breakpoint-up(lg) {
    .modal-dialog {
      width: 540px;
    }

    .modal-body {
      margin: 74px 70px 0;
    }
  }
}

.popup-add-product {
  @include media-breakpoint-up(lg) {
    .modal-dialog {
      width: 568px;
    }
  }

  .nav-item a {
    font-weight: 500;
  }

  &__content {
    padding: 24px;
    border: 1px solid $gray-200;
  }

  &__title {
    display: inline-block;
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: $fs-l;
    font-weight: 500;
  }

  &__price {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(xl) {
      flex-direction: row;

      // stylelint-disable selector-class-pattern
      .cart__main-price,
      .cart__discount-price {
        width: 50%;
      }

      .cart__discount-price {
        margin-top: 10px;
      }

    }
  }

  &__main-price {
    flex-basis: 50%;
    font-size: 26px;
    font-weight: 500;
  }

  &__discount-price {
    flex-basis: 50%;
    margin-left: 16px;
    font-size: $fs-m;
  }

  &__discount-condition {
    margin-left: 5px;
    color: $gray-600;
  }

  &__calculator {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__total {
    @include media-breakpoint-up(md) {
      margin-left: 24px;
    }
  }

  &__total-price {
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-size: 26px;
    font-weight: 500;
    border-right: 1px solid $gray-300;
  }

  &__total-info {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    font-size: $fs-m;
    color: $gray-600;

    i {
      position: relative;
      top: 2px;
      font-size: 19px;
    }
  }
}

.popup-auth {
  @include media-breakpoint-up(lg) {
    .modal-dialog {
      width: 540px;
    }

    .modal-content {
      padding: 74px 72px 79px;
    }
  }

  form {
    margin: 16px 0 24px;

    @include media-breakpoint-up(lg) {
      margin-top: 28px 0;
    }
  }

  &__social {
    &-wrap {
      margin-top: 24px;

      @include media-breakpoint-up(lg) {
        margin-top: 32px;
      }
    }

    &-text {
      @include media-breakpoint-up(lg) {
        font-weight: 500;
      }
    }

    &-facebook {
      width: 27px;
    }

    &-google {
      width: 29px;
      margin-left: 16px;
    }
  }
}
