.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  @include font-size($breadcrumb-font-size);
  list-style: none;
  background-color: $breadcrumb-bg;
  @include border-radius($breadcrumb-border-radius);
}

.breadcrumb-item {
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  a {
    font-size: $breadcrumb-font-size;
    color: $gray-600;
    text-decoration: none;

    &:hover {
      color: $orange;
    }
  }

  + .breadcrumb-item {
    padding-left: $breadcrumb-item-padding-x;

    &::before {
      float: left; // Suppress inline spacings and underlining of the separator
      padding-right: $breadcrumb-item-padding-x;
      color: $breadcrumb-divider-color;
      content: var(--#{$variable-prefix}breadcrumb-divider, escape-svg($breadcrumb-divider)) #{"/* rtl:"} var(--#{$variable-prefix}breadcrumb-divider, escape-svg($breadcrumb-divider-flipped)) #{"*/"};
    }
  }

  &.active {
    color: $gray-400;
  }

  @include media-breakpoint-down(md) {
    display: none;

    + .breadcrumb-item {
      padding-left: 0;
    }

    &.active {
      display: block;
      color: $breadcrumb-divider-color;

      &::before {
        padding-right: .25rem;
        padding-left: 0;
        font-family: $font-family-icon;
        content: "\e93e";
      }
    }
  }
}
