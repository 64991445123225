//
// Base styles
//

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  font-size: 1rem;
  font-weight: $font-weight-medium;
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $alert-dismissible-padding-r;

  // Adjust close link position
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $stretched-link-z-index + 1;
    padding: $alert-padding-y * 1.25 $alert-padding-x * 1.25;
  }
}


// scss-docs-start alert-modifiers
@each $state, $value in $alert-theme-colors {
  $alert-background: $value;
  $alert-border: map_get($alert-border-colors, #{$state});
  $alert-color: $black;

  .alert-#{$state} {
    @include alert-variant($alert-background, $alert-border, $alert-color);
    svg {
      fill: map_get($alert-icon-colors, #{$state});
    }
  }
}
// scss-docs-end alert-modifiers
