.branches-page {
  margin-bottom: 4rem;

  section {
    margin-bottom: 2.5rem;
  }

  &__subtitle {
    margin-bottom: 1.5rem;
  }

  &__nav {
    margin-bottom: 1.5rem;

    .nav-link {
      font-size: $fs-l;
      font-weight: 500;
    }
  }

  // Contacts
  &-contacts {
    .table {
      td {
        @include media-breakpoint-up(sm) {
          width: 25%;

          &:first-child {
            width: 18%;
          }
        }

        @include media-breakpoint-up(lg) {
          &:first-child {
            width: 25%;
          }
        }
      }

      &-mobile {
        &__wrap {
          margin-bottom: 2.5rem;

          > div:not(:last-child) {
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid $border-color;
          }
        }

        &__title {
          color: $gray-600;
        }
      }
    }
  }

  // Employees
  .employees {
    &-item {
      display: flex;
      flex-direction: column;
      margin-top: .75rem;

      &__name {
        margin-bottom: .5rem;
      }

      &__position,
      &__email,
      &__phone {
        font-size: $fs-m;
      }

      &__position,
      &__phone {
        color: $gray-700;
      }
    }
  }

  // Requisites
  .requisites {
    &__item {
      margin-bottom: 1rem;
    }
  }

  // Services
  .services {
    &__wrap {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &-card {
      @include media-breakpoint-up(md) {
        min-width: 330px;
        min-height: 444px;
        padding: 1.5rem 2rem;
        border: 1px solid $border-color;

        &:not(:first-child):not(:nth-child(2n+1)) {
          border-left: none;
        }

        &:not(:first-child):not(:nth-child(2)) {
          border-top: none;
        }
      }

      @include media-breakpoint-up(xl) {
        &:not(:first-child):not(:nth-child(2)) {
          border-top: 1px solid $border-color;
        }

        &:not(:first-child) {
          border-left: none;
        }
      }

      img {
        width: 100%;
        margin-bottom: 1rem;
      }

      &__item {
        display: flex;
        align-items: baseline;
        margin-bottom: .75rem;
      }

      &__name {
        margin-bottom: 1rem;
      }

      &__link {
        display: flex;
        flex-direction: column;
      }

      &__price {
        white-space: nowrap;
      }

      &__dots {
        flex-grow: 1;
        margin-right: .25rem;
        margin-left: .25rem;
        border-bottom: 1px dotted $border-color;
      }
    }
  }
}
