.cart-empty {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 64px 0 80px;

  @include media-breakpoint-up(md) {
    padding: 98px 0 83px;
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 0 16px;

    img {
      @include size(270px, 130px);
      z-index: 2;
      max-width: 100%;
      margin-left: 18px;

      @include media-breakpoint-down(sm) {
        @include size(200px, 96px);
        max-width: none;
        margin-left: 0;
        object-fit: cover;
      }

      @include media-breakpoint-up(md) {
        @include size(425px, 188px);
        margin-left: 0;
      }
    }
  }

  &__yellow {
    @include size(256px, 27px);

    position: absolute;
    top: 98px;
    left: -1px;
    background-color: $yellow;

    @include media-breakpoint-down(sm) {
      @include size(185px, 27px);

      top: 69px;
      left: -1px;
    }

    @include media-breakpoint-up(md) {
      @include size(474px, 48px);

      top: 140px;
      left: -107px;
    }

    @include media-breakpoint-up(xl) {
      @include size(572px, 48px);

      top: 140px;
      left: -205px;
    }
  }

  &__orange {
    @include size(16.5px, 34px);

    position: absolute;
    top: 64px;
    left: 255px;
    background-color: $orange;

    @include media-breakpoint-down(sm) {
      top: 35px;
      left: 184px;
    }

    @include media-breakpoint-up(md) {
      @include size(29px, 60px);

      top: 80px;
      left: 367px;
    }
  }

  &__red {
    @include size(47px, 5px);

    position: absolute;
    top: 40px;
    left: 271px;
    background-color: $red;

    @include media-breakpoint-down(sm) {
      top: 21px;
      left: 201px;
    }

    @include media-breakpoint-up(md) {
      @include size(111px, 9px);

      top: 45px;
      left: 395px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    max-width: 155px;
    margin: 32px 0 24px;

    @include media-breakpoint-up(md) {
      max-width: none;
    }
  }
}
