.delivery-page {
  .delivery-address {
    .link-button {
      color: $orange;
      cursor: pointer;

      @include media-breakpoint-up(xl) {
        // stylelint-disable-next-line
        margin-top: auto !important;
        margin-bottom: 25px;
      }
    }
  }

  &__alert {
    i {
      margin-right: 5px;
    }

    a {
      margin-left: 5px;
      // stylelint-disable-next-line
      color: inherit !important;
      // stylelint-disable-next-line
      text-decoration: none !important;
      letter-spacing: 1px;
      white-space: nowrap;
    }
  }
}

.balloon-address {
  display: none;
  padding: 50px;
  background-color: #fff;
}

.custom-suggestions {
  position: absolute;
  top: 41px;
  display: none;
  width: 100%;
  padding: 10px 0;
  font-family: $font-family-base;
  font-weight: $font-weight-light;
  background-color: $white;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, .3), 0 1px 7px 2px rgba(0, 0, 0, .03);

  &__label {
    display: block;
    padding: 0 10px 10px;
    font-family: inherit;
    font-size: $fs-l;
    font-weight: inherit;
    color: $gray-600;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: block;
      padding: 5px 12px;
      cursor: pointer;

      &:hover {
        background-color: $yellow-400;
      }

      span {
        color: $orange;
      }
    }
  }
}

.balloon-map {
  position: absolute;
  top: 120px;
  left: 10px;
  z-index: 50;
  max-width: 382px;
  padding: 25px;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 0 32px -8px rgba(0, 21, 51, .1);

  @include media-breakpoint-down(md) {
    top: auto;
    bottom: 0;
    left: auto;
    width: 100%;
    max-width: 100%;
  }

  &__address {
    display: block;
    padding-bottom: 20px;
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
  }
}
