@import "../node_modules/air-datepicker/src/sass/datepicker";
@import "../node_modules/air-datepicker/src/sass/datepicker-config";
@import "../node_modules/air-datepicker/src/sass/timepicker";
@import "../node_modules/air-datepicker/src/sass/cell";
@import "../node_modules/air-datepicker/src/sass/navigation";


.custom-datepicker {
  @include size(310px, auto);
  border-color: $white;

  .datepicker {
    font-family: $font-family-base;

    &--pointer {
      display: none;
    }

    &--cell {
      &-day {
        width: 14.2857142857%;
        font-family: $font-family-base;
      }

      &-month {
        font-family: $font-family-base;
      }

      &-year {
        font-family: $font-family-base;
      }
    }

    &--nav {
      @include size(auto, 40px);
      position: relative;
      margin: 15px 0;
      border-color: $white;
    }

    &--nav-action {
      position: absolute;
      width: 20px;

      path {
        stroke: $color-contrast-dark;
      }
    }

    &--nav-action:hover {
      background: $white;
    }

    &--nav-action:first-child {
      right: 35px;
    }

    &--nav-action:last-child {
      right: 15px;
    }

    &--day-name {
      font-family: $font-family-base;
      font-size: 14px;
      color: $color-contrast-dark;
    }

    &--nav-title {
      font-family: $font-family-base;
      font-size: $fs-l;
      font-weight: 500;
    }

    &--nav-title i {
      font-family: $font-family-base;
    }

    &--button {
      flex: inherit;
      padding: 0 24px;
      color: $color-contrast-dark;
      background: $white;
    }

    &--buttons {
      display: flex;
      justify-content: space-around;
      margin: 10px 0 30px;
      border-color: $white;
    }

    &__accept-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      font-family: $font-family-base;
      font-size: 13px;
      font-weight: $font-weight-medium;
      color: $white;
      text-transform: uppercase;
      cursor: pointer;
      background: $orange;
      border-radius: 0;
    }

    &__controls-wrap {
      display: flex;
      justify-content: space-between;
      margin: 25px 10px 0;
    }

    &__control {
      text-align: center;
    }

    &__control-wrap {
      width: 100%;
      padding: 7px 18px;
      margin-right: 8px;
      font-family: $font-family-base;
      font-size: 13px;
      font-weight: $font-weight-medium;
      color: $black;
      text-transform: uppercase;
      cursor: pointer;
      border: 1px solid $gray-300;

      &:hover {
        color: $white;
        background: $orange;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}


.date-picker {
  @include size(312px,40px);
  padding-left: 12px;
  background: $white;
  border: 1px solid $gray-300;

  &:focus {
    border: 1px solid $orange;
    outline: $orange;
  }
}

/* stylelint-disable */
.datepicker {
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, .3), 0 1px 7px 2px rgba(0, 0, 0, .03) !important;
  transform: translateY(-10px) !important;

  & .-focus- {
    background: #FDE3AB;
  }

  & .-selected-.-focus- {
    background: #FAB82E;
  }


  & .-current- {
    color: black !important;
  }

  & .-range-from- {
    border-color: #FBE4D1;
    background: #FDE3AB;
  }

  & .-range-to- {
    border-color: #FBE4D1;
    background: #FDE3AB;
  }

  &--cell.-in-range- {
    background: #FDE3AB;
  }

  & .-selected-.-current- {
    background: #FAB82E;
    color: black;
  }

  &--cell {
    border-radius: 0 !important;
  }

  &--nav-action.-disabled- {
    visibility: inherit;
  }

  & .-selected- {
    background: #FAB82E;
    color: black;
  }

  &--button {
    font-family: $font-family-base;
  }
}

/* stylelint-enable */
