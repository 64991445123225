.warehouse-popup {
  padding: 40px;

  .popup-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
  }

  .popup-wrapper {
    margin-bottom: 30px;
  }
}

.popup-warehouse {
  .modal-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  .modal-content {
    padding: 0;
  }

  &__map {
    @include media-breakpoint-down(md) {
      order: 2;
      // stylelint-disable-next-line declaration-no-important
      height: calc(100vh - 150px) !important;
    }

    @include media-breakpoint-up(lg) {
      width: calc(100% - 360px);
      // stylelint-disable-next-line declaration-no-important
      opacity: 1 !important;
    }

    &--full {
      width: 100%;
      padding-top: 70px;
    }

    img {
      @include size(100%);
      object-fit: cover;
    }
  }

  &__list {
    &-wrap {
      width: 100%;
      box-shadow: 0 1px 3px rgba(0, 21, 51, .05), 0 0 32px -8px rgba(0, 21, 51, .1);

      @include media-breakpoint-up(lg) {
        width: 360px;
      }
    }

    &-title {
      padding: 24px 16px 0;

      @include media-breakpoint-up(md) {
        padding: 44px 32px 0;
      }

      @include media-breakpoint-up(lg) {
        padding: 32px 24px 0;
      }

      &_text {
        margin-bottom: 16px;
        font-size: $fs-xl;
        font-weight: 500;
      }
    }
  }

  &__nav {
    @include media-breakpoint-down(md) {
      border-bottom: 1px solid $border-color;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__nav-item {
    padding: 10px 0 12px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;

    &:first-child {
      margin-right: 24px;
    }

    &:hover {
      color: $orange-hover;
    }

    &.active,
    &:hover {
      border-bottom: 2px solid $orange;
    }
  }

  &__search {
    @include media-breakpoint-down(md) {
      display: none;
    }

    &-wrap {
      padding: 16px;
      border-bottom: 1px solid $border-color;

      @include media-breakpoint-between(md, lg) {
        border-top: 1px solid $border-color;
      }

      @include media-breakpoint-up(md) {
        padding: 16px 32px;
      }

      @include media-breakpoint-up(lg) {
        padding: 16px 24px;
      }

      i {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
        color: $gray-600;
      }
    }
  }

  &__warning {
    padding: 24px 16px 16px;
    background: $gray-100;
    border-bottom: 1px solid $border-color;

    @include media-breakpoint-up(md) {
      padding: 24px 32px 16px;
    }

    @include media-breakpoint-up(lg) {
      padding: 24px 24px 16px;
    }

    &-title {
      margin-bottom: 10px;
      font-size: $fs-xl;
      font-weight: 500;
    }

    i {
      font-size: 20px;
    }

    span {
      padding-left: 6px;
      font-size: $fs-m;
    }
  }

  &__item {
    position: relative;
    padding: 24px 36px 24px 16px;
    cursor: pointer;
    border-bottom: 1px solid $border-color;

    &::after {
      position: absolute;
      top: calc((100% - 20px) / 2);
      right: 8px;
      font-family: EvrazIcon, serif;
      font-size: 20px;
      color: $orange;
      content: "\e93c";
    }

    @include media-breakpoint-up(md) {
      padding-left: 32px;
    }

    @include media-breakpoint-up(lg) {
      padding: 24px 36px 24px 24px;
    }

    &-wrap {
      height: calc(100vh - 180px);
      overflow: auto;

      @include media-breakpoint-up(md) {
        height: calc(100vh - 200px);
      }

      @include media-breakpoint-up(lg) {
        height: calc(100vh - 150px);
      }

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-300;
        border: 3px solid $white;
        border-radius: 6px;
      }
    }

    &-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      font-weight: 500;

      span {
        white-space: nowrap;
      }

      span + span {
        margin-left: 16px;
      }
    }

    &-address {
      margin-bottom: 8px;
      line-height: 1.4;
    }

    &-status {
      display: flex;
      align-items: center;

      i {
        padding-right: 6px;
        font-size: 20px;
      }
    }
  }

  &__info {
    padding: 16px;

    @include media-breakpoint-up(md) {
      padding: 44px 32px;
    }

    @include media-breakpoint-up(lg) {
      padding: 32px 24px 52px;
    }

    &-back {
      display: flex;
      align-items: center;
      color: $gray-600;
      cursor: pointer;

      i {
        padding-right: 6px;
        font-size: 20px;
      }
    }

    &-item {
      flex-grow: 1;
      margin-left: 8px;
    }

    &-warning {
      display: flex;
      padding: 16px;
      background: $yellow-200;
      border: 1px solid $yellow-400;

      i {
        margin-right: 8px;
        font-size: 20px;
      }
    }

    &-wrap {
      display: flex;
      margin: 16px 0;

      @include media-breakpoint-up(lg) {
        margin: 32px 0 24px;
      }
    }
  }

  &__details {
    font-size: $fs-m;

    &-label {
      margin: 16px 0 8px;
      font-weight: 500;
    }

    &-product {
      width: 50%;
    }

    &-col {
      width: 65px;

      &:first-child {
        width: 80px;
      }
    }

    &-title {
      display: flex;
      padding: 8px 0;

      &.gray {
        color: $gray-700;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }
  }
}
