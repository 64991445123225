.total-block {
  padding: 32px 16px;
  margin: 24px 0 0;
  color: $dark;
  background-color: $white;

  @include media-breakpoint-up(md) {
    padding: 32px;
    margin-bottom: 24px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  &__wrapper {
    position: sticky;
    top: 32px;
  }

  &__title {
    margin-bottom: 24px;
    font-size: 20px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 26px;
    }
  }

  &__item {
    display: flex;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 24px;
    }

    &-wrap {
      margin-bottom: 32px;
    }

    &-title {
      margin-bottom: 0;
    }

    &-dots {
      flex-grow: 1;
      margin-right: 4px;
      margin-left: 4px;
      border-bottom: 1px dotted $border-color;
    }

    &-price {
      margin-bottom: 0;
      font-weight: 500;
    }

    &-info {
      font-size: $fs-m;
      color: $gray-700;
    }
  }

  &__btn {
    width: 100%;
    min-height: 56px;
    padding: 16px 32px;
    margin-bottom: 0;
    font-size: $fs-l;
    white-space: normal;

    @include media-breakpoint-up(md) {
      margin-bottom: 24px;
    }
  }

  &__quick-order {
    width: fit-content;
    margin: 0 auto;
    border-bottom: 2px dotted $border-color;

    &:hover {
      cursor: pointer;
    }
  }

  &__hint {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 32px;
    background-color: $white;

    @include media-breakpoint-down(md) {
      top: -32px;
    }

    i {
      font-size: 32px;
    }

    span {
      padding-left: 24px;
      font-size: $fs-m;
    }
  }

  &__consent {
    margin-bottom: 24px;
    font-size: $fs-m;

    @include media-breakpoint-between(md, lg) {
      text-align: center;
    }
  }

  &__callback {
    margin-bottom: 0;

    @include media-breakpoint-between(md, lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
    }
  }

  .form-check-label {
    font-size: $fs-m;
  }
}
