.appeal {
  &__popup-content {
    min-width: 450px;

    @include media-breakpoint-down(md) {
      min-width: initial;
    }
  }

  &__arrow {
    top: 10px;
  }

  &__title {
    margin-bottom: 24px;
    font-size: 26px;
    font-weight: $font-weight-medium;
    text-align: initial;
  }

  &__select {
    margin-bottom: 24px;
  }

  &__textarea {
    margin-bottom: 24px;

    &--mod {
      margin-bottom: 0;
    }
  }

  &__info {
    margin-top: 8px;
    font-size: 14px;
    color: #565655;
  }

  &__btn {
    padding: 11px 24px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    background: $orange;
    border: 2px solid $orange;

    @include media-breakpoint-down(lg) {
      @include size(100%, auto);
    }

    &--mod {
      @include media-breakpoint-down(lg) {
        margin-top: 10px;
      }
    }

    &-wrap {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;

      &--mod {
        justify-content: space-between;

        @include media-breakpoint-down(lg) {
          flex-direction: column;
        }
      }
    }
  }

  &__bold {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: $font-weight-medium;
    text-align: start;
  }

  &__light {
    margin-top: 8px;
    font-size: 14px;
    font-weight: initial;
    color: #868786;
    text-align: start;
  }

  &-page {
    &__title {
      @include media-breakpoint-down(md) {
        padding: 0 10px;
      }
    }

    &__btn {
      @include size(fit-content);
      padding: 11px 24px;
      font-size: 13px;
      font-weight: $font-weight-medium;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      background: $orange;
      border: 2px solid $orange;

      @include media-breakpoint-down(lg) {
        @include size(100%, auto);
      }

      &-wrap {
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
          padding: 0 10px;
        }
      }
    }

    &__appeals {
      border-bottom: 1px solid $gray-300;
    }

    &__container {
      margin-bottom: 60px;
    }

    &__wrap {
      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }
  }

  &__load {
    &-file {
      display: flex;
      align-items: center;
      font-size: 16px;
      cursor: pointer;

      &-img {
        margin-right: 8px;
      }

      &-text {
        border-bottom: 1px dashed $black;
      }
    }
  }
}

.single-appeal {
  display: flex;
  padding: 12px 20px;
  border-top: 1px solid $gray-300;

  &__icon {
    @include size(48px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 28px;
    background: $yellow;
    border-radius: 24px;

    &-wrap {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &--green {
      background: $green;
    }
  }

  &__main {
    &-light {
      margin-bottom: 4px;
      font-size: 14px;
      color: #868786;
    }

    &-title {
      margin-bottom: 4px;
      font-weight: $font-weight-medium;
    }

    &-text {
      margin-bottom: 4px;
    }

    &-info {
      &-title {
        position: relative;
        width: fit-content;
        padding-right: 15px;
        color: $orange;
        cursor: pointer;
      }

      &-content {
        padding-left: 16px;
        margin-top: 8px;
        border-left: 1px solid $gray-400;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-text {
      width: fit-content;
      padding: 2px 8px;
      color: $green;
      text-align: center;
      background: #e7f0e6;
      border: 1px solid $green-400;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    &-file {
      display: flex;
      margin-top: 30px;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        margin-top: 10px;
      }

      &-icon {
        margin-right: 5px;
      }

      &-name {
        line-height: 140%;
        color: $gray-400;
      }
    }

    @include media-breakpoint-down(md) {
      align-items: flex-start;
      margin-top: 10px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__arrow {
    @include size(8px);
    position: absolute;
    top: 5px;
    right: 0;
    border-bottom: 2px solid $orange;
    border-left: 2px solid $orange;
    transition-duration: .2s;
    transform: rotate(-45deg);
  }

  &--green {
    background: #e7f0e6;
  }
}
