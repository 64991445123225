.city-list {
  ul {
    margin: 0;
    list-style-type: none;
  }

  &__parent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 480px;
    padding-left: 0;
    overflow: hidden;
    list-style-type: none;

    @include media-breakpoint-down(lg) {
      max-height: 800px;
      overflow-y: auto;
    }

    @include media-breakpoint-down(md) {
      flex-wrap: nowrap;
    }

    > li {
      position: relative;

      > span {
        position: absolute;
        top: 0;
        left: 0;
        font-size: $fs-m;
        font-weight: 500;
        color: $gray-600;
        text-transform: uppercase;
      }
    }
  }

  &__inner {
    padding-left: 20px;
    margin: 0;
    list-style-type: none;

    li {
      margin-bottom: 10px;
    }
  }

  //TODO: Refactor this code

  // stylelint-disable
  &.special {

    .city-list__parent {
      columns-count: 4;
    }

    .city-list__inner {
      li {
        margin-bottom: 16px;
      }
    }
  }
  // stylelint-enable
}
