.news-card {
  padding: 24px 12px;

  img {
    width: 100%;
    margin-bottom: 16px;
  }

  .date {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
    color: $gray-700;
  }
  .title {
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    color: $gray-900;
  }
}
