.buyout-form {
  &__content {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__left {
    flex-basis: 25%;
    margin-right: 35px;

    &-elem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      text-transform: uppercase;
      cursor: pointer;
      border-left: 4px solid $white;

      &--active {
        color: $orange;
        border-left: 4px solid $orange;

        div {
          border-color: $orange;
        }
      }

      &-arrow {
        @include size(10px, 10px);
        border-top: 2px solid $gray-900;
        border-left: 2px solid $gray-900;
        transform: rotate(135deg);

        &--mod {
          margin-top: 5px;
          margin-left: 12px;
          transition-duration: .2s;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__right {
    flex-basis: 75%;
    margin-bottom: 80px;
    margin-left: 35px;

    &-elem {
      display: none;

      @include media-breakpoint-down(md) {
        margin-top: 15px;
      }

      &--active {
        display: block;
      }
    }

    &-title {
      margin-bottom: 25px;
      font-size: 26px;
      font-weight: $font-weight-medium;

      &-big {
        margin-bottom: 35px;
        font-size: 32px;
      }
    }

    &-subtitle {
      margin-bottom: 15px;
      font-size: $fs-l;
      line-height: 1.375;
    }

    &-item {
      margin-bottom: 25px;
      font-size: $fs-l;
      line-height: 140%;
      color: $gray-900;

      &-link {
        color: $gray-900;
        text-decoration: none;
        border-bottom: 1px solid $yellow;
      }
    }

    &-info {
      font-size: $fs-m;
      line-height: 140%;
      color: $gray-700;
    }

    &-tabs {
      display: flex;
      margin-bottom: 40px;
    }

    &-tab {
      padding: 11px 16px;
      margin-right: 16px;
      font-size: 13px;
      font-weight: $font-weight-medium;
      color: $gray-900;
      text-transform: uppercase;
      cursor: pointer;
      border: 1px solid $gray-300;

      &--active {
        border: 1px solid $orange;
      }
    }
  }

  &__first-part {
    display: flex;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  &__form {
    &-left {
      flex-basis: 55%;
      margin-right: 30px;
    }

    &-input-title {
      margin-bottom: 15px;
      font-weight: $font-weight-medium;
    }

    &-right {
      flex-basis: 35%;
      margin-left: 30px;

      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }

      &-input {
        display: none;
      }

      &-title {
        margin-bottom: 8px;
        font-size: 26px;
        font-weight: $font-weight-medium;
      }

      &-label {
        margin-left: 6px;
        border-bottom: 1px dashed $gray-900;

        &-wrap {
          display: flex;
          align-items: center;
        }
      }

      &--first {
        @include media-breakpoint-down(lg) {
          margin-top: 40px;
        }
      }
    }
    /* stylelint-disable */

    & .buyout-form__form-right--first {
      display: none;
    }

    &--table {

      & .buyout-form__form-right--first {
        display: block;
      }

      & .buyout-form__second-part {
        display: none;
      }

      & .buyout-form__btn--table {
        display: block;
      }
    }
    /* stylelint-enable */
  }

  &__input {
    @include size(100%, 40px);
    padding: 5px;
    border: 1px solid $gray-300;
    outline: $white;

    &-title {
      margin-bottom: 8px;
      font-size: $fs-l;
      font-weight: $font-weight-medium;
      color: $gray-900;
    }

    &-wrap {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 15px;

      &--right {
        margin-right: 10px;
      }

      &--left {
        margin-left: 10px;

        @include media-breakpoint-down(lg) {
          margin-left: 0;
        }
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }
  }

  &__btn {
    padding: 11px 24px;
    margin-top: 25px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    color: $white;
    text-transform: uppercase;
    background: $orange;
    border: 0;

    &--table {
      display: none;

      @include media-breakpoint-down(lg) {
        display: block;
      }
    }

    &--form {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  &__second-part {
    margin-top: 40px;
  }

  &__products-block {
    display: flex;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  &__product-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: $font-weight-medium;
    line-height: 140%;
    color: $gray-900;
    cursor: pointer;

    /* stylelint-disable */
    &--active {
      & .buyout-form__left-elem-arrow {
        transform: rotate(225deg);
        margin-top: -5px;
      }
    }
    /* stylelint-enable */
  }

  &__product-template {
    display: none;
  }

  &__product-add {
    @include size(fit-content, auto);
    display: flex;
    cursor: pointer;

    &-text {
      margin-left: 10px;
      font-size: $fs-l;
      color: $orange;
    }

    &-icon {
      @include size(12px, auto);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__product-input {
    display: none;

    &--active {
      display: block;
    }
  }

  &__product-delete {
    @include size(fit-content, auto);
    display: none;
    margin-top: 10px;
    font-size: $fs-l;
    color: $gray-600;
    cursor: pointer;

    &-icon {
      @include size(12px, auto);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -2px;
      margin-right: 12px;
      transform: rotate(45deg);
    }
  }

  &__product-move-container {
    margin-bottom: 15px;
  }

  &__plus {
    @include size(2px, 12px);
    background: $orange;

    &--horizont {
      position: absolute;
      transform: rotate(90deg);
    }

    &--grey {
      background: $gray-600;
    }
  }

  &__table-title {
    margin-bottom: 16px;
  }

  &__upload-wrap {
    display: flex;
    align-items: center;
  }

  &__upload-container {
    margin-top: 30px;
  }

  &__products-wrap {
    /* stylelint-disable */
    &--delete {
      & .buyout-form__product-delete {
        display: flex;
      }
    }
    /* stylelint-enable */
  }

  &__uploaded {
    &-template {
      display: none;
    }

    &-info {
      font-size: $fs-m;
      color: $gray-600;
      text-transform: uppercase;
    }

    &-container {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 20px;
    }
  }
}
