.delivery-table {
  padding-bottom: 10px;

  &__header {
    @include size(100%, 38px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    color: $gray-600;
    border: 1px solid $gray-300;
    border-right: none;
    border-left: none;
  }

  &__header-item-type,
  &__header-item-icon {
    width: 200px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 10px 0 20px;
    border-bottom: 1px solid $border-color;
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__subtitle {
    font-family: $font-family-base;
    font-size: $fs-m;

    &--fz-base {
      font-size: $font-size-base;
    }
  }

  &__icon {
    margin: 5px 0 5px -6px;

    &--big {
      font-size: rem(32px);
    }
  }

  &__label {
    color: $gray-600;
  }

  &__value {
    font-weight: 500;
  }

  @include media-breakpoint-up(md) {
    border-bottom: 1px solid $border-color;

    &__item {
      @include size(100%, 60px);
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      padding-right: 20px;
      border-bottom: none;

      span {
        margin: 0;
      }
    }

    &__icon {
      order: 1;
      width: 200px;
      margin: 0;
      margin-top: 12px;
    }

    &__title {
      order: 0;
      width: 200px;
    }

    &__subtitle {
      font-family: $font-family-base;
    }

    &__value {
      order: 2;
    }

    &__city {
      order: 3;
    }

    &__country {
      order: 4;
    }
  }
}
