.document,
.product-document {
  display: flex;
  min-height: 45px;
  margin-bottom: 20px;
  color: $gray-900;
  text-decoration: none;
  cursor: pointer;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
  }

  &__name {
    margin-bottom: 4px;
    font-size: $fs-l;
    cursor: pointer;
  }

  &__size {
    font-size: $fs-l;
    color: $gray-600;

    &--small {
      font-size: $fs-m;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    font-size: 32px;
    color: $gray-900;
  }
}
