$slider-background: $yellow-200 !default;

.main-page {
  .slider{
    background-color: $slider-background;

    @include media-breakpoint-up(lg) {
      border-bottom: 2px solid $primary;
    }

    .carousel {
      height: 320px;

      @include media-breakpoint-up(md) {
        height: 280px;
      }

      @include media-breakpoint-up(lg) {
        position: relative;
        width: 100%;
      }

      &__main-button {
        @include size(fit-content, 40px);
        margin-top: 20px;
        font-size: $fs-m;
        font-weight: $font-weight-medium;
        text-decoration: none;
        border-color: $gray-900;
      }

      &__main {
        @include size(100%);
        display: flex;
        flex-direction: column;
        padding: 30px 0 48px;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 50px 0 48px;
        }

        @include media-breakpoint-up(xl) {
          padding: 0;
        }

        &-info {
          display: flex;
          flex-basis: 50%;
          flex-direction: column;
          align-self: baseline;
          justify-content: center;

          @include media-breakpoint-up(xl) {
            align-self: flex-start;
            padding-top: 46px;
          }
        }

        &-img {
          display: flex;
          flex-basis: 50%;
          align-self: center;

          @include media-breakpoint-up(md) {
            align-self: flex-end;
          }

          @include media-breakpoint-up(xl) {
            align-self: flex-start;
            justify-content: center;
          }

          img {
            align-self: flex-end;
            max-width: 300px;
            max-height: 103px;

            @include media-breakpoint-up(md) {
              max-width: 100%;
              max-height: initial;
            }
          }
        }
      }

      &__title {
        margin-bottom: 0;
        color: $gray-900;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
          margin-bottom: 6px;
        }
      }

      &__subtitle {
        font-family: $font-family-base;
        font-size: $fs-m;
        color: $gray-900;

        @include media-breakpoint-up(md) {
          font-size: $fs-l;
        }

        @include media-breakpoint-up(xl) {
          font-size: rem(20px);
        }
      }
    }

    .carousel-indicators {
      bottom: -2.5rem;

      @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin: 0 auto;

        [data-bs-target] {
          flex: 1 1 auto;
          height: 50px;
          margin: 0;
          text-indent: initial;
          background-color: $white;
          border: none;
          box-shadow: 0 10px 30px rgba(79, 105, 118, .1);
          opacity: 1;

          &::after {
            display: none;
          }

          &:not(:last-child) {
            border-right: 1px solid $light-gray;
          }
        }

        .active {
          height: 60px;
          background-color: $primary;
          box-shadow: 0 5px 20px rgba(81, 81, 81, .2);

          &:not(:last-child) {
            border-right: none;
          }
        }
      }

      &__item {
        display: none;

        span {
          margin-left: 10px;
          font-weight: $font-weight-medium;
        }

        i {
          font-size: 32px;
        }

        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          span {
            font-size: $fs-m;
          }
        }

        @include media-breakpoint-up(xl) {
          span {
            font-size: $fs-l;
          }
        }

        &--mobile {
          @include size(100%, 48px);
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $primary;

          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
      }
    }
  }

  .price-update-info {
    padding-top: 46px;

    @include media-breakpoint-up(md) {
      padding-top: 40px;
    }

    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }

  .main-card {

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include media-breakpoint-up(lg) {
        text-align: center;
      }
    }

    &__img {
      width: 100%;
      height: 120px;
      margin-top: 15px;
      overflow: hidden;

      img {
        min-width: 100%;
        min-height: 100%;
      }
    }

    &__button {
      @include size(100%, 40px);
      font-size: 13px;

      @include media-breakpoint-up(lg) {
        width: fit-content;
        padding-right: 20px;
        padding-left: 30px;
        margin: auto;
      }
    }

    &__price {
      ul {
        padding-left: 0;
      }

      margin-top: 10px;
    }

    &__price-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 0;

      span {
        font-size: $fs-m;

        @include media-breakpoint-up(lg) {
          font-size: $fs-l;
        }
      }

      span:first-child {
        position: relative;
        //display: inline-block;

        //&::before {
        //  position: absolute;
        //  bottom: -4px;
        //  left: 0;
        //  width: 100%;
        //  height: 2px;
        //  content: "";
        //  background-color: $primary;
        //}
      }

      span:last-child {
        min-width: fit-content;
        font-weight: $font-weight-medium;
      }
    }

    &__price-separator {
      position: relative;
      top: 4px;
      flex-grow: 1;
      height: 2px;
      margin: 0 5px;
      border-bottom: 1px dotted $gray-400;
    }
  }

  .title-block {
    margin-bottom: 40px;

    &__subtitle {
      font-size: 20px;
    }

    @include media-breakpoint-up(md) {
      text-align: center;

      h2 {
        font-size: 40px;
      }

      &__tabs {
        display: flex;
        justify-content: center;
      }
    }
  }

  .nav-link:focus {
    box-shadow: none;
  }

  .nav-link {
    font-size: $fs-m;
    font-weight: $font-weight-medium;
  }

  .steps-block-container {
    .steps-block {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .steps-note {
    font-size: $font-size-sm;

    @include media-breakpoint-up(md) {
      font-size: 12px;
    }

    @include media-breakpoint-up(lg) {
      font-size: $font-size-sm;
    }
  }

  .step {
    width: calc(100% - 25px);

    @include media-breakpoint-up(md) {
      min-width: calc(33.333% - 16px);
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      min-width: calc(33.333% - 24px);
      margin-left: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &__rect {
      @include size(100%, 120px);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: start;
      padding: 20px;
      margin-top: 10px;

      @include media-breakpoint-up(md) {
        span {
          font-size: 14px;
        }

        h3 {
          font-size: 18px;
        }
      }

      @include media-breakpoint-up(lg) {
        span {
          font-size: $font-size-base;
        }

        h3 {
          font-size: 20px;
        }
      }

      @include media-breakpoint-up(xxl) {
        span {
          font-size: 20px;
        }

        h3 {
          font-size: 26px;
        }
      }

      &-title {
        font-family: $font-family-base;
      }

      &--primary {
        background-color: $primary;
      }

      &--secondary {
        color: $white;
        background-color: $secondary;

        h3 {
          color: $white;
        }
      }

      &--red {
        height: 144px;
        color: $white;
        background-color: $red;

        h3 {
          color: $white;
        }

        @include media-breakpoint-up(md) {
          height: 120px;
        }
      }
    }

    &__button {
      @include size(100%, 40px);
      font-size: 13px;

      span {
        padding-left: 4px;
      }

      @include media-breakpoint-up(md) {
        margin-top: 8px;
      }

      @include media-breakpoint-up(lg) {
        width: 163px;
        margin: 8px auto 0;
      }
    }
  }

  .warehouse {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-300;
    }

    &:first-child span:first-child {
      margin-top: 20px;
    }

    span {
      font-size: $fs-l;
    }

    &__name {
      margin-top: 32px;
    }

    &__name,
    &__phone {
      font-weight: $font-weight-medium;
    }

    &__phone {
      margin-bottom: 6px;
    }

    &__label {
      margin-top: 20px;
      margin-bottom: 10px;
      color: $gray-600;
    }
  }

  .warehouses-table {

    thead {
      border-top: none;

      th {
        padding-top: 0;
        font-weight: $font-weight-medium;
        color: $black;
      }
    }

    tr {
      @include media-breakpoint-down(md) {
        &:nth-child(2) {
          td {
            padding-top: 0;
          }
        }
      }

      &:nth-last-child(-n+2) {
        td {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }

    td {
      width: 25%;

      span {
        display: inline-block;

        @include media-breakpoint-up(md) {
          margin-bottom: 4px;
        }
      }
    }

    &__phone {
      font-weight: $font-weight-medium;
    }
  }

  .products-tabs {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      width: auto;
      margin-bottom: 2rem;
      overflow-x: auto;

      &::-webkit-scrollbar {
        @include size(0);
        background: transparent;
      }
    }
  }

  .review-person {
    display: flex;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    &__ava {
      @include size(66px);
      border: 1px solid $gray-300;
    }

    &__name {
      font-size: $fs-m;
      font-weight: $font-weight-medium;
      color: $gray-900;

      @include media-breakpoint-up(lg) {
        font-size: $fs-l;
      }
    }

    &__company {
      font-family: $font-family-base;
      font-size: $fs-s;
      color: $gray-700;

      @include media-breakpoint-up(lg) {
        font-size: $fs-m;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 66px;
      margin-left: 14px;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-left: 30px;

        .raiting {
          margin-top: -3px;
        }
      }
    }


    &__main-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 40px;
    }
  }

  .raiting {
    img {
      @include size(12px);
    }
  }

  .review {
    @include media-breakpoint-up(md) {
      padding: 20px 30px;
      border: 1px solid $gray-300;
    }

    @include media-breakpoint-up(lg) {
      padding: 30px;

      &__text {
        margin-top: -30px;
        margin-left: 97px;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
    }

    &__short-quote {
      display: inline-block;
      margin-top: 15px;
      font-size: $fs-s;
      font-weight: $font-weight-medium;
      color: $gray-900;

      @include media-breakpoint-up(md) {
        font-size: $fs-m;
      }

      @include media-breakpoint-up(lg) {
        font-size: $fs-l;
      }
    }

    &__quote {
      display: inline-block;
      padding-left: 10px;
      margin-top: 20px;
      border-left: 1px solid $gray-400;

      p {
        font-family: $font-family-base;
        font-size: $fs-s;
        color: $gray-600;

        &:last-child {
          margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
          font-size: $fs-m;
        }
      }
    }
  }

  .show-more-button-wrapper  {
    width: 100%;
    text-align: center;

    &__button {
      width: 100%;

      @include media-breakpoint-up(lg) {
        display: inline-flex;
        width: auto;
      }
    }
  }
}
