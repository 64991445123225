// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  &--no-wrap {
    flex-wrap: nowrap;

    @include media-breakpoint-down(xxl) {
      overflow-x: auto;
    }

    a {
      white-space: nowrap;
    }
  }

  &--shrink {
    width: fit-content;
    border-bottom: 1px solid $nav-tabs-border-color;

    .nav-item {
      margin-bottom: -1px;

      &:not(:last-child) {
        .nav-link {
          margin-right: 24px;
        }
      }

      .nav-link {
        padding-right: 0;
        padding-left: 0;
      }

      .nav-link:not(.active) {
        border-bottom: none;
      }
    }
  }
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;
  @include font-size($nav-link-font-size);
  font-weight: $nav-link-font-weight;
  line-height: 140%;
  color: $nav-link-color;
  text-decoration: if($link-decoration == none, null, none);
  text-transform: uppercase;
  letter-spacing: .04em;
  @include transition($nav-link-transition);
  border-bottom: 1px solid $nav-tabs-border-color;

  &--bold {
    font-weight: 500;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &:hover {
    padding-bottom: calc(1rem - 1px);
    color: $nav-link-hover-color;
    border-bottom: 2px solid $orange;
  }

  &:active,
  &.active {
    padding-bottom: calc(1rem - 1px);
    color: $nav-link-color;
    border-bottom: 2px solid $orange;
  }

  &:focus {
    padding-bottom: calc(1rem - 1px);
    color: $nav-link-color;
    border-bottom: 2px solid $orange;
    box-shadow: 0 0 0 2px $orange-200;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

//
// Tabs
//

.nav-tabs {

  .nav-link {
    margin-bottom: -$nav-tabs-border-width;
    background: none;
    border-bottom: none;

    &--bold {
      font-weight: 500;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &:hover,
    &:focus {
      color: $nav-link-color;
      border: none;
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      isolation: isolate;
      box-shadow: none;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-link-color;
    background-color: $nav-tabs-link-active-bg;
    border: 1px solid $nav-tabs-border-color;
    border-bottom: none;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}


//
// Pills
//

.nav-pills {
  .nav-link {
    background: none;
    border: 0;
    @include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    @include gradient-bg($nav-pills-link-active-bg);
  }
}


//
// Justified variants
//

.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

.nav-fill,
.nav-justified {
  .nav-item .nav-link {
    width: 100%; // Make sure button will grow
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
