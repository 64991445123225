.contacts-page {
  &__subtitle {
    margin-bottom: 1.5rem;
  }

  &__nav {
    margin-bottom: 1.5rem;

    .nav-link {
      font-size: $fs-l;
      font-weight: 500;
    }
  }

  // Branches
  &-branches {
    margin-bottom: 2.5rem;

    &__nav {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
      }
    }

    &__info {
      margin: 1rem 0 2rem;
      color: $gray-600;

      @include media-breakpoint-up(md) {
        margin: 0;
      }

      i {
        vertical-align: middle;
      }
    }

    &__wrap {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }

    &__column {
      flex: 1 1 25%;
    }

    &__link {
      margin-bottom: 1rem;

      a {
        margin-right: .5rem;
      }

      i {
        vertical-align: middle;
      }
    }
  }

  // Company
  &-company {
    margin-bottom: 2.5rem;

    .table {
      td {
        @include media-breakpoint-up(sm) {
          width: 25%;

          &:first-child {
            width: 18%;
          }
        }

        @include media-breakpoint-up(lg) {
          &:first-child {
            width: 25%;
          }
        }
      }

      &-mobile {
        &__wrap {
          margin-bottom: 2.5rem;

          > div:not(:last-child) {
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid $border-color;
          }
        }

        &__title {
          color: $gray-600;
        }
      }
    }
  }

  // Contacts
  &-contacts {
    margin-bottom: 2.5rem;

    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
      }
    }

    &__info {
      width: 100%;
      margin-top: 1.5rem;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 2.5rem;
      }
    }

    &__position {
      font-size: $fs-m;
      color: $gray-700;
    }

    &__email {
      font-size: $fs-m;
    }

    &__btn {
      width: 100%;
      margin-top: 1rem;

      @include media-breakpoint-up(md) {
        width: auto;
      }

      i {
        margin-right: .5rem;
        font-size: 16px;
      }
    }
  }
}
