$header-height: 40px !default;

.header-top {
  @include size(100%, $header-height);
  display: flex;
  align-items: center;
  background-color: $gray-100;
}

.header-top-col {
  display: flex;
  padding-right: 0;
  padding-left: 0;
  line-height: $header-height;
}

.header-main {
  @include size(100%, 56px);
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    @include size(100%, 80px);
  }

  &__logo {
    @include size(140px, 22px);

    @include media-breakpoint-up(md) {
      @include size(180px, 28px);
    }
  }

  &__catalog-btn {
    height: 40px;
    padding: 0 20px;
    margin-left: 56px;
    font-size: 13px;

    i {
      margin-right: 10px;
      font-size: 20px;
    }

    .icn-close {
      display: none;
    }

    &.active {
      .icn-bars {
        display: none;
      }

      .icn-close {
        display: unset;
      }
    }
  }

  &__search {
    position: relative;
    flex-grow: 1;

    .input-group {
      position: relative;
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
        width: 100%;
        margin-left: 32px;
      }

      .search-icn {
        position: absolute;
        z-index: 5;
        flex-grow: 0;
        padding: 10px;
        margin: 0;
        font-size: 20px;
        color: $gray-600;
      }

      .form-control {
        padding-left: 2.5rem;
      }
    }

    .btn {
      position: absolute;
      right: 0;
      z-index: 3;
      align-items: center;
      width: 95px;
      font-size: 13px;
    }
  }

  &__search-but {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-left: auto;
    font-family: $font-family-base;
    font-size: $font-size-base;
    color: $gray-600;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    i {
      font-size: 18px;
    }
  }

  &__cart {
    position: relative;
    z-index: 5;
    height: 100%;

    &:hover {
      background-color: #fff;
      filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
    }

    .cart-product {
      position: relative;

      &:hover {
        > i {
          display: block;
        }
      }

      &:first-child {
        // stylelint-disable-next-line
        padding-top: 0 !important;

        i {
          padding-top: 0;
        }
      }

      &:last-child {
        // stylelint-disable-next-line
        padding-bottom: 0 !important;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }

      &-wrap {
        height: 294px;
        margin: 16px 8px 16px 0;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #c4c4c4;
          border-radius: 6px;
        }
      }

      &__title{
        position: relative;
        margin-right: 24px;

        a {
          width: 256px;
          overflow: hidden;
          white-space: nowrap;
        }

        &-blur {
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 100%;
          background-image: linear-gradient(90deg, rgba($white, 0) 0%, rgba($white, 1) 100%);
        }
      }

      &__service {
        position: relative;

        &:hover {
          > i {
            display: block;
          }
        }
      }

      &-close {
        position: absolute;
        top: 2px;
        right: 7px;
        display: none;
        padding: 16px 0 0 4px;
        font-size: .75rem;
        font-weight: 600;
        color: $gray-600;
        cursor: pointer;
      }
    }

    .badge {
      @include size($font-size-base);
      display: flex;
    }

    i {
      font-size: 20px;
      color: $gray-900;
    }
  }

  &--search-focused {
    @include media-breakpoint-down(lg) {
      @include size(100%);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2000;
      box-sizing: border-box;
      align-items: baseline;
      justify-content: baseline;
      padding: 20px 32px;
      background: $white;
    }

    @include media-breakpoint-down(md) {
      padding: 20px 12px;
    }

    // stylelint-disable selector-class-pattern
    .header-main__search-btn {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .search__res,
    .search__popular,
    .search__cancel {
      display: block;
    }

    .header-main__search-but,
    .header-main__logo {
      display: none;
    }

    .input-group {
      display: flex;
    }
  }
}

.dropdown {
  display: inline-flex;

  &:hover {
    background-color: $white;
  }

  .dropdown-toggle {
    padding: 0 16px;
  }
}

.dropdown-location {
  .dropdown-toggle {
    display: flex;
    align-items: center;
    font-weight: $font-weight-medium;

    i {
      font-size: 20px;
    }
  }

  .city {
    display: flex;
    align-items: center;

    span {
      margin: 0 5px;
    }
  }

  .dropdown-menu {
    width: 20rem;
    padding: 1.5rem 1rem;
    // stylelint-disable-next-line declaration-no-important
    margin-top: -2px !important;

    @include media-breakpoint-up(md) {
      width: 48rem;
      padding: 2rem;
    }

    @include media-breakpoint-up(lg) {
      width: 13.25rem;
      padding: 1.375rem 1.25rem 1.5rem 1rem;
    }

    @include media-breakpoint-up(xl) {
      width: 13rem;
    }

    .btn {
      display: flex;
      justify-content: space-between;
      width: 18rem;
      padding: 0;
      font-family: $font-family-base;
      font-size: $font-size-base;
      color: $black;
      text-transform: unset;
      background-color: $white;
      border: none;

      @include media-breakpoint-up(md) {
        width: 704px;
        font-weight: $font-weight-medium;
      }

      @include media-breakpoint-up(lg) {
        width: 164px;
        font-weight: $font-weight-normal;
      }

      &.active,
      &.focus,
      &:hover {
        color: $orange;
        box-shadow: none;
      }

      i {
        margin-top: .25rem;
      }
    }

    .divider {
      margin: 16px 0;
      color: $gray-300;
    }
  }
}

.modal-catalog {
  display: flex;
  flex-direction: column;
  width: 904px;
  max-width: unset;
  margin-top: 7.5rem;

  @include media-breakpoint-up(xl) {
    width: 1320px;
  }

  .menu-catalog {
    display: flex;
    flex-direction: column;
    padding: 0 2rem 2rem;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }
  }


  .dropdown-menu {
    &-catalog {
      display: flex;
      flex-direction: row;
    }

    &-main__list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;

      li {
        display: block;
        width: 33%;
        padding-top: 2rem;

        a {
          padding: 0;

          &:hover {
            color: $orange;
          }

          &:focus {
            box-shadow: 0 0 0 2px $orange-200;
          }
        }

        i {
          position: absolute;
          margin-top: -.25rem;
          font-size: 2rem;
        }
      }

      .dropdown-menu-item {
        margin: 0 1.5rem;
        color: $dropdown-link-color;
        text-decoration: none;
        white-space: nowrap;

        &__header {
          @include font-size($font-size-sm);
          padding-left: 47px;
          font-weight: $font-weight-medium;
          text-transform: uppercase;
        }

        &__content {
          width: 70%;
          padding-left: 4.5rem;
          margin-top: 8px;

          @include media-breakpoint-up(xl) {
            width: unset;
          }

          li {
            width: auto;
            padding-top: .5rem;
          }

          &-item {
            @include font-size($font-size-base);
            line-height: 2;
            color: $dropdown-link-color;
            text-decoration: none;
          }
        }
      }
    }

    &-side {
      width: fit-content;
      padding: 2rem 0 0;

      i {
        position: absolute;
        margin-top: -.35rem;
        font-size: 2rem;

        @include media-breakpoint-up(xl) {
          margin-top: -.25rem;
        }
      }

      &__item {
        display: block;
        padding: .8125rem;
        color: $black;
        text-decoration: none;
        background-color: $gray-100;

        &:hover {
          color: $orange;
        }

        &:focus {
          box-shadow: 0 0 0 2px $orange-200;
        }


        @include media-breakpoint-up(xl) {
          padding: 1.25rem 1.5rem;
        }

        &-header {
          @include font-size($font-size-sm);
          padding-left: 47px;
          font-weight: $font-weight-normal;
          text-transform: uppercase;
        }
      }
    }
  }
}

.dropdown-user {
  position: relative;
  z-index: 5;
  align-items: center;
  height: 80px;
  margin-right: 16px;
  margin-left: 16px;

  &:hover {
    background-color: $white;
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  }


  .dropdown-toggle {
    display: flex;
    align-items: center;
    max-width: 224px;
    height: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(xxl) {
      max-width: 320px;
    }

    span {
      @include text-truncate();
      padding: 0 4px;
      font-weight: $font-weight-medium;
    }

    i {
      position: relative;
      font-size: 20px;
    }
  }

  .dropdown-menu {
    width: 253px;
    // stylelint-disable-next-line declaration-no-important
    margin-top: -3px !important;
    box-shadow: none;

    @include media-breakpoint-up(lg) {
      width: 261px;
    }

    @include media-breakpoint-up(xl) {
      width: 320px;
    }

    li:first-child {
      a {
        padding-top: 19px;
      }
    }
  }

  .dropdown-item {
    display: flex;
    justify-content: space-between;
    line-height: 1.25;

    &.logout {
      padding-top: 25px;
      color: $gray-600;

      i {
        font-size: 20px;
      }
    }
  }

  .badge {
    @include size(20px);
    line-height: 1.5;
  }
}

.dropdown-contacts {
  margin-left: 40px;
  line-height: 1rem;

  .dropdown-toggle {
    display: flex;
    align-items: center;
    font-weight: $font-weight-medium;

    i {
      font-size: 20px;
    }
  }

  .dropdown-menu {
    width: 510px;
    padding: 24px;
    // stylelint-disable-next-line declaration-no-important
    margin-top: -2px !important;
  }

  .phone {
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
      border-bottom: 1px dotted $black;
    }
  }

  .mail {
    display: flex;
    align-items: center;
    margin: 0 5px 0 23px;

    span {
      margin-left: 13px;
      border-bottom: 1px dotted $black;
    }
  }
}

.dropdown-trigger {
  cursor: pointer;

  span {
    font-weight: $font-weight-medium;
  }

  i {
    position: relative;
    top: 3px;
    font-size: 20px;
  }
}

.header-menu {
  display: inline-flex;
  margin-bottom: 0;
  margin-left: auto;
  list-style: none;

  &__item {
    a {
      color: $gray-900;
      text-decoration: none;

      &:hover {
        color: $orange;
      }

      &:focus {
        border: 1px solid $orange-200;
      }
    }

    .dropdown {
      margin-top: -3px;

      span {
        font-family: $font-family-base;
      }
    }

    &:not(:first-child) {
      margin-left: 30px;
    }
  }

  &__more {
    .dropdown-menu {
      width: 320px;
      // stylelint-disable-next-line declaration-no-important
      margin: -2px 0 0 1px !important;
    }
  }
}
