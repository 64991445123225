.checkout-cart-page {
  padding: 1.5rem 0 2rem;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 0 4rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 2rem 0 4rem;
  }

  .cart-main {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  // Warning
  .cart-warning {
    order: 2;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(lg) {
      order: 1;
    }
  }


  // Product
  .product {
    order: 1;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(lg) {
      order: 2;
    }

    &-item {
      position: relative;
      padding: 1rem;
      background-color: $white;

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }

      &__close {
        margin-left: .75rem;
        font-size: $fs-s;
        font-weight: 600;
        color: $gray-600;
        cursor: pointer;
      }
    }

    &-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: .75rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }

      &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: .75rem;

        @include media-breakpoint-up(md) {
          flex-direction: column;
          margin-bottom: 0;

          &.name {
            width: 50%;
          }

          &.quantity {
            width: 25%;
            margin-left: 24px;
          }

          &.price {
            align-items: flex-end;
            width: 25%;
          }
        }

        @include media-breakpoint-up(xl) {
          &.quantity {
            margin-left: 15%;
          }
        }
      }

      &__title {
        display: block;
        margin-right: .5rem;
        margin-bottom: .25rem;
      }

      &__subtitle {
        font-size: $fs-m;
        line-height: 1.6;
        color: $gray-600;
        vertical-align: middle;

        &.black {
          color: $black;
        }
      }

      &__change {
        display: block;
        font-size: $fs-m;
        color: $gray-700;
        cursor: pointer;

        &:hover {
          color: $orange-hover;
        }
      }
    }

    &-service {
      &__add {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          i,
          span {
            color: $orange-hover;
          }
        }

        i {
          font-size: 20px;
          color: $secondary;
        }

        span {
          padding-left: 4px;
          font-size: $fs-m;
          color: $gray-700;
        }
      }

      &-info {
        padding-left: .75rem;
        border-left: 1px solid $border-color;

        &__wrap {
          &.name {
            @include media-breakpoint-up(md) {
              width: calc(50% - 16px);
            }
          }
        }
      }
    }
  }

  // Info
  .info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 2rem;
    background-color: $white;

    &__text {
      margin-left: 1.5rem;
    }
  }

  // Missing product
  .missing-products {
    order: 3;
    margin-bottom: 1.5rem;
    background-color: $white;

    &-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1.25rem 1rem;
      color: $gray-600;
      border-bottom: 1px solid $border-color;

      &__title {
        margin-bottom: 0;
        font-weight: 500;
        color: $gray-600;
      }

      &__btn {
        white-space: nowrap;
        border-bottom: 1px dotted $border-color;

        &:hover {
          color: $orange;
          cursor: pointer;
          border-color: $orange;
        }
      }
    }

    &-body {
      padding: 1rem;
      color: $gray-600;

      @include media-breakpoint-up(md) {
        padding: 1.5rem 1rem;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      &__wrap {
        margin-bottom: .75rem;
      }

      &__title {
        margin-bottom: .25rem;
        color: $gray-600;
      }

      &__btn {
        padding: .75rem 1.5rem;
      }
    }
  }


  // Cross sell
  .cross-sell {
    order: 4;
    padding: 2rem 0;
    margin-bottom: 1.5rem;
    background-color: $white;

    @include media-breakpoint-up(md) {
      padding: 2rem;
    }

    &-title {
      padding: 0 1rem;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(md) {
        padding: 0;
      }
    }

    &__wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      height: calc(100% - 54px);
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;

      @include media-breakpoint-up(md) {
        overflow: initial;
      }
    }

    &-item {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      margin-right: 1rem;

      &:first-child {
        margin-left: 1rem;
      }

      @include media-breakpoint-up(md) {
        margin-right: 1.5rem;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &__img {
        width: 170px;
        margin-bottom: .5rem;

        @include media-breakpoint-up(md) {
          width: 193px;
        }

        @include media-breakpoint-up(lg) {
          width: 160px;
        }

        @include media-breakpoint-up(xxl) {
          width: 200px;
        }
      }

      &__price {
        margin-bottom: .5rem;

        span {
          font-size: $fs-l;
        }
      }

      &__title {
        margin-bottom: .25rem;
      }

      &__subtitle {
        color: $gray-700;
      }

      &__btn {
        width: fit-content;
        padding: .75rem 1.5rem;
        margin-top: .75rem;
        margin-right: 0;
        color: $white;
        cursor: pointer;
        background-color: $secondary;
      }
    }
  }
}

.checkout-cart-footer {
  margin-top: 0;
}
