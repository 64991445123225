.about-company {
  padding: 0 1rem;
  margin-top: 45px;
  margin-bottom: 32px;

  @include media-breakpoint-up(md) {
    padding: 0;
    margin-top: 76px;
    margin-bottom: 80px;
  }

  .title-block {
    margin-bottom: 0;

    h1 {
      font-size: 2rem;

      @include media-breakpoint-up(md) {
        font-size: 76px;
        font-weight: $font-weight-normal;
      }
    }

    .brand-art {
      display: flex;

      &__item:first-child {
        @include size(116px, 32px);
        position: relative;
        top: -17px;
        left: -16px;
        background-color: $primary;

        @include media-breakpoint-up(md) {
          @include size(140px, 45px);
          top: -32px;
          left: 2px;
        }
      }

      &__item:last-child {
        @include size(23px, 49px);
        position: relative;
        left: -24px;
        background-color: $secondary;

        @include media-breakpoint-up(md) {
          @include size(32px, 68px);
          top: -10px;
          left: -5px;
        }
      }
    }
  }

  &__items {
    font-size: $font-size-sm;
    line-height: 1.4;

    p {
      padding-top: 1rem;
    }

    img {
      position: relative;
      width: 100%;
      object-fit: cover;
      margin-top: 1rem;
    }

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;

      img {
        margin-top: 0;
      }
    }
  }

  &__item {
    padding-bottom: 8px;

    .item-header {
      margin: 22px 0 24px;

      h6 {
        position: relative;
        z-index: 10;
        margin-bottom: 0;
        font-size: 72px;
        font-weight: 500;
        line-height: 79px;
      }

      p {
        position: relative;
        top: -8px;
        max-width: 230px;
        padding: 0;
        font-size: $font-size-base;
      }

      @include media-breakpoint-up(md) {
        margin: 0;

        h6 {
          position: relative;
          font-size: 152px;
          line-height: 167px;
        }

        p {
          font-size: 20px;
        }
      }
    }

    .item-body {
      margin: 24px 0 8px;


      @include media-breakpoint-up(md) {
        margin: 24px 26px 24px 0;
      }

      p {
        position: relative;
        padding: 0 0 24px;

        &:last-of-type {
          padding: 0;
        }

        a {
          text-decoration: none;
        }
      }

    }

    .brand-art {
      z-index: 0;
      display: flex;

      &__yellow {
        @include size(196px, 34px);
        position: relative;
        top: 62px;
        left: -16px;
        background-color: $primary;

        @include media-breakpoint-up(md) {
          @include size(423px, 34px);
          left: 0;
        }
      }

      &__orange {
        @include size(17px, 32px);
        position: relative;
        top: 96px;
        left: -16px;
        background-color: $secondary;

        @include media-breakpoint-up(md) {
          @include size(25px, 32px);
          left: 0;
        }
      }

      &__red {
        @include size(91px, 16px);
        position: relative;
        top: 128px;
        left: -16px;
        background-color: $red;

        @include media-breakpoint-up(md) {
          @include size(199px, 16px);
          left: 0;
        }
      }
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 124px;
      }

      p {
        font-size: $font-size-base;
      }

      .item-image {
        display: flex;
        justify-content: center;
      }

      .item-content {
        max-width: 50%;
      }
    }
  }

  .intro {
    @include media-breakpoint-up(md) {
      .item-content {
        margin-top: 52px;
        margin-right: 26px;
      }

      .item-image {
        margin-top: 52px;
      }
    }

    @include media-breakpoint-up(xl) {

      .item-content {
        margin-top: 0;
      }

      .item-image {
        position: relative;
        top: -42px;
        width: 55%;
        margin-top: 0;
      }
    }
  }

  .branches {

    .brand-art {
      &__side-yellow {
        position: absolute;
        left: 0;
        z-index: 0;
        display: flex;
        @include size(16px, 110px);
        margin-top: 16px;
        background-color: $primary;

        @include media-breakpoint-up(md) {
          @include size(32px, 110px);
          margin-top: 0;
        }

        @include media-breakpoint-up(lg) {
          @include size(87px, 110px);
          left: 5.9vw;
        }

        @include media-breakpoint-up(xl) {
          left: 7vw;
        }

        @include media-breakpoint-up(xxl) {
          left: 4.2vw;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .item-content {
        order: 1;
        margin-top: 7.5px;
        margin-left: 5px;
      }

      .item-header {
        h6 {
          top: -70px;
          padding-left: 87px;
        }

        p {
          top: -90px;
          padding-left: 92px;
        }
      }

      .item-body {
        padding-left: 92px;
        margin: 0;

        p {
          max-width: 545px;
        }
      }

      .item-image {
        justify-content: unset;
      }
    }

    @include media-breakpoint-up(lg) {
      .item-content {
        margin-left: 64px;
      }

      .item-body {
        padding-left: 64px;
      }

      .item-image {
        padding-left: 64px;
      }
    }

    @include media-breakpoint-up(xl) {
      .item-content {
        margin-left: 75px;
      }

      .item-body {
        padding-left: 92px;
      }

      .item-image {
        padding-left: 87px;
      }
    }
  }

  .equipment {
    .item-header {
      margin-bottom: 32px;
    }

    .item-image {
      .brand-art {
        align-items: flex-end;

        &__side-orange {
          position: relative;
          bottom: 155px;
          left: 100%;
          z-index: 0;
          display: flex;
          margin-bottom: -155px;
          @include size(16px, 155px);
          background-color: $secondary;

          @include media-breakpoint-up(md) {
            @include size(32px, 155px);
            bottom: 0;
            left: 0;
            margin-bottom: 0;
          }

          @include media-breakpoint-up(lg) {
            @include size(116px, 155px);
          }

          @include media-breakpoint-up(xl) {
            @include size(116px, 155px);
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      .item-content {
        margin-right: 32px;
      }

      .item-header {
        margin-bottom: 0;

        h6 {
          top: -70px;
          padding-left: 16px;
        }

        p {
          top: -90px;
          max-width: 410px;
          padding-left: 16px;
        }
      }

      .item-body {
        padding-left: 16px;
        margin: 0;

        p {
          max-width: 501px;
          margin-bottom: 83px;
        }
      }

      .item-image {
        max-width: 426px;
        padding-top: 144px;
      }
    }

    @include media-breakpoint-up(lg) {
      .item-content {
        margin-right: 64px;
      }

      .item-header {
        h6 {
          padding-left: 68px;
        }

        p {
          max-width: 310px;
          padding-left: 58px;
        }
      }

      .item-body {
        padding-left: 69px;
      }

      .item-image {
        margin-left: 64px;
      }
    }

    @include media-breakpoint-up(xl) {
      .item-header {
        h6 {
          padding-left: 79px;
        }

        p {
          max-width: 420px;
          padding-left: 69px;
        }
      }

      .item-body {
        padding-left: 69px;
        margin-top: 32px;
      }
    }
  }

  .quality {
    .brand-art {
      &__horizontal-yellow {
        position: relative;
        top: 16px;
        left: -16px;
        z-index: 0;
        display: flex;
        @include size(103px, 21px);
        background-color: $primary;

        @include media-breakpoint-up(md) {
          @include size(302px, 21px);
          top: 0;
          left: 25%;
        }

        @include media-breakpoint-up(lg) {
          left: 302px;
        }

        @include media-breakpoint-up(xl) {
          left: calc(50% - 200px);
        }
      }

      &__horizontal-red {
        position: relative;
        left: -16px;
        z-index: 0;
        display: flex;
        @include size(59px, 17px);
        background-color: $red;

        @include media-breakpoint-up(md) {
          @include size(119px, 38px);
          left: 49%;
        }
      }
    }

    .item-content {
      @include media-breakpoint-up(md) {
        width: unset;
        max-width: unset;

        .item-body {
          max-width: 50%;
        }

        .item-image {
          max-width: 648px;
        }

        &:last-child {
          margin-top: 40px;

          .item-image {
            flex-direction: row;
          }

          img {
            max-width: 50%;
            padding-right: 16px;
          }

          .item-body {
            flex-direction: column;
            justify-content: center;
            margin: 0;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        &:last-child {
          .item-image {
            margin-left: 0;
          }

          .item-body {
            margin-left: 10px;
          }
        }
      }

      @include media-breakpoint-up(xxl) {
        .item-body {
          margin: 53px 26px 53px 0;
        }

        &:last-child {
          img {
            padding-right: 24px;
          }
        }
      }
    }
  }

  .offers {
    margin-top: 56px;

    .item-header {
      margin-bottom: 16px;

      h2 {
        position: relative;
        z-index: 10;
        font-size: 32px;
        line-height: 1.1;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 40px;

        h2 {
          font-size: 72px;
          font-weight: $font-weight-base;
          line-height: 79.2px;
        }
      }
    }

    .brand-art {
      &__yellow {
        @include size(327px, 28px);
        top: -15px;
        left: -16px;

        @include media-breakpoint-up(md) {
          @include size(448px, 56px);
          top: -25px;
          left: 25vw;
        }

        @include media-breakpoint-up(lg) {
          @include size(565px, 56px);
          left: 200px;
        }
      }

      &__orange {
        @include size(77px, 16px);
        top: 13px;
        left: -16px;

        @include media-breakpoint-up(md) {
          @include size(88px, 31px);
          top: 30px;
          left: 25vw;
        }

        @include media-breakpoint-up(lg) {
          @include size(164px, 31px);
          left: 200px;
        }
      }
    }

    .item-content {
      max-width: 100%;
    }

    .item-body {
      flex-direction: column;
      margin: 0;
    }

    .offer {
      margin-bottom: 32px;

      img {
        width: 64px;
        margin-top: 0;
      }

      &-content {
        margin-top: 16px;

        h3 {
          font-size: $font-size-base;
        }

        @include media-breakpoint-up(md) {
          margin-top: 0;
          margin-left: 24px;

          h3 {
            margin-bottom: 16px;
            font-size: $font-size-lg;
          }
        }
      }

      @include media-breakpoint-up(md) {
        &:nth-child(2) {
          order: 3;
        }

        &:nth-child(3) {
          order: 5;
        }

        &:nth-child(4) {
          order: 2;
        }

        &:nth-child(5) {
          order: 4;
        }

        &:nth-child(6) {
          order: 6;
        }

        &:nth-child(n+4) {
          margin-left: calc(50% + 32px);
        }

        &:nth-child(-n+3) {
          margin-right: calc(50% + 32px);
        }

        &:not(:first-child) {
          margin-bottom: 14px;
        }


      }

      @include media-breakpoint-up(xl) {
        &:nth-child(n+4) {
          margin-left: calc(50% + 90px);
        }

        &:nth-child(-n+3) {
          margin-right: calc(50% + 90px);
        }

        &:not(:first-child) {
          margin-bottom: 14px;
        }
      }
    }
  }

  .metalwork {
    .item-header {
      h2 {
        position: relative;
        z-index: 10;
        font-size: 2rem;

        @include media-breakpoint-up(md) {
          padding-left: 14px;
          font-size: 72px;
          font-weight: $font-weight-base;
          line-height: 79.2px;
        }
      }

      .brand-art {
        &__yellow {
          @include size(248px, 56px);
          top: -56px;

          @include media-breakpoint-up(md) {
            @include size(253px, 110px);
            top: -100px;
          }
        }
      }
    }

    .item-body {
      position: relative;
      top: -56px;

      img {
        margin: 24px 0 16px;
      }

      @include media-breakpoint-up(md) {
        top: -100px;
        margin-bottom: 76px;

        img {
          margin: 0;
        }

        .item-content {
          max-width: 50%;
          margin: 26px 24px 26px 0;
        }
      }

      @include media-breakpoint-up(xl) {
        .item-content {
          max-width: 636px;
          margin: 54px 38px 0 0;
        }
      }

      &__yellow {
        @include size(100vw, 648px);
        position: relative;
        top: -56px;
        left: -16px;
        z-index: 0;
        padding: 14px 16px 16px;
        background-color: $primary;

        @include media-breakpoint-up(md) {
          @include size(calc(100vw - 64px), 500px);
          left: 0;
          justify-content: space-between;
          padding: 30px 46px;

          h3 {
            margin-bottom: 16px;
          }
        }

        @include media-breakpoint-up(lg) {
          @include size(100%, 480px);
        }

        @include media-breakpoint-up(xl) {
          @include size(100%, 380px);
        }

        @include media-breakpoint-up(xxl) {
          @include size(100%, 304px);
        }

        &-content {
          margin-bottom: 16px;

          span {
            display: block;
            width: fit-content;
            border-bottom: 1px dotted $gray-900;
          }

          p {
            &:first-of-type {
              font-size: $font-size-base;
              line-height: 1.6;
            }
          }

          @include media-breakpoint-up(md) {
            margin-bottom: 32px;

            span {
              display: initial;
              border-bottom: 1px dotted $gray-900;
            }

            p {
              &:first-of-type {
                padding-top: 0;
                font-size: $font-size-lg;
                line-height: 1.1;
              }
            }
          }

          @include media-breakpoint-up(xl) {
            max-width: 35%;
            margin-right: 54px;

            span {
              display: initial;
              border-bottom: 1px dotted $gray-900;
            }

            p {
              &:first-of-type {
                padding-top: 0;
                font-size: $font-size-lg;
                line-height: 1.1;
              }
            }
          }

          @include media-breakpoint-up(xxl) {
            max-width: 525px;
          }
        }

        .service-block {
          @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        .service-card {
          padding: 32px 32px 16px;
          margin: 0;
          background: $white;
          border: $border-width solid $gray-300;

          &:not(:first-child) {
            position: relative;
            left: -2px;
            border-left: none;
          }
        }

        .img-fluid {
          margin-bottom: 0;
        }
      }
    }
  }

  .benefits {
    margin-top: 172px;

    @include media-breakpoint-up(md) {
      margin-top: 234px;
    }

    .item-header {
      h2 {
        position: relative;
        z-index: 10;
        font-size: 2rem;

        @include media-breakpoint-up(md) {
          font-size: 72px;
          font-weight: $font-weight-base;
        }
      }

      .brand-art {
        @include media-breakpoint-down(md) {
          width: calc(100vw - 16px);
        }

        &__yellow {
          @include size(100vw, 27px);
          top: -18px;
          left: 0;

          @include media-breakpoint-up(md) {
            @include size(400px, 27px);
            top: -32px;
            left: 25%;
          }

          @include media-breakpoint-up(lg) {
            @include size(648px, 27px);
            top: -32px;
            left: 22%;
          }
        }
      }
    }

    .item-content {
      position: relative;
      top: -32px;
      width: 100%;
      max-width: unset;
    }

    .item-body {
      margin: 40px 0 0;

      p {
        padding-bottom: 24px;
      }

      ol {
        padding-left: 16px;
        margin-bottom: 8px;
      }

      li {
        padding-left: 16px;

        &:not(:last-of-type) {
          padding-bottom: 16px;
        }
      }

      @include media-breakpoint-up(md) {
        max-width: 50%;
        margin: 32px 23px 56px 0;
      }

      @include media-breakpoint-up(xl) {
        max-width: 638px;
        margin: 48px 23px 56px 0;
      }
    }

    .item-image {

      @include media-breakpoint-up(md) {
        justify-content: unset;
        width: 50%;
      }
    }

    .brand-art {
      &__horizontal-red {
        position: relative;
        top: -48px;
        left: -16px;
        @include size(16px, 16px);
        background-color: $red;

        @include media-breakpoint-up(md) {
          @include size(301px, 23px);
          top: -55px;
          left: 40px;
        }

        @include media-breakpoint-up(lg) {
          left: 146px;
        }

        @include media-breakpoint-up(xl) {
          left: 27%;
        }

        @include media-breakpoint-up(xxl) {
          left: 25%;
        }
      }

      &__horizontal-orange {
        position: relative;
        top: -32px;
        left: -22px;
        @include size(94px, 15px);
        background-color: $secondary;

        @include media-breakpoint-up(md) {
          @include size(90px, 25px);
          left: 40px;
        }

        @include media-breakpoint-up(lg) {
          left: 146px;
        }

        @include media-breakpoint-up(xl) {
          left: 27%;
        }

        @include media-breakpoint-up(xxl) {
          left: 25%;
        }
      }
    }
  }

  .entity-details {
    margin-top: 26px;

    .item-header {
      h2 {
        font-size: 32px;

        @include media-breakpoint-up(md) {
          font-size: 72px;
          font-weight: $font-weight-base;
        }
      }

      .brand-art {
        position: relative;
        top: -.5px;
        display: flex;

        &__item:first-child {
          @include size(116px, 32px);
          position: relative;
          top: -17px;
          left: -16px;
          background-color: $primary;

          @include media-breakpoint-up(md) {
            @include size(140px, 45px);
            top: -32px;
            left: 2px;
          }
        }

        &__item:last-child {
          @include size(23px, 49px);
          position: relative;
          left: -24px;
          background-color: $secondary;

          @include media-breakpoint-up(md) {
            @include size(32px, 68px);
            top: -10px;
            left: -5px;
          }
        }
      }
    }

    .item-body {
      p:not(:last-of-type) {
        padding: 0 0 16px;
      }

      &:last-child {
        width: 90%;

        p:first-of-type {
          font-size: $font-size-base;
          font-weight: $font-weight-medium;

          @include media-breakpoint-up(lg) {
            font-size: $font-size-lg;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        margin-top: 16px;
      }
    }
  }
}
