.management-page {
  &__directors-title {
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      margin-bottom: 32px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 32px 24px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include media-breakpoint-up(xxl) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__item {
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  &__item-name {
    display: block;
    margin: 12px 0 8px;
  }

  &__item-info {
    font-size: $fs-m;
    line-height: 1.4;
    color: $gray-700;

    span {
      display: block;
    }
  }

  &__managers {
    padding-bottom: 40px;
    background-color: $gray-100;
  }

  &__managers-title {
    padding: 40px 0 24px;
    margin: 0;

    @include media-breakpoint-up(md) {
      padding-bottom: 32px;
    }
  }
}

.popup-manager {
  .modal-dialog {
    @include media-breakpoint-up(lg) {
      width: 630px;
    }
  }

  .modal-content {
    padding-top: 60px;
    padding-bottom: 60px;

    @include media-breakpoint-up(lg) {
      max-height: 75vh;
      overflow-y: scroll;
    }
  }

  .modal-body {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
    }
  }

  &__bio {
    overflow-y: auto;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      margin-bottom: 32px;
    }

    img {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: auto;
        padding-right: 14px;
      }
    }
  }

  &__info-name {
    margin-top: 16px;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      margin-top: 4px;
    }
  }

  &__info-company {
    margin-top: 24px;
    line-height: 1.4;

    @include media-breakpoint-up(lg) {
      margin: 32px 0 0;
    }

    &_name {
      padding-bottom: 6px;
    }

    span {
      display: block;
    }
  }

}
