@charset "UTF-8";
/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #2070df;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #e32112;
  --bs-orange: #ed7817;
  --bs-yellow: #fab82e;
  --bs-green: #4a8f40;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #868786;
  --bs-gray-dark: #343a40;
  --bs-red-hover: #d61305;
  --bs-orange-hover: #dd6908;
  --bs-light-gray: #eaeaea;
  --bs-primary: #fab82e;
  --bs-secondary: #ed7817;
  --bs-success: #4a8f40;
  --bs-info: #2070df;
  --bs-warning: #fab82e;
  --bs-danger: #e32112;
  --bs-light: #f3f2f2;
  --bs-gray: #d9dada;
  --bs-dark: #000;
  --bs-font-sans-serif: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-size: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.3;
  color: #000;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  flex: 1 0 auto;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.3;
  color: #000;
}

h1, .h1 {
  font-size: 1.625rem;
}
@media (min-width: 768px) {
  h1, .h1 {
    font-size: 2rem;
  }
}
@media (min-width: 1280px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  h2, .h2 {
    font-size: 1.625rem;
  }
}
@media (min-width: 1280px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: 1.25rem;
}
@media (min-width: 768px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1280px) {
  h3, .h3 {
    font-size: 1.625rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 500;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #fab82e;
  text-decoration: underline;
}
a.no-underline {
  text-decoration: none;
}
a.no-underline:hover {
  text-decoration: none;
}
a:hover {
  color: #c89325;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin: 0;
  font-size: 1rem;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #000;
  border-radius: 0;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #868786;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  min-height: 96px;
  padding: 9px 12px;
  resize: none;
}
textarea::-moz-placeholder {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #868786;
}
textarea::placeholder {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #868786;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #868786;
}
.blockquote-footer::before {
  content: "— ";
}

.page-content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.underline-stroke-link {
  display: inline;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 0;
  color: #000;
  text-decoration: underline;
  text-underline-offset: 3px;
  -webkit-text-decoration-color: #fab82e;
  text-decoration-color: #fab82e;
}
.underline-stroke-link span {
  font-weight: 400 !important;
  background-image: linear-gradient(180deg, transparent 0%, #fab82e 0%);
  background-repeat: no-repeat;
  background-size: 0 100%;
  border-bottom: none;
  transition: 0.3s cubic-bezier(0.5, 0.61, 0.355, 1) all;
}
.underline-stroke-link:hover span {
  color: #000;
  background-size: 100% 100%;
  transition: 0.1s cubic-bezier(0.5, 0.61, 0.355, 1) all;
}

.underline-hover-link {
  color: #ed7817;
  text-decoration: none;
}
.underline-hover-link:hover {
  color: #ed7817;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.underline-default-link {
  color: #ed7817;
  text-decoration: none;
  border-bottom: 1px dotted #ed7817;
}
.underline-default-link:hover {
  color: #dd6908;
  text-decoration: none;
}

.underline-secondary-link {
  color: #000;
  text-decoration: underline;
  -webkit-text-decoration-style: dotted;
  text-decoration-style: dotted;
  text-underline-offset: 2px;
}
.underline-secondary-link:hover {
  color: #dd6908;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #d9dada;
  border-radius: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #868786;
}

.gray-bg {
  background-color: #f3f2f2;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 320px) {
  .container-sm, .container {
    max-width: 702px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 704px;
  }
}
@media (min-width: 1024px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 904px;
  }
}
@media (min-width: 1280px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1160px;
  }
}
@media (min-width: 1440px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 320px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1024px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1280px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1440px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-c,
.gx-c {
  --bs-gutter-x: 0.75rem;
}

.g-c,
.gy-c {
  --bs-gutter-y: 0.75rem;
}

.g-32,
.gx-32 {
  --bs-gutter-x: 2rem;
}

.g-32,
.gy-32 {
  --bs-gutter-y: 2rem;
}

.g-40,
.gx-40 {
  --bs-gutter-x: 2.5rem;
}

.g-40,
.gy-40 {
  --bs-gutter-y: 2.5rem;
}

.g-50,
.gx-50 {
  --bs-gutter-x: 3.125rem;
}

.g-50,
.gy-50 {
  --bs-gutter-y: 3.125rem;
}

.g-64,
.gx-64 {
  --bs-gutter-x: 4rem;
}

.g-64,
.gy-64 {
  --bs-gutter-y: 4rem;
}

.g-80,
.gx-80 {
  --bs-gutter-x: 5rem;
}

.g-80,
.gy-80 {
  --bs-gutter-y: 5rem;
}

.g-96,
.gx-96 {
  --bs-gutter-x: 6rem;
}

.g-96,
.gy-96 {
  --bs-gutter-y: 6rem;
}

@media (min-width: 320px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-c,
.gx-sm-c {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-c,
.gy-sm-c {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-32,
.gx-sm-32 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-32,
.gy-sm-32 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-40,
.gx-sm-40 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-40,
.gy-sm-40 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-50,
.gx-sm-50 {
    --bs-gutter-x: 3.125rem;
  }

  .g-sm-50,
.gy-sm-50 {
    --bs-gutter-y: 3.125rem;
  }

  .g-sm-64,
.gx-sm-64 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-64,
.gy-sm-64 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-80,
.gx-sm-80 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-80,
.gy-sm-80 {
    --bs-gutter-y: 5rem;
  }

  .g-sm-96,
.gx-sm-96 {
    --bs-gutter-x: 6rem;
  }

  .g-sm-96,
.gy-sm-96 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }

  .g-md-c,
.gx-md-c {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-c,
.gy-md-c {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-32,
.gx-md-32 {
    --bs-gutter-x: 2rem;
  }

  .g-md-32,
.gy-md-32 {
    --bs-gutter-y: 2rem;
  }

  .g-md-40,
.gx-md-40 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-40,
.gy-md-40 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-50,
.gx-md-50 {
    --bs-gutter-x: 3.125rem;
  }

  .g-md-50,
.gy-md-50 {
    --bs-gutter-y: 3.125rem;
  }

  .g-md-64,
.gx-md-64 {
    --bs-gutter-x: 4rem;
  }

  .g-md-64,
.gy-md-64 {
    --bs-gutter-y: 4rem;
  }

  .g-md-80,
.gx-md-80 {
    --bs-gutter-x: 5rem;
  }

  .g-md-80,
.gy-md-80 {
    --bs-gutter-y: 5rem;
  }

  .g-md-96,
.gx-md-96 {
    --bs-gutter-x: 6rem;
  }

  .g-md-96,
.gy-md-96 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 1024px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-c,
.gx-lg-c {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-c,
.gy-lg-c {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-32,
.gx-lg-32 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-32,
.gy-lg-32 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-40,
.gx-lg-40 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-40,
.gy-lg-40 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-50,
.gx-lg-50 {
    --bs-gutter-x: 3.125rem;
  }

  .g-lg-50,
.gy-lg-50 {
    --bs-gutter-y: 3.125rem;
  }

  .g-lg-64,
.gx-lg-64 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-64,
.gy-lg-64 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-80,
.gx-lg-80 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-80,
.gy-lg-80 {
    --bs-gutter-y: 5rem;
  }

  .g-lg-96,
.gx-lg-96 {
    --bs-gutter-x: 6rem;
  }

  .g-lg-96,
.gy-lg-96 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 1280px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-c,
.gx-xl-c {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-c,
.gy-xl-c {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-32,
.gx-xl-32 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-32,
.gy-xl-32 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-40,
.gx-xl-40 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-40,
.gy-xl-40 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-50,
.gx-xl-50 {
    --bs-gutter-x: 3.125rem;
  }

  .g-xl-50,
.gy-xl-50 {
    --bs-gutter-y: 3.125rem;
  }

  .g-xl-64,
.gx-xl-64 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-64,
.gy-xl-64 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-80,
.gx-xl-80 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-80,
.gy-xl-80 {
    --bs-gutter-y: 5rem;
  }

  .g-xl-96,
.gx-xl-96 {
    --bs-gutter-x: 6rem;
  }

  .g-xl-96,
.gy-xl-96 {
    --bs-gutter-y: 6rem;
  }
}
@media (min-width: 1440px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-c,
.gx-xxl-c {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-c,
.gy-xxl-c {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-32,
.gx-xxl-32 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-32,
.gy-xxl-32 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-40,
.gx-xxl-40 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-40,
.gy-xxl-40 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-50,
.gx-xxl-50 {
    --bs-gutter-x: 3.125rem;
  }

  .g-xxl-50,
.gy-xxl-50 {
    --bs-gutter-y: 3.125rem;
  }

  .g-xxl-64,
.gx-xxl-64 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-64,
.gy-xxl-64 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-80,
.gx-xxl-80 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-80,
.gy-xxl-80 {
    --bs-gutter-y: 5rem;
  }

  .g-xxl-96,
.gx-xxl-96 {
    --bs-gutter-x: 6rem;
  }

  .g-xxl-96,
.gy-xxl-96 {
    --bs-gutter-y: 6rem;
  }
}
table,
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #000;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #000;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #000;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1.75rem;
  color: #000;
  vertical-align: top;
  border-color: #d9dada;
}
table tr,
.table tr {
  vertical-align: top;
}
table td,
.table td {
  padding-top: 1.2rem;
  padding-bottom: 1rem;
  vertical-align: top;
}
@media (min-width: 768px) {
  table td,
.table td {
    padding-top: 1.8rem;
    padding-bottom: 1.6rem;
  }
}
table .catalog-switch,
.table .catalog-switch {
  justify-content: flex-end;
  color: #000;
}
@media (min-width: 768px) {
  table .catalog-switch,
.table .catalog-switch {
    justify-content: center;
  }
}
@media (min-width: 1024px) {
  table .catalog-switch,
.table .catalog-switch {
    justify-content: flex-start;
  }
}
table .catalog-switch .form-check,
.table .catalog-switch .form-check {
  margin-bottom: 0;
}
table .catalog-switch .form-check-input,
.table .catalog-switch .form-check-input {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
table .catalog-item,
.table .catalog-item {
  vertical-align: middle;
}
table .catalog-item__price,
.table .catalog-item__price {
  font-weight: 500;
}
@media (min-width: 768px) {
  table .catalog-item__price,
.table .catalog-item__price {
    text-align: center;
  }
}
@media (min-width: 1024px) {
  table .catalog-item__price,
.table .catalog-item__price {
    text-align: right;
  }
}
table .catalog-item__icon,
.table .catalog-item__icon {
  padding: 0.6rem 1rem;
  margin-right: 0;
  color: #fff;
  background-color: #ed7817;
}
table-more,
.table-more {
  width: 100%;
  padding: 0.75rem 1.5rem;
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  table-more,
.table-more {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
table > :not(caption) > * > *,
.table > :not(caption) > * > * {
  padding: 1rem 0.5rem;
  font-weight: 400;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
table > tbody,
.table > tbody {
  vertical-align: inherit;
}
table > thead,
.table > thead {
  color: #868786;
  vertical-align: bottom;
  border-top-width: 1px;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-cell-middle td {
  vertical-align: middle;
}

.table-primary {
  --bs-table-bg: #fef1d5;
  --bs-table-striped-bg: #f1e5ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5d9c0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebdfc5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e5d9c0;
}

.table-secondary {
  --bs-table-bg: #fbe4d1;
  --bs-table-striped-bg: #eed9c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2cdbc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8d3c1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e2cdbc;
}

.table-success {
  --bs-table-bg: #dbe9d9;
  --bs-table-striped-bg: #d0ddce;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5d2c3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbd8c9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c5d2c3;
}

.table-info {
  --bs-table-bg: #d2e2f9;
  --bs-table-striped-bg: #c8d7ed;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdcbe0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2d1e6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bdcbe0;
}

.table-warning {
  --bs-table-bg: #fef1d5;
  --bs-table-striped-bg: #f1e5ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5d9c0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebdfc5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e5d9c0;
}

.table-danger {
  --bs-table-bg: #f9d3d0;
  --bs-table-striped-bg: #edc8c6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0bebb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6c3c0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e0bebb;
}

.table-light {
  --bs-table-bg: #f3f2f2;
  --bs-table-striped-bg: #e7e6e6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dbdada;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e1e0e0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dbdada;
}

.table-dark {
  --bs-table-bg: #000;
  --bs-table-striped-bg: #0d0d0d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1a1a1a;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #131313;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #1a1a1a;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 319.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1023.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1279.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
/* GT Eesti Pro Font */
@font-face {
  font-family: "GT Eesti Pro Text";
  font-style: normal;
  font-weight: 400;
  src: local("GTEestiProText-Book"), local("GT Eesti Pro Text Book"), url("../../assets/fonts/GTEestiProText-Book.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "GT Eesti Pro Text";
  font-style: normal;
  font-weight: 500;
  src: local("GTEestiProText-Medium"), local("GT Eesti Pro Text Medium"), url("../../assets/fonts/GTEestiProText-Medium.woff") format("woff");
  font-display: swap;
}
/* EvrazIcon Font */
@font-face {
  font-family: EvrazIcon;
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/evraz-iconEvrazIcon.eot?6hnzjm");
  src: url("../../assets/fonts/evraz-iconEvrazIcon.eot?6hnzjm#iefix") format("embedded-opentype"), url("../../assets/fonts/evraz-icon/EvrazIcon.ttf?6hnzjm") format("truetype"), url("../../assets/fonts/evraz-icon/EvrazIcon.woff?6hnzjm") format("woff"), url("../../assets/fonts/evraz-icon/EvrazIcon.svg?6hnzjm#EvrazIcon") format("svg");
  font-display: swap;
}
[class^=icn-],
[class*=" icn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "EvrazIcon" !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: never;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icn-close-color::before {
  color: #e32112;
  content: "";
}

.icn-info-color::before {
  color: #ed7817;
  content: "";
}

.icn-percent-color::before {
  color: #e32112;
  content: "";
}

.icn-phone-color::before {
  color: #4a8f40;
  content: "";
}

.icn-question-color::before {
  color: #b1b2b2;
  content: "";
}

.icn-success-color::before {
  color: #4a8f40;
  content: "";
}

.icn-warning-color::before {
  color: #fab82e;
  content: "";
}

.icn-phone::before {
  content: "";
}

.icn-wallet::before {
  content: "";
}

.icn-wishlist::before {
  content: "";
}

.icn-wishlist-delete::before {
  content: "";
}

.icn-user-bold::before {
  content: "";
}

.icn-user::before {
  content: "";
}

.icn-trash::before {
  content: "";
}

.icn-upload::before {
  content: "";
}

.icn-small-plus::before {
  content: "";
}

.icn-sort::before {
  content: "";
}

.icn-success-circle::before {
  content: "";
}

.icn-warranty::before {
  content: "";
}

.icn-success-circle-bold::before {
  content: "";
}

.icn-warning-circle::before {
  content: "";
}

.icn-close-circle::before {
  content: "";
}

.icn-info-circle::before {
  content: "";
}

.icn-question-circle-bold::before {
  content: "";
}

.icn-question-circle::before {
  content: "";
}

.icn-close::before {
  content: "";
}

.icn-small-close::before {
  content: "";
}

.icn-shipping::before {
  content: "";
}

.icn-search::before {
  content: "";
}

.icn-saw-bold::before {
  content: "";
}

.icn-saw::before {
  content: "";
}

.icn-reload::before {
  content: "";
}

.icn-pin::before {
  content: "";
}

.icn-plus::before {
  content: "";
}

.icn-print::before {
  content: "";
}

.icn-minus::before {
  content: "";
}

.icn-options::before {
  content: "";
}

.icn-logout::before {
  content: "";
}

.icn-mail::before {
  content: "";
}

.icn-map::before {
  content: "";
}

.icn-kebab::before {
  content: "";
}

.icn-lightning::before {
  content: "";
}

.icn-location::before {
  content: "";
}

.icn-grid::before {
  content: "";
}

.icn-hamburger::before {
  content: "";
}

.icn-geoposition::before {
  content: "";
}

.icn-filter::before {
  content: "";
}

.icn-file-data::before {
  content: "";
}

.icn-file-attach::before {
  content: "";
}

.icn-file::before {
  content: "";
}

.icn-edit::before {
  content: "";
}

.icn-eye-closed::before {
  content: "";
}

.icn-eye-open::before {
  content: "";
}

.icn-crane::before {
  content: "";
}

.icn-delivery::before {
  content: "";
}

.icn-download::before {
  content: "";
}

.icn-comment-dots::before {
  content: "";
}

.icn-clock::before {
  content: "";
}

.icn-cursor::before {
  content: "";
}

.icn-chevron-right::before {
  content: "";
}

.icn-chevron-up::before {
  content: "";
}

.icn-chevron-left::before {
  content: "";
}

.icn-check::before {
  content: "";
}

.icn-chevron-down::before {
  content: "";
}

.icn-cart::before {
  content: "";
}

.icn-arrow-left::before {
  content: "";
}

.icn-arrow-right::before {
  content: "";
}

.icn-calendar::before {
  content: "";
}

.icn-bars::before {
  content: "";
}

.icn-pdf::before {
  content: "";
}

.icn-xls::before {
  content: "";
}

.icn-trolley::before {
  content: "";
}

.icn-request::before {
  content: "";
}

.icn-pipes::before {
  content: "";
}

.icn-pipe::before {
  content: "";
}

.icn-operator::before {
  content: "";
}

.icn-offer::before {
  content: "";
}

.icn-nail::before {
  content: "";
}

.icn-layer::before {
  content: "";
}

.icn-guillotine::before {
  content: "";
}

.icn-gas::before {
  content: "";
}

.icn-fittings::before {
  content: "";
}

.icn-claim::before {
  content: "";
}

.icn-calc-delivery::before {
  content: "";
}

.icn-book::before {
  content: "";
}

.icn-min::before {
  content: "";
}

.icn-big-truck::before {
  content: "";
}

.icn-medium-truck::before {
  content: "";
}

.icn-small-truck::before {
  content: "";
}

.icn-crane-truck::before {
  content: "";
}

.icn-calc::before {
  content: "";
}

.icn-truck::before {
  content: "";
}

.icn-price::before {
  content: "";
}

.icn-paper::before {
  content: "";
}

.icn-small-clock::before {
  content: "";
}

.icn-metiz::before {
  content: "";
}

.icn-bars-big::before {
  content: "";
}

.icn-credit-card::before {
  content: "";
}

.icn-doc::before {
  content: "";
}

.icn-jpg::before {
  content: "";
}

.icn-pptx::before {
  content: "";
}

.form-label {
  margin-bottom: 8px;
  font-weight: 500;
  color: #000;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.3;
  color: #000;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #868786;
}

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px 40px 8px 12px;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9dada;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #000;
  background-color: #fff;
  border-color: #ed7817;
  outline: 0;
  box-shadow: 0 0 0 4px #fbe4d1;
}
.form-control::-webkit-date-and-time-value {
  height: 1.3em;
}
.form-control::-moz-placeholder {
  color: #b1b2b2;
  opacity: 1;
}
.form-control::placeholder {
  color: #b1b2b2;
  opacity: 1;
}
.form-control:disabled, .form-control[disabled], .form-control[readonly] {
  color: #e8e9e9;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background: transparent;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #000;
  background-color: #e8e9e9;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dcdddd;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #000;
  background-color: #e8e9e9;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dcdddd;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.3;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.3em + 26px);
  padding: 12px 18px;
  font-size: 0.875rem;
  border-radius: 0;
}
.form-control-sm::file-selector-button {
  padding: 12px 18px;
  margin: -12px -18px;
  -webkit-margin-end: 18px;
  margin-inline-end: 18px;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 12px 18px;
  margin: -12px -18px;
  -webkit-margin-end: 18px;
  margin-inline-end: 18px;
}

.form-control-lg {
  min-height: calc(1.3em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.3em;
  border-radius: 0;
}
.form-control-color::-webkit-color-swatch {
  height: 1.3em;
  border-radius: 0;
}

.form-control-invalid {
  margin-top: 8px;
  font-size: 0.875rem;
  color: #e32112;
}

.date-picker ~ .icn-calendar {
  position: absolute;
  top: 9px;
  right: 12px;
  z-index: 3;
  font-size: 20px;
  color: #868786;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #d9dada;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #ed7817;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem #fbe4d1;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e8e9e9;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.form-select-sm {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 18px;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-height: 1.3rem;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}
.form-check:focus {
  box-shadow: 0 0 0 2px #fbe4d1;
}
.form-check .form-check-input {
  float: left;
  padding-left: 0.25em;
  margin-left: -1.5rem;
}
.form-check .form-check-warning {
  display: none;
  margin: 8px 0 0 8px;
}
.form-check .form-check-warning i {
  padding-right: 6px;
  font-size: 20px;
}
.form-check .form-check-warning span {
  font-size: 0.875rem;
}

.form-check-input {
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #b1b2b2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0;
}
.form-check-input[type=checkbox]:hover {
  border-color: #868786;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input[type=radio]:hover {
  border-color: #dd6908;
}
.form-check-input[type=radio]:checked ~ .form-check-warning {
  display: flex;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:checked {
  background-color: #ed7817;
  border-color: #ed7817;
}
.form-check-input:checked:hover {
  background-color: #dd6908;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #ed7817;
  border-color: #ed7817;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-check-label {
  padding-left: 0.5em;
  color: #000;
  cursor: pointer;
}

.form-switch {
  box-sizing: content-box;
  height: 24px;
  padding-left: 0;
  margin: 0;
  margin-bottom: 10px;
  cursor: pointer;
}
.form-switch .form-check-label {
  padding-left: 8px;
}
.form-switch .form-check-input {
  position: relative;
  box-sizing: content-box;
  width: 48px;
  height: 24px;
  margin: 0;
  margin-right: 0;
  cursor: pointer;
  background-color: #d9dada;
  border: none;
  border-radius: 48px;
  transition: left 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input::after {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-left: 2px;
  content: "";
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0, 0, 0, 0.2);
  transition: left 0.15s ease-in-out;
}
.form-switch .form-check-input:hover {
  background-color: #b1b2b2;
}
.form-switch .form-check-input:focus {
  box-shadow: none;
}
.form-switch .form-check-input:focus::before {
  position: absolute;
  top: -2px;
  left: -2px;
  display: block;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  padding: 4px;
  content: "";
  border: 2px solid #fbe4d1;
}
.form-switch .form-check-input:checked {
  box-sizing: content-box;
  background-color: #ed7817;
  background-image: none;
}
.form-switch .form-check-input:checked::after {
  left: 30px;
  margin-right: 2px;
  margin-left: 0;
}
.form-switch .form-check-input:disabled {
  background-color: #f3f2f2;
  border-color: #d9dada;
  border-style: solid;
  border-width: 1px;
  opacity: 1;
}
.form-switch .form-check-input:disabled::after {
  background-color: #b1b2b2;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 50%;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem #fbe4d1;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem #fbe4d1;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ed7817;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fad7b9;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d9dada;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ed7817;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fad7b9;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d9dada;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group .input-with-icon {
  position: relative;
  display: block;
  width: 100%;
}
.input-group .input-with-icon i {
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  transform: translateY(-50%);
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #e8e9e9;
  border: 1px solid #d9dada;
  border-radius: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 12px 18px;
  font-size: 0.875rem;
  border-radius: 0;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #4a8f40;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(74, 143, 64, 0.9);
  border-radius: 0;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #4a8f40;
  padding-right: calc(1.3em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234a8f40' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.325em + 0.1875rem) center;
  background-size: calc(0.65em + 0.375rem) calc(0.65em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #4a8f40;
  box-shadow: 0 0 0 0.25rem rgba(74, 143, 64, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.3em + 0.75rem);
  background-position: top calc(0.325em + 0.1875rem) right calc(0.325em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #4a8f40;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234a8f40' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.65em + 0.375rem) calc(0.65em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #4a8f40;
  box-shadow: 0 0 0 0.25rem rgba(74, 143, 64, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #4a8f40;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #4a8f40;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(74, 143, 64, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4a8f40;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e32112;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(227, 33, 18, 0.9);
  border-radius: 0;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e32112;
  padding-right: calc(1.3em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e32112'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e32112' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.325em + 0.1875rem) center;
  background-size: calc(0.65em + 0.375rem) calc(0.65em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e32112;
  box-shadow: 0 0 0 0.25rem rgba(227, 33, 18, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.3em + 0.75rem);
  background-position: top calc(0.325em + 0.1875rem) right calc(0.325em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #e32112;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e32112'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e32112' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.65em + 0.375rem) calc(0.65em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #e32112;
  box-shadow: 0 0 0 0.25rem rgba(227, 33, 18, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #e32112;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #e32112;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(227, 33, 18, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e32112;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  line-height: 1.3;
  color: #000;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid;
  padding: 0.375rem 30px;
  font-size: 0.8125rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #000;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 50%;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: #ed7817;
  border-color: #ed7817;
}
.btn-primary:hover {
  color: #fff;
  background-color: #dc7015;
  border-color: #dc7015;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #ed7817;
  border-color: #ed7817;
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ed7817;
  border-color: #ed7817;
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #ed7817;
  border-color: #ed7817;
}

.btn-danger {
  color: #fff;
  background-color: #e32112;
  border-color: #e32112;
}
.btn-danger:hover {
  color: #fff;
  background-color: #d31f11;
  border-color: #d31f11;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #e32112;
  border-color: #e32112;
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e32112;
  border-color: #e32112;
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #e32112;
  border-color: #e32112;
}

.btn-default {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-default:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .btn-default, .btn-default:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:checked + .btn-default, .btn-check:active + .btn-default, .btn-default:active, .btn-default.active, .show > .btn-default.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-default:disabled, .btn-default.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-blocked {
  color: #868786;
  background-color: #d9dada;
  border-color: #d9dada;
}
.btn-blocked:hover {
  color: #000;
  background-color: #dcdddd;
  border-color: #dcdddd;
}
.btn-check:focus + .btn-blocked, .btn-blocked:focus {
  color: #000;
  background-color: #d9dada;
  border-color: #d9dada;
}
.btn-check:checked + .btn-blocked, .btn-check:active + .btn-blocked, .btn-blocked:active, .btn-blocked.active, .show > .btn-blocked.dropdown-toggle {
  color: #000;
  background-color: #d9dada;
  border-color: #d9dada;
}
.btn-blocked:disabled, .btn-blocked.disabled {
  color: #000;
  background-color: #d9dada;
  border-color: #d9dada;
}

.btn-outline-primary {
  color: #ed7817;
  border-color: #ed7817;
  border-width: 2px;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #ed7817;
  border-color: #ed7817;
}
.btn-check:focus + .btn-outline-primary {
  color: #ed7817;
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary.dropdown-toggle.show {
  color: #ed7817;
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #ed7817;
  background-color: transparent;
}

.btn-outline-danger {
  color: #e32112;
  border-color: #e32112;
  border-width: 2px;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #e32112;
  border-color: #e32112;
}
.btn-check:focus + .btn-outline-danger {
  color: #e32112;
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger.dropdown-toggle.show {
  color: #e32112;
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #e32112;
  background-color: transparent;
}

.btn-outline-default {
  color: #000;
  border-color: #000;
  border-width: 2px;
}
.btn-outline-default:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:focus + .btn-outline-default {
  color: #000;
}
.btn-check:checked + .btn-outline-default, .btn-check:active + .btn-outline-default, .btn-outline-default.dropdown-toggle.show {
  color: #000;
}
.btn-outline-default:disabled, .btn-outline-default.disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-blocked {
  color: #d9dada;
  border-color: #d9dada;
  border-width: 2px;
}
.btn-outline-blocked:hover {
  color: #000;
  background-color: #d9dada;
  border-color: #d9dada;
}
.btn-check:focus + .btn-outline-blocked {
  color: #d9dada;
}
.btn-check:checked + .btn-outline-blocked, .btn-check:active + .btn-outline-blocked, .btn-outline-blocked.dropdown-toggle.show {
  color: #d9dada;
}
.btn-outline-blocked:disabled, .btn-outline-blocked.disabled {
  color: #d9dada;
  background-color: transparent;
}

.btn-black-orange {
  color: #000;
  border-color: #000;
  border-width: 2px;
}
.btn-black-orange:hover {
  color: #fff;
  background: #ed7817;
  border-color: #ed7817;
}

.btn-link {
  font-weight: 400;
  color: #fab82e;
  text-decoration: underline;
}
.btn-link:hover {
  color: #c89325;
}
.btn-link:disabled, .btn-link.disabled {
  color: #868786;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 12px 18px;
  font-size: 0.875rem;
  border-radius: 0;
}

.filter-btn {
  width: 275px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px #d9dada;
}
.filter-btn.active {
  box-shadow: inset 0 0 0 2px #ed7817;
}
.filter-btn .badge {
  margin-left: 4px;
  font-weight: 500;
}
.filter-btn + .filter-btn {
  margin-left: 12px;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-arrow {
  display: inline-block;
}
.dropdown-toggle[aria-expanded=true] .dropdown-arrow {
  transform: rotate(180deg);
}

.dropdown-menu {
  font-size: 1rem;
  border-radius: 0;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0;
  margin: 0;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}
.dropdown-menu li:first-child a {
  padding-top: 21px;
}
.dropdown-menu li:last-child a {
  padding-bottom: 21px;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 320px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1280px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 17px 16px;
  clear: both;
  font-weight: 400;
  line-height: 1rem;
  color: #000;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: black;
  background-color: #f3f2f2;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868786;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #000;
}

.dropdown-menu-dark {
  color: #d9dada;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #d9dada;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #ed7817;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #d9dada;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.dropdown-filter {
  margin-right: 12px;
}
.dropdown-filter__counter {
  display: none;
  padding: 0 0.3rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #ed7817;
  border-radius: 100%;
}
.dropdown-filter__counter_visible {
  display: inline;
}
.dropdown-filter .dropdown-toggle {
  padding: 0.6rem 1rem 0.6rem 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  text-transform: initial;
  border: 1px solid #d9dada;
}
.dropdown-filter .dropdown-toggle i {
  padding-left: 0.75rem;
  vertical-align: middle;
}
.dropdown-filter .dropdown-toggle::after {
  display: none;
}
.dropdown-filter .dropdown-menu {
  min-width: 13.5rem;
  padding: 1.5rem 0;
  filter: drop-shadow(0 2px 16px rgba(0, 0, 0, 0.25));
  border-color: transparent;
}
.dropdown-filter .dropdown-menu::after {
  position: absolute;
  top: -8px;
  left: 50%;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: #fff;
  transform: translateX(-50%);
}
.dropdown-filter .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
}
.dropdown-filter .dropdown-menu .dropdown-item:first-child {
  padding-top: 0;
}
.dropdown-filter .dropdown-menu .dropdown-item:last-child {
  margin-bottom: 0.25rem;
}
.dropdown-filter .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}
.dropdown-filter .dropdown-menu .dropdown-item .form-check {
  margin-bottom: 0;
}
.dropdown-filter .dropdown-menu .dropdown-item .form-check-input[type=checkbox]:hover {
  border-color: rgba(177, 178, 178, 0.25);
}
.dropdown-filter .dropdown-menu .dropdown-item__count {
  font-size: 0.875rem;
  color: #868786;
}
.dropdown-filter .dropdown-menu .dropdown-line {
  width: 100%;
  height: 1px;
  background-color: #d9dada;
}
.dropdown-filter .dropdown-menu .dropdown-btn {
  display: block;
  width: calc(100% - 3rem);
  padding: 11px 42px;
  margin: 1.5rem auto 0;
  font-size: 0.875rem;
  font-weight: 500;
}

.select {
  position: relative;
}
.select.disabled .select-toggle {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.select-toggle {
  display: flex;
  align-items: center;
  min-width: 10rem;
  height: 40px;
  padding: 12px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #e8e9e9;
  border-radius: 0;
}
.select-toggle::after {
  position: absolute;
  right: 10px;
  display: block;
  width: 16px;
  height: 10px;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px 12px;
}
.select-toggle:hover, .select-toggle:active, .select-toggle:focus {
  border-color: #ed7817;
  box-shadow: 0 0 0 0.25rem #fbe4d1;
}
.select-toggle.show::after {
  transform: rotate(180deg);
}

.select-menu {
  position: absolute;
  top: -2px !important;
  z-index: 1000;
  display: none;
  min-width: 100%;
  padding-left: 0;
  margin: 0;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3), 0 1px 7px 2px rgba(0, 0, 0, 0.03);
  font-size: 1rem;
}
.select-menu li {
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
}
.select-menu li:hover, .select-menu li:focus, .select-menu li:visited {
  color: #fab82e;
  background-color: #fff;
}

.select-menu-start {
  --bs-position: start;
}
.select-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.select-menu-end {
  --bs-position: end;
}
.select-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 320px) {
  .select-menu-sm-start {
    --bs-position: start;
  }
  .select-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .select-menu-sm-end {
    --bs-position: end;
  }
  .select-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .select-menu-md-start {
    --bs-position: start;
  }
  .select-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .select-menu-md-end {
    --bs-position: end;
  }
  .select-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .select-menu-lg-start {
    --bs-position: start;
  }
  .select-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .select-menu-lg-end {
    --bs-position: end;
  }
  .select-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1280px) {
  .select-menu-xl-start {
    --bs-position: start;
  }
  .select-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .select-menu-xl-end {
    --bs-position: end;
  }
  .select-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .select-menu-xxl-start {
    --bs-position: start;
  }
  .select-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .select-menu-xxl-end {
    --bs-position: end;
  }
  .select-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .select-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropend .select-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .select-toggle::after {
  vertical-align: 0;
}

.dropstart .select-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .select-toggle::before {
  vertical-align: 0;
}

.select-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.select-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #000;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.select-item:hover, .select-item:focus {
  color: black;
  background-color: #e8e9e9;
}
.select-item.active, .select-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ed7817;
}

.select-menu.show {
  display: block;
}

.select-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868786;
  white-space: nowrap;
}

.select-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #000;
}

.select-menu-dark {
  color: #d9dada;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.select-menu-dark .select-item {
  color: #d9dada;
}
.select-menu-dark .select-item:hover, .select-menu-dark .select-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.select-menu-dark .select-item.active, .select-menu-dark .select-item:active {
  color: #fff;
  background-color: #ed7817;
}
.select-menu-dark .select-item.disabled, .select-menu-dark .select-item:disabled {
  color: #adb5bd;
}
.select-menu-dark .select-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.select-menu-dark .select-item-text {
  color: #d9dada;
}
.select-menu-dark .select-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 22.5px;
  padding-left: 22.5px;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 13.5px;
  padding-left: 13.5px;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav--no-wrap {
  flex-wrap: nowrap;
}
@media (max-width: 1439.98px) {
  .nav--no-wrap {
    overflow-x: auto;
  }
}
.nav--no-wrap a {
  white-space: nowrap;
}
.nav--shrink {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 1px solid #d9dada;
}
.nav--shrink .nav-item {
  margin-bottom: -1px;
}
.nav--shrink .nav-item:not(:last-child) .nav-link {
  margin-right: 24px;
}
.nav--shrink .nav-item .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.nav--shrink .nav-item .nav-link:not(.active) {
  border-bottom: none;
}

.nav-link {
  display: block;
  padding: 1rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 140%;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  border-bottom: 1px solid #d9dada;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link--bold {
  font-weight: 500;
}
.nav-link--bold:first-child {
  padding-left: 0;
}
.nav-link--bold:last-child {
  padding-right: 0;
}
.nav-link:hover {
  padding-bottom: calc(1rem - 1px);
  color: #ed7817;
  border-bottom: 2px solid #ed7817;
}
.nav-link:active, .nav-link.active {
  padding-bottom: calc(1rem - 1px);
  color: #000;
  border-bottom: 2px solid #ed7817;
}
.nav-link:focus {
  padding-bottom: calc(1rem - 1px);
  color: #000;
  border-bottom: 2px solid #ed7817;
  box-shadow: 0 0 0 2px #fbe4d1;
}
.nav-link.disabled {
  color: #868786;
  pointer-events: none;
  cursor: default;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border-bottom: none;
}
.nav-tabs .nav-link--bold {
  font-weight: 500;
}
.nav-tabs .nav-link--bold:first-child {
  padding-left: 0;
}
.nav-tabs .nav-link--bold:last-child {
  padding-right: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  color: #000;
  border: none;
  isolation: isolate;
  box-shadow: none;
}
.nav-tabs .nav-link.disabled {
  color: #868786;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000;
  background-color: #fff;
  border: 1px solid #d9dada;
  border-bottom: none;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ed7817;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.news-page {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .news-page {
    margin-bottom: 80px;
  }
}
.news-page__items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px 24px;
}
@media (min-width: 768px) {
  .news-page__items {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1280px) {
  .news-page__items {
    grid-template-columns: repeat(4, 1fr);
  }
}
.news-page__item {
  color: unset;
  text-decoration: none;
}
.news-page__item:hover {
  color: #ed7817;
  text-decoration: none;
}
.news-page__item img {
  width: 100%;
  height: 170px;
  -o-object-fit: cover;
  object-fit: cover;
}
.news-page__item-date {
  padding: 16px 0 10px;
  font-size: 0.875rem;
  color: #565655;
}
.news-page__btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.article-page {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .article-page {
    margin-bottom: 80px;
  }
}
.article-page__content-wrapper {
  max-width: 648px;
  margin: 16px auto 32px;
}
.article-page__content-wrapper img {
  width: 100%;
  height: 354px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 24px;
}
.article-page p + p {
  margin-top: 10px;
}
.article-page h1, .article-page .h1 {
  margin-bottom: 24px;
  line-height: 44px;
}
.article-page h2, .article-page .h2 {
  margin-bottom: 16px;
}
.article-page__date {
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 0.875rem;
  color: #565655;
}
.article-page__back-link {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #565655;
  text-decoration: none;
}
.article-page__back-link:hover {
  color: #565655;
  text-decoration: none;
}
.article-page__back-link i {
  font-size: 20px;
}
.article-page__back-link span {
  padding-left: 8px;
}
.article-page__news-item {
  color: unset;
  text-decoration: none;
}
.article-page__news-item:hover {
  color: #ed7817;
  text-decoration: none;
}
.article-page__news-item img {
  width: 100%;
  height: 170px;
  -o-object-fit: cover;
  object-fit: cover;
}
.article-page__news-date {
  padding: 16px 0 10px;
  font-size: 0.875rem;
  color: #565655;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.8375rem;
  padding-bottom: 0.8375rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 320px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 320px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-item {
  font-size: 1rem;
}
.accordion-gray .accordion-item {
  background-color: #f3f2f2;
}

.accordion-body {
  padding: 15px 0 10px;
}
.accordion-gray .accordion-body {
  padding: 10px 20px;
}

.accordion-collapse.show {
  border-bottom: 1px solid #d9dada;
}

.accordion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  background-color: #fff;
  border: 0;
  overflow-anchor: none;
}
.accordion-gray .accordion-button {
  padding: 10px 16px;
  background-color: #f3f2f2;
}
.accordion-button:not(.collapsed) i {
  transform: rotate(-180deg);
}
.accordion-button.collapsed {
  border-bottom: 1px solid #d9dada;
}
.accordion-button i {
  font-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button i {
    transition: none;
  }
}
.accordion-button:hover {
  background-color: #e8e9e9;
  border-bottom: none;
}
.accordion-gray .accordion-button:hover.collapsed {
  border-bottom: 1px solid #d9dada;
}
.accordion-button:focus {
  z-index: 3;
  border: none;
  outline: 0;
  box-shadow: inset 0 0 0 2px #fbe4d1;
}
.accordion-gray .accordion-button:focus {
  background-color: #f3f2f2;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  list-style: none;
}

.breadcrumb-item a {
  font-size: 0.875rem;
  color: #868786;
  text-decoration: none;
}
.breadcrumb-item a:hover {
  color: #ed7817;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #868786;
  content: var(--bs-breadcrumb-divider, "—") /* rtl: var(--bs-breadcrumb-divider, "—") */;
}
.breadcrumb-item.active {
  color: #b1b2b2;
}
@media (max-width: 767.98px) {
  .breadcrumb-item {
    display: none;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
  }
  .breadcrumb-item.active {
    display: block;
    color: #868786;
  }
  .breadcrumb-item.active::before {
    padding-right: 0.25rem;
    padding-left: 0;
    font-family: Evrazicon;
    content: "";
  }
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #fab82e;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #d9dada;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #c89325;
  background-color: #e8e9e9;
  border-color: #d9dada;
}
.page-link:focus {
  z-index: 3;
  color: #c89325;
  background-color: #e8e9e9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #fbe4d1;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ed7817;
  border-color: #ed7817;
}
.page-item.disabled .page-link {
  color: #868786;
  pointer-events: none;
  background-color: #fff;
  border-color: #d9dada;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.badge {
  top: 0;
  left: 100%;
  align-items: center;
  justify-content: space-around;
  min-width: 1rem;
  height: 1rem;
  padding: 2px 5px;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #ed7817;
  font-size: 0.75rem;
  border-radius: 10px;
}
.badge.square {
  border-radius: 0;
}
.badge:empty {
  display: none;
}
.badge .bg-sale {
  position: relative;
  overflow: hidden;
  color: #fff;
  background-color: #fab82e;
  transform: rotate -45deg;
}
.badge-xl {
  padding: 2px 8px;
  margin-right: 4px;
  font-size: 0.875rem;
  color: #000;
  background: #f3f2f2;
  border: 1px solid #d9dada;
  border-radius: 0;
}
.badge-xl-success {
  color: #4a8f40;
  background: #e7f0e6;
  border: 1px solid #b5d5b0;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  font-size: 1rem;
  font-weight: 500;
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1.25rem;
}

.alert-light {
  color: #000;
  background-color: #fff;
  border-color: #d9dada;
}
.alert-light .alert-link {
  color: black;
}
.alert-light svg {
  fill: #ed7817;
}

.alert-warning {
  color: #000;
  background-color: #fef4dd;
  border-color: #fde3ab;
}
.alert-warning .alert-link {
  color: black;
}
.alert-warning svg {
  fill: #fab82e;
}

.alert-success {
  color: #000;
  background-color: #e7f0e5;
  border-color: #b5d5b0;
}
.alert-success .alert-link {
  color: black;
}
.alert-success svg {
  fill: #4a8f40;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e8e9e9;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #fab82e;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #565655;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #565655;
  text-decoration: none;
  background-color: #f3f2f2;
}
.list-group-item-action:active {
  color: #000;
  background-color: #e8e9e9;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #868786;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ed7817;
  border-color: #ed7817;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 320px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1280px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #966e1c;
  background-color: #fef1d5;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #966e1c;
  background-color: #e5d9c0;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #966e1c;
  border-color: #966e1c;
}

.list-group-item-secondary {
  color: #8e480e;
  background-color: #fbe4d1;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #8e480e;
  background-color: #e2cdbc;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #8e480e;
  border-color: #8e480e;
}

.list-group-item-success {
  color: #2c5626;
  background-color: #dbe9d9;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #2c5626;
  background-color: #c5d2c3;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #2c5626;
  border-color: #2c5626;
}

.list-group-item-info {
  color: #134386;
  background-color: #d2e2f9;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #134386;
  background-color: #bdcbe0;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #134386;
  border-color: #134386;
}

.list-group-item-warning {
  color: #966e1c;
  background-color: #fef1d5;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #966e1c;
  background-color: #e5d9c0;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #966e1c;
  border-color: #966e1c;
}

.list-group-item-danger {
  color: #88140b;
  background-color: #f9d3d0;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #88140b;
  background-color: #e0bebb;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #88140b;
  border-color: #88140b;
}

.list-group-item-light {
  color: #929191;
  background-color: #fdfcfc;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #929191;
  background-color: #e4e3e3;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #929191;
  border-color: #929191;
}

.list-group-item-gray {
  color: #828383;
  background-color: #f7f8f8;
}
.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #828383;
  background-color: #dedfdf;
}
.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #828383;
  border-color: #828383;
}

.list-group-item-dark {
  color: black;
  background-color: #cccccc;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-close {
  box-sizing: content-box;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0 0;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23000'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7071 4.70726L17.4142 4.00015L16 2.58594L15.2929 3.29304L9.99997 8.58594L4.70707 3.29304L3.99997 2.58594L2.58575 4.00015L3.29286 4.70726L8.58575 10.0002L3.29286 15.293L2.58575 16.0002L3.99997 17.4144L4.70707 16.7073L9.99997 11.4144L15.2929 16.7073L16 17.4144L17.4142 16.0002L16.7071 15.293L11.4142 10.0002L16.7071 4.70726Z' /%3e%3c/svg%3e") center/1.25rem auto no-repeat;
  border: 0;
  border-radius: 0;
  opacity: 1;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  position: relative;
  width: 18rem;
  max-width: 100%;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}
.toast .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  margin: 1rem 1rem;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 0.25rem;
  font-size: 1rem;
  font-weight: 500;
  color: inherit;
}

.toast-warning {
  color: #000;
  background-color: #fab82e;
  border-color: #fab82e;
}
.toast-warning .alert-link {
  color: black;
}
.toast-warning .toast-icon {
  fill: #000;
}
.toast-warning .btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23000'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7071 4.70726L17.4142 4.00015L16 2.58594L15.2929 3.29304L9.99997 8.58594L4.70707 3.29304L3.99997 2.58594L2.58575 4.00015L3.29286 4.70726L8.58575 10.0002L3.29286 15.293L2.58575 16.0002L3.99997 17.4144L4.70707 16.7073L9.99997 11.4144L15.2929 16.7073L16 17.4144L17.4142 16.0002L16.7071 15.293L11.4142 10.0002L16.7071 4.70726Z' /%3e%3c/svg%3e");
}

.toast-success {
  color: #fff;
  background-color: #4a8f40;
  border-color: #4a8f40;
}
.toast-success .alert-link {
  color: #cccccc;
}
.toast-success .toast-icon {
  fill: #fff;
}
.toast-success .btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23fff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7071 4.70726L17.4142 4.00015L16 2.58594L15.2929 3.29304L9.99997 8.58594L4.70707 3.29304L3.99997 2.58594L2.58575 4.00015L3.29286 4.70726L8.58575 10.0002L3.29286 15.293L2.58575 16.0002L3.99997 17.4144L4.70707 16.7073L9.99997 11.4144L15.2929 16.7073L16 17.4144L17.4142 16.0002L16.7071 15.293L11.4142 10.0002L16.7071 4.70726Z' /%3e%3c/svg%3e");
}

.toast-danger {
  color: #fff;
  background-color: #e32112;
  border-color: #e32112;
}
.toast-danger .alert-link {
  color: #cccccc;
}
.toast-danger .toast-icon {
  fill: #fff;
}
.toast-danger .btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23fff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7071 4.70726L17.4142 4.00015L16 2.58594L15.2929 3.29304L9.99997 8.58594L4.70707 3.29304L3.99997 2.58594L2.58575 4.00015L3.29286 4.70726L8.58575 10.0002L3.29286 15.293L2.58575 16.0002L3.99997 17.4144L4.70707 16.7073L9.99997 11.4144L15.2929 16.7073L16 17.4144L17.4142 16.0002L16.7071 15.293L11.4142 10.0002L16.7071 4.70726Z' /%3e%3c/svg%3e");
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  width: auto;
  height: 100%;
  position: relative;
  margin: 0;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.25;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #d9dada;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
  font-size: 20px;
  color: #868786;
  cursor: pointer;
}

.modal-title {
  margin-right: 24px;
  margin-bottom: 24px;
  line-height: 1.3;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  overflow: auto;
}

.modal-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-top: 24px;
  background-color: #fff;
}
.modal-footer button {
  width: 100%;
}
.modal-footer button + button {
  margin-left: 16px;
}

@media (min-width: 768px) {
  .modal-body {
    flex-grow: 0;
  }

  .modal-close {
    top: 24px;
    right: 24px;
  }

  .modal-content {
    padding: 44px 32px;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }

  .modal-footer {
    position: relative;
    flex-wrap: wrap;
    flex-shrink: 0;
    justify-content: flex-end;
    padding: 0;
  }
  .modal-footer button {
    width: auto;
  }
}
@media (min-width: 1024px) {
  .modal-dialog {
    max-width: 800px;
    height: auto;
    margin: 1.75rem auto;
  }

  .modal-content {
    height: auto;
    padding: 44px 40px;
  }

  .modal-lg,
.modal-xl {
    max-width: 800px;
  }

  .modal-body {
    flex-grow: 1;
    overflow: unset;
  }
}
@media (min-width: 1280px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 319.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1023.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1279.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1439.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 0.5rem;
  color: #fff;
  text-align: center;
  text-transform: lowercase;
  background-color: #000;
  border-radius: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  width: 24rem;
  padding: 1.5rem;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0;
  box-shadow: 0 0.6rem 1rem -0.5rem rgba(0, 0, 0, 0.3), 0 0.06rem 0.4rem 0.12rem rgba(0, 0, 0, 0.03);
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #fff;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  position: relative;
  padding-left: calc(1.2rem + .5rem);
  margin-bottom: 0.25rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.popover-header:empty {
  display: none;
}
.popover-header::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  content: "!";
  background-color: #fab82e;
  border-radius: 50%;
}

.popover-body {
  padding: 0 calc(1.2rem + .5rem);
  margin-bottom: 1.5rem;
  color: #000;
}

.popover-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.popover-buttons .popover-button {
  padding: 0.6rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid;
}
.popover-buttons .popover-button:not(:last-child) {
  margin-right: 0.5rem;
}
.popover-buttons .popover-button-cancel {
  border-color: #000;
}
.popover-buttons .popover-button-submit {
  color: #fff;
  background-color: #ed7817;
  border-color: #ed7817;
}

.carousel {
  position: relative;
}
@media (min-width: 768px) {
  .carousel:not([data-bs-ride=false]):hover .carousel-indicators .active::after {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  .carousel:not([data-bs-ride=false]) .carousel-indicators .active {
    position: relative;
    background-color: rgba(237, 120, 23, 0.2);
  }
  .carousel:not([data-bs-ride=false]) .carousel-indicators .active::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 4px;
    content: "";
    background-color: #ed7817;
    -webkit-animation: animate-active-indicator 5s ease-out infinite;
    animation: animate-active-indicator 5s ease-out infinite;
  }
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease-in-out;
  position: relative;
  display: none;
  float: left;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: #000;
  text-align: center;
  background: none;
  border: 0;
  opacity: 1;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
  color: #000;
  text-decoration: none;
  outline: 0;
  opacity: 0.8;
}

.carousel-control-prev {
  left: -0.75rem;
}
@media (min-width: 1280px) {
  .carousel-control-prev {
    left: -0.5rem;
  }
}

.carousel-control-next {
  right: -0.75rem;
}
@media (min-width: 1280px) {
  .carousel-control-next {
    right: -0.5rem;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #d9dada;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 60%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23000'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.33091 4.58414L8.6238 5.29124L4.91424 9.0008L17.5 9.0008H18.5V11.0008H17.5L4.91424 11.0008L8.6238 14.7104L9.33091 15.4175L7.9167 16.8317L7.20959 16.1246L1.79292 10.7079L1.08582 10.0008L1.79292 9.29369L7.20959 3.87703L7.9167 3.16992L9.33091 4.58414Z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23000'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.393 3.16992L12.1001 3.87703L17.5168 9.2937L18.2239 10.0008L17.5168 10.7079L12.1001 16.1246L11.393 16.8317L9.97881 15.4175L10.6859 14.7104L14.3955 11.0008L1.80969 11.0008L0.809692 11.0008L0.809693 9.0008L1.80969 9.0008L14.3955 9.0008L10.6859 5.29124L9.97881 4.58414L11.393 3.16992Z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -2rem;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 3rem;
  height: 4px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ed7817;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.2;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .carousel-indicators [data-bs-target] {
    width: 4px;
    height: 4px;
    background-color: #d9dada;
    opacity: 1;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
@media (max-width: 767.98px) {
  .carousel-indicators .active {
    background-color: #ed7817;
    opacity: 1;
  }
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark:not([data-bs-ride=false]) .carousel-indicators .active {
  background-color: #fff;
}
.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #fff;
  opacity: 1;
}
@media (max-width: 767.98px) {
  .carousel-dark .carousel-indicators [data-bs-target] .active {
    background-color: #ed7817;
  }
}
.carousel-dark .carousel-caption {
  color: #000;
}

@media (max-width: 767.98px) {
  .carousel-multi .carousel-control-prev,
.carousel-multi .carousel-control-next {
    display: none;
  }
}
.carousel-multi .carousel-inner {
  left: 50%;
  display: flex;
  width: 100%;
  transform: translateX(-50%);
}
.carousel-multi .carousel-inner .carousel-item.active,
.carousel-multi .carousel-inner .carousel-item-next,
.carousel-multi .carousel-inner .carousel-item-prev {
  display: flex;
  width: 100%;
}
.carousel-multi .carousel-inner .carousel-item .card {
  flex: 0 0 auto;
  margin: 0 1%;
  border: none;
}
@media (max-width: 767.98px) {
  .carousel-multi .carousel-inner .carousel-item .card {
    flex: 1 1 auto;
    min-width: 80%;
    margin: 0 2.5%;
  }
}
.carousel-multi .carousel-inner .carousel-item-end,
.carousel-multi .carousel-inner .carousel-item-start {
  transform: translateX(0);
}

@-webkit-keyframes animate-active-indicator {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes animate-active-indicator {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.3;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.nav-menu {
  width: 100%;
}

.nav-menu-item {
  padding: 0 25px;
}
.nav-menu-item i {
  position: absolute;
  font-size: 32px;
}
.nav-menu-item + .nav-menu-item {
  margin-top: 35px;
  line-height: 2;
}
.nav-menu-item_grey {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-top: 24px;
  padding-right: 62px;
  padding-bottom: 24px;
  background-color: #f3f2f2;
}
.nav-menu-item_grey a {
  color: #000;
  text-decoration: none;
}
.nav-menu-item__counter {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  background: #ed7817;
  border-radius: 8px;
}

.nav-menu-header {
  padding-left: 47px;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
}
.nav-menu-header:hover {
  color: #ed7817;
  cursor: pointer;
}
.nav-menu-header:hover:not(.collapsed) {
  cursor: initial;
}
.nav-menu-header:not(.collapsed) {
  color: #ed7817;
}

.nav-menu-content {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 47px;
}
.nav-menu-content.collapsed {
  display: none;
}

.nav-menu-content-item {
  font-size: 0.875rem;
  line-height: 2.3;
  color: #000;
  text-decoration: none;
}
.nav-menu-content-item:hover {
  color: #ed7817;
  cursor: pointer;
}
.nav-menu-content-item.active {
  font-weight: 500;
  color: #ed7817;
  cursor: initial;
}

.nav-menu-secondary {
  padding: 0;
  margin: 0;
  list-style: none;
}
.nav-menu-secondary .nav-menu__item {
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 20px;
  cursor: pointer;
}
.nav-menu-secondary .nav-menu__item:hover {
  color: #ed7817;
}
.nav-menu-secondary .nav-menu__item-text {
  margin-right: 8px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}
.nav-menu-secondary .nav-menu__item.active {
  padding: 16px;
  color: #ed7817;
  border-left: 4px solid #ed7817;
}

.count-selector {
  width: 135px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.count-selector__decrement, .count-selector__increment {
  width: 33px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #868786;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #d9dada;
}
.count-selector__decrement.disabled, .count-selector__increment.disabled {
  color: #e8e9e9;
  cursor: default;
}
.count-selector__value {
  width: 67px;
  height: 100%;
  flex-grow: 1;
}
.count-selector__value input {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid #d9dada;
  border-right: none;
  border-left: none;
  outline: 0;
}
.count-selector__value input:focus {
  border: 2px solid #fab82e;
}
@media (min-width: 1024px) {
  .count-selector {
    width: 147px;
    height: 48px;
  }
}

.header-top {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #f3f2f2;
}

.header-top-col {
  display: flex;
  padding-right: 0;
  padding-left: 0;
  line-height: 40px;
}

.header-main {
  width: 100%;
  height: 56px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .header-main {
    width: 100%;
    height: 80px;
  }
}
.header-main__logo {
  width: 140px;
  height: 22px;
}
@media (min-width: 768px) {
  .header-main__logo {
    width: 180px;
    height: 28px;
  }
}
.header-main__catalog-btn {
  height: 40px;
  padding: 0 20px;
  margin-left: 56px;
  font-size: 13px;
}
.header-main__catalog-btn i {
  margin-right: 10px;
  font-size: 20px;
}
.header-main__catalog-btn .icn-close {
  display: none;
}
.header-main__catalog-btn.active .icn-bars {
  display: none;
}
.header-main__catalog-btn.active .icn-close {
  display: unset;
}
.header-main__search {
  position: relative;
  flex-grow: 1;
}
.header-main__search .input-group {
  position: relative;
  display: none;
}
@media (min-width: 1024px) {
  .header-main__search .input-group {
    display: flex;
    width: 100%;
    margin-left: 32px;
  }
}
.header-main__search .input-group .search-icn {
  position: absolute;
  z-index: 5;
  flex-grow: 0;
  padding: 10px;
  margin: 0;
  font-size: 20px;
  color: #868786;
}
.header-main__search .input-group .form-control {
  padding-left: 2.5rem;
}
.header-main__search .btn {
  position: absolute;
  right: 0;
  z-index: 3;
  align-items: center;
  width: 95px;
  font-size: 13px;
}
.header-main__search-but {
  display: flex;
  justify-content: space-between;
  width: 100px;
  margin-left: auto;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #868786;
}
@media (min-width: 1024px) {
  .header-main__search-but {
    display: none;
  }
}
.header-main__search-but i {
  font-size: 18px;
}
.header-main__cart {
  position: relative;
  z-index: 5;
  height: 100%;
}
.header-main__cart:hover {
  background-color: #fff;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}
.header-main__cart .cart-product {
  position: relative;
}
.header-main__cart .cart-product:hover > i {
  display: block;
}
.header-main__cart .cart-product:first-child {
  padding-top: 0 !important;
}
.header-main__cart .cart-product:first-child i {
  padding-top: 0;
}
.header-main__cart .cart-product:last-child {
  padding-bottom: 0 !important;
}
.header-main__cart .cart-product:not(:last-child) {
  border-bottom: 1px solid #d9dada;
}
.header-main__cart .cart-product-wrap {
  height: 294px;
  margin: 16px 8px 16px 0;
  overflow-y: scroll;
}
.header-main__cart .cart-product-wrap::-webkit-scrollbar {
  width: 6px;
}
.header-main__cart .cart-product-wrap::-webkit-scrollbar-track {
  background-color: transparent;
}
.header-main__cart .cart-product-wrap::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 6px;
}
.header-main__cart .cart-product__title {
  position: relative;
  margin-right: 24px;
}
.header-main__cart .cart-product__title a {
  width: 256px;
  overflow: hidden;
  white-space: nowrap;
}
.header-main__cart .cart-product__title-blur {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%);
}
.header-main__cart .cart-product__service {
  position: relative;
}
.header-main__cart .cart-product__service:hover > i {
  display: block;
}
.header-main__cart .cart-product-close {
  position: absolute;
  top: 2px;
  right: 7px;
  display: none;
  padding: 16px 0 0 4px;
  font-size: 0.75rem;
  font-weight: 600;
  color: #868786;
  cursor: pointer;
}
.header-main__cart .badge {
  width: 1rem;
  height: 1rem;
  display: flex;
}
.header-main__cart i {
  font-size: 20px;
  color: #000;
}
@media (max-width: 1023.98px) {
  .header-main--search-focused {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    box-sizing: border-box;
    align-items: baseline;
    justify-content: baseline;
    padding: 20px 32px;
    background: #fff;
  }
}
@media (max-width: 767.98px) {
  .header-main--search-focused {
    padding: 20px 12px;
  }
}
@media (max-width: 1023.98px) {
  .header-main--search-focused .header-main__search-btn {
    display: none;
  }
}
.header-main--search-focused .search__res,
.header-main--search-focused .search__popular,
.header-main--search-focused .search__cancel {
  display: block;
}
.header-main--search-focused .header-main__search-but,
.header-main--search-focused .header-main__logo {
  display: none;
}
.header-main--search-focused .input-group {
  display: flex;
}

.dropdown {
  display: inline-flex;
}
.dropdown:hover {
  background-color: #fff;
}
.dropdown .dropdown-toggle {
  padding: 0 16px;
}

.dropdown-location .dropdown-toggle {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.dropdown-location .dropdown-toggle i {
  font-size: 20px;
}
.dropdown-location .city {
  display: flex;
  align-items: center;
}
.dropdown-location .city span {
  margin: 0 5px;
}
.dropdown-location .dropdown-menu {
  width: 20rem;
  padding: 1.5rem 1rem;
  margin-top: -2px !important;
}
@media (min-width: 768px) {
  .dropdown-location .dropdown-menu {
    width: 48rem;
    padding: 2rem;
  }
}
@media (min-width: 1024px) {
  .dropdown-location .dropdown-menu {
    width: 13.25rem;
    padding: 1.375rem 1.25rem 1.5rem 1rem;
  }
}
@media (min-width: 1280px) {
  .dropdown-location .dropdown-menu {
    width: 13rem;
  }
}
.dropdown-location .dropdown-menu .btn {
  display: flex;
  justify-content: space-between;
  width: 18rem;
  padding: 0;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #000;
  text-transform: unset;
  background-color: #fff;
  border: none;
}
@media (min-width: 768px) {
  .dropdown-location .dropdown-menu .btn {
    width: 704px;
    font-weight: 500;
  }
}
@media (min-width: 1024px) {
  .dropdown-location .dropdown-menu .btn {
    width: 164px;
    font-weight: 400;
  }
}
.dropdown-location .dropdown-menu .btn.active, .dropdown-location .dropdown-menu .btn.focus, .dropdown-location .dropdown-menu .btn:hover {
  color: #ed7817;
  box-shadow: none;
}
.dropdown-location .dropdown-menu .btn i {
  margin-top: 0.25rem;
}
.dropdown-location .dropdown-menu .divider {
  margin: 16px 0;
  color: #d9dada;
}

.modal-catalog {
  display: flex;
  flex-direction: column;
  width: 904px;
  max-width: unset;
  margin-top: 7.5rem;
}
@media (min-width: 1280px) {
  .modal-catalog {
    width: 1320px;
  }
}
.modal-catalog .menu-catalog {
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem;
}
@media (min-width: 1280px) {
  .modal-catalog .menu-catalog {
    flex-direction: row;
  }
}
.modal-catalog .dropdown-menu-catalog {
  display: flex;
  flex-direction: row;
}
.modal-catalog .dropdown-menu-main__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.modal-catalog .dropdown-menu-main__list li {
  display: block;
  width: 33%;
  padding-top: 2rem;
}
.modal-catalog .dropdown-menu-main__list li a {
  padding: 0;
}
.modal-catalog .dropdown-menu-main__list li a:hover {
  color: #ed7817;
}
.modal-catalog .dropdown-menu-main__list li a:focus {
  box-shadow: 0 0 0 2px #fbe4d1;
}
.modal-catalog .dropdown-menu-main__list li i {
  position: absolute;
  margin-top: -0.25rem;
  font-size: 2rem;
}
.modal-catalog .dropdown-menu-main__list .dropdown-menu-item {
  margin: 0 1.5rem;
  color: #000;
  text-decoration: none;
  white-space: nowrap;
}
.modal-catalog .dropdown-menu-main__list .dropdown-menu-item__header {
  font-size: 0.875rem;
  padding-left: 47px;
  font-weight: 500;
  text-transform: uppercase;
}
.modal-catalog .dropdown-menu-main__list .dropdown-menu-item__content {
  width: 70%;
  padding-left: 4.5rem;
  margin-top: 8px;
}
@media (min-width: 1280px) {
  .modal-catalog .dropdown-menu-main__list .dropdown-menu-item__content {
    width: unset;
  }
}
.modal-catalog .dropdown-menu-main__list .dropdown-menu-item__content li {
  width: auto;
  padding-top: 0.5rem;
}
.modal-catalog .dropdown-menu-main__list .dropdown-menu-item__content-item {
  font-size: 1rem;
  line-height: 2;
  color: #000;
  text-decoration: none;
}
.modal-catalog .dropdown-menu-side {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2rem 0 0;
}
.modal-catalog .dropdown-menu-side i {
  position: absolute;
  margin-top: -0.35rem;
  font-size: 2rem;
}
@media (min-width: 1280px) {
  .modal-catalog .dropdown-menu-side i {
    margin-top: -0.25rem;
  }
}
.modal-catalog .dropdown-menu-side__item {
  display: block;
  padding: 0.8125rem;
  color: #000;
  text-decoration: none;
  background-color: #f3f2f2;
}
.modal-catalog .dropdown-menu-side__item:hover {
  color: #ed7817;
}
.modal-catalog .dropdown-menu-side__item:focus {
  box-shadow: 0 0 0 2px #fbe4d1;
}
@media (min-width: 1280px) {
  .modal-catalog .dropdown-menu-side__item {
    padding: 1.25rem 1.5rem;
  }
}
.modal-catalog .dropdown-menu-side__item-header {
  font-size: 0.875rem;
  padding-left: 47px;
  font-weight: 400;
  text-transform: uppercase;
}

.dropdown-user {
  position: relative;
  z-index: 5;
  align-items: center;
  height: 80px;
  margin-right: 16px;
  margin-left: 16px;
}
.dropdown-user:hover {
  background-color: #fff;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}
.dropdown-user .dropdown-toggle {
  display: flex;
  align-items: center;
  max-width: 224px;
  height: 100%;
  margin: 0 auto;
}
@media (min-width: 1440px) {
  .dropdown-user .dropdown-toggle {
    max-width: 320px;
  }
}
.dropdown-user .dropdown-toggle span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 4px;
  font-weight: 500;
}
.dropdown-user .dropdown-toggle i {
  position: relative;
  font-size: 20px;
}
.dropdown-user .dropdown-menu {
  width: 253px;
  margin-top: -3px !important;
  box-shadow: none;
}
@media (min-width: 1024px) {
  .dropdown-user .dropdown-menu {
    width: 261px;
  }
}
@media (min-width: 1280px) {
  .dropdown-user .dropdown-menu {
    width: 320px;
  }
}
.dropdown-user .dropdown-menu li:first-child a {
  padding-top: 19px;
}
.dropdown-user .dropdown-item {
  display: flex;
  justify-content: space-between;
  line-height: 1.25;
}
.dropdown-user .dropdown-item.logout {
  padding-top: 25px;
  color: #868786;
}
.dropdown-user .dropdown-item.logout i {
  font-size: 20px;
}
.dropdown-user .badge {
  width: 20px;
  height: 20px;
  line-height: 1.5;
}

.dropdown-contacts {
  margin-left: 40px;
  line-height: 1rem;
}
.dropdown-contacts .dropdown-toggle {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.dropdown-contacts .dropdown-toggle i {
  font-size: 20px;
}
.dropdown-contacts .dropdown-menu {
  width: 510px;
  padding: 24px;
  margin-top: -2px !important;
}
.dropdown-contacts .phone {
  display: flex;
  align-items: center;
}
.dropdown-contacts .phone span {
  margin-left: 10px;
  border-bottom: 1px dotted #000;
}
.dropdown-contacts .mail {
  display: flex;
  align-items: center;
  margin: 0 5px 0 23px;
}
.dropdown-contacts .mail span {
  margin-left: 13px;
  border-bottom: 1px dotted #000;
}

.dropdown-trigger {
  cursor: pointer;
}
.dropdown-trigger span {
  font-weight: 500;
}
.dropdown-trigger i {
  position: relative;
  top: 3px;
  font-size: 20px;
}

.header-menu {
  display: inline-flex;
  margin-bottom: 0;
  margin-left: auto;
  list-style: none;
}
.header-menu__item a {
  color: #000;
  text-decoration: none;
}
.header-menu__item a:hover {
  color: #ed7817;
}
.header-menu__item a:focus {
  border: 1px solid #fbe4d1;
}
.header-menu__item .dropdown {
  margin-top: -3px;
}
.header-menu__item .dropdown span {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.header-menu__item:not(:first-child) {
  margin-left: 30px;
}
.header-menu__more .dropdown-menu {
  width: 320px;
  margin: -2px 0 0 1px !important;
}

.title-block {
  margin-bottom: 1.5rem;
}
@media (min-width: 1024px) {
  .title-block {
    margin-bottom: 2rem;
  }
}
.title-block__brand-art {
  width: 81px;
  height: 4px;
  display: inline-flex;
  justify-content: space-between;
  min-width: 24px;
  background-color: #ed7817;
}
.title-block__brand-art::before {
  width: 43px;
  height: 4px;
  display: block;
  min-width: 43px;
  content: "";
  background-color: #fab82e;
}
.title-block__brand-art::after {
  width: 14px;
  height: 4px;
  display: block;
  min-width: 14px;
  content: "";
  background-color: #e32112;
}

.total-block {
  padding: 32px 16px;
  margin: 24px 0 0;
  color: #000;
  background-color: #fff;
}
@media (min-width: 768px) {
  .total-block {
    padding: 32px;
    margin-bottom: 24px;
  }
}
@media (min-width: 1024px) {
  .total-block {
    margin-top: 0;
  }
}
.total-block__wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 32px;
}
.total-block__title {
  margin-bottom: 24px;
  font-size: 20px;
}
@media (min-width: 768px) {
  .total-block__title {
    font-size: 24px;
  }
}
@media (min-width: 1024px) {
  .total-block__title {
    font-size: 26px;
  }
}
.total-block__item {
  display: flex;
  margin-bottom: 16px;
}
.total-block__item:last-child {
  margin-bottom: 24px;
}
.total-block__item-wrap {
  margin-bottom: 32px;
}
.total-block__item-title {
  margin-bottom: 0;
}
.total-block__item-dots {
  flex-grow: 1;
  margin-right: 4px;
  margin-left: 4px;
  border-bottom: 1px dotted #d9dada;
}
.total-block__item-price {
  margin-bottom: 0;
  font-weight: 500;
}
.total-block__item-info {
  font-size: 0.875rem;
  color: #565655;
}
.total-block__btn {
  width: 100%;
  min-height: 56px;
  padding: 16px 32px;
  margin-bottom: 0;
  font-size: 1rem;
  white-space: normal;
}
@media (min-width: 768px) {
  .total-block__btn {
    margin-bottom: 24px;
  }
}
.total-block__quick-order {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  border-bottom: 2px dotted #d9dada;
}
.total-block__quick-order:hover {
  cursor: pointer;
}
.total-block__hint {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 32px;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .total-block__hint {
    top: -32px;
  }
}
.total-block__hint i {
  font-size: 32px;
}
.total-block__hint span {
  padding-left: 24px;
  font-size: 0.875rem;
}
.total-block__consent {
  margin-bottom: 24px;
  font-size: 0.875rem;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .total-block__consent {
    text-align: center;
  }
}
.total-block__callback {
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .total-block__callback {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
  }
}
.total-block .form-check-label {
  font-size: 0.875rem;
}

.subscription {
  display: flex;
  padding: 2rem 0;
  background-color: #f3f2f2;
  border-bottom: 1px solid #d9dada;
}
.subscription__title {
  display: flex;
}
@media (min-width: 768px) {
  .subscription__title {
    display: none;
  }
}
@media (min-width: 1280px) {
  .subscription__title {
    display: flex;
    flex-basis: 33.3333%;
  }
}
.subscription__title-text {
  display: flex;
  flex-direction: column;
}
.subscription__form {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .subscription__form {
    flex-basis: 50%;
  }
}
@media (min-width: 1280px) {
  .subscription__form {
    flex-basis: 33.3333%;
    flex-direction: row;
    align-items: center;
  }
}
.subscription__form-title {
  display: none;
}
@media (min-width: 768px) {
  .subscription__form-title {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .subscription__form-title {
    display: none;
  }
}
.subscription__form-control {
  width: 100%;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .subscription__form-control {
    margin-right: -2px;
    margin-bottom: 0;
  }
}
.subscription__form-control .form-control-invalid {
  margin-top: 5px;
}
.subscription .form {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .subscription .form {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .subscription .form {
    width: 100%;
    margin-top: 0;
  }
}
.subscription button {
  width: 100%;
}
@media (min-width: 768px) {
  .subscription button {
    width: auto;
    max-width: 144px;
  }
}
@media (min-width: 1024px) {
  .subscription button {
    max-width: 134px;
  }
}
@media (min-width: 1280px) {
  .subscription button {
    max-width: 150px;
  }
}
.subscription__brand {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .subscription__brand {
    flex-basis: 50%;
    justify-content: flex-start;
  }
}
@media (min-width: 1280px) {
  .subscription__brand {
    flex: 1 0 auto;
    justify-content: center;
  }
}
@media (min-width: 1440px) {
  .subscription__brand {
    justify-content: flex-start;
  }
}
.subscription-brand-art {
  position: relative;
  width: 287px;
}
.subscription-brand-art__main {
  width: 205px;
}
@media (min-width: 1440px) {
  .subscription-brand-art__main {
    width: 290px;
  }
}
.subscription-brand-art__main img {
  width: 100px;
  height: 107px;
  position: relative;
  z-index: 2;
  margin: 0 0 -8px 48px;
}
@media (min-width: 1440px) {
  .subscription-brand-art__main img {
    width: 145px;
    height: 140px;
    margin-bottom: -4px;
    margin-left: 68px;
  }
}
.subscription-brand-art__main div:last-child {
  width: 205px;
  height: 42px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fab82e;
}
@media (min-width: 1440px) {
  .subscription-brand-art__main div:last-child {
    width: 290px;
    height: 57px;
  }
}
.subscription-brand-art__item:nth-child(2) {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 42px;
  left: 205px;
  background-color: #ed7817;
}
@media (min-width: 1440px) {
  .subscription-brand-art__item:nth-child(2) {
    width: 48px;
    height: 48px;
    bottom: 57px;
    left: 290px;
  }
}
.subscription-brand-art__item:last-child {
  width: 43px;
  height: 13px;
  position: absolute;
  bottom: 72px;
  left: 235px;
  background-color: #e32112;
}
@media (min-width: 1440px) {
  .subscription-brand-art__item:last-child {
    width: 60px;
    height: 18px;
    bottom: 105px;
    left: 338px;
  }
}

.footer {
  display: flex;
  width: 100%;
  padding: 30px 0 20px;
  background-color: #f3f2f2;
}

.footer-contacts__phone, .footer-contacts__email {
  display: flex;
  flex-direction: column;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.footer-contacts__phone a:hover, .footer-contacts__email a:hover {
  color: #ed7817;
}
.footer-contacts__phone {
  margin-top: 24px;
}
.footer-contacts__email {
  margin-top: 16px;
}
.footer-contacts__label {
  font-size: 14px;
  color: #565655;
}
.footer-contacts__value {
  margin-top: 3px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.footer-contacts__value:hover {
  color: #000;
}

.footer-menu ul {
  padding: 0;
  margin-top: 32px;
  list-style: none;
}
@media (min-width: 1024px) {
  .footer-menu ul {
    margin-top: 0;
  }
}
.footer-menu li {
  display: block;
  margin-bottom: 10px;
}
.footer-menu li a {
  font-size: 16px;
  color: #000;
  text-decoration: none;
}
.footer-menu li a:hover {
  color: #ed7817;
}
.footer-menu li a:focus {
  border: 1px solid #fbe4d1;
}

.footer-logos {
  height: 90px;
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  .footer-logos {
    display: flex;
  }
}

.footer-brand-art {
  display: flex;
  width: 100%;
  margin: 40px 0;
}
.footer-brand-art__item:nth-child(1) {
  width: 16px;
  height: 16px;
  position: relative;
  top: 4px;
  min-width: 16px;
  background-color: #fab82e;
}
.footer-brand-art__item:nth-child(2) {
  width: 100%;
  height: 4px;
  background-color: #ed7817;
}
.footer-brand-art__item:nth-child(3) {
  width: 88px;
  height: 8px;
  position: relative;
  bottom: 8px;
  min-width: 88px;
  background-color: #e32112;
}

.footer-bottom ul {
  padding: 0;
  list-style: none;
}
.footer-bottom li {
  margin-top: 10px;
}
.footer-bottom li a {
  color: #000;
  text-decoration: none;
}
.footer-bottom li a:hover {
  color: #ed7817;
}
.footer-bottom li a:focus {
  border: 1px solid #fbe4d1;
}
.footer-bottom a,
.footer-bottom span {
  font-size: 14px;
}
@media (min-width: 768px) {
  .footer-bottom ul {
    display: flex;
    justify-content: flex-end;
  }
  .footer-bottom ul li {
    margin-top: 0;
  }
  .footer-bottom ul li:not(:first-child) {
    margin-left: 20px;
  }
}

.footer-x-light {
  font-size: 0.875rem;
  background-color: #f3f2f2;
}
.footer-x-light__wrap {
  padding-top: 32px;
  padding-bottom: 32px;
  border-top: 1px solid #d9dada;
}

.footer-light {
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  line-height: 1.5em;
  background-color: #f3f2f2;
}
.footer-light__title {
  font-size: 24px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .footer-light__title {
    font-size: 32px;
    line-height: 1.2em;
  }
}
.footer-light__figure {
  position: relative;
}
.footer-light__figure img {
  position: absolute;
  top: -61px;
  left: 0;
  z-index: 1;
  width: 165px;
}
@media (min-width: 1024px) {
  .footer-light__figure img {
    top: -82px;
    width: 220px;
  }
}
.footer-light__input {
  margin-top: 10px;
  margin-top: 28px;
}
@media (min-width: 768px) {
  .footer-light__input {
    min-width: 258px;
    margin-top: 0;
  }
}
.footer-light__button {
  width: 100%;
  margin-top: 20px;
  color: #fff;
}
.footer-light__button:hover {
  color: #fff;
}
@media (min-width: 768px) {
  .footer-light__button {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .footer-light__form {
    box-sizing: content-box;
    display: flex;
    height: 40px;
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  .footer-light__form-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
@media (min-width: 1280px) {
  .footer-light {
    height: 220px;
  }
  .footer-light .container,
.footer-light .row {
    height: 100%;
  }
}

.footer-brand-art-2 {
  display: flex;
  margin-top: 80px;
}
.footer-brand-art-2__item:nth-child(1) {
  width: 100%;
  height: 47px;
  position: relative;
  top: 4px;
  width: 100%;
  height: 42px;
  background-color: #fab82e;
}
@media (min-width: 768px) {
  .footer-brand-art-2__item:nth-child(1) {
    width: 100%;
    height: 57px;
  }
}
.footer-brand-art-2__item:nth-child(2) {
  width: 30px;
  height: 28px;
  position: relative;
  top: -24px;
  min-width: 30px;
  background-color: #ed7817;
}
@media (min-width: 768px) {
  .footer-brand-art-2__item:nth-child(2) {
    width: 40px;
    height: 40px;
    top: -35px;
    min-width: 40px;
  }
}
.footer-brand-art-2__item:nth-child(3) {
  width: 43px;
  height: 13x;
  position: relative;
  top: -36px;
  min-width: 43px;
  background-color: #e32112;
}
@media (min-width: 768px) {
  .footer-brand-art-2__item:nth-child(3) {
    width: 59px;
    height: 18px;
    top: -53px;
    min-width: 59px;
  }
}

.main-page .slider {
  background-color: #fef4dd;
}
@media (min-width: 1024px) {
  .main-page .slider {
    border-bottom: 2px solid #fab82e;
  }
}
.main-page .slider .carousel {
  height: 320px;
}
@media (min-width: 768px) {
  .main-page .slider .carousel {
    height: 280px;
  }
}
@media (min-width: 1024px) {
  .main-page .slider .carousel {
    position: relative;
    width: 100%;
  }
}
.main-page .slider .carousel__main-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  margin-top: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  border-color: #000;
}
.main-page .slider .carousel__main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 0 48px;
}
@media (min-width: 768px) {
  .main-page .slider .carousel__main {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0 48px;
  }
}
@media (min-width: 1280px) {
  .main-page .slider .carousel__main {
    padding: 0;
  }
}
.main-page .slider .carousel__main-info {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  align-self: baseline;
  justify-content: center;
}
@media (min-width: 1280px) {
  .main-page .slider .carousel__main-info {
    align-self: flex-start;
    padding-top: 46px;
  }
}
.main-page .slider .carousel__main-img {
  display: flex;
  flex-basis: 50%;
  align-self: center;
}
@media (min-width: 768px) {
  .main-page .slider .carousel__main-img {
    align-self: flex-end;
  }
}
@media (min-width: 1280px) {
  .main-page .slider .carousel__main-img {
    align-self: flex-start;
    justify-content: center;
  }
}
.main-page .slider .carousel__main-img img {
  align-self: flex-end;
  max-width: 300px;
  max-height: 103px;
}
@media (min-width: 768px) {
  .main-page .slider .carousel__main-img img {
    max-width: 100%;
    max-height: initial;
  }
}
.main-page .slider .carousel__title {
  margin-bottom: 0;
  color: #000;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .main-page .slider .carousel__title {
    margin-bottom: 6px;
  }
}
.main-page .slider .carousel__subtitle {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  color: #000;
}
@media (min-width: 768px) {
  .main-page .slider .carousel__subtitle {
    font-size: 1rem;
  }
}
@media (min-width: 1280px) {
  .main-page .slider .carousel__subtitle {
    font-size: 1.25rem;
  }
}
.main-page .slider .carousel-indicators {
  bottom: -2.5rem;
}
@media (min-width: 1024px) {
  .main-page .slider .carousel-indicators {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin: 0 auto;
  }
  .main-page .slider .carousel-indicators [data-bs-target] {
    flex: 1 1 auto;
    height: 50px;
    margin: 0;
    text-indent: initial;
    background-color: #fff;
    border: none;
    box-shadow: 0 10px 30px rgba(79, 105, 118, 0.1);
    opacity: 1;
  }
  .main-page .slider .carousel-indicators [data-bs-target]::after {
    display: none;
  }
  .main-page .slider .carousel-indicators [data-bs-target]:not(:last-child) {
    border-right: 1px solid #eaeaea;
  }
  .main-page .slider .carousel-indicators .active {
    height: 60px;
    background-color: #fab82e;
    box-shadow: 0 5px 20px rgba(81, 81, 81, 0.2);
  }
  .main-page .slider .carousel-indicators .active:not(:last-child) {
    border-right: none;
  }
}
.main-page .slider .carousel-indicators__item {
  display: none;
}
.main-page .slider .carousel-indicators__item span {
  margin-left: 10px;
  font-weight: 500;
}
.main-page .slider .carousel-indicators__item i {
  font-size: 32px;
}
@media (min-width: 1024px) {
  .main-page .slider .carousel-indicators__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .main-page .slider .carousel-indicators__item span {
    font-size: 0.875rem;
  }
}
@media (min-width: 1280px) {
  .main-page .slider .carousel-indicators__item span {
    font-size: 1rem;
  }
}
.main-page .slider .carousel-indicators__item--mobile {
  width: 100%;
  height: 48px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fab82e;
}
@media (min-width: 1024px) {
  .main-page .slider .carousel-indicators__item--mobile {
    display: none;
  }
}
.main-page .price-update-info {
  padding-top: 46px;
}
@media (min-width: 768px) {
  .main-page .price-update-info {
    padding-top: 40px;
  }
}
@media (min-width: 1024px) {
  .main-page .price-update-info {
    text-align: center;
  }
}
.main-page .main-card__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .main-page .main-card__title {
    text-align: center;
  }
}
.main-page .main-card__img {
  width: 100%;
  height: 120px;
  margin-top: 15px;
  overflow: hidden;
}
.main-page .main-card__img img {
  min-width: 100%;
  min-height: 100%;
}
.main-page .main-card__button {
  width: 100%;
  height: 40px;
  font-size: 13px;
}
@media (min-width: 1024px) {
  .main-page .main-card__button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 20px;
    padding-left: 30px;
    margin: auto;
  }
}
.main-page .main-card__price {
  margin-top: 10px;
}
.main-page .main-card__price ul {
  padding-left: 0;
}
.main-page .main-card__price-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0;
}
.main-page .main-card__price-item span {
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .main-page .main-card__price-item span {
    font-size: 1rem;
  }
}
.main-page .main-card__price-item span:first-child {
  position: relative;
}
.main-page .main-card__price-item span:last-child {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-weight: 500;
}
.main-page .main-card__price-separator {
  position: relative;
  top: 4px;
  flex-grow: 1;
  height: 2px;
  margin: 0 5px;
  border-bottom: 1px dotted #b1b2b2;
}
.main-page .title-block {
  margin-bottom: 40px;
}
.main-page .title-block__subtitle {
  font-size: 20px;
}
@media (min-width: 768px) {
  .main-page .title-block {
    text-align: center;
  }
  .main-page .title-block h2, .main-page .title-block .h2 {
    font-size: 40px;
  }
  .main-page .title-block__tabs {
    display: flex;
    justify-content: center;
  }
}
.main-page .nav-link:focus {
  box-shadow: none;
}
.main-page .nav-link {
  font-size: 0.875rem;
  font-weight: 500;
}
.main-page .steps-block-container .steps-block {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .main-page .steps-block-container .steps-block {
    flex-direction: row;
    justify-content: space-between;
  }
}
.main-page .steps-note {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .main-page .steps-note {
    font-size: 12px;
  }
}
@media (min-width: 1024px) {
  .main-page .steps-note {
    font-size: 0.875rem;
  }
}
.main-page .step {
  width: calc(100% - 25px);
}
@media (min-width: 768px) {
  .main-page .step {
    min-width: calc(33.333% - 16px);
    margin-left: 16px;
  }
  .main-page .step:first-child {
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .main-page .step {
    min-width: calc(33.333% - 24px);
    margin-left: 24px;
  }
}
.main-page .step:not(:last-child) {
  margin-bottom: 16px;
}
.main-page .step__rect {
  width: 100%;
  height: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 20px;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .main-page .step__rect span {
    font-size: 14px;
  }
  .main-page .step__rect h3, .main-page .step__rect .h3 {
    font-size: 18px;
  }
}
@media (min-width: 1024px) {
  .main-page .step__rect span {
    font-size: 1rem;
  }
  .main-page .step__rect h3, .main-page .step__rect .h3 {
    font-size: 20px;
  }
}
@media (min-width: 1440px) {
  .main-page .step__rect span {
    font-size: 20px;
  }
  .main-page .step__rect h3, .main-page .step__rect .h3 {
    font-size: 26px;
  }
}
.main-page .step__rect-title {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.main-page .step__rect--primary {
  background-color: #fab82e;
}
.main-page .step__rect--secondary {
  color: #fff;
  background-color: #ed7817;
}
.main-page .step__rect--secondary h3, .main-page .step__rect--secondary .h3 {
  color: #fff;
}
.main-page .step__rect--red {
  height: 144px;
  color: #fff;
  background-color: #e32112;
}
.main-page .step__rect--red h3, .main-page .step__rect--red .h3 {
  color: #fff;
}
@media (min-width: 768px) {
  .main-page .step__rect--red {
    height: 120px;
  }
}
.main-page .step__button {
  width: 100%;
  height: 40px;
  font-size: 13px;
}
.main-page .step__button span {
  padding-left: 4px;
}
@media (min-width: 768px) {
  .main-page .step__button {
    margin-top: 8px;
  }
}
@media (min-width: 1024px) {
  .main-page .step__button {
    width: 163px;
    margin: 8px auto 0;
  }
}
.main-page .warehouse {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}
.main-page .warehouse:not(:last-child) {
  border-bottom: 1px solid #d9dada;
}
.main-page .warehouse:first-child span:first-child {
  margin-top: 20px;
}
.main-page .warehouse span {
  font-size: 1rem;
}
.main-page .warehouse__name {
  margin-top: 32px;
}
.main-page .warehouse__name, .main-page .warehouse__phone {
  font-weight: 500;
}
.main-page .warehouse__phone {
  margin-bottom: 6px;
}
.main-page .warehouse__label {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #868786;
}
.main-page .warehouses-table thead {
  border-top: none;
}
.main-page .warehouses-table thead th {
  padding-top: 0;
  font-weight: 500;
  color: #000;
}
@media (max-width: 767.98px) {
  .main-page .warehouses-table tr:nth-child(2) td {
    padding-top: 0;
  }
}
.main-page .warehouses-table tr:nth-last-child(-n+2) td {
  padding-bottom: 0;
  border-bottom: none;
}
.main-page .warehouses-table td {
  width: 25%;
}
.main-page .warehouses-table td span {
  display: inline-block;
}
@media (min-width: 768px) {
  .main-page .warehouses-table td span {
    margin-bottom: 4px;
  }
}
.main-page .warehouses-table__phone {
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .main-page .products-tabs {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: auto;
    margin-bottom: 2rem;
    overflow-x: auto;
  }
  .main-page .products-tabs::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}
.main-page .review-person {
  display: flex;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .main-page .review-person {
    margin-top: 0;
  }
}
.main-page .review-person__ava {
  width: 66px;
  height: 66px;
  border: 1px solid #d9dada;
}
.main-page .review-person__name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #000;
}
@media (min-width: 1024px) {
  .main-page .review-person__name {
    font-size: 1rem;
  }
}
.main-page .review-person__company {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.75rem;
  color: #565655;
}
@media (min-width: 1024px) {
  .main-page .review-person__company {
    font-size: 0.875rem;
  }
}
.main-page .review-person__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 66px;
  margin-left: 14px;
}
@media (min-width: 1024px) {
  .main-page .review-person__info {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-left: 30px;
  }
  .main-page .review-person__info .raiting {
    margin-top: -3px;
  }
}
.main-page .review-person__main-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;
}
.main-page .raiting img {
  width: 12px;
  height: 12px;
}
@media (min-width: 768px) {
  .main-page .review {
    padding: 20px 30px;
    border: 1px solid #d9dada;
  }
}
@media (min-width: 1024px) {
  .main-page .review {
    padding: 30px;
  }
  .main-page .review__text {
    margin-top: -30px;
    margin-left: 97px;
  }
}
.main-page .review__text {
  display: flex;
  flex-direction: column;
}
.main-page .review__short-quote {
  display: inline-block;
  margin-top: 15px;
  font-size: 0.75rem;
  font-weight: 500;
  color: #000;
}
@media (min-width: 768px) {
  .main-page .review__short-quote {
    font-size: 0.875rem;
  }
}
@media (min-width: 1024px) {
  .main-page .review__short-quote {
    font-size: 1rem;
  }
}
.main-page .review__quote {
  display: inline-block;
  padding-left: 10px;
  margin-top: 20px;
  border-left: 1px solid #b1b2b2;
}
.main-page .review__quote p {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.75rem;
  color: #868786;
}
.main-page .review__quote p:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .main-page .review__quote p {
    font-size: 0.875rem;
  }
}
.main-page .show-more-button-wrapper {
  width: 100%;
  text-align: center;
}
.main-page .show-more-button-wrapper__button {
  width: 100%;
}
@media (min-width: 1024px) {
  .main-page .show-more-button-wrapper__button {
    display: inline-flex;
    width: auto;
  }
}

.product-page time {
  font-weight: 500;
}
.product-page .title-block .price-update {
  margin-top: 8px;
  font-size: 0.875rem;
  color: #000;
}
.product-page .title-block__content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .product-page .title-block__content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .product-page .title-block__content .price-update {
    width: 120px;
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .product-page .title-block__content .price-update {
    width: auto;
  }
}
.product-page .auth-warning {
  margin-top: 20px;
  margin-bottom: 16px;
  background-color: #fef4dd;
  border: 1px solid #fde3ab;
}
@media (min-width: 768px) {
  .product-page .auth-warning {
    margin-bottom: 32px;
  }
}
.product-page .auth-warning__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
}
@media (max-width: 767.98px) {
  .product-page .auth-warning .container {
    padding-right: 0;
    padding-left: 0;
  }
}
.product-page .auth-warning a,
.product-page .auth-warning span {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .product-page .auth-warning a,
.product-page .auth-warning span {
    font-size: 1rem;
  }
}
.product-page .auth-warning .icn-user-bold {
  position: relative;
  top: 2px;
  margin-right: 10px;
  font-size: 20px;
}
.product-page .auth-warning .icn-close {
  font-size: 20px;
  cursor: pointer;
}
.product-page .auth-warning a {
  color: #ed7817;
  text-decoration: none;
}
.product-page .product-main-row {
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .product-page .product-main-row .product-main-col:nth-child(1) {
    width: calc(25% - 30px);
  }
  .product-page .product-main-row .product-main-col:nth-child(2) {
    width: calc(33.333333% + 30px);
  }
  .product-page .product-main-row .product-main-col:last-child {
    padding-right: 0;
  }
}
.product-page .gallery {
  width: 100%;
  height: 100%;
}
@media (min-width: 1024px) {
  .product-page .gallery {
    height: 221px;
  }
}
.product-page .values {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}
.product-page .values__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(25% - (8px / 4 * 3));
  height: 32px;
  margin-right: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #d9dada;
}
.product-page .values__item--selected {
  border-color: #ed7817;
  border-width: 2px;
}
.product-page .values__item:hover {
  border-color: #ed7817;
  border-width: 2px;
}
.product-page .values__item--more {
  border: none;
}
.product-page .values__item--more span {
  border-bottom: 1px dotted #000;
}
@media (max-width: 1279.98px) {
  .product-page .values__item:nth-child(4n+4) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .product-page .values__item {
    width: calc(16.666% - (8px / 6 * 5));
    margin-right: 8px;
  }
  .product-page .values__item:nth-child(6n+6) {
    margin-right: 0;
  }
}
.product-page .main-info__title {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 0.875rem;
  font-weight: 500;
}
@media (min-width: 1024px) {
  .product-page .main-info__title {
    font-size: 1rem;
  }
}
.product-page .main-info__question-icon {
  position: relative;
  top: 4px;
  font-size: 19px;
  color: #b1b2b2;
  cursor: pointer;
}
.product-page .main-info__props {
  position: relative;
  display: flex;
  flex-direction: column;
}
.product-page .main-info__prop-separator {
  position: relative;
  bottom: -12px;
  flex-grow: 1;
  height: 2px;
  margin: 0 10px;
  border-bottom: 1px dotted #b1b2b2;
}
.product-page .main-info__prop {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  text-align: left;
}
.product-page .main-info__prop span {
  font-size: 0.875rem;
}
.product-page .main-info__prop span:last-child {
  width: 110px;
}
@media (min-width: 1024px) {
  .product-page .main-info__prop span:last-child {
    width: 150px;
  }
}
.product-page .cart {
  margin-top: 32px;
}
@media (min-width: 768px) {
  .product-page .cart {
    margin-top: 0;
  }
}
.product-page .cart .nav-item a {
  padding-top: 13px;
  padding-right: 24px;
  padding-left: 24px;
  font-weight: 500;
}
.product-page .cart__content {
  padding: 26px 16px 0;
  border: 1px solid #e8e9e9;
}
@media (min-width: 768px) {
  .product-page .cart__content {
    padding: 42px 16px 0;
  }
}
@media (min-width: 1024px) {
  .product-page .cart__content {
    padding: 17px 20px 0;
  }
}
@media (min-width: 1440px) {
  .product-page .cart__content {
    padding: 17px 28px 0;
  }
}
.product-page .cart__title {
  display: inline-block;
  margin-bottom: 3px;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .product-page .cart__title {
    margin-bottom: 6px;
    font-size: 1rem;
  }
}
.product-page .cart__price {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .product-page .cart__price {
    margin-bottom: 0;
  }
}
@media (min-width: 1280px) {
  .product-page .cart__price {
    flex-direction: row;
  }
  .product-page .cart__price .cart__main-price {
    width: 40%;
  }
  .product-page .cart__price .cart__discount-price {
    width: 60%;
    margin-top: 10px;
  }
}
.product-page .cart__main-price {
  margin-bottom: 4px;
  font-size: 26px;
  font-weight: 500;
}
@media (min-width: 1024px) {
  .product-page .cart__main-price {
    margin-bottom: 0;
  }
}
.product-page .cart__main-price span {
  font-size: 20px;
}
.product-page .cart__discount-price {
  font-size: 0.875rem;
}
.product-page .cart__discount-price span {
  font-weight: 500;
}
.product-page .cart__discount-condition {
  color: #868786;
  white-space: nowrap;
}
.product-page .cart__availability {
  margin-top: 4px;
  font-size: 0.875rem;
  color: #4a8f40;
}
.product-page .cart__availability i {
  margin-right: 4px;
  font-size: 1rem;
  vertical-align: middle;
}
@media (min-width: 1024px) {
  .product-page .cart__availability {
    margin-top: 12px;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  .product-page .cart__availability i {
    margin-right: 11px;
  }
}
.product-page .cart__calculator {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 1024px) {
  .product-page .cart__calculator {
    flex-direction: row;
    margin-top: 20px;
  }
}
.product-page .cart__total-inner {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
@media (min-width: 1024px) {
  .product-page .cart__total-inner {
    flex-direction: column;
    justify-content: space-around;
  }
}
@media (min-width: 1280px) {
  .product-page .cart__total-inner {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}
.product-page .cart__amount {
  margin-bottom: 16px;
}
@media (min-width: 1024px) {
  .product-page .cart__amount {
    width: 40%;
    padding-right: 16px;
    margin-bottom: 0;
  }
}
.product-page .cart__amount .count-selector {
  width: 120px;
}
@media (max-width: 767.98px) {
  .product-page .cart__total span {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .product-page .cart__total {
    width: 60%;
    margin-left: 24px;
  }
}
@media (min-width: 1280px) {
  .product-page .cart__total {
    margin-left: 0;
  }
}
.product-page .cart__total-price {
  display: flex;
  align-items: center;
  height: 40px;
  padding-right: 12px;
  font-size: 20px;
  font-weight: 500;
  border-right: 1px solid #d9dada;
}
@media (min-width: 1024px) {
  .product-page .cart__total-price {
    align-items: flex-start;
    height: 25px;
    margin-bottom: 5px;
    border-right: none;
  }
}
@media (min-width: 1280px) {
  .product-page .cart__total-price {
    align-items: center;
    height: 40px;
    margin-bottom: 0;
    border-right: 1px solid #d9dada;
  }
}
.product-page .cart__total-product {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 10px;
  margin-top: -7px;
  font-size: 0.875rem;
  color: #868786;
}
.product-page .cart__total-product i {
  position: relative;
  top: 2px;
  font-size: 19px;
}
@media (min-width: 1024px) {
  .product-page .cart__total-product {
    flex-direction: row;
    padding-left: 0;
  }
  .product-page .cart__total-product span:first-child {
    padding-right: 4px;
    margin-right: 4px;
    border-right: 1px solid #d9dada;
  }
  .product-page .cart__total-product span:last-child {
    position: relative;
    top: 4px;
  }
}
@media (min-width: 1280px) {
  .product-page .cart__total-product {
    flex-direction: column;
    padding-left: 12px;
  }
  .product-page .cart__total-product span:first-child {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }
  .product-page .cart__total-product span:last-child {
    position: relative;
    top: 0;
  }
}
.product-page .cart__button {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.product-page .cart__button button {
  width: 100%;
  height: 56px;
}
.product-page .cart__button button i {
  margin-right: 10px;
  font-size: 20px;
}
.product-page .cart__button a {
  margin-top: 15px;
  font-size: 0.875rem;
}
@media (min-width: 1024px) {
  .product-page .cart__button {
    flex-direction: row;
    align-items: center;
    height: 56px;
    margin-top: 16px;
  }
  .product-page .cart__button button {
    width: 147px;
  }
  .product-page .cart__button a {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-left: 24px;
  }
}
@media (min-width: 1280px) {
  .product-page .cart__button button {
    width: 229px;
  }
  .product-page .cart__button a {
    margin-left: 29px;
  }
}
.product-page .cart__info {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
@media (min-width: 1024px) {
  .product-page .cart__info {
    margin-top: 10px;
  }
}
.product-page .cart__info i {
  position: relative;
  top: 4px;
  left: 4px;
  font-size: 20px;
}
.product-page .cart__info span {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 0.875rem;
  line-height: 140%;
}
@media (min-width: 768px) {
  .product-page .cart__info span {
    margin-bottom: 6px;
  }
}
@media (min-width: 1024px) {
  .product-page .cart__info span {
    margin-bottom: 10px;
  }
}
@media (min-width: 1280px) {
  .product-page .cart__info span {
    margin-bottom: 8px;
  }
}
.product-page .cart__info span:nth-child(2) {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .product-page .cart__info span:nth-child(2) {
    margin-bottom: 0;
  }
}
.product-page .cart__info span:last-child {
  margin-bottom: 11px;
}
@media (min-width: 1280px) {
  .product-page .cart__info span:last-child {
    margin-bottom: 20px;
  }
}
.product-page .cart__bottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
}
.product-page .cart__bottom::before {
  position: absolute;
  top: 0;
  left: -10px;
  display: block;
  width: calc(100% + 20px);
  height: 1px;
  content: "";
  background-color: #e8e9e9;
}
@media (min-width: 768px) {
  .product-page .cart__bottom::before {
    left: -20px;
    width: calc(100% + 40px);
  }
}
.product-page .cart__bottom span,
.product-page .cart__bottom a {
  font-size: 0.875rem;
}
.product-page .cart__bottom i {
  position: relative;
  top: 3px;
  margin-right: 4px;
  font-size: 20px;
}
@media (min-width: 1024px) {
  .product-page .cart__bottom i {
    margin-right: 8px;
  }
}
.product-page .cart__bottom a {
  margin-top: 6px;
}
.product-page .product-tabs {
  margin-top: 36px;
}
.product-page .product-tabs .product-main-tabs::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
.product-page .product-tabs .product-main-tabs .nav-link:not(.active) {
  border-bottom: none;
}
.product-page .product-tabs .nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: auto;
  margin-bottom: 2rem;
  overflow-x: auto;
  border-bottom: 1px solid #d9dada;
  scrollbar-width: none;
}
@media (min-width: 1024px) {
  .product-page .product-tabs .nav {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 1279.98px) {
  .product-page .product-tabs .nav {
    justify-content: flex-start;
  }
}
.product-page .product-tabs .nav a {
  padding-right: 0;
  padding-left: 0;
  margin-right: 30px;
}
.product-page .product-tabs .nav .nav-link {
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
}
.product-page .product-tabs .nav .nav-link:focus {
  box-shadow: none;
}
@media (min-width: 768px) {
  .product-page .product-tabs .nav .nav-link {
    font-size: 1rem;
  }
}
.product-page .product-tabs .tab-pane {
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .product-page .product-tabs .tab-pane {
    margin-bottom: 2.5rem;
  }
}
.product-page .product-tabs i {
  font-size: 40px;
}
@media (min-width: 768px) {
  .product-page .product-tabs i {
    vertical-align: middle;
  }
}
.product-page .product-tabs .delivery-title {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .product-page .product-tabs .delivery-title {
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
  }
}
.product-page .product-tabs .delivery-title__title {
  margin-top: 12px;
  margin-bottom: 8px;
  font-size: 1.125rem;
  font-weight: 500;
}
@media (min-width: 768px) {
  .product-page .product-tabs .delivery-title__title {
    margin: 0;
  }
}
.product-page .product-tabs .delivery-title__text {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .product-page .product-tabs .delivery-title__text {
    margin-left: 20px;
  }
}
.product-page .product-tabs .delivery-title__text span {
  font-weight: 500;
}
.product-page .product-tabs .delivery-title h3, .product-page .product-tabs .delivery-title .h3 {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .product-page .product-tabs .delivery-title h3, .product-page .product-tabs .delivery-title .h3 {
    margin-bottom: 4px;
  }
}
.product-page .product-tabs .delivery-table__value {
  font-weight: 500;
}
.product-page .product-tabs .delivery-table__value span {
  display: flex;
  font-size: 14px;
  font-weight: 400;
}
.product-page .product-tabs .delivery-table-title {
  display: inline-block;
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: 500;
}
.product-page .product-tabs .delivery .icn-small-truck {
  font-size: 24px;
}
.product-page .product-tabs .delivery .icn-medium-truck {
  font-size: 28px;
}
.product-page .product-tabs .delivery .icn-big-truck {
  font-size: 30px;
}
.product-page .product-tabs .delivery .icn-crane-truck {
  font-size: 37px;
}
.product-page .product-tabs .delivery-calculator {
  margin-top: 20px;
}
.product-page .product-tabs .delivery-calculator button {
  height: 40px;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 500;
  color: #000;
}
.product-page .product-tabs .delivery-calculator i {
  position: relative;
  top: -1px;
  margin-right: 10px;
  font-size: 1rem;
}
.product-page .product-tabs .delivery-nearest-date {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 24px;
  background-color: #f3f2f2;
}
.product-page .product-tabs .delivery-nearest-date span {
  font-size: 1rem;
}
.product-page .product-tabs .delivery-nearest-date i {
  position: relative;
  top: -1px;
  margin-right: 10px;
  font-size: 22px;
}
.product-page .product-tabs .warehouses-tabs {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #d9dada;
}
@media (min-width: 768px) {
  .product-page .product-tabs .warehouses-tabs {
    display: inline-flex;
    width: auto;
    border-bottom: none;
  }
}
.product-page .product-tabs .warehouses-tabs .nav-item:first-child {
  margin-right: 20px;
}
.product-page .product-tabs .warehouses-tabs .nav-link {
  align-items: center;
  justify-content: space-around;
  padding: 16px 0;
  font-size: 13px;
  background-color: transparent;
  border: none;
}
@media (min-width: 768px) {
  .product-page .product-tabs .warehouses-tabs .nav-link {
    height: 40px;
    padding: 0 20px;
    border: 1px solid #d9dada;
  }
}
.product-page .product-tabs .warehouses-tabs .active {
  border-bottom: 2px solid #ed7817;
}
@media (min-width: 768px) {
  .product-page .product-tabs .warehouses-tabs .active {
    border: 2px solid #ed7817;
  }
}
.product-page .warehouse {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 1px solid #d9dada;
}
.product-page .warehouse:first-child .warehouse__name {
  margin-top: 40px;
}
.product-page .warehouse span {
  font-size: 1rem;
}
.product-page .warehouse__name {
  margin-top: 24px;
}
.product-page .warehouse__name, .product-page .warehouse__phone {
  font-weight: 500;
}
.product-page .warehouse__phone {
  margin-bottom: 6px;
}
.product-page .warehouse__label {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #868786;
}
.product-page .warehouses-table {
  margin-top: 24px;
}
.product-page .warehouses-table td {
  width: 25%;
}
.product-page .warehouses-table__phone {
  display: inline-block;
  margin-bottom: 4px;
  font-weight: 500;
}

.balloon-office {
  position: relative;
  max-width: 500px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3), 0 1px 7px 2px rgba(0, 0, 0, 0.03);
}
.balloon-office__title {
  display: block;
  padding-bottom: 5px;
  font-size: 26px;
}
.balloon-office__route-btn {
  font-size: 16px;
  font-weight: 500;
  color: #ed7817;
  white-space: nowrap;
  cursor: pointer;
}
.balloon-office__info {
  padding-top: 20px;
}
.balloon-office__close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
  color: #565655;
}
.balloon-office__close i {
  font-size: inherit !important;
}

.office-info {
  display: flex;
  padding-bottom: 10px;
}
.office-info__phone {
  padding-bottom: 5px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.office-info__email, .office-info__phone {
  display: block;
  color: #000;
  text-decoration: none;
}
.office-info__label {
  width: 100px;
  color: #565655;
}
.office-info__content {
  width: calc(100% - 100px);
  padding-left: 50px;
  font-weight: 500;
}
.office-info__address {
  color: #000;
}

.base-wrapper {
  margin: 65px 0;
}
.base-wrapper h2, .base-wrapper .h2 {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.base-wrapper p {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  font-size: 16px;
}
.base-wrapper .content {
  line-height: 1.375;
}

.catalog-page .title-block__content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .catalog-page .title-block__content .price-update {
    width: auto;
  }
}
@media (min-width: 1024px) {
  .catalog-page .title-block__content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .catalog-page .title-block__content .price-update {
    margin-top: 0;
  }
}
.catalog-page .title-block__content_left {
  display: flex;
  align-items: baseline;
}
.catalog-page .title-block__content_left span {
  display: none;
}
@media (min-width: 768px) {
  .catalog-page .title-block__content_left span {
    display: inline-block;
    margin-left: 1rem;
    font-size: 0.875rem;
    color: #868786;
  }
}
.catalog-page .title-block .price-update {
  margin-top: 8px;
  font-size: 0.875rem;
  color: #000;
}
.catalog-page .title-block .price-update time {
  font-weight: 500;
}
.catalog-page .auth-warning {
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fef4dd;
  border: 1px solid #fde3ab;
}
@media (max-width: 767.98px) {
  .catalog-page .auth-warning {
    margin-top: 16px;
    margin-bottom: 0;
  }
}
.catalog-page .auth-warning__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
}
@media (max-width: 767.98px) {
  .catalog-page .auth-warning .container {
    padding-right: 0;
    padding-left: 0;
  }
}
.catalog-page .auth-warning a,
.catalog-page .auth-warning span {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .catalog-page .auth-warning a,
.catalog-page .auth-warning span {
    font-size: 1rem;
  }
}
.catalog-page .auth-warning .icn-user-bold {
  position: relative;
  top: 2px;
  margin-right: 10px;
  font-size: 20px;
}
.catalog-page .auth-warning .icn-close {
  font-size: 20px;
  cursor: pointer;
}
.catalog-page .auth-warning a {
  color: #ed7817;
  text-decoration: none;
}
.catalog-page .promo {
  padding: 14px 10px;
  margin-bottom: 1.5rem;
  background-color: #fef4dd;
}
.catalog-page .promo-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.catalog-page .promo-item i {
  margin-right: 0.5rem;
  font-size: 30px;
}
.catalog-page .promo-item__text {
  font-size: 14px;
}
.catalog-page .promo-item__text span {
  white-space: nowrap;
}
.catalog-page .promo-item:last-child {
  white-space: nowrap;
}
.catalog-page .icn-question-circle {
  color: #565655;
}
.catalog-page .table {
  margin-bottom: 1.75rem;
}
.catalog-page .table th,
.catalog-page .table td {
  width: 25%;
}
@media (max-width: 767.98px) {
  .catalog-page .table th,
.catalog-page .table td {
    width: 50%;
  }
}
.catalog-page .table tr {
  vertical-align: top;
}
.catalog-page .table th > a {
  color: #000;
}
.catalog-page .table .catalog-switch {
  justify-content: flex-end;
  color: #000;
}
@media (min-width: 768px) {
  .catalog-page .table .catalog-switch {
    justify-content: center;
  }
}
@media (min-width: 1024px) {
  .catalog-page .table .catalog-switch {
    justify-content: flex-start;
  }
}
.catalog-page .table .catalog-switch .form-check {
  margin-bottom: 0;
}
.catalog-page .table .catalog-switch .form-check-input {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.catalog-page .table .catalog-item {
  vertical-align: middle;
}
.catalog-page .table .catalog-item__price {
  font-weight: 500;
}
@media (min-width: 768px) {
  .catalog-page .table .catalog-item__price {
    text-align: center;
  }
}
@media (min-width: 1024px) {
  .catalog-page .table .catalog-item__price {
    text-align: right;
  }
}
.catalog-page .table .catalog-item__icon {
  padding: 10px;
  margin-right: 0;
  color: #fff;
  background-color: #ed7817;
}
@media (min-width: 768px) {
  .catalog-page .table .catalog-item__icon {
    padding: 0.6rem 1rem;
  }
}
.catalog-page .table .catalog-item-checked {
  position: relative;
}
.catalog-page .table .catalog-item-checked::after {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 3px;
  right: 4px;
  content: "";
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.75 7.33329L0 3.99996L1.25 2.88885L3.75 5.11107L8.75 0.666626L10 1.77774L3.75 7.33329Z' fill='%23000'/%3e%3c/svg%3e ");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  border-radius: 100%;
}
.catalog-page .table-more {
  width: 100%;
  padding: 0.75rem 1.5rem;
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .catalog-page .table-more {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
.catalog-page .info h2, .catalog-page .info .h2 {
  font-size: 32px;
}
.catalog-page .info p {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .catalog-page .info p {
    margin-bottom: 1rem;
  }
}
.catalog-page .filter-modal .modal-header {
  padding: 0;
  margin-bottom: 24px;
  border-bottom: none;
}
.catalog-page .filter-modal .modal-header h5, .catalog-page .filter-modal .modal-header .h5 {
  margin: 0;
}
.catalog-page .filter-modal .modal-header span {
  color: #868786;
}
.catalog-page .filter-modal .modal-header i {
  padding-right: 6px;
  vertical-align: text-top;
}
.catalog-page .filter-modal .modal-list {
  padding: 0;
  margin: 0;
}
.catalog-page .filter-modal .modal-list__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}
.catalog-page .filter-modal .modal-list__item > span:last-child {
  color: #868786;
}
.catalog-page .filter-modal .modal-list__item a {
  color: #000;
  text-decoration: none;
}
.catalog-page .sorting {
  padding: 0;
}
.catalog-page .sorting ul {
  margin: 0.25rem 0 0;
}
.catalog-page .sorting ul .dropdown-item {
  padding: 0;
}
.catalog-page .sorting ul .dropdown-item:last-child {
  margin-bottom: 0;
}
.catalog-page .sorting ul a {
  width: 100%;
  padding: 17px 25px;
  color: #000;
  text-decoration: none;
}
.catalog-page .sorting ul a:hover, .catalog-page .sorting ul a:active {
  color: #dd6908;
  background-color: #f3f2f2;
}

.checkout-cart-page {
  padding: 1.5rem 0 2rem;
}
@media (min-width: 768px) {
  .checkout-cart-page {
    padding: 1.5rem 0 4rem;
  }
}
@media (min-width: 1024px) {
  .checkout-cart-page {
    padding: 2rem 0 4rem;
  }
}
@media (max-width: 767.98px) {
  .checkout-cart-page .cart-main {
    padding: 0;
  }
}
.checkout-cart-page .cart-warning {
  order: 2;
  margin-bottom: 1.5rem;
}
@media (min-width: 1024px) {
  .checkout-cart-page .cart-warning {
    order: 1;
  }
}
.checkout-cart-page .product {
  order: 1;
  margin-bottom: 1.5rem;
}
@media (min-width: 1024px) {
  .checkout-cart-page .product {
    order: 2;
  }
}
.checkout-cart-page .product-item {
  position: relative;
  padding: 1rem;
  background-color: #fff;
}
.checkout-cart-page .product-item:not(:last-child) {
  border-bottom: 1px solid #d9dada;
}
.checkout-cart-page .product-item__close {
  margin-left: 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #868786;
  cursor: pointer;
}
.checkout-cart-page .product-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .checkout-cart-page .product-info {
    margin-bottom: 1rem;
  }
}
.checkout-cart-page .product-info__wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .checkout-cart-page .product-info__wrap {
    flex-direction: column;
    margin-bottom: 0;
  }
  .checkout-cart-page .product-info__wrap.name {
    width: 50%;
  }
  .checkout-cart-page .product-info__wrap.quantity {
    width: 25%;
    margin-left: 24px;
  }
  .checkout-cart-page .product-info__wrap.price {
    align-items: flex-end;
    width: 25%;
  }
}
@media (min-width: 1280px) {
  .checkout-cart-page .product-info__wrap.quantity {
    margin-left: 15%;
  }
}
.checkout-cart-page .product-info__title {
  display: block;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
}
.checkout-cart-page .product-info__subtitle {
  font-size: 0.875rem;
  line-height: 1.6;
  color: #868786;
  vertical-align: middle;
}
.checkout-cart-page .product-info__subtitle.black {
  color: #000;
}
.checkout-cart-page .product-info__change {
  display: block;
  font-size: 0.875rem;
  color: #565655;
  cursor: pointer;
}
.checkout-cart-page .product-info__change:hover {
  color: #dd6908;
}
.checkout-cart-page .product-service__add {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.checkout-cart-page .product-service__add:hover i,
.checkout-cart-page .product-service__add:hover span {
  color: #dd6908;
}
.checkout-cart-page .product-service__add i {
  font-size: 20px;
  color: #ed7817;
}
.checkout-cart-page .product-service__add span {
  padding-left: 4px;
  font-size: 0.875rem;
  color: #565655;
}
.checkout-cart-page .product-service-info {
  padding-left: 0.75rem;
  border-left: 1px solid #d9dada;
}
@media (min-width: 768px) {
  .checkout-cart-page .product-service-info__wrap.name {
    width: calc(50% - 16px);
  }
}
.checkout-cart-page .info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5rem 2rem;
  background-color: #fff;
}
.checkout-cart-page .info__text {
  margin-left: 1.5rem;
}
.checkout-cart-page .missing-products {
  order: 3;
  margin-bottom: 1.5rem;
  background-color: #fff;
}
.checkout-cart-page .missing-products-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem 1rem;
  color: #868786;
  border-bottom: 1px solid #d9dada;
}
.checkout-cart-page .missing-products-header__title {
  margin-bottom: 0;
  font-weight: 500;
  color: #868786;
}
.checkout-cart-page .missing-products-header__btn {
  white-space: nowrap;
  border-bottom: 1px dotted #d9dada;
}
.checkout-cart-page .missing-products-header__btn:hover {
  color: #ed7817;
  cursor: pointer;
  border-color: #ed7817;
}
.checkout-cart-page .missing-products-body {
  padding: 1rem;
  color: #868786;
}
@media (min-width: 768px) {
  .checkout-cart-page .missing-products-body {
    padding: 1.5rem 1rem;
  }
}
.checkout-cart-page .missing-products-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.checkout-cart-page .missing-products-item:not(:last-child) {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .checkout-cart-page .missing-products-item {
    flex-direction: row;
  }
}
.checkout-cart-page .missing-products-item__wrap {
  margin-bottom: 0.75rem;
}
.checkout-cart-page .missing-products-item__title {
  margin-bottom: 0.25rem;
  color: #868786;
}
.checkout-cart-page .missing-products-item__btn {
  padding: 0.75rem 1.5rem;
}
.checkout-cart-page .cross-sell {
  order: 4;
  padding: 2rem 0;
  margin-bottom: 1.5rem;
  background-color: #fff;
}
@media (min-width: 768px) {
  .checkout-cart-page .cross-sell {
    padding: 2rem;
  }
}
.checkout-cart-page .cross-sell-title {
  padding: 0 1rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .checkout-cart-page .cross-sell-title {
    padding: 0;
  }
}
.checkout-cart-page .cross-sell__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(100% - 54px);
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) {
  .checkout-cart-page .cross-sell__wrap {
    overflow: initial;
  }
}
.checkout-cart-page .cross-sell-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-right: 1rem;
}
.checkout-cart-page .cross-sell-item:first-child {
  margin-left: 1rem;
}
@media (min-width: 768px) {
  .checkout-cart-page .cross-sell-item {
    margin-right: 1.5rem;
  }
  .checkout-cart-page .cross-sell-item:first-child {
    margin-left: 0;
  }
  .checkout-cart-page .cross-sell-item:last-child {
    margin-right: 0;
  }
}
.checkout-cart-page .cross-sell-item__img {
  width: 170px;
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .checkout-cart-page .cross-sell-item__img {
    width: 193px;
  }
}
@media (min-width: 1024px) {
  .checkout-cart-page .cross-sell-item__img {
    width: 160px;
  }
}
@media (min-width: 1440px) {
  .checkout-cart-page .cross-sell-item__img {
    width: 200px;
  }
}
.checkout-cart-page .cross-sell-item__price {
  margin-bottom: 0.5rem;
}
.checkout-cart-page .cross-sell-item__price span {
  font-size: 1rem;
}
.checkout-cart-page .cross-sell-item__title {
  margin-bottom: 0.25rem;
}
.checkout-cart-page .cross-sell-item__subtitle {
  color: #565655;
}
.checkout-cart-page .cross-sell-item__btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.75rem 1.5rem;
  margin-top: 0.75rem;
  margin-right: 0;
  color: #fff;
  cursor: pointer;
  background-color: #ed7817;
}

.checkout-cart-footer {
  margin-top: 0;
}

.filter {
  margin-bottom: 1rem;
}
.filter .dropdown-toggle {
  padding: 9px 10px;
}
@media (max-width: 1023.98px) {
  .filter {
    margin-bottom: 1.5rem;
  }
  .filter .dropdown {
    display: none;
  }
  .filter .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    border: none;
  }
  .filter .dropdown-toggle span {
    margin-right: auto;
    margin-left: 0.25rem;
  }
  .filter .dropdown-toggle .icn-chevron-down {
    padding: 0;
    transform: rotate(-90deg);
  }
  .filter .dropdown-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
  }
}
.filter > button {
  display: none;
}
.filter-mobile {
  box-sizing: initial;
  margin-bottom: 0;
}
@media (max-width: 1023.98px) {
  .filter-mobile {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    padding: 2.75rem 2rem;
    background-color: #fff;
  }
}
@media (max-width: 767.98px) {
  .filter-mobile {
    padding: 1.5rem 1rem 0.85rem;
  }
}
.filter-mobile .dropdown {
  display: flex;
}
.filter-mobile .filter-button {
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 20px;
  font-weight: 500;
}
.filter-mobile .filter-button__icon:first-child {
  display: none;
}
.filter-mobile .filter-button__icon:last-child {
  display: inline;
}
.filter-mobile .filter-button .dropdown {
  display: flex;
}
@media (min-width: 768px) {
  .filter-mobile .filter-button {
    font-size: 26px;
  }
}
.filter-mobile > button {
  position: absolute;
  bottom: 24px;
  left: 50%;
  display: flex;
  width: 85%;
  margin: 0 auto;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .filter-mobile > button {
    position: static;
    width: 100%;
    margin-top: 2.25rem;
    transform: translateX(0);
  }
}
.filter-button {
  color: #000;
}
.filter-button__icon {
  margin-right: 0.5rem;
  font-size: 20px;
}
.filter-button__icon:first-child {
  display: inline;
}
.filter-button__icon:last-child {
  display: none;
}
.filter-clean {
  display: none;
  color: #868786;
}
.filter-clean i {
  padding-right: 0.3rem;
}

.service-block {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.service-block--small-card {
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
}
@media (min-width: 768px) {
  .service-block {
    grid-template-columns: repeat(2, 1fr);
  }
  .service-block--small-card {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
}
@media (min-width: 1024px) {
  .service-block {
    grid-template-columns: repeat(3, 1fr);
  }
  .service-block--small-card {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1440px) {
  .service-block {
    grid-template-columns: repeat(4, 1fr);
  }
  .service-block--small-card {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 24px;
  }
}

.service-card {
  padding-bottom: 32px;
  margin-top: 16px;
}
.service-card__img {
  width: 100%;
  height: 120px;
  margin-bottom: 24px;
  overflow: hidden;
}
.service-card__img img {
  min-width: 100%;
  min-height: 100%;
}
@media (min-width: 768px) {
  .service-card {
    padding: 24px 32px;
    margin: 0 -1px -1px 0;
    border: 1px solid #d9dada;
  }
  .service-card--small {
    padding: 0;
    border: none;
  }
}
.service-card h3, .service-card .h3 {
  margin: 0;
  font-size: 20px;
}
.service-card .prices-list {
  margin-top: 16px;
}
.service-card .prices-list ul {
  padding-left: 0;
  margin: 0;
}
.service-card .prices-list ul li:last-child {
  padding-bottom: 0;
}
.service-card .prices-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
@media (min-width: 768px) {
  .service-card .prices-list-item {
    align-items: initial;
  }
}
.service-card .prices-list-item a {
  margin-right: 5px;
}
.service-card .prices-list-item__price {
  margin-left: 5px;
  font-weight: 500;
  white-space: nowrap;
}
.service-card .prices-list-item__price i {
  position: relative;
  top: 2px;
  font-size: 20px;
}
.service-card .price-separator {
  position: relative;
  flex-grow: 1;
  height: 16px;
  border-bottom: 1px dotted #b1b2b2;
}

.service-branches__item {
  display: grid;
  grid-template-columns: 36px 1fr;
}
.service-branches__item:not(:last-child) {
  margin-bottom: 32px;
}
.service-branches__item-wrap:first-child {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .service-branches__item-wrap:first-child {
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.service-branches__item-letter {
  font-weight: 500;
  color: #868786;
}
.service-branches__item-column {
  display: grid;
  grid-auto-flow: row;
  row-gap: 32px;
}
.service-branches__item-row {
  display: flex;
  flex-direction: column;
}
.service-branches__item-row a:not(:last-child) {
  margin-bottom: 16px;
}
.service-branches__item-title {
  margin-bottom: 16px;
  font-size: 1.125rem;
  font-weight: 500;
}
.service-branches-map {
  width: 100%;
  height: 486px;
}

.news-card {
  padding: 24px 12px;
}
.news-card img {
  width: 100%;
  margin-bottom: 16px;
}
.news-card .date {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #565655;
}
.news-card .title {
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  color: #000;
}

.error-page {
  padding: 3rem 0;
}
@media (min-width: 768px) {
  .error-page {
    padding: 5rem 0;
  }
}
@media (min-width: 1024px) {
  .error-page {
    padding: 150px 0;
  }
}
.error-page .brand-art-left {
  display: flex;
}
.error-page .brand-art-left__item:first-child {
  width: 35px;
  height: 16px;
  position: relative;
  background-color: #fab82e;
}
@media (min-width: 768px) {
  .error-page .brand-art-left__item:first-child {
    width: 70px;
    height: 32px;
  }
}
@media (min-width: 1280px) {
  .error-page .brand-art-left__item:first-child {
    width: 140px;
    height: 64px;
  }
}
.error-page .brand-art-left__item:last-child {
  width: 8px;
  height: 17px;
  position: relative;
  top: 16px;
  background-color: #ed7817;
}
@media (min-width: 768px) {
  .error-page .brand-art-left__item:last-child {
    width: 16px;
    height: 34px;
    top: 32px;
  }
}
@media (min-width: 1280px) {
  .error-page .brand-art-left__item:last-child {
    width: 32px;
    height: 68px;
    top: 64px;
  }
}
.error-page .brand-art-right {
  align-self: flex-end;
}
.error-page .brand-art-right__item {
  width: 43px;
  height: 4px;
  position: relative;
  background-color: #e32112;
}
@media (min-width: 768px) {
  .error-page .brand-art-right__item {
    width: 86px;
    height: 8px;
  }
}
@media (min-width: 1280px) {
  .error-page .brand-art-right__item {
    width: 172px;
    height: 16px;
  }
}
.error-page__wrap {
  display: flex;
}
.error-page-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}
@media (min-width: 1024px) {
  .error-page-main {
    flex-direction: row;
    margin: 0 1.5rem;
  }
}
@media (min-width: 1280px) {
  .error-page-main {
    margin: 0 52px;
  }
}
.error-page-main__title {
  flex: 1 1 50%;
  font-size: 100px;
  font-weight: 500;
}
@media (min-width: 1024px) {
  .error-page-main__title {
    font-size: 180px;
    line-height: 0.75;
  }
}
.error-page-main__wrap {
  flex: 1 1 50%;
}
.error-page-main__subtitle {
  margin-bottom: 1.5rem;
}
@media (max-width: 767.98px) {
  .error-page-main__subtitle {
    font-size: 1.2rem;
  }
}
.error-page-link {
  display: flex;
}
.error-page-link__wrap {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .error-page-link__wrap {
    flex: 0 1 50%;
  }
}
.error-page-link__wrap:first-child {
  margin-right: 1rem;
}
.error-page-link__item {
  font-size: 0.75rem;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .error-page-link__item {
    font-size: 1rem;
  }
}
.error-page-link__item:first-child {
  margin-bottom: 1.5rem;
}

@media (min-width: 1440px) {
  .ts-type-radio {
    max-width: 640px;
  }
}
.ts-type-radio__cheapest-msg {
  display: flex;
  justify-content: flex-end;
}
.ts-type-radio__cheapest-msg span {
  padding: 2px 8px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: #fab82e;
}
.ts-type-radio__item-wrapper {
  position: relative;
  margin-bottom: 16px;
}
.ts-type-radio__item-wrapper:last-child {
  margin-bottom: 0;
}
.ts-type-radio__item {
  width: 100%;
  padding: 16px 16px 16px 52px;
  box-shadow: inset 0 0 0 1px #d9dada;
}
.ts-type-radio__item-title, .ts-type-radio__item-price {
  margin-bottom: 4px;
  font-size: 1rem;
  font-weight: 500;
}
.ts-type-radio__item-price {
  text-align: right;
  white-space: nowrap;
}
.ts-type-radio__item-warning {
  display: flex;
  margin-top: 16px;
}
.ts-type-radio__item-warning i {
  font-size: 20px;
}
.ts-type-radio__item-warning span {
  padding-left: 6px;
  font-size: 0.875rem;
}
.ts-type-radio__item-main {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .ts-type-radio__item-main {
    flex-basis: 60%;
  }
}
@media (min-width: 1024px) and (max-width: 1439.98px) {
  .ts-type-radio__item-main {
    flex-basis: 50%;
  }
}
@media (min-width: 1440px) {
  .ts-type-radio__item-main {
    flex-basis: 60%;
  }
}
.ts-type-radio__item-additional {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .ts-type-radio__item-additional {
    flex-basis: 40%;
    margin-top: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1439.98px) {
  .ts-type-radio__item-additional {
    flex-basis: 50%;
  }
}
@media (min-width: 1440px) {
  .ts-type-radio__item-additional {
    flex-basis: 40%;
  }
}
.ts-type-radio__item-additional i {
  margin-right: 8px;
  font-size: 30px;
}
@media (min-width: 768px) {
  .ts-type-radio__item-additional i {
    margin: 0;
  }
}
.ts-type-radio .form-check-input {
  position: absolute;
  top: 16px;
  left: 16px;
}
.ts-type-radio .form-check-input:checked ~ .ts-type-radio__item {
  box-shadow: inset 0 0 0 2px #ed7817;
}
.ts-type-radio .form-check-input[disabled] {
  background-color: #fff;
  border-color: #b1b2b2;
  opacity: 1;
}
.ts-type-radio .form-check-input[disabled] ~ .ts-type-radio__item {
  color: #868786;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #f3f2f2;
  box-shadow: inset 0 0 0 1px #d9dada;
}

.checkout-page {
  padding-bottom: 24px;
  background: #f3f2f2;
}
@media (min-width: 1440px) {
  .checkout-page__left {
    min-width: 898px;
  }
  .checkout-page__right {
    max-width: 422px;
  }
}
.checkout-page hr {
  margin: 0;
  background-color: #d9dada;
}
.checkout-page h3, .checkout-page .h3 {
  margin-bottom: 24px;
}
.checkout-page .form-switch {
  margin-bottom: 4px;
}
.checkout-page .title-block {
  padding-top: 32px;
  background-color: #f3f2f2;
}
.checkout-page .nav-buttons-block {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: auto;
  background-color: #fff;
}
.checkout-page .nav-buttons-block li {
  width: 100%;
}
.checkout-page .nav-buttons-block li + li {
  margin-left: 16px;
}
.checkout-page .nav-buttons-block__item {
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: 0 0 0 2px #d9dada;
}
.checkout-page .nav-buttons-block__item:hover, .checkout-page .nav-buttons-block__item.active {
  box-shadow: 0 0 0 2px #ed7817;
}
.checkout-page .nav-buttons-block.delivery {
  margin: 0;
}
.checkout-page .nav-buttons-block.delivery .nav-buttons-block__item {
  padding: 17px 24px;
  font-size: 1rem;
}
.checkout-page .nav-buttons-block.customer {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px;
}
@media (min-width: 1440px) {
  .checkout-page .delivery-customer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .checkout-page .delivery-customer > div:last-child {
    width: 640px;
    margin-right: 24px;
  }
}
.checkout-page .delivery-customer__login {
  display: flex;
  margin-bottom: 24px;
}
@media (min-width: 1440px) {
  .checkout-page .delivery-customer__login {
    display: block;
    margin-bottom: 0;
  }
}
.checkout-page .delivery-customer__login a {
  display: inline-block;
  margin-left: 8px;
}
@media (min-width: 1440px) {
  .checkout-page .delivery-customer__login a {
    padding-top: 4px;
    margin-left: 0;
  }
}
.checkout-page .link-button {
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: #ed7817;
  white-space: nowrap;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .checkout-page .link-button {
    margin-top: 0;
    margin-left: 24px;
  }
}
.checkout-page .link-button i {
  padding-right: 6px;
  font-size: 20px;
}
.checkout-page .link-button:hover {
  color: #dd6908;
}
.checkout-page .delivery-date {
  margin-top: 34px;
}
@media (min-width: 768px) {
  .checkout-page .delivery-date {
    display: flex;
  }
  .checkout-page .delivery-date.pickup {
    display: block;
  }
  .checkout-page .delivery-date div + div {
    margin-left: 24px;
  }
}
@media (min-width: 1024px) {
  .checkout-page .delivery-date.pickup .delivery-date__day {
    width: 225px;
  }
}
@media (min-width: 1440px) {
  .checkout-page .delivery-date.pickup .delivery-date__day {
    width: 248px;
  }
}
.checkout-page .delivery-date.pickup .delivery-date__interval {
  margin-top: 16px;
  margin-left: 0;
}
.checkout-page .delivery-date__day {
  width: 248px;
}
.checkout-page .delivery-date__interval {
  margin-top: 24px;
}
@media (min-width: 768px) {
  .checkout-page .delivery-date__interval {
    margin-top: 0;
  }
}
.checkout-page .delivery-date__interval .select {
  width: 184px;
}
.checkout-page .transport-types-info {
  margin-top: 24px;
}
.checkout-page .transport-types-info span {
  font-size: 1rem;
  color: #565655;
}
.checkout-page .transport-types-info i {
  padding-right: 6px;
  font-size: 20px;
  color: #000;
}
.checkout-page .delivery-info-text {
  font-size: 0.875rem;
  color: #565655;
}
.checkout-page .delivery-payment-info {
  font-size: 1rem;
  color: #565655;
}
.checkout-page .warehouse-distance {
  margin-top: 16px;
  color: #565655;
}
@media (min-width: 1440px) {
  .checkout-page .warehouse-distance {
    margin-top: 12px;
  }
}
.checkout-page .warehouse-distance i {
  position: relative;
  top: 2px;
  font-size: 20px;
}

.warehouse-block {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .warehouse-block {
    flex-direction: row;
  }
}
.warehouse-block__info-wrapper, .warehouse-block__map {
  width: 100%;
}
.warehouse-block__name {
  font-size: 1.125rem;
  font-weight: 500;
}
.warehouse-block__change {
  margin-left: 16px;
  font-size: 1.125rem;
  color: #ed7817;
  cursor: pointer;
}
@media (min-width: 1024px) and (max-width: 1439.98px) {
  .warehouse-block__change {
    margin-top: 8px;
    margin-left: 0;
  }
}
.warehouse-block__change:hover {
  color: #dd6908;
}
.warehouse-block__success-msg {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.warehouse-block__success-msg span {
  padding-left: 6px;
  color: #4a8f40;
}
.warehouse-block__success-msg i {
  font-size: 20px;
}
.warehouse-block__info-wrapper {
  display: flex;
}
@media (min-width: 768px) {
  .warehouse-block__info-wrapper {
    padding-right: 24px;
  }
}
.warehouse-block__info-wrapper .icn-location {
  margin-right: 10px;
  font-size: 22px;
}
.warehouse-block__map {
  margin-top: 24px;
}
@media (min-width: 768px) {
  .warehouse-block__map {
    margin-top: 0;
  }
}
.warehouse-block__map img {
  width: 100%;
  height: 220px;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (min-width: 1440px) {
  .warehouse-block__map img {
    height: 310px;
  }
}

.content-block {
  width: 100%;
  padding: 32px 16px 40px;
  background-color: #fff;
}
@media (min-width: 768px) {
  .content-block {
    padding: 32px;
  }
}

.form-check {
  max-width: 100%;
}

.cart-popup {
  padding: 40px;
}
.cart-popup .popup-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
}
.cart-popup .popup-wrapper {
  margin-bottom: 30px;
}

@media (min-width: 1024px) {
  .popup-cart .modal-dialog {
    width: 864px;
  }
}
.popup-cart .modal-content {
  padding: 24px 16px 16px;
}
@media (min-width: 768px) {
  .popup-cart .modal-content {
    padding: 0;
  }
}
.popup-cart .modal-title {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .popup-cart .modal-title {
    padding: 40px 24px 24px 40px;
    margin: 0;
    border-bottom: 1px solid #d9dada;
  }
}
.popup-cart .modal-body {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .popup-cart .modal-body {
    flex-direction: row;
    flex-grow: 1;
  }
}
.popup-cart__params {
  overflow-y: auto;
}
@media (min-width: 768px) {
  .popup-cart__params {
    width: calc(100% - 301px);
    border-right: 1px solid #d9dada;
  }
}
@media (min-width: 1024px) {
  .popup-cart__params {
    width: calc(100% - 279px);
  }
}
.popup-cart__info {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px 16px 16px;
  background-color: #fff;
}
@media (min-width: 768px) {
  .popup-cart__info {
    position: relative;
    width: 301px;
    padding: 26px 55px 40px 39px;
  }
}
@media (min-width: 1024px) {
  .popup-cart__info {
    width: 279px;
    padding: 26px 39px 40px 33px;
  }
}
.popup-cart__price {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .popup-cart__price {
    margin-bottom: 16px;
  }
}
.popup-cart__main-price {
  font-size: 26px;
  font-weight: 500;
}
.popup-cart__main-price span {
  font-size: 20px;
}
.popup-cart__discount-price {
  font-size: 0.875rem;
}
.popup-cart__discount-condition {
  color: #868786;
}
.popup-cart__subtitle {
  font-size: 0.875rem;
  font-weight: 500;
}
@media (min-width: 768px) {
  .popup-cart__subtitle {
    font-size: 1rem;
  }
}
.popup-cart__buy-click {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.popup-cart__buy-click i {
  font-size: 20px;
}
.popup-cart__buy-click span {
  margin-left: 14px;
  font-size: 0.875rem;
  border-bottom: 1px dotted #000;
}
.popup-cart__feature {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .popup-cart__feature {
    margin: 33px 40px 0;
  }
}
@media (min-width: 1024px) {
  .popup-cart__feature {
    margin-bottom: 40px;
  }
}
.popup-cart__feature-icon {
  position: relative;
  top: 4px;
  display: none;
  font-size: 20px;
  color: #b1b2b2;
  cursor: pointer;
}
@media (min-width: 768px) {
  .popup-cart__feature-icon {
    display: inline-block;
  }
}
.popup-cart__feature-values {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 4px;
}
@media (min-width: 768px) {
  .popup-cart__feature-values {
    margin-top: 10px;
  }
}
.popup-cart__feature-item {
  min-width: 72px;
  height: 32px;
  margin-right: 8px;
  margin-bottom: 10px;
}
.popup-cart__feature-item_label {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px #d9dada;
}
.popup-cart__feature-item_label:hover {
  box-shadow: inset 0 0 0 2.01px #fab82e;
}
.popup-cart__feature-item_input {
  position: absolute;
  opacity: 0;
}
.popup-cart__feature-item_input:checked ~ .popup-cart__feature-item_label {
  box-shadow: inset 0 0 0 2.01px #fab82e;
}
.popup-cart__feature-btn {
  width: 100%;
  margin-top: 24px;
}
@media (min-width: 768px) {
  .popup-cart__feature-btn {
    width: auto;
    margin-top: 115px;
  }
}
@media (min-width: 1024px) {
  .popup-cart__feature-btn {
    margin-top: 102px;
  }
}
.popup-cart__button {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
@media (min-width: 768px) {
  .popup-cart__button {
    margin-top: 16px;
  }
}
.popup-cart__button-control {
  width: 100%;
}
@media (min-width: 768px) {
  .popup-cart__button-control {
    height: 56px;
    margin-bottom: 16px;
  }
}
.popup-cart__button-control i {
  margin-right: 8px;
}
.popup-cart__button-favorite {
  padding: 0 16px;
  font-size: 20px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .popup-cart__button-favorite {
    display: none;
  }
}
@media (min-width: 768px) {
  .popup-cart .count-selector {
    width: auto;
    height: 48px;
  }
}
.popup-cart__calculator {
  display: flex;
  width: 100%;
}
@media (min-width: 768px) {
  .popup-cart__calculator {
    flex-direction: column;
  }
}
.popup-cart__total-inner {
  display: flex;
  margin-top: 3px;
}
@media (min-width: 768px) {
  .popup-cart__total-inner {
    margin-top: 0;
  }
}
.popup-cart__amount {
  margin-bottom: 16px;
}
.popup-cart__amount-title {
  display: inline-block;
  margin-bottom: 3px;
  font-size: 0.875rem;
  font-weight: 500;
}
@media (min-width: 768px) {
  .popup-cart__amount-title {
    margin-bottom: 6px;
    font-size: 1rem;
  }
}
.popup-cart__total {
  margin-left: 16px;
}
@media (max-width: 767.98px) {
  .popup-cart__total span {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .popup-cart__total {
    margin-left: 0;
  }
}
.popup-cart__total-price {
  display: flex;
  align-items: center;
  height: 40px;
  padding-right: 12px;
  font-size: 1.125rem;
  font-weight: 500;
  border-right: 1px solid #d9dada;
}
.popup-cart__total-product {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 3px;
  font-size: 0.875rem;
  color: #868786;
}
@media (min-width: 768px) {
  .popup-cart__total-product {
    margin-top: -7px;
  }
}
.popup-cart__total-product i {
  position: relative;
  top: 2px;
  font-size: 20px;
  cursor: pointer;
}

.checkout-popup {
  padding: 40px;
}
.checkout-popup .popup-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
}
.checkout-popup .popup-wrapper {
  margin-bottom: 30px;
}

.popup-empty-basket__text {
  font-size: 1rem;
}
.popup-empty-basket__text:first-child {
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .popup-empty-basket .modal-dialog {
    width: 568px;
  }
}

@media (min-width: 1024px) {
  .popup-quick-order .modal-dialog {
    width: 568px;
  }
}
.popup-quick-order__consent {
  font-size: 0.875rem;
}

.popup-item-info__name {
  font-size: 0.875rem;
  font-weight: 500;
}
@media (min-width: 1024px) {
  .popup-item-info__name {
    font-size: 1rem;
  }
}
.popup-item-info__length {
  margin-top: 4px;
  font-size: 0.875rem;
  color: #565655;
}
@media (min-width: 768px) {
  .popup-item-info__length {
    font-size: 1rem;
  }
}
.popup-item-info__type {
  margin-left: 4px;
  font-size: 0.875rem;
  font-weight: 500;
}
.popup-item-info__type--mobile {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  color: #565655;
}
@media (min-width: 768px) {
  .popup-item-info__type--mobile {
    font-size: 1rem;
  }
}
.popup-item-info__quantity {
  font-weight: 500;
}

@media (min-width: 1024px) {
  .popup-error .modal-content {
    padding: 0;
  }
}
.popup-error .modal-footer {
  justify-content: flex-start;
}
@media (min-width: 1024px) {
  .popup-error .modal-footer {
    margin: 28px 70px 74px;
  }
}
.popup-error img {
  width: 64px;
  height: 64px;
  margin-bottom: 28px;
}
@media (min-width: 1024px) {
  .popup-error .modal-dialog {
    width: 540px;
  }
  .popup-error .modal-body {
    margin: 74px 70px 0;
  }
}

@media (min-width: 1024px) {
  .popup-add-product .modal-dialog {
    width: 568px;
  }
}
.popup-add-product .nav-item a {
  font-weight: 500;
}
.popup-add-product__content {
  padding: 24px;
  border: 1px solid #e8e9e9;
}
.popup-add-product__title {
  display: inline-block;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 500;
}
.popup-add-product__price {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1280px) {
  .popup-add-product__price {
    flex-direction: row;
  }
  .popup-add-product__price .cart__main-price,
.popup-add-product__price .cart__discount-price {
    width: 50%;
  }
  .popup-add-product__price .cart__discount-price {
    margin-top: 10px;
  }
}
.popup-add-product__main-price {
  flex-basis: 50%;
  font-size: 26px;
  font-weight: 500;
}
.popup-add-product__discount-price {
  flex-basis: 50%;
  margin-left: 16px;
  font-size: 0.875rem;
}
.popup-add-product__discount-condition {
  margin-left: 5px;
  color: #868786;
}
.popup-add-product__calculator {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}
@media (min-width: 768px) {
  .popup-add-product__calculator {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .popup-add-product__total {
    margin-left: 24px;
  }
}
.popup-add-product__total-price {
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-size: 26px;
  font-weight: 500;
  border-right: 1px solid #d9dada;
}
.popup-add-product__total-info {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  font-size: 0.875rem;
  color: #868786;
}
.popup-add-product__total-info i {
  position: relative;
  top: 2px;
  font-size: 19px;
}

@media (min-width: 1024px) {
  .popup-auth .modal-dialog {
    width: 540px;
  }
  .popup-auth .modal-content {
    padding: 74px 72px 79px;
  }
}
.popup-auth form {
  margin: 16px 0 24px;
}
@media (min-width: 1024px) {
  .popup-auth form {
    margin-top: 28px 0;
  }
}
.popup-auth__social-wrap {
  margin-top: 24px;
}
@media (min-width: 1024px) {
  .popup-auth__social-wrap {
    margin-top: 32px;
  }
}
@media (min-width: 1024px) {
  .popup-auth__social-text {
    font-weight: 500;
  }
}
.popup-auth__social-facebook {
  width: 27px;
}
.popup-auth__social-google {
  width: 29px;
  margin-left: 16px;
}

.warehouse-popup {
  padding: 40px;
}
.warehouse-popup .popup-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
}
.warehouse-popup .popup-wrapper {
  margin-bottom: 30px;
}

.popup-warehouse .modal-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media (min-width: 1024px) {
  .popup-warehouse .modal-body {
    flex-direction: row;
  }
}
.popup-warehouse .modal-content {
  padding: 0;
}
@media (max-width: 767.98px) {
  .popup-warehouse__map {
    order: 2;
    height: calc(100vh - 150px) !important;
  }
}
@media (min-width: 1024px) {
  .popup-warehouse__map {
    width: calc(100% - 360px);
    opacity: 1 !important;
  }
}
.popup-warehouse__map--full {
  width: 100%;
  padding-top: 70px;
}
.popup-warehouse__map img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.popup-warehouse__list-wrap {
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 21, 51, 0.05), 0 0 32px -8px rgba(0, 21, 51, 0.1);
}
@media (min-width: 1024px) {
  .popup-warehouse__list-wrap {
    width: 360px;
  }
}
.popup-warehouse__list-title {
  padding: 24px 16px 0;
}
@media (min-width: 768px) {
  .popup-warehouse__list-title {
    padding: 44px 32px 0;
  }
}
@media (min-width: 1024px) {
  .popup-warehouse__list-title {
    padding: 32px 24px 0;
  }
}
.popup-warehouse__list-title_text {
  margin-bottom: 16px;
  font-size: 1.125rem;
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .popup-warehouse__nav {
    border-bottom: 1px solid #d9dada;
  }
}
@media (min-width: 1024px) {
  .popup-warehouse__nav {
    display: none;
  }
}
.popup-warehouse__nav-item {
  padding: 10px 0 12px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}
.popup-warehouse__nav-item:first-child {
  margin-right: 24px;
}
.popup-warehouse__nav-item:hover {
  color: #dd6908;
}
.popup-warehouse__nav-item.active, .popup-warehouse__nav-item:hover {
  border-bottom: 2px solid #ed7817;
}
@media (max-width: 767.98px) {
  .popup-warehouse__search {
    display: none;
  }
}
.popup-warehouse__search-wrap {
  padding: 16px;
  border-bottom: 1px solid #d9dada;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .popup-warehouse__search-wrap {
    border-top: 1px solid #d9dada;
  }
}
@media (min-width: 768px) {
  .popup-warehouse__search-wrap {
    padding: 16px 32px;
  }
}
@media (min-width: 1024px) {
  .popup-warehouse__search-wrap {
    padding: 16px 24px;
  }
}
.popup-warehouse__search-wrap i {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #868786;
}
.popup-warehouse__warning {
  padding: 24px 16px 16px;
  background: #f3f2f2;
  border-bottom: 1px solid #d9dada;
}
@media (min-width: 768px) {
  .popup-warehouse__warning {
    padding: 24px 32px 16px;
  }
}
@media (min-width: 1024px) {
  .popup-warehouse__warning {
    padding: 24px 24px 16px;
  }
}
.popup-warehouse__warning-title {
  margin-bottom: 10px;
  font-size: 1.125rem;
  font-weight: 500;
}
.popup-warehouse__warning i {
  font-size: 20px;
}
.popup-warehouse__warning span {
  padding-left: 6px;
  font-size: 0.875rem;
}
.popup-warehouse__item {
  position: relative;
  padding: 24px 36px 24px 16px;
  cursor: pointer;
  border-bottom: 1px solid #d9dada;
}
.popup-warehouse__item::after {
  position: absolute;
  top: calc((100% - 20px) / 2);
  right: 8px;
  font-family: EvrazIcon, serif;
  font-size: 20px;
  color: #ed7817;
  content: "";
}
@media (min-width: 768px) {
  .popup-warehouse__item {
    padding-left: 32px;
  }
}
@media (min-width: 1024px) {
  .popup-warehouse__item {
    padding: 24px 36px 24px 24px;
  }
}
.popup-warehouse__item-wrap {
  height: calc(100vh - 180px);
  overflow: auto;
}
@media (min-width: 768px) {
  .popup-warehouse__item-wrap {
    height: calc(100vh - 200px);
  }
}
@media (min-width: 1024px) {
  .popup-warehouse__item-wrap {
    height: calc(100vh - 150px);
  }
}
.popup-warehouse__item-wrap::-webkit-scrollbar {
  width: 12px;
}
.popup-warehouse__item-wrap::-webkit-scrollbar-track {
  background-color: transparent;
}
.popup-warehouse__item-wrap::-webkit-scrollbar-thumb {
  background-color: #d9dada;
  border: 3px solid #fff;
  border-radius: 6px;
}
.popup-warehouse__item-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-weight: 500;
}
.popup-warehouse__item-info span {
  white-space: nowrap;
}
.popup-warehouse__item-info span + span {
  margin-left: 16px;
}
.popup-warehouse__item-address {
  margin-bottom: 8px;
  line-height: 1.4;
}
.popup-warehouse__item-status {
  display: flex;
  align-items: center;
}
.popup-warehouse__item-status i {
  padding-right: 6px;
  font-size: 20px;
}
.popup-warehouse__info {
  padding: 16px;
}
@media (min-width: 768px) {
  .popup-warehouse__info {
    padding: 44px 32px;
  }
}
@media (min-width: 1024px) {
  .popup-warehouse__info {
    padding: 32px 24px 52px;
  }
}
.popup-warehouse__info-back {
  display: flex;
  align-items: center;
  color: #868786;
  cursor: pointer;
}
.popup-warehouse__info-back i {
  padding-right: 6px;
  font-size: 20px;
}
.popup-warehouse__info-item {
  flex-grow: 1;
  margin-left: 8px;
}
.popup-warehouse__info-warning {
  display: flex;
  padding: 16px;
  background: #fef4dd;
  border: 1px solid #fde3ab;
}
.popup-warehouse__info-warning i {
  margin-right: 8px;
  font-size: 20px;
}
.popup-warehouse__info-wrap {
  display: flex;
  margin: 16px 0;
}
@media (min-width: 1024px) {
  .popup-warehouse__info-wrap {
    margin: 32px 0 24px;
  }
}
.popup-warehouse__details {
  font-size: 0.875rem;
}
.popup-warehouse__details-label {
  margin: 16px 0 8px;
  font-weight: 500;
}
.popup-warehouse__details-product {
  width: 50%;
}
.popup-warehouse__details-col {
  width: 65px;
}
.popup-warehouse__details-col:first-child {
  width: 80px;
}
.popup-warehouse__details-title {
  display: flex;
  padding: 8px 0;
}
.popup-warehouse__details-title.gray {
  color: #565655;
}
.popup-warehouse__details-title:not(:last-child) {
  border-bottom: 1px solid #d9dada;
}

.popup-address-edit.show {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1023.98px) {
  .popup-address-edit.show {
    display: block !important;
  }
}
.popup-address-edit__title {
  margin-bottom: 4px;
  font-size: 1.25rem;
  font-weight: 500;
}
@media (min-width: 1024px) {
  .popup-address-edit__title {
    font-size: 26px;
  }
}
.popup-address-edit__subtitle {
  margin-bottom: 24px;
  color: #868786;
}
.popup-address-edit__nav {
  margin-bottom: 24px;
}
.popup-address-edit__map {
  height: calc(100vh - 154px);
}
@media (min-width: 1024px) {
  .popup-address-edit__map {
    height: auto;
    opacity: 1 !important;
  }
}
.popup-address-edit__map img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .popup-phone-edit .modal-dialog {
    width: 540px;
  }
}

.checkout-success {
  margin: 0 auto 40px;
}
@media (min-width: 1024px) {
  .checkout-success {
    max-width: 594px;
    margin-bottom: 72px;
  }
}
@media (min-width: 1280px) {
  .checkout-success {
    max-width: none;
    margin: 0 155px 40px;
  }
}
@media (min-width: 1440px) {
  .checkout-success {
    max-width: 870px;
    margin: 0 auto 40px;
  }
}
.checkout-success h1, .checkout-success .h1 {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .checkout-success h1, .checkout-success .h1 {
    margin-bottom: 16px;
  }
}
.checkout-success h3, .checkout-success .h3 {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .checkout-success h3, .checkout-success .h3 {
    margin-bottom: 24px;
  }
}
.checkout-success__wrapper {
  position: relative;
}
@media (max-width: 1023.98px) {
  .checkout-success__wrapper {
    padding: 0;
  }
}
.checkout-success__text-wrapper {
  padding: 0 12px;
}
@media (min-width: 1024px) {
  .checkout-success__text-wrapper {
    padding: 0;
  }
}
.checkout-success__text {
  font-size: 1.25rem;
}
.checkout-success .info {
  padding: 32px 16px 40px;
  margin: 24px 0;
  line-height: 1.4;
  background-color: #f3f2f2;
}
@media (min-width: 768px) {
  .checkout-success .info {
    padding: 32px 32px 40px;
    margin: 32px 12px;
  }
}
@media (min-width: 1024px) {
  .checkout-success .info {
    margin: 32px 0;
  }
}
.checkout-success .info__item-wrapper {
  padding-left: 36px;
}
.checkout-success .info__item-title {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.checkout-success .info__item-title i {
  font-size: 20px;
}
.checkout-success .info__item-title span {
  padding-left: 16px;
  color: #565655;
}
.checkout-success .info p + p {
  margin-top: 4px;
}
.checkout-success .order {
  margin: 0 12px;
}
@media (min-width: 1024px) {
  .checkout-success .order {
    margin: 0;
  }
}
.checkout-success .order__title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .checkout-success .order__title {
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 24px;
  }
}
.checkout-success .order__header {
  display: none;
  padding: 8px 0;
  color: #868786;
  border-top: 1px solid #d9dada;
  border-bottom: 1px solid #d9dada;
}
@media (min-width: 768px) {
  .checkout-success .order__header {
    display: flex;
  }
}
.checkout-success .order__header-left {
  flex-basis: 60%;
}
.checkout-success .order__header-right {
  flex-basis: 40%;
}
.checkout-success .product {
  margin-bottom: 1.5rem;
  border-top: 1px solid #d9dada;
}
@media (min-width: 768px) {
  .checkout-success .product {
    border: 0;
  }
}
.checkout-success .product-item {
  margin-bottom: 1rem;
}
.checkout-success .product-item:not(:last-child) {
  border-bottom: 1px solid #d9dada;
}
.checkout-success .product-item:first-child {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .checkout-success .product-info {
    margin-bottom: 1rem;
  }
}
.checkout-success .product-info__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .checkout-success .product-info__title {
    flex-basis: calc(60% - 20px);
    margin-right: 20px;
  }
}
.checkout-success .product-info__data {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .checkout-success .product-info__data {
    flex-basis: 40%;
    flex-direction: row;
    justify-content: space-between;
  }
}
.checkout-success .product-info__data-text {
  margin-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .checkout-success .product-info__data-text {
    margin-bottom: 0;
  }
}
.checkout-success .product-info__total, .checkout-success .product-info__subtitle {
  font-size: 0.875rem;
  color: #565655;
}
.checkout-success .product-info__subtitle {
  padding-top: 4px;
  line-height: 1.6;
}
.checkout-success .product-service__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .checkout-success .product-service__title {
    flex-basis: 60%;
    margin-bottom: 0;
  }
}
.checkout-success .product-service__info {
  padding-left: 0.75rem;
  margin-bottom: 1rem;
  border-left: 1px solid #d9dada;
}

.success-brand-art {
  position: absolute;
  display: none;
}
@media (min-width: 1024px) {
  .success-brand-art {
    display: block;
  }
}
.success-brand-art.yellow {
  background-color: #fab82e;
}
@media (min-width: 1024px) {
  .success-brand-art.yellow {
    width: 99px;
    height: 64px;
    top: 368px;
    left: 0;
  }
}
@media (min-width: 1440px) {
  .success-brand-art.yellow {
    width: 140px;
    height: 64px;
  }
}
.success-brand-art.orange {
  background-color: #ed7817;
}
@media (min-width: 1024px) {
  .success-brand-art.orange {
    width: 32px;
    height: 68px;
    top: 432px;
    left: 99px;
  }
}
@media (min-width: 1440px) {
  .success-brand-art.orange {
    left: 140px;
  }
}
.success-brand-art.red {
  background-color: #e32112;
}
@media (min-width: 1024px) {
  .success-brand-art.red {
    width: 131px;
    height: 16px;
    top: 530px;
    right: 0;
  }
}
@media (min-width: 1440px) {
  .success-brand-art.red {
    width: 172px;
    height: 16px;
  }
}

@media (min-width: 1024px) {
  .popup-add-service .modal-dialog {
    width: 568px;
  }
}
.popup-add-service .modal-footer .btn-primary:disabled, .popup-add-service .modal-footer .btn-primary.disabled {
  color: #868786;
  background-color: #d9dada;
  border: #d9dada;
}
@media (max-width: 767.98px) {
  .popup-add-service .modal-footer button {
    width: 50%;
  }
}
@media (max-width: 1023.98px) {
  .popup-add-service .modal-footer button {
    width: 48.85%;
  }
}
.popup-add-service.cart .modal-body {
  padding: 24px 16px 0;
}
@media (min-width: 768px) {
  .popup-add-service.cart .modal-body {
    padding: 36px 32px 0;
  }
}
.popup-add-service.cart .modal-content {
  padding: 0;
}
.popup-add-service.cart .modal-footer {
  padding: 0 16px 16px;
}
@media (min-width: 768px) {
  .popup-add-service.cart .modal-footer {
    padding: 0 32px 40px;
  }
}
.popup-add-service__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #d9dada;
}
.popup-add-service__success i {
  padding-bottom: 5px;
  font-size: 60px;
}
.popup-add-service__heading {
  display: flex;
  justify-content: center;
  padding: 24px 0;
}
.popup-add-service__title {
  font-weight: 500;
}
.popup-add-service__parts {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .popup-add-service__parts {
    flex-direction: row;
  }
}
.popup-add-service__parts-item {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .popup-add-service__parts-item {
    margin-top: 0;
  }
  .popup-add-service__parts-item:not(:last-child) {
    margin-right: 16px;
  }
}
.popup-add-service__parts-item input {
  width: 100%;
}
@media (min-width: 768px) {
  .popup-add-service__parts-item input {
    width: 157.3px;
  }
}
.popup-add-service__item {
  width: 100%;
  height: 112px;
  position: relative;
  min-width: 155px;
}
.popup-add-service__item + .popup-add-service__item {
  margin-left: 8px;
}
.popup-add-service__item-price {
  margin-top: 4px;
  font-weight: 500;
}
.popup-add-service__label {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 12px 16px 16px;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px #d9dada;
}
.popup-add-service__label:hover {
  box-shadow: inset 0 0 0 2.01px #fab82e;
}
.popup-add-service__label i {
  font-size: 32px;
}
.popup-add-service__input {
  position: absolute;
  opacity: 0;
}
.popup-add-service__input:checked ~ .popup-add-service__label {
  box-shadow: inset 0 0 0 2.01px #fab82e;
}
.popup-add-service__warning {
  display: flex;
  width: 100%;
  padding: 16px 17px;
  font-size: 0.875rem;
  background-color: #fef4dd;
  border: 1px solid #fde3ab;
}
.popup-add-service__warning i {
  margin-right: 8px;
  font-size: 18px;
}
.popup-add-service__description {
  height: 96px;
}
.popup-add-service__file {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.popup-add-service__file i {
  margin-right: 6px;
  font-size: 20px;
}
.popup-add-service__file-drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  cursor: pointer;
  border: 1px dotted #b1b2b2;
}
.popup-add-service__file-drop i {
  padding-bottom: 5px;
  font-size: 20px;
  color: #868786;
}
.popup-add-service__file-drop span {
  font-size: 0.875rem;
}
.popup-add-service__file-text {
  border-bottom: 1px dotted #000;
}
.popup-add-service__file-hint {
  margin-top: 8px;
  font-size: 0.875rem;
  color: #565655;
}
.popup-add-service__message {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}
.popup-add-service__message i {
  margin-right: 24px;
  font-size: 32px;
}
.popup-add-service__discount {
  position: absolute;
  top: 13px;
  left: -20px;
  height: 0;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  border-right: 25px solid transparent;
  border-bottom: 18px solid #fab82e;
  border-left: 18px solid transparent;
  transform: rotate(-47deg);
}
.popup-add-service__amount-title {
  margin-bottom: 8px;
  font-weight: 500;
}
.popup-add-service__total {
  margin-left: 24px;
  font-weight: 500;
}
.popup-add-service__total-title {
  margin-bottom: 8px;
}
.popup-add-service__total-price {
  margin-top: 12px;
  font-size: 26px;
}
@media (min-width: 1024px) {
  .popup-add-service__total-price {
    margin-top: 15px;
  }
}
.popup-add-service__load-file {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}
.popup-add-service__load-file-img {
  margin-right: 8px;
}
.popup-add-service__load-file-text {
  border-bottom: 1px dashed #000;
}
.popup-add-service__info {
  display: flex;
}
.popup-add-service__info-img {
  margin-right: 24px;
}
.popup-add-service__info-text {
  font-size: 14px;
}
.popup-add-service__icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  font-size: 28px;
  text-align: center;
  border: 2px solid #000;
  border-radius: 16px;
}

.contacts-page__subtitle {
  margin-bottom: 1.5rem;
}
.contacts-page__nav {
  margin-bottom: 1.5rem;
}
.contacts-page__nav .nav-link {
  font-size: 1rem;
  font-weight: 500;
}
.contacts-page-branches {
  margin-bottom: 2.5rem;
}
.contacts-page-branches__nav {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .contacts-page-branches__nav {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
}
.contacts-page-branches__info {
  margin: 1rem 0 2rem;
  color: #868786;
}
@media (min-width: 768px) {
  .contacts-page-branches__info {
    margin: 0;
  }
}
.contacts-page-branches__info i {
  vertical-align: middle;
}
.contacts-page-branches__wrap {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .contacts-page-branches__wrap {
    flex-direction: row;
  }
}
.contacts-page-branches__column {
  flex: 1 1 25%;
}
.contacts-page-branches__link {
  margin-bottom: 1rem;
}
.contacts-page-branches__link a {
  margin-right: 0.5rem;
}
.contacts-page-branches__link i {
  vertical-align: middle;
}
.contacts-page-company {
  margin-bottom: 2.5rem;
}
@media (min-width: 320px) {
  .contacts-page-company .table td {
    width: 25%;
  }
  .contacts-page-company .table td:first-child {
    width: 18%;
  }
}
@media (min-width: 1024px) {
  .contacts-page-company .table td:first-child {
    width: 25%;
  }
}
.contacts-page-company .table-mobile__wrap {
  margin-bottom: 2.5rem;
}
.contacts-page-company .table-mobile__wrap > div:not(:last-child) {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #d9dada;
}
.contacts-page-company .table-mobile__title {
  color: #868786;
}
.contacts-page-contacts {
  margin-bottom: 2.5rem;
}
.contacts-page-contacts__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .contacts-page-contacts__wrap {
    flex-direction: row;
    align-items: center;
  }
}
.contacts-page-contacts__info {
  width: 100%;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .contacts-page-contacts__info {
    margin-top: 0;
    margin-left: 2.5rem;
  }
}
.contacts-page-contacts__position {
  font-size: 0.875rem;
  color: #565655;
}
.contacts-page-contacts__email {
  font-size: 0.875rem;
}
.contacts-page-contacts__btn {
  width: 100%;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .contacts-page-contacts__btn {
    width: auto;
  }
}
.contacts-page-contacts__btn i {
  margin-right: 0.5rem;
  font-size: 16px;
}

.branches-page {
  margin-bottom: 4rem;
}
.branches-page section {
  margin-bottom: 2.5rem;
}
.branches-page__subtitle {
  margin-bottom: 1.5rem;
}
.branches-page__nav {
  margin-bottom: 1.5rem;
}
.branches-page__nav .nav-link {
  font-size: 1rem;
  font-weight: 500;
}
@media (min-width: 320px) {
  .branches-page-contacts .table td {
    width: 25%;
  }
  .branches-page-contacts .table td:first-child {
    width: 18%;
  }
}
@media (min-width: 1024px) {
  .branches-page-contacts .table td:first-child {
    width: 25%;
  }
}
.branches-page-contacts .table-mobile__wrap {
  margin-bottom: 2.5rem;
}
.branches-page-contacts .table-mobile__wrap > div:not(:last-child) {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #d9dada;
}
.branches-page-contacts .table-mobile__title {
  color: #868786;
}
.branches-page .employees-item {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
}
.branches-page .employees-item__name {
  margin-bottom: 0.5rem;
}
.branches-page .employees-item__position, .branches-page .employees-item__email, .branches-page .employees-item__phone {
  font-size: 0.875rem;
}
.branches-page .employees-item__position, .branches-page .employees-item__phone {
  color: #565655;
}
.branches-page .requisites__item {
  margin-bottom: 1rem;
}
.branches-page .services__wrap {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .branches-page .services__wrap {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .branches-page .services-card {
    min-width: 330px;
    min-height: 444px;
    padding: 1.5rem 2rem;
    border: 1px solid #d9dada;
  }
  .branches-page .services-card:not(:first-child):not(:nth-child(2n+1)) {
    border-left: none;
  }
  .branches-page .services-card:not(:first-child):not(:nth-child(2)) {
    border-top: none;
  }
}
@media (min-width: 1280px) {
  .branches-page .services-card:not(:first-child):not(:nth-child(2)) {
    border-top: 1px solid #d9dada;
  }
  .branches-page .services-card:not(:first-child) {
    border-left: none;
  }
}
.branches-page .services-card img {
  width: 100%;
  margin-bottom: 1rem;
}
.branches-page .services-card__item {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.75rem;
}
.branches-page .services-card__name {
  margin-bottom: 1rem;
}
.branches-page .services-card__link {
  display: flex;
  flex-direction: column;
}
.branches-page .services-card__price {
  white-space: nowrap;
}
.branches-page .services-card__dots {
  flex-grow: 1;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  border-bottom: 1px dotted #d9dada;
}

.delivery-table {
  padding-bottom: 10px;
}
.delivery-table__header {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  color: #868786;
  border: 1px solid #d9dada;
  border-right: none;
  border-left: none;
}
.delivery-table__header-item-type, .delivery-table__header-item-icon {
  width: 200px;
}
.delivery-table__item {
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px;
  border-bottom: 1px solid #d9dada;
}
.delivery-table__title {
  margin-bottom: 10px;
  font-weight: 500;
}
.delivery-table__subtitle {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
}
.delivery-table__subtitle--fz-base {
  font-size: 1rem;
}
.delivery-table__icon {
  margin: 5px 0 5px -6px;
}
.delivery-table__icon--big {
  font-size: 2rem;
}
.delivery-table__label {
  color: #868786;
}
.delivery-table__value {
  font-weight: 500;
}
@media (min-width: 768px) {
  .delivery-table {
    border-bottom: 1px solid #d9dada;
  }
  .delivery-table__item {
    width: 100%;
    height: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    padding-right: 20px;
    border-bottom: none;
  }
  .delivery-table__item span {
    margin: 0;
  }
  .delivery-table__icon {
    order: 1;
    width: 200px;
    margin: 0;
    margin-top: 12px;
  }
  .delivery-table__title {
    order: 0;
    width: 200px;
  }
  .delivery-table__subtitle {
    font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  .delivery-table__value {
    order: 2;
  }
  .delivery-table__city {
    order: 3;
  }
  .delivery-table__country {
    order: 4;
  }
}

.delivery-address {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.delivery-address--gray {
  background-color: #f3f2f2;
}
@media (min-width: 1280px) {
  .delivery-address {
    flex-direction: row;
    justify-content: space-between;
  }
  .delivery-address__form {
    width: 620px;
  }
  .delivery-address .link-button {
    margin-top: 90px;
  }
}

.footnote {
  padding-top: 16px;
  color: #565655;
}
.footnote ul {
  padding-left: 16px;
}
.footnote li {
  padding-bottom: 20px;
}

.delivery-info {
  display: flex;
}
.delivery-info--top-offset {
  padding-top: 20px;
}
.delivery-info__icon {
  padding-right: 12px;
}
.delivery-info i {
  font-size: 49px;
}
.delivery-info__content strong {
  padding-bottom: 10px;
}

.delivery-place__title {
  display: block;
  padding-bottom: 1rem;
}
.delivery-place__city {
  padding-bottom: 1rem;
}
.delivery-place__city span {
  border-bottom: 1px dashed #000;
}

.delivery-page .delivery-address .link-button {
  color: #ed7817;
  cursor: pointer;
}
@media (min-width: 1280px) {
  .delivery-page .delivery-address .link-button {
    margin-top: auto !important;
    margin-bottom: 25px;
  }
}
.delivery-page__alert i {
  margin-right: 5px;
}
.delivery-page__alert a {
  margin-left: 5px;
  color: inherit !important;
  text-decoration: none !important;
  letter-spacing: 1px;
  white-space: nowrap;
}

.balloon-address {
  display: none;
  padding: 50px;
  background-color: #fff;
}

.custom-suggestions {
  position: absolute;
  top: 41px;
  display: none;
  width: 100%;
  padding: 10px 0;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
  background-color: #fff;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3), 0 1px 7px 2px rgba(0, 0, 0, 0.03);
}
.custom-suggestions__label {
  display: block;
  padding: 0 10px 10px;
  font-family: inherit;
  font-size: 1rem;
  font-weight: inherit;
  color: #868786;
}
.custom-suggestions__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.custom-suggestions__list li {
  display: block;
  padding: 5px 12px;
  cursor: pointer;
}
.custom-suggestions__list li:hover {
  background-color: #fde3ab;
}
.custom-suggestions__list li span {
  color: #ed7817;
}

.balloon-map {
  position: absolute;
  top: 120px;
  left: 10px;
  z-index: 50;
  max-width: 382px;
  padding: 25px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 32px -8px rgba(0, 21, 51, 0.1);
}
@media (max-width: 767.98px) {
  .balloon-map {
    top: auto;
    bottom: 0;
    left: auto;
    width: 100%;
    max-width: 100%;
  }
}
.balloon-map__address {
  display: block;
  padding-bottom: 20px;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
}

.document,
.product-document {
  display: flex;
  min-height: 45px;
  margin-bottom: 20px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.document__text,
.product-document__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
}
.document__name,
.product-document__name {
  margin-bottom: 4px;
  font-size: 1rem;
  cursor: pointer;
}
.document__size,
.product-document__size {
  font-size: 1rem;
  color: #868786;
}
.document__size--small,
.product-document__size--small {
  font-size: 0.875rem;
}
.document__icon,
.product-document__icon {
  display: flex;
  align-items: center;
  font-size: 32px;
  color: #000;
}

.city-list ul {
  margin: 0;
  list-style-type: none;
}
.city-list__parent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 480px;
  padding-left: 0;
  overflow: hidden;
  list-style-type: none;
}
@media (max-width: 1023.98px) {
  .city-list__parent {
    max-height: 800px;
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .city-list__parent {
    flex-wrap: nowrap;
  }
}
.city-list__parent > li {
  position: relative;
}
.city-list__parent > li > span {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.875rem;
  font-weight: 500;
  color: #868786;
  text-transform: uppercase;
}
.city-list__inner {
  padding-left: 20px;
  margin: 0;
  list-style-type: none;
}
.city-list__inner li {
  margin-bottom: 10px;
}
.city-list.special .city-list__parent {
  columns-count: 4;
}
.city-list.special .city-list__inner li {
  margin-bottom: 16px;
}

.payment-info .container {
  max-width: 1240px;
  padding: 0 1rem;
}
.payment-info__wrap {
  margin-bottom: 2rem;
}
.payment-info p {
  margin-bottom: 1rem;
}
.payment-info .title-block__content {
  margin-top: 0.25rem;
  font-weight: 500;
}
.payment-info .pills-payment-info-nav .nav-link {
  display: flex;
  justify-content: space-between;
  width: 17rem;
  padding: 1rem;
  font-size: 13px;
  color: #000;
  background-color: #fff;
}
.payment-info .pills-payment-info-nav .nav-link.active, .payment-info .pills-payment-info-nav .nav-link.focus {
  color: #ed7817;
  border-left: 4px solid #ed7817;
  box-shadow: none;
}
.payment-info .pills-payment-info-nav .nav-link i {
  margin-top: 1px;
}
.payment-info .select {
  margin-bottom: 2.5rem;
}
.payment-info .pills-top-menu-payment-tab .form-select {
  margin-bottom: 2.5rem;
}
.payment-info .info h2, .payment-info .info .h2,
.payment-info .info h5,
.payment-info .info .h5 {
  margin-bottom: 2rem;
}
.payment-info .info h3, .payment-info .info .h3 {
  margin-bottom: 1.5rem;
}
.payment-info .info h6, .payment-info .info .h6 {
  margin-bottom: 0.5rem;
}
.payment-info .info ul {
  padding-left: 1.5rem;
}
.payment-info .info .location-container {
  padding: 1.5rem;
  background-color: #f3f2f2;
}
.payment-info .info .location-container__location {
  cursor: pointer;
}
.payment-info .info .location-container__location .border-bottom {
  border-bottom: 1px dotted #000;
}
.payment-info .info .payment-lists {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
}
.payment-info .info .payment-lists li:not(:last-of-type) {
  padding-bottom: 0.5rem;
}
@media (min-width: 1280px) {
  .payment-info .info .payment-lists {
    flex-direction: row;
  }
  .payment-info .info .payment-lists__block {
    width: 50%;
  }
  .payment-info .info .payment-lists ul {
    margin-bottom: 0;
  }
}
.payment-info .info .payment-notes {
  padding-top: 1.5rem;
  font-size: 0.875rem;
  color: #565655;
  border-top: 1px solid #d9dada;
}
.payment-info .info .payment-notes__list {
  margin-bottom: 0;
}
.payment-info .info .payment-notes__list li:not(:last-of-type) {
  padding-bottom: 1.5rem;
}
.payment-info .info .payment-options__desc {
  margin-top: 2.5rem;
}
.payment-info .info .payment-options__desc-header img {
  width: 3rem;
  margin-right: 0.5rem;
}
.payment-info .info .payment-options__desc-header .logos {
  margin-bottom: 1.5rem;
}
.payment-info .info .payment-options__desc-content li:not(:last-of-type) {
  padding-bottom: 1rem;
}
.payment-info .info .payment-options__desc-note {
  font-size: 0.875rem;
  color: #565655;
}

.gallery {
  width: 100%;
  height: auto;
}
.gallery__items {
  display: flex;
  max-width: 270px;
  padding: 0;
  margin-top: 15px;
  overflow-x: auto;
  list-style: none;
}
@media (max-width: 767.98px) {
  .gallery__items {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .gallery__image-wrap {
    display: none;
  }
}
.gallery__image {
  width: 100%;
  height: 270px;
  max-width: 270px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}
.gallery__item {
  width: 33px;
  height: 33px;
  min-width: 33px;
  margin: 0 3px;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .gallery__item {
    width: 180px;
    height: 180px;
    min-width: 180px;
    margin: 0 8px;
  }
}
@media (max-width: 319.98px) {
  .gallery__item {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
}
.gallery__item:first-child {
  margin-left: 0;
}
.gallery__item:last-child {
  margin-right: 0;
}
.gallery__item--active div {
  border: 2px solid #fab82e;
}
@media (max-width: 767.98px) {
  .gallery__item--active div {
    border-width: 0;
  }
}
.gallery__item-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid #eaeaea;
}
@media (max-width: 767.98px) {
  .gallery__item-image {
    border-width: 0;
  }
}
.gallery-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: none;
  justify-content: center;
}
.gallery-popup--active {
  display: flex;
}
.gallery-popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.gallery-popup__items {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 50px auto 0;
}
.gallery-popup__list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.gallery-popup__item {
  width: 60px;
  height: 60px;
  margin: 0 3px;
  cursor: pointer;
}
.gallery-popup__item:first-child {
  margin-left: 0;
}
.gallery-popup__item:last-child {
  margin-right: 0;
}
.gallery-popup__item-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid #eaeaea;
}
.gallery-popup__item-image--active {
  border: 2px solid #fab82e;
}
@media (max-width: 767.98px) {
  .gallery-popup__item {
    display: none;
  }
}
.gallery-popup__content {
  position: relative;
  z-index: 2;
  padding: 50px;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3), 0 1px 7px 2px rgba(0, 0, 0, 0.03);
}
@media (max-width: 767.98px) {
  .gallery-popup__content {
    width: 100%;
    height: auto;
    padding: 135px 16px 50px;
    margin: auto 0;
  }
}
.gallery-popup__wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: #fff;
  opacity: 0.7;
}
.gallery-popup__image-wrap {
  width: 600px;
  height: 600px;
  margin: auto;
}
@media (max-width: 767.98px) {
  .gallery-popup__image-wrap {
    width: 280px;
    height: 280px;
  }
}
.gallery-popup__image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.gallery-popup__arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  cursor: pointer;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
}
.gallery-popup__arrow--left {
  left: -10px;
  transform: rotate(45deg);
}
@media (max-width: 767.98px) {
  .gallery-popup__arrow--left {
    left: -20px;
  }
}
.gallery-popup__arrow--right {
  right: -10px;
  transform: rotate(-135deg);
}
@media (max-width: 767.98px) {
  .gallery-popup__arrow--right {
    right: -20px;
  }
}
.gallery-popup__pagination {
  display: none;
}
@media (max-width: 767.98px) {
  .gallery-popup__pagination {
    display: block;
    font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 500;
  }
}

.datepicker--cell-year.-other-decade-, .datepicker--cell-day.-other-month- {
  color: #dedede;
}
.datepicker--cell-year.-other-decade-:hover, .datepicker--cell-day.-other-month-:hover {
  color: #c5c5c5;
}
.-disabled-.-focus-.datepicker--cell-year.-other-decade-, .-disabled-.-focus-.datepicker--cell-day.-other-month- {
  color: #dedede;
}
.-selected-.datepicker--cell-year.-other-decade-, .-selected-.datepicker--cell-day.-other-month- {
  color: #fff;
  background: #a2ddf6;
}
.-selected-.-focus-.datepicker--cell-year.-other-decade-, .-selected-.-focus-.datepicker--cell-day.-other-month- {
  background: #8ad5f4;
}
.-in-range-.datepicker--cell-year.-other-decade-, .-in-range-.datepicker--cell-day.-other-month- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.-in-range-.-focus-.datepicker--cell-year.-other-decade-, .-in-range-.-focus-.datepicker--cell-day.-other-month- {
  background-color: rgba(92, 196, 239, 0.2);
}
.datepicker--cell-year.-other-decade-:empty, .datepicker--cell-day.-other-month-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */
.datepickers-container {
  position: absolute;
  left: 0;
  top: 0;
}
@media print {
  .datepickers-container {
    display: none;
  }
}

.datepicker {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: content-box;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  z-index: 100;
}
.datepicker.-from-top- {
  transform: translateY(-8px);
}
.datepicker.-from-right- {
  transform: translateX(8px);
}
.datepicker.-from-bottom- {
  transform: translateY(8px);
}
.datepicker.-from-left- {
  transform: translateX(-8px);
}
.datepicker.active {
  opacity: 1;
  transform: translate(0);
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
}

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  transform: none;
}
.datepicker-inline .datepicker--pointer {
  display: none;
}

.datepicker--content {
  box-sizing: content-box;
  padding: 4px;
}
.-only-timepicker- .datepicker--content {
  display: none;
}

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
}
.-top-left- .datepicker--pointer, .-top-center- .datepicker--pointer, .-top-right- .datepicker--pointer {
  top: calc(100% - 4px);
  transform: rotate(135deg);
}
.-right-top- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  right: calc(100% - 4px);
  transform: rotate(225deg);
}
.-bottom-left- .datepicker--pointer, .-bottom-center- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4px);
  transform: rotate(315deg);
}
.-left-top- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-bottom- .datepicker--pointer {
  left: calc(100% - 4px);
  transform: rotate(45deg);
}
.-top-left- .datepicker--pointer, .-bottom-left- .datepicker--pointer {
  left: 10px;
}
.-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  right: 10px;
}
.-top-center- .datepicker--pointer, .-bottom-center- .datepicker--pointer {
  left: calc(50% - 10px / 2);
}
.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
  top: 10px;
}
.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  bottom: 10px;
}
.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
  top: calc(50% - 10px / 2);
}

.datepicker--body {
  display: none;
}
.datepicker--body.active {
  display: block;
}

.datepicker--cell-year.-other-decade-, .datepicker--cell-day.-other-month- {
  color: #dedede;
}
.datepicker--cell-year.-other-decade-:hover, .datepicker--cell-day.-other-month-:hover {
  color: #c5c5c5;
}
.-disabled-.-focus-.datepicker--cell-year.-other-decade-, .-disabled-.-focus-.datepicker--cell-day.-other-month- {
  color: #dedede;
}
.-selected-.datepicker--cell-year.-other-decade-, .-selected-.datepicker--cell-day.-other-month- {
  color: #fff;
  background: #a2ddf6;
}
.-selected-.-focus-.datepicker--cell-year.-other-decade-, .-selected-.-focus-.datepicker--cell-day.-other-month- {
  background: #8ad5f4;
}
.-in-range-.datepicker--cell-year.-other-decade-, .-in-range-.datepicker--cell-day.-other-month- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.-in-range-.-focus-.datepicker--cell-year.-other-decade-, .-in-range-.-focus-.datepicker--cell-day.-other-month- {
  background-color: rgba(92, 196, 239, 0.2);
}
.datepicker--cell-year.-other-decade-:empty, .datepicker--cell-day.-other-month-:empty {
  background: none;
  border: none;
}

.datepicker--cell-year.-other-decade-, .datepicker--cell-day.-other-month- {
  color: #dedede;
}
.datepicker--cell-year.-other-decade-:hover, .datepicker--cell-day.-other-month-:hover {
  color: #c5c5c5;
}
.-disabled-.-focus-.datepicker--cell-year.-other-decade-, .-disabled-.-focus-.datepicker--cell-day.-other-month- {
  color: #dedede;
}
.-selected-.datepicker--cell-year.-other-decade-, .-selected-.datepicker--cell-day.-other-month- {
  color: #fff;
  background: #a2ddf6;
}
.-selected-.-focus-.datepicker--cell-year.-other-decade-, .-selected-.-focus-.datepicker--cell-day.-other-month- {
  background: #8ad5f4;
}
.-in-range-.datepicker--cell-year.-other-decade-, .-in-range-.datepicker--cell-day.-other-month- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.-in-range-.-focus-.datepicker--cell-year.-other-decade-, .-in-range-.-focus-.datepicker--cell-day.-other-month- {
  background-color: rgba(92, 196, 239, 0.2);
}
.datepicker--cell-year.-other-decade-:empty, .datepicker--cell-day.-other-month-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Timepicker
   ------------------------------------------------- */
.datepicker--time {
  border-top: 1px solid #efefef;
  display: flex;
  align-items: center;
  padding: 4px;
  position: relative;
}
.datepicker--time.-am-pm- .datepicker--time-sliders {
  flex: 0 1 138px;
  max-width: 138px;
}
.-only-timepicker- .datepicker--time {
  border-top: none;
}

.datepicker--time-sliders {
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}

.datepicker--time-label {
  display: none;
  font-size: 12px;
}

.datepicker--time-current {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px;
}

.datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}

.datepicker--time-current-hours,
.datepicker--time-current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}
.datepicker--time-current-hours:after,
.datepicker--time-current-minutes:after {
  content: "";
  background: #f0f0f0;
  border-radius: 4px;
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0;
}
.datepicker--time-current-hours.-focus-:after,
.datepicker--time-current-minutes.-focus-:after {
  opacity: 1;
}

.datepicker--time-current-ampm {
  text-transform: uppercase;
  align-self: flex-end;
  color: #9c9c9c;
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px;
}

.datepicker--time-row {
  display: flex;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat;
}
.datepicker--time-row:first-child {
  margin-bottom: 4px;
}
.datepicker--time-row input[type=range] {
  background: none;
  cursor: pointer;
  flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}
.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.datepicker--time-row input[type=range]::-ms-tooltip {
  display: none;
}
.datepicker--time-row input[type=range]:hover::-webkit-slider-thumb {
  border-color: #b8b8b8;
}
.datepicker--time-row input[type=range]:hover::-moz-range-thumb {
  border-color: #b8b8b8;
}
.datepicker--time-row input[type=range]:hover::-ms-thumb {
  border-color: #b8b8b8;
}
.datepicker--time-row input[type=range]:focus {
  outline: none;
}
.datepicker--time-row input[type=range]:focus::-webkit-slider-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}
.datepicker--time-row input[type=range]:focus::-moz-range-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}
.datepicker--time-row input[type=range]:focus::-ms-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}
.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.datepicker--time-row input[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
}
.datepicker--time-row input[type=range]::-ms-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  -ms-transition: background 0.2s;
  transition: background 0.2s;
}
.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  margin-top: -6px;
}
.datepicker--time-row input[type=range]::-webkit-slider-runnable-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.datepicker--time-row input[type=range]::-moz-range-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.datepicker--time-row input[type=range]::-ms-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.datepicker--time-row input[type=range]::-ms-fill-lower {
  background: transparent;
}
.datepicker--time-row input[type=range]::-ms-fill-upper {
  background: transparent;
}
.datepicker--time-row span {
  padding: 0 12px;
}

.datepicker--time-icon {
  color: #9c9c9c;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
  position: relative;
  margin: 0 5px -1px 0;
  width: 1em;
  height: 1em;
}
.datepicker--time-icon:after, .datepicker--time-icon:before {
  content: "";
  background: currentColor;
  position: absolute;
}
.datepicker--time-icon:after {
  height: 0.4em;
  width: 1px;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  transform: translateY(-100%);
}
.datepicker--time-icon:before {
  width: 0.4em;
  height: 1px;
  top: calc(50% + 1px);
  left: calc(50% - 1px);
}

.datepicker--cell-year.-other-decade-, .datepicker--cell-day.-other-month- {
  color: #dedede;
}
.datepicker--cell-year.-other-decade-:hover, .datepicker--cell-day.-other-month-:hover {
  color: #c5c5c5;
}
.-disabled-.-focus-.datepicker--cell-year.-other-decade-, .-disabled-.-focus-.datepicker--cell-day.-other-month- {
  color: #dedede;
}
.-selected-.datepicker--cell-year.-other-decade-, .-selected-.datepicker--cell-day.-other-month- {
  color: #fff;
  background: #a2ddf6;
}
.-selected-.-focus-.datepicker--cell-year.-other-decade-, .-selected-.-focus-.datepicker--cell-day.-other-month- {
  background: #8ad5f4;
}
.-in-range-.datepicker--cell-year.-other-decade-, .-in-range-.datepicker--cell-day.-other-month- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.-in-range-.-focus-.datepicker--cell-year.-other-decade-, .-in-range-.-focus-.datepicker--cell-day.-other-month- {
  background-color: rgba(92, 196, 239, 0.2);
}
.datepicker--cell-year.-other-decade-:empty, .datepicker--cell-day.-other-month-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker cells
   ------------------------------------------------- */
.datepicker--cells {
  display: flex;
  flex-wrap: wrap;
}

.datepicker--cell {
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 32px;
  z-index: 1;
}
.datepicker--cell.-focus- {
  background: #f0f0f0;
}
.datepicker--cell.-current- {
  color: #4EB5E6;
}
.datepicker--cell.-current-.-focus- {
  color: #4a4a4a;
}
.datepicker--cell.-current-.-in-range- {
  color: #4EB5E6;
}
.datepicker--cell.-in-range- {
  background: rgba(92, 196, 239, 0.1);
  color: #4a4a4a;
  border-radius: 0;
}
.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, 0.2);
}
.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae;
}
.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae;
}
.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1;
}
.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae;
}
.datepicker--cell.-range-from- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 4px 0 0 4px;
}
.datepicker--cell.-range-to- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0 4px 4px 0;
}
.datepicker--cell.-range-from-.-range-to- {
  border-radius: 4px;
}
.datepicker--cell.-selected- {
  color: #fff;
  border: none;
  background: #5cc4ef;
}
.datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #5cc4ef;
}
.datepicker--cell.-selected-.-focus- {
  background: #45bced;
}
.datepicker--cell:empty {
  cursor: default;
}

.datepicker--days-names {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 3px;
}

.datepicker--day-name {
  color: #FF9A19;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}

.datepicker--cell-day {
  width: 14.2857142857 %;
}
.datepicker--cells-months {
  height: 170px;
}

.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}

.datepicker--years {
  height: 170px;
}

.datepicker--cells-years {
  height: 170px;
}

.datepicker--cell-year {
  width: 25%;
  height: 33.33%;
}
.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}
.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}
.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}
.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}
.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Navigation
   ------------------------------------------------- */
.datepicker--nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px;
}
.-only-timepicker- .datepicker--nav {
  display: none;
}

.datepicker--nav-title,
.datepicker--nav-action {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.datepicker--nav-action {
  width: 32px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.datepicker--nav-action:hover {
  background: #f0f0f0;
}
.datepicker--nav-action.-disabled- {
  visibility: hidden;
}
.datepicker--nav-action svg {
  width: 32px;
  height: 32px;
}
.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 2px;
}

.datepicker--nav-title {
  border-radius: 4px;
  padding: 0 8px;
}
.datepicker--nav-title i {
  font-style: normal;
  color: #9c9c9c;
  margin-left: 5px;
}
.datepicker--nav-title:hover {
  background: #f0f0f0;
}
.datepicker--nav-title.-disabled- {
  cursor: default;
  background: none;
}

.datepicker--buttons {
  display: flex;
  padding: 4px;
  border-top: 1px solid #efefef;
}

.datepicker--button {
  color: #4EB5E6;
  cursor: pointer;
  border-radius: 4px;
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}
.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0;
}

.custom-datepicker {
  width: 310px;
  height: auto;
  border-color: #fff;
}
.custom-datepicker .datepicker {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.custom-datepicker .datepicker--pointer {
  display: none;
}
.custom-datepicker .datepicker--cell-day {
  width: 14.2857142857%;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.custom-datepicker .datepicker--cell-month {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.custom-datepicker .datepicker--cell-year {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.custom-datepicker .datepicker--nav {
  width: auto;
  height: 40px;
  position: relative;
  margin: 15px 0;
  border-color: #fff;
}
.custom-datepicker .datepicker--nav-action {
  position: absolute;
  width: 20px;
}
.custom-datepicker .datepicker--nav-action path {
  stroke: #000;
}
.custom-datepicker .datepicker--nav-action:hover {
  background: #fff;
}
.custom-datepicker .datepicker--nav-action:first-child {
  right: 35px;
}
.custom-datepicker .datepicker--nav-action:last-child {
  right: 15px;
}
.custom-datepicker .datepicker--day-name {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  color: #000;
}
.custom-datepicker .datepicker--nav-title {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 500;
}
.custom-datepicker .datepicker--nav-title i {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.custom-datepicker .datepicker--button {
  flex: inherit;
  padding: 0 24px;
  color: #000;
  background: #fff;
}
.custom-datepicker .datepicker--buttons {
  display: flex;
  justify-content: space-around;
  margin: 10px 0 30px;
  border-color: #fff;
}
.custom-datepicker .datepicker__accept-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background: #ed7817;
  border-radius: 0;
}
.custom-datepicker .datepicker__controls-wrap {
  display: flex;
  justify-content: space-between;
  margin: 25px 10px 0;
}
.custom-datepicker .datepicker__control {
  text-align: center;
}
.custom-datepicker .datepicker__control-wrap {
  width: 100%;
  padding: 7px 18px;
  margin-right: 8px;
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #d9dada;
}
.custom-datepicker .datepicker__control-wrap:hover {
  color: #fff;
  background: #ed7817;
}
.custom-datepicker .datepicker__control-wrap:last-child {
  margin-right: 0;
}

.date-picker {
  width: 312px;
  height: 40px;
  padding-left: 12px;
  background: #fff;
  border: 1px solid #d9dada;
}
.date-picker:focus {
  border: 1px solid #ed7817;
  outline: #ed7817;
}

/* stylelint-disable */
.datepicker {
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3), 0 1px 7px 2px rgba(0, 0, 0, 0.03) !important;
  transform: translateY(-10px) !important;
}
.datepicker .-focus- {
  background: #FDE3AB;
}
.datepicker .-selected-.-focus- {
  background: #FAB82E;
}
.datepicker .-current- {
  color: black !important;
}
.datepicker .-range-from- {
  border-color: #FBE4D1;
  background: #FDE3AB;
}
.datepicker .-range-to- {
  border-color: #FBE4D1;
  background: #FDE3AB;
}
.datepicker--cell.-in-range- {
  background: #FDE3AB;
}
.datepicker .-selected-.-current- {
  background: #FAB82E;
  color: black;
}
.datepicker--cell {
  border-radius: 0 !important;
}
.datepicker--nav-action.-disabled- {
  visibility: inherit;
}
.datepicker .-selected- {
  background: #FAB82E;
  color: black;
}
.datepicker--button {
  font-family: "GT Eesti Pro Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* stylelint-enable */
.header-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  padding: 10px 0 8px;
  background-color: #fff;
  border-top: 2px solid #f3f2f2;
}
@supports (-webkit-touch-callout: none) {
  .header-bottom {
    padding: 12px 0;
  }
}
@media (min-width: 768px) {
  .header-bottom {
    padding: 16px 32px 4px;
  }
}
@media (min-width: 1024px) {
  .header-bottom {
    display: none;
  }
}
@media (max-width: 1023.98px) {
  .header-bottom ~ .modal-backdrop {
    position: relative;
    height: 0;
  }
}
.header-bottom__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  font-size: 0.75rem;
  color: #565655;
}
.header-bottom__item i {
  padding-bottom: 5px;
  font-size: 22px;
}
.header-bottom__item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.popup-menu .modal-content {
  padding: 0;
}
@media (min-width: 768px) {
  .popup-menu .modal-content {
    padding: 24px 16px;
  }
}

.mobile-menu__item {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.mobile-menu__item-name {
  display: flex;
  align-items: center;
  padding-right: 16px;
}
.mobile-menu__item-name.special {
  padding: 16px;
  margin-bottom: 16px;
  color: #000;
  text-decoration: none;
  background-color: #f3f2f2;
}
.mobile-menu__item-name.special:hover {
  color: #000;
  text-decoration: none;
}
.mobile-menu__item-name i {
  font-size: 32px;
}
.mobile-menu__item-name span {
  padding-left: 4px;
  font-size: 1rem;
  color: #000;
}
.mobile-menu__link {
  display: block;
  padding: 16px;
  color: #000;
  text-decoration: none;
}
.mobile-menu__link:hover {
  color: #000;
  text-decoration: none;
}
.mobile-menu__sub {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: #fff;
}
.mobile-menu__sub-title {
  display: flex;
  align-items: center;
  padding: 24px 16px 16px;
}
@media (min-width: 768px) {
  .mobile-menu__sub-title {
    padding: 24px 32px 16px;
  }
}
.mobile-menu__sub-title i {
  padding-right: 8px;
  font-size: 20px;
}
@media (min-width: 768px) {
  .mobile-menu__sub a {
    padding: 16px 32px;
  }
}
.mobile-menu .icn-chevron-right {
  font-size: 20px;
  color: #868786;
}

.user-menu {
  padding: 16px;
}
.user-menu__item {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  line-height: 1.25;
  color: #000;
  text-decoration: none;
}
.user-menu__item:hover {
  color: #000;
  text-decoration: none;
}
.user-menu__item.logout {
  padding-top: 25px;
  color: #868786;
}
.user-menu__item.logout i {
  font-size: 20px;
}
.user-menu .badge {
  width: 20px;
  height: 20px;
  line-height: 1.5;
}

.search__res {
  margin-top: 6px;
}
@media (min-width: 1024px) {
  .search__res {
    position: absolute;
    left: 32px;
    z-index: 10;
    width: calc(100% - 32px);
    padding: 16px;
    margin-top: 0;
    background: #fff;
    box-shadow: 0 20px 15px rgba(0, 21, 51, 0.05), 0 20px 32px -8px rgba(0, 21, 51, 0.1);
  }
}
.search__res a {
  color: #000;
  text-decoration: none;
}
.search__res a:hover {
  color: #000;
  text-decoration: none;
}
.search__res-match {
  color: #ed7817;
}
@media (min-width: 1024px) {
  .search__res-match {
    font-weight: 500;
    color: #000;
  }
}
.search__res-item {
  display: flex;
  padding: 10px 0;
}
.search__res-goods {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.search__res-title {
  padding: 10px 0;
  color: #868786;
}
.search__res-category {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.search__res-category i {
  padding-right: 4px;
  font-size: 32px;
}
.search__cancel {
  display: none;
  padding-left: 16px;
  color: #868786;
  cursor: pointer;
}
.search__popular {
  display: none;
  margin-top: 16px;
  color: #868786;
}
.search__popular-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: #000;
  text-decoration: none;
}
.search__popular-item:hover {
  color: #000;
  text-decoration: none;
}
.search__popular-item i {
  font-size: 20px;
  color: #868786;
}
.search__popular-item span {
  padding-left: 4px;
  color: #000;
}

.contacts-menu {
  padding: 24px 16px;
}
@media (min-width: 768px) {
  .contacts-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.contacts-menu__info {
  display: flex;
  margin: 0 -8px;
}
@media (max-width: 767.98px) {
  .contacts-menu__info {
    flex-direction: column;
    margin: 0;
  }
}
.contacts-menu__info-text {
  display: flex;
  align-items: center;
}
.contacts-menu__info-success {
  color: #4a8f40;
}
.contacts-menu__info-blocks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  margin: 0 8px;
}
@media (max-width: 767.98px) {
  .contacts-menu__info-blocks {
    width: 100%;
    margin: 0;
  }
}
.contacts-menu__info-phone {
  margin: 12px 0;
  font-size: 20px;
  font-weight: 500;
}
.contacts-menu__info-send-order {
  line-height: 1.4;
}
@media (max-width: 767.98px) {
  .contacts-menu__info-callback {
    margin: 16px 0 24px;
  }
}
.contacts-menu__info button {
  width: 100%;
  height: 40px;
  margin-top: 23px;
  font-weight: 500;
}
.contacts-menu__info button span {
  padding-left: 7px;
  font-size: 13px;
}
.contacts-menu__info i {
  font-size: 18px;
}
.contacts-menu__info span {
  padding-left: 5px;
  font-size: 1rem;
}
.contacts-menu__hours-label {
  margin-bottom: 12px;
  font-size: 1rem;
  font-weight: 500;
}
.contacts-menu__hours-delimiter {
  position: relative;
  top: -3px;
  flex-grow: 1;
  margin: 0 2px;
  border-bottom: 1px dotted #b1b2b2;
}
.contacts-menu__hours-slot {
  display: flex;
}
.contacts-menu__hours-slot:first-of-type {
  margin-bottom: 12px;
}
.contacts-menu__manager {
  display: flex;
}
.contacts-menu__manager-ava {
  width: 40px;
  height: 40px;
}
.contacts-menu__manager-info {
  margin-left: 12px;
}
.contacts-menu__manager-name {
  padding-top: 2px;
  font-weight: 500;
}
.contacts-menu__manager-position {
  padding-top: 5px;
  color: #868786;
}
.contacts-menu__manager-phone {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
}
.contacts-menu__manager-mail {
  margin-top: 10px;
}
.contacts-menu__manager-mail_value {
  margin-top: 10px;
  font-size: 1rem;
}
.contacts-menu .divider {
  margin: 24px 0;
  color: #d9dada;
}

.contacts-menu__hours-slot + .contacts-menu__hours-slot {
  margin-top: 12px;
}

.management-page__directors-title {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .management-page__directors-title {
    margin-bottom: 32px;
  }
}
.management-page__items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px 24px;
}
@media (min-width: 768px) {
  .management-page__items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .management-page__items {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1280px) {
  .management-page__items {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1440px) {
  .management-page__items {
    grid-template-columns: repeat(5, 1fr);
  }
}
.management-page__item {
  cursor: pointer;
}
.management-page__item img {
  width: 100%;
}
.management-page__item-name {
  display: block;
  margin: 12px 0 8px;
}
.management-page__item-info {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #565655;
}
.management-page__item-info span {
  display: block;
}
.management-page__managers {
  padding-bottom: 40px;
  background-color: #f3f2f2;
}
.management-page__managers-title {
  padding: 40px 0 24px;
  margin: 0;
}
@media (min-width: 768px) {
  .management-page__managers-title {
    padding-bottom: 32px;
  }
}

@media (min-width: 1024px) {
  .popup-manager .modal-dialog {
    width: 630px;
  }
}
.popup-manager .modal-content {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 1024px) {
  .popup-manager .modal-content {
    max-height: 75vh;
    overflow-y: scroll;
  }
}
@media (min-width: 1024px) {
  .popup-manager .modal-body {
    display: flex;
    flex-direction: column;
  }
}
.popup-manager__bio {
  overflow-y: auto;
}
.popup-manager__info {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .popup-manager__info {
    flex-direction: row;
    margin-bottom: 32px;
  }
}
.popup-manager__info img {
  width: 100%;
}
@media (min-width: 1024px) {
  .popup-manager__info img {
    width: auto;
    padding-right: 14px;
  }
}
.popup-manager__info-name {
  margin-top: 16px;
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .popup-manager__info-name {
    margin-top: 4px;
  }
}
.popup-manager__info-company {
  margin-top: 24px;
  line-height: 1.4;
}
@media (min-width: 1024px) {
  .popup-manager__info-company {
    margin: 32px 0 0;
  }
}
.popup-manager__info-company_name {
  padding-bottom: 6px;
}
.popup-manager__info-company span {
  display: block;
}

.buyout-form__content {
  display: flex;
}
@media (max-width: 767.98px) {
  .buyout-form__content {
    flex-direction: column;
  }
}
.buyout-form__left {
  flex-basis: 25%;
  margin-right: 35px;
}
.buyout-form__left-elem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  text-transform: uppercase;
  cursor: pointer;
  border-left: 4px solid #fff;
}
.buyout-form__left-elem--active {
  color: #ed7817;
  border-left: 4px solid #ed7817;
}
.buyout-form__left-elem--active div {
  border-color: #ed7817;
}
.buyout-form__left-elem-arrow {
  width: 10px;
  height: 10px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  transform: rotate(135deg);
}
.buyout-form__left-elem-arrow--mod {
  margin-top: 5px;
  margin-left: 12px;
  transition-duration: 0.2s;
  transform: rotate(45deg);
}
.buyout-form__right {
  flex-basis: 75%;
  margin-bottom: 80px;
  margin-left: 35px;
}
.buyout-form__right-elem {
  display: none;
}
@media (max-width: 767.98px) {
  .buyout-form__right-elem {
    margin-top: 15px;
  }
}
.buyout-form__right-elem--active {
  display: block;
}
.buyout-form__right-title {
  margin-bottom: 25px;
  font-size: 26px;
  font-weight: 500;
}
.buyout-form__right-title-big {
  margin-bottom: 35px;
  font-size: 32px;
}
.buyout-form__right-subtitle {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.375;
}
.buyout-form__right-item {
  margin-bottom: 25px;
  font-size: 1rem;
  line-height: 140%;
  color: #000;
}
.buyout-form__right-item-link {
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid #fab82e;
}
.buyout-form__right-info {
  font-size: 0.875rem;
  line-height: 140%;
  color: #565655;
}
.buyout-form__right-tabs {
  display: flex;
  margin-bottom: 40px;
}
.buyout-form__right-tab {
  padding: 11px 16px;
  margin-right: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #d9dada;
}
.buyout-form__right-tab--active {
  border: 1px solid #ed7817;
}
.buyout-form__first-part {
  display: flex;
}
@media (max-width: 1023.98px) {
  .buyout-form__first-part {
    flex-direction: column;
  }
}
.buyout-form__form {
  /* stylelint-disable */
  /* stylelint-enable */
}
.buyout-form__form-left {
  flex-basis: 55%;
  margin-right: 30px;
}
.buyout-form__form-input-title {
  margin-bottom: 15px;
  font-weight: 500;
}
.buyout-form__form-right {
  flex-basis: 35%;
  margin-left: 30px;
}
@media (max-width: 1023.98px) {
  .buyout-form__form-right {
    margin-left: 0;
  }
}
.buyout-form__form-right-input {
  display: none;
}
.buyout-form__form-right-title {
  margin-bottom: 8px;
  font-size: 26px;
  font-weight: 500;
}
.buyout-form__form-right-label {
  margin-left: 6px;
  border-bottom: 1px dashed #000;
}
.buyout-form__form-right-label-wrap {
  display: flex;
  align-items: center;
}
@media (max-width: 1023.98px) {
  .buyout-form__form-right--first {
    margin-top: 40px;
  }
}
.buyout-form__form .buyout-form__form-right--first {
  display: none;
}
.buyout-form__form--table .buyout-form__form-right--first {
  display: block;
}
.buyout-form__form--table .buyout-form__second-part {
  display: none;
}
.buyout-form__form--table .buyout-form__btn--table {
  display: block;
}
.buyout-form__input {
  width: 100%;
  height: 40px;
  padding: 5px;
  border: 1px solid #d9dada;
  outline: #fff;
}
.buyout-form__input-title {
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}
.buyout-form__input-wrap {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
}
.buyout-form__input-wrap--right {
  margin-right: 10px;
}
.buyout-form__input-wrap--left {
  margin-left: 10px;
}
@media (max-width: 1023.98px) {
  .buyout-form__input-wrap--left {
    margin-left: 0;
  }
}
.buyout-form__input-container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023.98px) {
  .buyout-form__input-container {
    flex-direction: column;
  }
}
.buyout-form__btn {
  padding: 11px 24px;
  margin-top: 25px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  background: #ed7817;
  border: 0;
}
.buyout-form__btn--table {
  display: none;
}
@media (max-width: 1023.98px) {
  .buyout-form__btn--table {
    display: block;
  }
}
@media (max-width: 1023.98px) {
  .buyout-form__btn--form {
    display: none;
  }
}
.buyout-form__second-part {
  margin-top: 40px;
}
.buyout-form__products-block {
  display: flex;
}
@media (max-width: 1023.98px) {
  .buyout-form__products-block {
    flex-direction: column;
  }
}
.buyout-form__product-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  color: #000;
  cursor: pointer;
  /* stylelint-disable */
  /* stylelint-enable */
}
.buyout-form__product-title--active .buyout-form__left-elem-arrow {
  transform: rotate(225deg);
  margin-top: -5px;
}
.buyout-form__product-template {
  display: none;
}
.buyout-form__product-add {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  display: flex;
  cursor: pointer;
}
.buyout-form__product-add-text {
  margin-left: 10px;
  font-size: 1rem;
  color: #ed7817;
}
.buyout-form__product-add-icon {
  width: 12px;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buyout-form__product-input {
  display: none;
}
.buyout-form__product-input--active {
  display: block;
}
.buyout-form__product-delete {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  display: none;
  margin-top: 10px;
  font-size: 1rem;
  color: #868786;
  cursor: pointer;
}
.buyout-form__product-delete-icon {
  width: 12px;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2px;
  margin-right: 12px;
  transform: rotate(45deg);
}
.buyout-form__product-move-container {
  margin-bottom: 15px;
}
.buyout-form__plus {
  width: 2px;
  height: 12px;
  background: #ed7817;
}
.buyout-form__plus--horizont {
  position: absolute;
  transform: rotate(90deg);
}
.buyout-form__plus--grey {
  background: #868786;
}
.buyout-form__table-title {
  margin-bottom: 16px;
}
.buyout-form__upload-wrap {
  display: flex;
  align-items: center;
}
.buyout-form__upload-container {
  margin-top: 30px;
}
.buyout-form__products-wrap {
  /* stylelint-disable */
  /* stylelint-enable */
}
.buyout-form__products-wrap--delete .buyout-form__product-delete {
  display: flex;
}
.buyout-form__uploaded-template {
  display: none;
}
.buyout-form__uploaded-info {
  font-size: 0.875rem;
  color: #868786;
  text-transform: uppercase;
}
.buyout-form__uploaded-container {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.about-company {
  padding: 0 1rem;
  margin-top: 45px;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .about-company {
    padding: 0;
    margin-top: 76px;
    margin-bottom: 80px;
  }
}
.about-company .title-block {
  margin-bottom: 0;
}
.about-company .title-block h1, .about-company .title-block .h1 {
  font-size: 2rem;
}
@media (min-width: 768px) {
  .about-company .title-block h1, .about-company .title-block .h1 {
    font-size: 76px;
    font-weight: 400;
  }
}
.about-company .title-block .brand-art {
  display: flex;
}
.about-company .title-block .brand-art__item:first-child {
  width: 116px;
  height: 32px;
  position: relative;
  top: -17px;
  left: -16px;
  background-color: #fab82e;
}
@media (min-width: 768px) {
  .about-company .title-block .brand-art__item:first-child {
    width: 140px;
    height: 45px;
    top: -32px;
    left: 2px;
  }
}
.about-company .title-block .brand-art__item:last-child {
  width: 23px;
  height: 49px;
  position: relative;
  left: -24px;
  background-color: #ed7817;
}
@media (min-width: 768px) {
  .about-company .title-block .brand-art__item:last-child {
    width: 32px;
    height: 68px;
    top: -10px;
    left: -5px;
  }
}
.about-company__items {
  font-size: 0.875rem;
  line-height: 1.4;
}
.about-company__items p {
  padding-top: 1rem;
}
.about-company__items img {
  position: relative;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .about-company__items {
    font-size: 1rem;
  }
  .about-company__items img {
    margin-top: 0;
  }
}
.about-company__item {
  padding-bottom: 8px;
}
.about-company__item .item-header {
  margin: 22px 0 24px;
}
.about-company__item .item-header h6, .about-company__item .item-header .h6 {
  position: relative;
  z-index: 10;
  margin-bottom: 0;
  font-size: 72px;
  font-weight: 500;
  line-height: 79px;
}
.about-company__item .item-header p {
  position: relative;
  top: -8px;
  max-width: 230px;
  padding: 0;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .about-company__item .item-header {
    margin: 0;
  }
  .about-company__item .item-header h6, .about-company__item .item-header .h6 {
    position: relative;
    font-size: 152px;
    line-height: 167px;
  }
  .about-company__item .item-header p {
    font-size: 20px;
  }
}
.about-company__item .item-body {
  margin: 24px 0 8px;
}
@media (min-width: 768px) {
  .about-company__item .item-body {
    margin: 24px 26px 24px 0;
  }
}
.about-company__item .item-body p {
  position: relative;
  padding: 0 0 24px;
}
.about-company__item .item-body p:last-of-type {
  padding: 0;
}
.about-company__item .item-body p a {
  text-decoration: none;
}
.about-company__item .brand-art {
  z-index: 0;
  display: flex;
}
.about-company__item .brand-art__yellow {
  width: 196px;
  height: 34px;
  position: relative;
  top: 62px;
  left: -16px;
  background-color: #fab82e;
}
@media (min-width: 768px) {
  .about-company__item .brand-art__yellow {
    width: 423px;
    height: 34px;
    left: 0;
  }
}
.about-company__item .brand-art__orange {
  width: 17px;
  height: 32px;
  position: relative;
  top: 96px;
  left: -16px;
  background-color: #ed7817;
}
@media (min-width: 768px) {
  .about-company__item .brand-art__orange {
    width: 25px;
    height: 32px;
    left: 0;
  }
}
.about-company__item .brand-art__red {
  width: 91px;
  height: 16px;
  position: relative;
  top: 128px;
  left: -16px;
  background-color: #e32112;
}
@media (min-width: 768px) {
  .about-company__item .brand-art__red {
    width: 199px;
    height: 16px;
    left: 0;
  }
}
@media (min-width: 768px) {
  .about-company__item {
    padding-bottom: 0;
  }
  .about-company__item:not(:last-child) {
    margin-bottom: 124px;
  }
  .about-company__item p {
    font-size: 1rem;
  }
  .about-company__item .item-image {
    display: flex;
    justify-content: center;
  }
  .about-company__item .item-content {
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .about-company .intro .item-content {
    margin-top: 52px;
    margin-right: 26px;
  }
  .about-company .intro .item-image {
    margin-top: 52px;
  }
}
@media (min-width: 1280px) {
  .about-company .intro .item-content {
    margin-top: 0;
  }
  .about-company .intro .item-image {
    position: relative;
    top: -42px;
    width: 55%;
    margin-top: 0;
  }
}
.about-company .branches .brand-art__side-yellow {
  position: absolute;
  left: 0;
  z-index: 0;
  display: flex;
  width: 16px;
  height: 110px;
  margin-top: 16px;
  background-color: #fab82e;
}
@media (min-width: 768px) {
  .about-company .branches .brand-art__side-yellow {
    width: 32px;
    height: 110px;
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .about-company .branches .brand-art__side-yellow {
    width: 87px;
    height: 110px;
    left: 5.9vw;
  }
}
@media (min-width: 1280px) {
  .about-company .branches .brand-art__side-yellow {
    left: 7vw;
  }
}
@media (min-width: 1440px) {
  .about-company .branches .brand-art__side-yellow {
    left: 4.2vw;
  }
}
@media (min-width: 768px) {
  .about-company .branches .item-content {
    order: 1;
    margin-top: 7.5px;
    margin-left: 5px;
  }
  .about-company .branches .item-header h6, .about-company .branches .item-header .h6 {
    top: -70px;
    padding-left: 87px;
  }
  .about-company .branches .item-header p {
    top: -90px;
    padding-left: 92px;
  }
  .about-company .branches .item-body {
    padding-left: 92px;
    margin: 0;
  }
  .about-company .branches .item-body p {
    max-width: 545px;
  }
  .about-company .branches .item-image {
    justify-content: unset;
  }
}
@media (min-width: 1024px) {
  .about-company .branches .item-content {
    margin-left: 64px;
  }
  .about-company .branches .item-body {
    padding-left: 64px;
  }
  .about-company .branches .item-image {
    padding-left: 64px;
  }
}
@media (min-width: 1280px) {
  .about-company .branches .item-content {
    margin-left: 75px;
  }
  .about-company .branches .item-body {
    padding-left: 92px;
  }
  .about-company .branches .item-image {
    padding-left: 87px;
  }
}
.about-company .equipment .item-header {
  margin-bottom: 32px;
}
.about-company .equipment .item-image .brand-art {
  align-items: flex-end;
}
.about-company .equipment .item-image .brand-art__side-orange {
  position: relative;
  bottom: 155px;
  left: 100%;
  z-index: 0;
  display: flex;
  margin-bottom: -155px;
  width: 16px;
  height: 155px;
  background-color: #ed7817;
}
@media (min-width: 768px) {
  .about-company .equipment .item-image .brand-art__side-orange {
    width: 32px;
    height: 155px;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .about-company .equipment .item-image .brand-art__side-orange {
    width: 116px;
    height: 155px;
  }
}
@media (min-width: 1280px) {
  .about-company .equipment .item-image .brand-art__side-orange {
    width: 116px;
    height: 155px;
  }
}
@media (min-width: 768px) {
  .about-company .equipment .item-content {
    margin-right: 32px;
  }
  .about-company .equipment .item-header {
    margin-bottom: 0;
  }
  .about-company .equipment .item-header h6, .about-company .equipment .item-header .h6 {
    top: -70px;
    padding-left: 16px;
  }
  .about-company .equipment .item-header p {
    top: -90px;
    max-width: 410px;
    padding-left: 16px;
  }
  .about-company .equipment .item-body {
    padding-left: 16px;
    margin: 0;
  }
  .about-company .equipment .item-body p {
    max-width: 501px;
    margin-bottom: 83px;
  }
  .about-company .equipment .item-image {
    max-width: 426px;
    padding-top: 144px;
  }
}
@media (min-width: 1024px) {
  .about-company .equipment .item-content {
    margin-right: 64px;
  }
  .about-company .equipment .item-header h6, .about-company .equipment .item-header .h6 {
    padding-left: 68px;
  }
  .about-company .equipment .item-header p {
    max-width: 310px;
    padding-left: 58px;
  }
  .about-company .equipment .item-body {
    padding-left: 69px;
  }
  .about-company .equipment .item-image {
    margin-left: 64px;
  }
}
@media (min-width: 1280px) {
  .about-company .equipment .item-header h6, .about-company .equipment .item-header .h6 {
    padding-left: 79px;
  }
  .about-company .equipment .item-header p {
    max-width: 420px;
    padding-left: 69px;
  }
  .about-company .equipment .item-body {
    padding-left: 69px;
    margin-top: 32px;
  }
}
.about-company .quality .brand-art__horizontal-yellow {
  position: relative;
  top: 16px;
  left: -16px;
  z-index: 0;
  display: flex;
  width: 103px;
  height: 21px;
  background-color: #fab82e;
}
@media (min-width: 768px) {
  .about-company .quality .brand-art__horizontal-yellow {
    width: 302px;
    height: 21px;
    top: 0;
    left: 25%;
  }
}
@media (min-width: 1024px) {
  .about-company .quality .brand-art__horizontal-yellow {
    left: 302px;
  }
}
@media (min-width: 1280px) {
  .about-company .quality .brand-art__horizontal-yellow {
    left: calc(50% - 200px);
  }
}
.about-company .quality .brand-art__horizontal-red {
  position: relative;
  left: -16px;
  z-index: 0;
  display: flex;
  width: 59px;
  height: 17px;
  background-color: #e32112;
}
@media (min-width: 768px) {
  .about-company .quality .brand-art__horizontal-red {
    width: 119px;
    height: 38px;
    left: 49%;
  }
}
@media (min-width: 768px) {
  .about-company .quality .item-content {
    width: unset;
    max-width: unset;
  }
  .about-company .quality .item-content .item-body {
    max-width: 50%;
  }
  .about-company .quality .item-content .item-image {
    max-width: 648px;
  }
  .about-company .quality .item-content:last-child {
    margin-top: 40px;
  }
  .about-company .quality .item-content:last-child .item-image {
    flex-direction: row;
  }
  .about-company .quality .item-content:last-child img {
    max-width: 50%;
    padding-right: 16px;
  }
  .about-company .quality .item-content:last-child .item-body {
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }
}
@media (min-width: 1280px) {
  .about-company .quality .item-content:last-child .item-image {
    margin-left: 0;
  }
  .about-company .quality .item-content:last-child .item-body {
    margin-left: 10px;
  }
}
@media (min-width: 1440px) {
  .about-company .quality .item-content .item-body {
    margin: 53px 26px 53px 0;
  }
  .about-company .quality .item-content:last-child img {
    padding-right: 24px;
  }
}
.about-company .offers {
  margin-top: 56px;
}
.about-company .offers .item-header {
  margin-bottom: 16px;
}
.about-company .offers .item-header h2, .about-company .offers .item-header .h2 {
  position: relative;
  z-index: 10;
  font-size: 32px;
  line-height: 1.1;
}
@media (min-width: 768px) {
  .about-company .offers .item-header {
    margin-bottom: 40px;
  }
  .about-company .offers .item-header h2, .about-company .offers .item-header .h2 {
    font-size: 72px;
    font-weight: 400;
    line-height: 79.2px;
  }
}
.about-company .offers .brand-art__yellow {
  width: 327px;
  height: 28px;
  top: -15px;
  left: -16px;
}
@media (min-width: 768px) {
  .about-company .offers .brand-art__yellow {
    width: 448px;
    height: 56px;
    top: -25px;
    left: 25vw;
  }
}
@media (min-width: 1024px) {
  .about-company .offers .brand-art__yellow {
    width: 565px;
    height: 56px;
    left: 200px;
  }
}
.about-company .offers .brand-art__orange {
  width: 77px;
  height: 16px;
  top: 13px;
  left: -16px;
}
@media (min-width: 768px) {
  .about-company .offers .brand-art__orange {
    width: 88px;
    height: 31px;
    top: 30px;
    left: 25vw;
  }
}
@media (min-width: 1024px) {
  .about-company .offers .brand-art__orange {
    width: 164px;
    height: 31px;
    left: 200px;
  }
}
.about-company .offers .item-content {
  max-width: 100%;
}
.about-company .offers .item-body {
  flex-direction: column;
  margin: 0;
}
.about-company .offers .offer {
  margin-bottom: 32px;
}
.about-company .offers .offer img {
  width: 64px;
  margin-top: 0;
}
.about-company .offers .offer-content {
  margin-top: 16px;
}
.about-company .offers .offer-content h3, .about-company .offers .offer-content .h3 {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .about-company .offers .offer-content {
    margin-top: 0;
    margin-left: 24px;
  }
  .about-company .offers .offer-content h3, .about-company .offers .offer-content .h3 {
    margin-bottom: 16px;
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) {
  .about-company .offers .offer:nth-child(2) {
    order: 3;
  }
  .about-company .offers .offer:nth-child(3) {
    order: 5;
  }
  .about-company .offers .offer:nth-child(4) {
    order: 2;
  }
  .about-company .offers .offer:nth-child(5) {
    order: 4;
  }
  .about-company .offers .offer:nth-child(6) {
    order: 6;
  }
  .about-company .offers .offer:nth-child(n+4) {
    margin-left: calc(50% + 32px);
  }
  .about-company .offers .offer:nth-child(-n+3) {
    margin-right: calc(50% + 32px);
  }
  .about-company .offers .offer:not(:first-child) {
    margin-bottom: 14px;
  }
}
@media (min-width: 1280px) {
  .about-company .offers .offer:nth-child(n+4) {
    margin-left: calc(50% + 90px);
  }
  .about-company .offers .offer:nth-child(-n+3) {
    margin-right: calc(50% + 90px);
  }
  .about-company .offers .offer:not(:first-child) {
    margin-bottom: 14px;
  }
}
.about-company .metalwork .item-header h2, .about-company .metalwork .item-header .h2 {
  position: relative;
  z-index: 10;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .about-company .metalwork .item-header h2, .about-company .metalwork .item-header .h2 {
    padding-left: 14px;
    font-size: 72px;
    font-weight: 400;
    line-height: 79.2px;
  }
}
.about-company .metalwork .item-header .brand-art__yellow {
  width: 248px;
  height: 56px;
  top: -56px;
}
@media (min-width: 768px) {
  .about-company .metalwork .item-header .brand-art__yellow {
    width: 253px;
    height: 110px;
    top: -100px;
  }
}
.about-company .metalwork .item-body {
  position: relative;
  top: -56px;
}
.about-company .metalwork .item-body img {
  margin: 24px 0 16px;
}
@media (min-width: 768px) {
  .about-company .metalwork .item-body {
    top: -100px;
    margin-bottom: 76px;
  }
  .about-company .metalwork .item-body img {
    margin: 0;
  }
  .about-company .metalwork .item-body .item-content {
    max-width: 50%;
    margin: 26px 24px 26px 0;
  }
}
@media (min-width: 1280px) {
  .about-company .metalwork .item-body .item-content {
    max-width: 636px;
    margin: 54px 38px 0 0;
  }
}
.about-company .metalwork .item-body__yellow {
  width: 100vw;
  height: 648px;
  position: relative;
  top: -56px;
  left: -16px;
  z-index: 0;
  padding: 14px 16px 16px;
  background-color: #fab82e;
}
@media (min-width: 768px) {
  .about-company .metalwork .item-body__yellow {
    width: calc(100vw - 64px);
    height: 500px;
    left: 0;
    justify-content: space-between;
    padding: 30px 46px;
  }
  .about-company .metalwork .item-body__yellow h3, .about-company .metalwork .item-body__yellow .h3 {
    margin-bottom: 16px;
  }
}
@media (min-width: 1024px) {
  .about-company .metalwork .item-body__yellow {
    width: 100%;
    height: 480px;
  }
}
@media (min-width: 1280px) {
  .about-company .metalwork .item-body__yellow {
    width: 100%;
    height: 380px;
  }
}
@media (min-width: 1440px) {
  .about-company .metalwork .item-body__yellow {
    width: 100%;
    height: 304px;
  }
}
.about-company .metalwork .item-body__yellow-content {
  margin-bottom: 16px;
}
.about-company .metalwork .item-body__yellow-content span {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 1px dotted #000;
}
.about-company .metalwork .item-body__yellow-content p:first-of-type {
  font-size: 1rem;
  line-height: 1.6;
}
@media (min-width: 768px) {
  .about-company .metalwork .item-body__yellow-content {
    margin-bottom: 32px;
  }
  .about-company .metalwork .item-body__yellow-content span {
    display: initial;
    border-bottom: 1px dotted #000;
  }
  .about-company .metalwork .item-body__yellow-content p:first-of-type {
    padding-top: 0;
    font-size: 1.25rem;
    line-height: 1.1;
  }
}
@media (min-width: 1280px) {
  .about-company .metalwork .item-body__yellow-content {
    max-width: 35%;
    margin-right: 54px;
  }
  .about-company .metalwork .item-body__yellow-content span {
    display: initial;
    border-bottom: 1px dotted #000;
  }
  .about-company .metalwork .item-body__yellow-content p:first-of-type {
    padding-top: 0;
    font-size: 1.25rem;
    line-height: 1.1;
  }
}
@media (min-width: 1440px) {
  .about-company .metalwork .item-body__yellow-content {
    max-width: 525px;
  }
}
@media (min-width: 1024px) {
  .about-company .metalwork .item-body__yellow .service-block {
    grid-template-columns: repeat(2, 1fr);
  }
}
.about-company .metalwork .item-body__yellow .service-card {
  padding: 32px 32px 16px;
  margin: 0;
  background: #fff;
  border: 1px solid #d9dada;
}
.about-company .metalwork .item-body__yellow .service-card:not(:first-child) {
  position: relative;
  left: -2px;
  border-left: none;
}
.about-company .metalwork .item-body__yellow .img-fluid {
  margin-bottom: 0;
}
.about-company .benefits {
  margin-top: 172px;
}
@media (min-width: 768px) {
  .about-company .benefits {
    margin-top: 234px;
  }
}
.about-company .benefits .item-header h2, .about-company .benefits .item-header .h2 {
  position: relative;
  z-index: 10;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .about-company .benefits .item-header h2, .about-company .benefits .item-header .h2 {
    font-size: 72px;
    font-weight: 400;
  }
}
@media (max-width: 767.98px) {
  .about-company .benefits .item-header .brand-art {
    width: calc(100vw - 16px);
  }
}
.about-company .benefits .item-header .brand-art__yellow {
  width: 100vw;
  height: 27px;
  top: -18px;
  left: 0;
}
@media (min-width: 768px) {
  .about-company .benefits .item-header .brand-art__yellow {
    width: 400px;
    height: 27px;
    top: -32px;
    left: 25%;
  }
}
@media (min-width: 1024px) {
  .about-company .benefits .item-header .brand-art__yellow {
    width: 648px;
    height: 27px;
    top: -32px;
    left: 22%;
  }
}
.about-company .benefits .item-content {
  position: relative;
  top: -32px;
  width: 100%;
  max-width: unset;
}
.about-company .benefits .item-body {
  margin: 40px 0 0;
}
.about-company .benefits .item-body p {
  padding-bottom: 24px;
}
.about-company .benefits .item-body ol {
  padding-left: 16px;
  margin-bottom: 8px;
}
.about-company .benefits .item-body li {
  padding-left: 16px;
}
.about-company .benefits .item-body li:not(:last-of-type) {
  padding-bottom: 16px;
}
@media (min-width: 768px) {
  .about-company .benefits .item-body {
    max-width: 50%;
    margin: 32px 23px 56px 0;
  }
}
@media (min-width: 1280px) {
  .about-company .benefits .item-body {
    max-width: 638px;
    margin: 48px 23px 56px 0;
  }
}
@media (min-width: 768px) {
  .about-company .benefits .item-image {
    justify-content: unset;
    width: 50%;
  }
}
.about-company .benefits .brand-art__horizontal-red {
  position: relative;
  top: -48px;
  left: -16px;
  width: 16px;
  height: 16px;
  background-color: #e32112;
}
@media (min-width: 768px) {
  .about-company .benefits .brand-art__horizontal-red {
    width: 301px;
    height: 23px;
    top: -55px;
    left: 40px;
  }
}
@media (min-width: 1024px) {
  .about-company .benefits .brand-art__horizontal-red {
    left: 146px;
  }
}
@media (min-width: 1280px) {
  .about-company .benefits .brand-art__horizontal-red {
    left: 27%;
  }
}
@media (min-width: 1440px) {
  .about-company .benefits .brand-art__horizontal-red {
    left: 25%;
  }
}
.about-company .benefits .brand-art__horizontal-orange {
  position: relative;
  top: -32px;
  left: -22px;
  width: 94px;
  height: 15px;
  background-color: #ed7817;
}
@media (min-width: 768px) {
  .about-company .benefits .brand-art__horizontal-orange {
    width: 90px;
    height: 25px;
    left: 40px;
  }
}
@media (min-width: 1024px) {
  .about-company .benefits .brand-art__horizontal-orange {
    left: 146px;
  }
}
@media (min-width: 1280px) {
  .about-company .benefits .brand-art__horizontal-orange {
    left: 27%;
  }
}
@media (min-width: 1440px) {
  .about-company .benefits .brand-art__horizontal-orange {
    left: 25%;
  }
}
.about-company .entity-details {
  margin-top: 26px;
}
.about-company .entity-details .item-header h2, .about-company .entity-details .item-header .h2 {
  font-size: 32px;
}
@media (min-width: 768px) {
  .about-company .entity-details .item-header h2, .about-company .entity-details .item-header .h2 {
    font-size: 72px;
    font-weight: 400;
  }
}
.about-company .entity-details .item-header .brand-art {
  position: relative;
  top: -0.5px;
  display: flex;
}
.about-company .entity-details .item-header .brand-art__item:first-child {
  width: 116px;
  height: 32px;
  position: relative;
  top: -17px;
  left: -16px;
  background-color: #fab82e;
}
@media (min-width: 768px) {
  .about-company .entity-details .item-header .brand-art__item:first-child {
    width: 140px;
    height: 45px;
    top: -32px;
    left: 2px;
  }
}
.about-company .entity-details .item-header .brand-art__item:last-child {
  width: 23px;
  height: 49px;
  position: relative;
  left: -24px;
  background-color: #ed7817;
}
@media (min-width: 768px) {
  .about-company .entity-details .item-header .brand-art__item:last-child {
    width: 32px;
    height: 68px;
    top: -10px;
    left: -5px;
  }
}
.about-company .entity-details .item-body p:not(:last-of-type) {
  padding: 0 0 16px;
}
.about-company .entity-details .item-body:last-child {
  width: 90%;
}
.about-company .entity-details .item-body:last-child p:first-of-type {
  font-size: 1rem;
  font-weight: 500;
}
@media (min-width: 1024px) {
  .about-company .entity-details .item-body:last-child p:first-of-type {
    font-size: 1.25rem;
  }
}
@media (min-width: 1024px) {
  .about-company .entity-details .item-body {
    margin-top: 16px;
  }
}

.service-page .nav-menu-item:not(:first-child) {
  margin-top: 32px;
}
.service-page .nav-menu-header {
  max-width: 200px;
  padding-left: 0;
}
.service-page .nav-menu-content {
  padding-top: 5px;
  padding-left: 0;
}
.service-page .title-block {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .service-page .title-block {
    margin-bottom: 32px;
  }
}
.service-page .service-main-row {
  justify-content: space-between;
  margin-bottom: 12px;
}
@media (min-width: 1024px) {
  .service-page .service-main-row {
    margin-bottom: 56px;
  }
}
.service-page .gallery {
  width: 100%;
  height: 100%;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .service-page .gallery {
    margin-top: 0;
  }
}
.service-page .gallery img {
  width: 100%;
}
@media (min-width: 1024px) {
  .service-page .gallery img {
    width: 200px;
  }
}
.service-page .cart {
  margin-top: 24px;
}
@media (min-width: 1024px) {
  .service-page .cart {
    max-width: 535px;
    margin-top: 0;
    margin-left: 24px;
  }
}
.service-page .cart__actual-price {
  font-size: 26px;
  font-weight: 500;
}
.service-page .cart__actual-price span {
  font-size: 20px;
}
.service-page .cart__old-price {
  padding-left: 16px;
  font-size: 0.875rem;
  color: #ed7817;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}
.service-page .cart__button {
  margin-top: 16px;
}
.service-page .cart__button .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .service-page .cart__button .btn {
    width: 100%;
    height: 56px;
  }
}
@media (min-width: 1024px) {
  .service-page .cart__button .btn {
    width: 176px;
    height: 56px;
  }
}
.service-page .cart__note {
  max-width: 230px;
  margin-top: 16px;
  font-size: 0.875rem;
  line-height: 20px;
  color: #565655;
}
@media (min-width: 768px) {
  .service-page .cart__note {
    max-width: unset;
  }
}
@media (min-width: 1024px) {
  .service-page .cart__note {
    font-size: 1rem;
  }
}
.service-page .cart__warning {
  margin-top: 16px;
  background-color: #fef4dd;
  border: 1px solid #fde3ab;
}
@media (min-width: 1024px) {
  .service-page .cart__warning {
    max-width: 535px;
  }
}
.service-page .cart__warning-inner {
  display: flex;
  padding: 10px;
}
@media (min-width: 1024px) {
  .service-page .cart__warning-inner {
    padding-left: 16px;
  }
}
.service-page .cart__warning-inner i {
  font-size: 20px;
}
.service-page .cart__warning-inner p {
  padding-top: 3px;
  margin-left: 8px;
  font-size: 0.875rem;
  line-height: 1.2;
}
@media (min-width: 1024px) {
  .service-page .cart__warning-inner p {
    margin-left: 12px;
    font-size: 1rem;
  }
}
@media (min-width: 1024px) {
  .service-page .popup-service .modal-dialog {
    width: 568px;
  }
  .service-page .popup-service .modal-content {
    padding: 36px 34px;
  }
  .service-page .popup-service .modal-close {
    top: 16px;
    right: 16px;
  }
}
.service-page .popup-service__description {
  width: calc(100% - 4px);
  margin-left: 2px;
}
.service-page .popup-service__description:focus {
  box-shadow: 0 0 0 2px #fbe4d1;
}
.service-page .popup-service__file {
  margin-left: 2px;
}
.service-page .popup-service h3, .service-page .popup-service .h3 {
  padding-left: 2px;
  margin-bottom: 16px;
}
.service-page .popup-service label {
  padding-left: 2px;
}
.service-page .popup-service input,
.service-page .popup-service textarea {
  width: calc(100% - 4px);
  margin-left: 2px;
}
.service-page .popup-service input:focus,
.service-page .popup-service textarea:focus {
  box-shadow: 0 0 0 2px #fbe4d1;
}
.service-page .popup-service .modal-footer {
  padding: 0 16px 8px;
  margin: 0;
}
@media (min-width: 768px) {
  .service-page .popup-service .modal-footer {
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .service-page .popup-service .modal-footer {
    position: relative;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 24px;
  }
}
.service-page .popup-service .modal-footer .buttons {
  width: 100%;
}
@media (min-width: 1024px) {
  .service-page .popup-service .modal-footer .buttons {
    justify-content: flex-end;
  }
}
.service-page .popup-service .modal-footer button {
  margin: 0 0 96px;
}
@media (min-width: 768px) {
  .service-page .popup-service .modal-footer button {
    margin-top: 24px;
  }
}
@media (min-width: 1024px) {
  .service-page .popup-service .modal-footer button {
    width: auto;
    height: 40px;
    margin: 0 0 0 16px;
  }
}
.service-page .service-tabs .service-main-tabs {
  margin-top: 32px;
}
@media (min-width: 1024px) {
  .service-page .service-tabs .service-main-tabs {
    margin-top: 44px;
  }
  .service-page .service-tabs .service-main-tabs .nav-link {
    font-size: 1rem;
  }
}
.service-page .service-tabs .tab-pane {
  margin-top: 40px;
}
@media (min-width: 1024px) {
  .service-page .service-tabs .tab-pane {
    margin-top: 44px;
  }
}
.service-page .service-tabs .tab-pane p {
  padding-top: 8px;
  padding-right: 22px;
}
@media (min-width: 1024px) {
  .service-page .service-tabs .tab-pane p {
    padding-right: 0;
    line-height: 1.4;
  }
}
.service-page .service-tabs .service__props {
  margin-top: 16px;
}
.service-page .service-tabs .service__props:first-of-type p {
  padding-top: 0;
}
.service-page .service-tabs .service__props-title {
  font-weight: 500;
  line-height: 1.4;
}
.service-page .service-tabs .service__props-list {
  position: relative;
  display: flex;
  flex-direction: column;
}
.service-page .service-tabs .service__prop {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  font-size: 0.875rem;
  text-align: left;
}
.service-page .service-tabs .service__prop span:first-child {
  max-width: 132px;
}
@media (min-width: 768px) {
  .service-page .service-tabs .service__prop span:first-child {
    max-width: unset;
  }
}
.service-page .service-tabs .service__prop-separator {
  position: relative;
  bottom: -12px;
  flex-grow: 1;
  height: 2px;
  margin: 0 4px;
  border-bottom: 1px dotted #b1b2b2;
}
.service-page .service-tabs .warehouses-tabs {
  margin-top: 16px;
}
.service-page .service-tabs .warehouses-tabs .nav-item:first-child {
  margin-right: 20px;
}
.service-page .service-tabs .warehouses-tabs .nav-link {
  align-items: center;
  justify-content: space-around;
  height: 40px;
  padding: 0 20px;
  font-size: 13px;
  background-color: transparent;
  border: 1px solid #d9dada;
}
.service-page .service-tabs .warehouses-tabs .nav-link:focus {
  box-shadow: none;
}
.service-page .service-tabs .warehouses-tabs .active {
  border: 2px solid #ed7817;
}
.service-page .service-tabs .warehouses-mobile td {
  padding: 24px 0;
}
.service-page .service-tabs .warehouses-mobile tr:first-child td {
  padding-top: 0;
}
.service-page .service-tabs .warehouses-mobile tr:last-child td {
  border-bottom: none;
}
.service-page .service-tabs .warehouses-mobile .warehouse {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 1px solid #d9dada;
}
.service-page .service-tabs .warehouses-mobile .warehouse:first-child .warehouse__name {
  margin-top: 40px;
}
.service-page .service-tabs .warehouses-mobile .warehouse span {
  font-size: 1rem;
}
.service-page .service-tabs .warehouses-mobile .warehouse__name {
  margin-top: 24px;
}
.service-page .service-tabs .warehouses-mobile .warehouse__name, .service-page .service-tabs .warehouses-mobile .warehouse__phone {
  font-weight: 500;
}
.service-page .service-tabs .warehouses-mobile .warehouse__phone {
  margin-bottom: 6px;
}
.service-page .service-tabs .warehouses-mobile .warehouse__label {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #868786;
}
.service-page .service-tabs .warehouses-table {
  margin-top: 24px;
}
.service-page .service-tabs .warehouses-table td {
  width: 25%;
}
.service-page .service-tabs .warehouses-table__phone {
  display: inline-block;
  margin-bottom: 4px;
  font-weight: 500;
}

.cart-empty {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 64px 0 80px;
}
@media (min-width: 768px) {
  .cart-empty {
    padding: 98px 0 83px;
  }
}
.cart-empty__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0 16px;
}
.cart-empty__inner img {
  width: 270px;
  height: 130px;
  z-index: 2;
  max-width: 100%;
  margin-left: 18px;
}
@media (max-width: 319.98px) {
  .cart-empty__inner img {
    width: 200px;
    height: 96px;
    max-width: none;
    margin-left: 0;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
@media (min-width: 768px) {
  .cart-empty__inner img {
    width: 425px;
    height: 188px;
    margin-left: 0;
  }
}
.cart-empty__yellow {
  width: 256px;
  height: 27px;
  position: absolute;
  top: 98px;
  left: -1px;
  background-color: #fab82e;
}
@media (max-width: 319.98px) {
  .cart-empty__yellow {
    width: 185px;
    height: 27px;
    top: 69px;
    left: -1px;
  }
}
@media (min-width: 768px) {
  .cart-empty__yellow {
    width: 474px;
    height: 48px;
    top: 140px;
    left: -107px;
  }
}
@media (min-width: 1280px) {
  .cart-empty__yellow {
    width: 572px;
    height: 48px;
    top: 140px;
    left: -205px;
  }
}
.cart-empty__orange {
  width: 16.5px;
  height: 34px;
  position: absolute;
  top: 64px;
  left: 255px;
  background-color: #ed7817;
}
@media (max-width: 319.98px) {
  .cart-empty__orange {
    top: 35px;
    left: 184px;
  }
}
@media (min-width: 768px) {
  .cart-empty__orange {
    width: 29px;
    height: 60px;
    top: 80px;
    left: 367px;
  }
}
.cart-empty__red {
  width: 47px;
  height: 5px;
  position: absolute;
  top: 40px;
  left: 271px;
  background-color: #e32112;
}
@media (max-width: 319.98px) {
  .cart-empty__red {
    top: 21px;
    left: 201px;
  }
}
@media (min-width: 768px) {
  .cart-empty__red {
    width: 111px;
    height: 9px;
    top: 45px;
    left: 395px;
  }
}
.cart-empty__title {
  display: flex;
  align-items: center;
  max-width: 155px;
  margin: 32px 0 24px;
}
@media (min-width: 768px) {
  .cart-empty__title {
    max-width: none;
  }
}

.vacancy {
  margin-top: 32px;
  margin-bottom: 80px;
}
.vacancy__content {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
@media (max-width: 767.98px) {
  .vacancy__content {
    flex-direction: column;
  }
}
.vacancy__content__left {
  flex-basis: 80%;
}
.vacancy__content__right {
  flex-basis: 20%;
}
.vacancy__profile {
  width: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 24px;
  background: #f3f2f2;
}
.vacancy__profile-img {
  width: 96px;
  height: 96px;
  background-position: center;
  background-size: contain;
  border-radius: 48px;
}
.vacancy__profile-title {
  margin-bottom: 25px;
  font-weight: 500;
}
.vacancy__info-wrap {
  margin-top: 24px;
}
.vacancy__info-description {
  margin-top: 8px;
}
.vacancy__info-name {
  line-height: 140%;
  color: #868786;
}
.vacancy__back {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}
.vacancy__back-arrow {
  width: 8px;
  height: 8px;
  position: absolute;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  transform: rotate(-45deg);
}
.vacancy__back-arrow-horizontal {
  width: 15px;
  height: auto;
  position: absolute;
  border-top: 2px solid #000;
}
.vacancy__back-arrow-wrap {
  display: flex;
  align-items: center;
}
.vacancy__back-link {
  margin-left: 25px;
  color: #565655;
  text-decoration: none;
}
.vacancy__header {
  display: flex;
  align-items: center;
}
.vacancy__header-separator {
  width: auto;
  height: 25px;
  margin: 0 10px;
  color: #868786;
}
.vacancy__organization {
  color: #868786;
}
.vacancy__city {
  color: #868786;
}
.vacancy__title {
  margin: 8px 0;
  font-size: 32px;
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .vacancy__title {
    font-size: 24px;
  }
}
.vacancy__subtitle {
  font-weight: 500;
}
.vacancy__btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 24px;
  margin: 32px 0;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background: #ed7817;
}
.vacancy__btn--mod {
  align-self: flex-end;
  margin: 0;
  font-size: 13px;
}
@media (max-width: 1023.98px) {
  .vacancy__btn--mod {
    width: 100%;
    height: auto;
    margin-top: 15px;
  }
}
.vacancy__list-wrap {
  margin-top: 32px;
}
.vacancy__list-name {
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .vacancy__list-name {
    font-size: 20px;
  }
}
.vacancy__item {
  line-height: 140%;
}
.vacancy__popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 110;
  display: none;
  align-items: center;
  justify-content: center;
}
.vacancy__popup--active {
  display: flex;
}
.vacancy__popup-background {
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.8);
}
.vacancy__popup-content {
  position: absolute;
  padding: 36px 32px;
  background: #fff;
}
@media (max-width: 1023.98px) {
  .vacancy__popup-content {
    width: 100%;
    height: 100%;
  }
}
.vacancy__popup-input {
  width: 100%;
  height: 40px;
  padding: 5px 12px;
  border: 1px solid #d9dada;
  outline: #fff;
}
.vacancy__popup-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.vacancy__popup-input-title {
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}
.vacancy__popup-input-wrap {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
}
.vacancy__popup-input-wrap--right {
  margin-right: 10px;
}
.vacancy__popup-input-wrap--left {
  margin-left: 10px;
}
@media (max-width: 1023.98px) {
  .vacancy__popup-input-wrap--left {
    margin-left: 0;
  }
}
.vacancy__popup-input-container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023.98px) {
  .vacancy__popup-input-container {
    flex-direction: column;
  }
}
.vacancy__popup-footer {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023.98px) {
  .vacancy__popup-footer {
    flex-direction: column;
  }
}
.vacancy__popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.vacancy__popup-title {
  margin-bottom: 24px;
  font-size: 26px;
  font-weight: 500;
}
.vacancy__uploaded-template {
  display: none;
}
.vacancy__uploaded-info {
  font-size: 0.875rem;
  color: #868786;
  text-transform: uppercase;
}
.vacancy__uploaded-container {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.cabinet__subtitle {
  margin-bottom: 16px;
}
.cabinet-section:not(:last-child) {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #d9dada;
}
@media (min-width: 1024px) {
  .cabinet-section:not(:last-child) {
    padding-bottom: 48px;
    margin-bottom: 32px;
  }
}
.cabinet-section__wrap {
  display: flex;
  flex-direction: column;
}
.cabinet-section__item {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .cabinet-section__item {
    flex-direction: row;
  }
}
.cabinet-section__item:not(:last-child) {
  margin-bottom: 16px;
}
.cabinet-section__item span:not(:last-child) {
  margin-bottom: 8px;
}
@media (min-width: 1024px) {
  .cabinet-section__item span:not(:last-child) {
    margin-bottom: 0;
  }
}
.cabinet-section__item-name {
  color: #868786;
}
@media (min-width: 1024px) {
  .cabinet-section__item-name {
    flex-basis: 30%;
  }
}
@media (min-width: 1280px) {
  .cabinet-section__item-name {
    flex-basis: 20%;
  }
}
.cabinet-section__item-action {
  color: #ed7817;
  cursor: pointer;
}
.cabinet-section__item-action i {
  margin-right: 4px;
}
.cabinet-section__item-action span:not(:last-child) {
  margin-right: 16px;
}
.cabinet-section__text-light {
  color: #868786;
}
.cabinet-section.recent-operations .cabinet-section__item {
  padding-bottom: 16px;
}
.cabinet-section.recent-operations .cabinet-section__item:not(:last-child) {
  margin-bottom: 16px;
  border-bottom: 1px solid #d9dada;
}
.cabinet-section.recent-operations .cabinet-section__item-img {
  width: 48px;
  height: 48px;
  position: relative;
  padding-top: 9px;
  padding-left: 8px;
  margin-right: 28px;
  font-size: 30px;
  color: #fff;
  background-color: #868786;
  border-radius: 50%;
}
.cabinet-section.recent-operations .cabinet-section__item-img::after {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -3px;
  right: -4px;
  font-family: Evrazicon;
  font-size: 0.875rem;
  color: #868786;
  text-align: center;
  content: "";
  background-color: #fff;
  border-radius: 50%;
}
.cabinet-section.recent-operations .cabinet-section__item-info {
  font-weight: 500;
}
.cabinet-section.recent-operations .cabinet-section__item.green .cabinet-section__item-img {
  background-color: #4a8f40;
}
.cabinet-section.recent-operations .cabinet-section__item.green .cabinet-section__item-img::after {
  content: "";
}
.cabinet-section.recent-operations .cabinet-section__item.green .cabinet-section__item-price {
  color: #4a8f40;
}
.cabinet .manager {
  max-width: 280px;
  padding: 16px 20px;
  border: 1px solid #d9dada;
}
.cabinet .manager-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #d9dada;
}
.cabinet .manager-info__img {
  margin-bottom: 8px;
}
.cabinet .manager-info__name {
  text-align: center;
}
.cabinet .manager-info__position {
  font-size: 0.875rem;
  color: #868786;
}
.cabinet .manager-contacts {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
}
.cabinet .manager-contacts__phone {
  color: #000;
  text-decoration: none;
}
.cabinet .manager-contacts__email {
  margin-bottom: 12px;
  color: #ed7817;
  text-decoration: none;
}
.cabinet .balance {
  padding: 16px;
  margin-bottom: 24px;
  background-color: #f3f2f2;
}
@media (min-width: 1024px) {
  .cabinet .balance {
    margin-bottom: 32px;
  }
}
.cabinet .balance__quantity {
  margin-left: 10px;
  font-size: 22px;
  font-weight: 500;
}
.cabinet .balance__icn {
  font-size: 20px;
  vertical-align: text-bottom;
}
.cabinet .banner {
  width: 100vw;
  padding-top: 30px;
  margin-top: 48px;
  margin-left: -16px;
  background-color: #f3f2f2;
}
@media (min-width: 768px) {
  .cabinet .banner {
    width: 100%;
    margin-left: 0;
  }
}
.cabinet .banner__wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.cabinet .banner__wrap::after {
  width: 100%;
  height: 159px;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background-image: url("/docs/5.0/assets/img/evraz/personal-cabinet/box.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
}
@media (min-width: 768px) {
  .cabinet .banner__wrap::after {
    height: 191px;
  }
}
.cabinet .banner-brand-art {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 147px;
  margin-top: 8px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .cabinet .banner-brand-art {
    width: 78%;
    margin-top: 40px;
  }
}
.cabinet .banner-brand-art__item:nth-child(1) {
  position: relative;
  top: 4px;
  flex-grow: 1;
  min-width: 235px;
  height: 52px;
  background-color: #fab82e;
}
@media (min-width: 768px) {
  .cabinet .banner-brand-art__item:nth-child(1) {
    width: 629px;
    height: 48px;
  }
}
.cabinet .banner-brand-art__item:nth-child(2) {
  width: 29px;
  height: 60px;
  position: relative;
  top: -44px;
  background-color: #ed7817;
}
.cabinet .banner-brand-art__item:nth-child(3) {
  width: 24px;
  height: 10px;
  position: relative;
  top: -134px;
  background-color: #e32112;
}
@media (min-width: 768px) {
  .cabinet .banner-brand-art__item:nth-child(3) {
    width: 105px;
    height: 9px;
  }
}
.cabinet .banner__info {
  padding: 0 16px 24px;
  text-align: center;
}
@media (min-width: 768px) {
  .cabinet .banner__info {
    padding: 0 64px 24px;
  }
}
.cabinet__back-link {
  margin-bottom: 16px;
  color: #868786;
}
.cabinet__back-link i {
  vertical-align: text-top;
}
.cabinet-input-group__input {
  border-right: none;
}
.cabinet-input-group__icon {
  color: #868786;
  background-color: transparent;
  border-left: none;
}

.vacancies {
  margin-bottom: 80px;
}
.vacancies__content {
  display: flex;
}
@media (max-width: 1023.98px) {
  .vacancies__content {
    flex-direction: column;
  }
}
.vacancies__left {
  flex-basis: 25%;
}
.vacancies__right {
  flex-basis: 75%;
  margin-left: 24px;
}
@media (max-width: 1023.98px) {
  .vacancies__right {
    margin-left: 0;
  }
}
.vacancies__subtitle {
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 500;
  line-height: 110%;
}
@media (max-width: 1023.98px) {
  .vacancies__subtitle {
    margin-top: 32px;
    font-size: 24px;
  }
}
.vacancies__location {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.vacancies__location-desc {
  margin-bottom: 8px;
  font-weight: 500;
}
.vacancies__location-name {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border-bottom: 1px dashed #000;
}
.vacancies__location-name-img {
  margin-right: 8px;
}
.vacancies__location-wrap {
  display: flex;
  cursor: pointer;
}
.vacancies__location-wrap--template {
  display: none;
}
.vacancies__info-wrap {
  margin-left: 20px;
}
@media (max-width: 1023.98px) {
  .vacancies__info-wrap {
    margin-top: 16px;
    margin-left: 0;
  }
}
.vacancies__info-description {
  margin-top: 8px;
}
.vacancies__info-name {
  display: flex;
  align-items: center;
  min-height: 25px;
  line-height: 140%;
  color: #868786;
}
.vacancies__header {
  display: flex;
  align-items: center;
}
.vacancies__header-separator {
  width: auto;
  height: 25px;
  margin: 0 10px;
  color: #868786;
}
.vacancies__organization {
  color: #868786;
}
.vacancies__city {
  color: #868786;
}
.vacancies__selected-elem {
  width: 100%;
  height: auto;
  position: relative;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #d9dada;
}
.vacancies__selected-elem--template {
  display: none;
}
.vacancies__arrow {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 12px;
  right: 10px;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  transition-duration: 0.2s;
  transform: rotate(-45deg);
}
.vacancies__filter {
  width: 100%;
  height: auto;
  position: absolute;
  display: none;
  padding: 0;
  list-style: none;
  outline: #fff;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3), 0 1px 7px 2px rgba(0, 0, 0, 0.03);
  /* stylelint-disable */
  /* stylelint-enable */
}
.vacancies__filter--active .vacancies__selected-elem {
  border: 1px solid #ed7817;
}
.vacancies__filter--active .vacancies__filter {
  display: block;
}
.vacancies__filter--active .vacancies__arrow {
  top: 15px;
  transform: rotate(135deg);
}
.vacancies__filter-wrap {
  position: relative;
}
.vacancies__filter-item {
  padding: 9px;
  background: #fff;
}
.vacancies__filter-item:hover {
  cursor: pointer;
  background: #fef4dd;
}
.vacancies__filter:focus {
  border: 1px solid #ed7817;
}
.vacancies__filter-title {
  margin-bottom: 8px;
  font-weight: 500;
}
.vacancies__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.vacancies__item {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid #d9dada;
  border-top: 0 solid #d9dada;
}
.vacancies__item:first-child {
  border-top: 1px solid #d9dada;
}
@media (max-width: 1023.98px) {
  .vacancies__item {
    flex-direction: column;
    padding: 16px 0;
    border-right: 0 solid #d9dada;
    border-left: 0 solid #d9dada;
  }
}
.vacancies__position {
  display: block;
  margin-top: 8px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.vacancies__position:hover {
  color: #ed7817;
}
.vacancies__price {
  font-weight: 500;
}
@media (max-width: 1023.98px) {
  .vacancies__price {
    margin-top: 16px;
  }
}
.vacancies__btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 11px 24px;
  margin-top: 32px;
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid #000;
}
@media (max-width: 1023.98px) {
  .vacancies__btn {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.vacancies__popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 110;
  display: none;
  align-items: center;
  justify-content: center;
}
.vacancies__popup--active {
  display: flex;
}
.vacancies__popup-background {
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.8);
}
.vacancies__popup-content {
  width: 760px;
  height: auto;
  position: absolute;
  top: 30px;
  padding: 36px 32px;
  background: #fff;
}
@media (max-width: 1023.98px) {
  .vacancies__popup-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
}
.vacancies__popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.vacancies__popup-title {
  margin-top: 20px;
  margin-bottom: 24px;
  font-size: 26px;
  font-weight: 500;
}
.vacancies__popup-input {
  width: 100%;
  height: 40px;
  max-width: 380px;
  padding: 5px 12px;
  border: 1px solid #d9dada;
  outline: #fff;
}
@media (max-width: 767.98px) {
  .vacancies__popup-input {
    max-width: inherit;
  }
}
.vacancies__popup-list {
  width: auto;
  height: 480px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 12px;
}
@media (max-width: 767.98px) {
  .vacancies__popup-list {
    width: auto;
    height: auto;
  }
}
.vacancies__popup-item {
  display: flex;
  margin-top: 12px;
}
.vacancies__popup-cities {
  margin-left: 24px;
}
.vacancies__popup-city {
  margin-top: 12px;
  font-size: 0.875rem;
  line-height: 140%;
  cursor: pointer;
}
.vacancies__popup-city:first-child {
  margin-top: 0;
}
.vacancies__popup-letter {
  font-size: 0.875rem;
  font-weight: 500;
  color: #868786;
}
.vacancies__popup-template {
  display: none;
}

dl dt,
dl dd {
  padding: 8px 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: #868786;
}
dl dd {
  color: #000;
}
dl.no-paddings dt,
dl.no-paddings dd {
  padding: 0;
}
dl.no-paddings dt {
  padding-top: 12px;
}
dl.no-paddings dd {
  padding-bottom: 12px;
}

.lk-template {
  display: flex;
}
.lk-template__left {
  flex-basis: 25%;
}
@media (max-width: 1023.98px) {
  .lk-template__left {
    display: none;
  }
}
.lk-template__right {
  flex-basis: 75%;
  width: 100%;
  margin-left: 24px;
}
@media (max-width: 1023.98px) {
  .lk-template__right {
    flex-basis: 100%;
    margin-left: 0;
  }
}
.lk-template__right .nav-item:first-child .nav-link {
  padding-left: 0;
}
.lk-template__right .nav-item:last-child .nav-link {
  padding-right: 0;
}
.lk-template__list {
  list-style: none;
}
.lk-template__title {
  width: auto;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
  border-left: 4px solid #fff;
}
.lk-template__title--active {
  color: #ed7817;
  border-left: 4px solid #ed7817;
}
.lk-template__title:hover {
  color: #ed7817;
}
.lk-template__item {
  cursor: pointer;
}
.lk-template__item-wrap {
  display: flex;
  align-items: center;
}
.lk-template__info {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  background: #ed7817;
  border-radius: 8px;
}
.lk-template th,
.lk-template td {
  width: 25%;
}
@media (max-width: 767.98px) {
  .lk-template th,
.lk-template td {
    width: 50%;
  }
}

.orders {
  margin-bottom: 60px;
}
.orders__filters {
  display: none;
}
.orders__filters--active {
  display: flex;
}
@media (max-width: 767.98px) {
  .orders__filters--active {
    flex-direction: column;
    margin-top: 10px;
  }
}
.orders__filters-wrap {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  min-height: 40px;
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  .orders__filters-wrap {
    flex-direction: column;
  }
}
.orders__filters-btn {
  display: flex;
  align-items: center;
  margin-left: 12px;
  color: #ed7817;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .orders__filters-btn {
    margin-left: 0;
  }
}
.orders__filters-btn-text {
  margin-left: 8px;
}
.orders__search-wrap {
  position: relative;
  margin-right: 12px;
}
@media (max-width: 767.98px) {
  .orders__search-wrap {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
.orders__search-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 20px;
  color: #868786;
  cursor: pointer;
}
.orders__title {
  font-size: 40px;
  font-weight: 500;
}
.orders__title-wrap {
  display: flex;
  align-items: flex-end;
  margin-bottom: 32px;
}
.orders__count {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 5px;
  margin-left: 20px;
  font-size: 14px;
  color: #868786;
}
.orders__list {
  padding: 0;
  list-style: none;
}
.orders__item {
  margin-bottom: 24px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.05);
  /* stylelint-disable */
  /* stylelint-enable */
}
.orders__item--canceled .orders__header {
  opacity: 0.6;
}
.orders__item--canceled .orders__main {
  opacity: 0.6;
}
.orders__item:last-child {
  margin-bottom: 0;
}
.orders__header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #f3f2f2;
}
@media (max-width: 767.98px) {
  .orders__header {
    flex-direction: column;
  }
}
.orders__header-info {
  display: flex;
}
@media (max-width: 767.98px) {
  .orders__header-info {
    flex-direction: column;
  }
}
.orders__header-buttons {
  display: flex;
  margin-top: 8px;
}
.orders__header-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2px 8px;
  margin-left: 8px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #d9dada;
}
.orders__header-btn:first-child {
  margin-left: 0;
}
.orders__header-btn--accept {
  color: #4a8f40;
  background: #e7f0e6;
  border: 1px solid #b5d5b0;
}
.orders__header-btn--warning {
  color: #ed7817;
  background: #fef4dd;
  border: 1px solid #fde3ab;
}
.orders__header-btn--danger {
  color: #e32112;
  background: #fadcd9;
  border: 1px solid #f4a6a0;
}
.orders__header-price {
  font-size: 18px;
  font-weight: 500;
}
.orders__header-weight {
  margin-top: 8px;
  font-size: 14px;
  color: #565655;
  text-align: end;
}
@media (max-width: 767.98px) {
  .orders__header-weight {
    text-align: start;
  }
}
@media (max-width: 767.98px) {
  .orders__header-content {
    margin-top: 16px;
  }
}
@media (max-width: 767.98px) {
  .orders__main {
    display: none;
  }
}
.orders__order-date {
  margin-left: 16px;
  font-size: 14px;
}
@media (max-width: 767.98px) {
  .orders__order-date {
    margin-top: 4px;
    margin-left: 0;
  }
}
.orders__order-number {
  font-weight: 500;
  color: #ed7817;
  text-transform: uppercase;
}
.orders__order-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  border-bottom: 1px solid #d9d9d9;
}
.orders__order-part-container {
  display: flex;
  align-items: center;
}
.orders__order-items {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  margin-top: 3px;
  margin-left: 16px;
}
.orders__order-item {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 16px;
  border-bottom: 1px solid #fab82e;
}
.orders__order-item:first-child {
  margin-left: 0;
}
.orders__order-stock {
  color: #565655;
  text-align: end;
}
.orders__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .orders__footer {
    flex-direction: column;
  }
}
.orders__footer-left {
  flex-basis: 60%;
}
@media (max-width: 1023.98px) {
  .orders__footer-left {
    flex-basis: 40%;
  }
}
.orders__footer-right {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  .orders__footer-right {
    flex-direction: column;
    width: 100%;
  }
}
.orders__footer-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 16px;
}
.orders__footer-btn:first-child {
  margin-left: 0;
}
@media (max-width: 767.98px) {
  .orders__footer-btn:first-child {
    margin-top: 0;
  }
}
.orders__footer-btn--dark {
  color: #868786;
}
.orders__footer-btn--light {
  color: #ed7817;
}
.orders__footer-btn--big {
  padding: 11px 24px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  background: #ed7817;
}
@media (max-width: 767.98px) {
  .orders__footer-btn--big {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 1023.98px) {
  .orders__footer-btn {
    margin-top: 16px;
  }
}
@media (max-width: 767.98px) {
  .orders__footer-btn {
    margin-left: 0;
  }
}
.orders__footer-info {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 1023.98px) {
  .orders__footer-info {
    margin-bottom: 16px;
  }
}
.orders__footer-info-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  color: #fff;
  background: #ed7817;
  border-radius: 8px;
}
@media (max-width: 1023.98px) {
  .orders__footer-info-icon {
    margin-top: 3px;
  }
}
.orders__footer-info-text {
  margin-left: 8px;
}
.orders__more {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 11px 24px;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  border: 2px solid #000;
}
@media (max-width: 1023.98px) {
  .orders__more {
    width: 100%;
    text-align: center;
  }
}

.act {
  /* stylelint-disable */
  /* stylelint-enable */
}
.act__title {
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .act__title {
    margin-bottom: 32px;
  }
}
.act-filter {
  padding: 16px;
  background-color: #f3f2f2;
}
.act-filter__title {
  margin-bottom: 16px;
}
.act-filter__wrap {
  display: flex;
  flex-direction: column;
}
.act-filter__wrap .input-group,
.act-filter__wrap .select {
  width: 100%;
  margin-right: 0;
  margin-bottom: 16px;
  background-color: #fff;
}
.act-filter__wrap .input-group > input::-moz-placeholder {
  color: #565655;
}
.act-filter__wrap .input-group > input::placeholder,
.act-filter__wrap .select > span {
  color: #565655;
}
@media (min-width: 1024px) {
  .act-filter__wrap {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .act-filter__wrap .input-group,
.act-filter__wrap .select {
    width: 314px;
    margin-right: 12px;
    margin-bottom: 0;
  }
}
.act-filter__btn {
  margin-bottom: 16px;
}
@media (min-width: 1024px) {
  .act-filter__btn {
    margin-right: 12px;
    margin-bottom: 0;
  }
}
.act-filter__clear {
  white-space: nowrap;
}
.act-filter__clear i {
  vertical-align: text-top;
}
.act-list {
  margin-bottom: 24px;
}
.act-list tr:first-child td {
  border-top: 1px solid #d9dada;
}
@media (min-width: 1024px) {
  .act-list {
    margin-bottom: 32px;
  }
}
.act__info {
  font-size: 1rem;
  font-weight: 400;
}
.act .text-primary {
  color: #ed7817 !important;
}
.act__icn-close {
  font-size: 20px;
}

.order {
  margin-bottom: 64px;
}
.order__popup-content {
  max-width: 450px;
}
@media (max-width: 1023.98px) {
  .order__popup-content {
    max-width: initial;
  }
}
.order__container {
  display: flex;
}
@media (max-width: 1279.98px) {
  .order__container {
    flex-direction: column;
  }
}
@media (max-width: 1023.98px) {
  .order__container {
    flex-direction: row;
  }
}
@media (max-width: 767.98px) {
  .order__container {
    flex-direction: column;
  }
}
.order__left {
  flex-basis: 50%;
  margin-right: 50px;
}
@media (max-width: 1279.98px) {
  .order__right {
    margin-top: 24px;
  }
}
@media (max-width: 1023.98px) {
  .order__right {
    margin-top: 0;
  }
}
@media (max-width: 767.98px) {
  .order__right {
    margin-top: 24px;
  }
}
.order__status {
  padding: 2px 8px;
  margin-left: 8px;
  text-align: center;
  background: #f3f2f2;
  border: 1px solid #d9dada;
}
.order__status:first-child {
  margin-left: 0;
}
.order__status-block {
  display: flex;
  margin-bottom: 32px;
}
.order__header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 18px;
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .order__header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
}
.order__title {
  font-size: 40px;
  font-weight: 500;
}
@media (max-width: 1279.98px) {
  .order__title {
    font-size: 36px;
  }
}
@media (max-width: 1023.98px) {
  .order__title {
    font-size: 30px;
  }
}
@media (max-width: 767.98px) {
  .order__title {
    font-size: 26px;
  }
}
.order__title-date {
  margin-bottom: 5px;
  margin-left: 10px;
  font-size: 26px;
}
@media (max-width: 1279.98px) {
  .order__title-date {
    font-size: 23px;
  }
}
@media (max-width: 1023.98px) {
  .order__title-date {
    font-size: 22px;
  }
}
@media (max-width: 767.98px) {
  .order__title-date {
    margin-left: 0;
    font-size: 16px;
  }
}
.order__info {
  display: flex;
  margin-top: 24px;
}
.order__info:first-child {
  margin-top: 0;
}
.order__info-text {
  margin-top: 4px;
}
.order__info-text--email {
  font-feature-settings: "tnum" on;
}
.order__info-title {
  margin-top: 4px;
  font-weight: 500;
}
.order__info-icon {
  margin-top: -3px;
  margin-right: 18px;
}
.order__info-header {
  display: flex;
}
.order__info-header-item {
  margin-left: 16px;
}
.order__info-header-item:first-child {
  margin-left: 0;
}
.order__info-header-item--gray {
  color: #868786;
}
.order__info-header-item--orange {
  display: flex;
  align-items: center;
  color: #ed7817;
  cursor: pointer;
}
.order__info-header-item--margin {
  margin-top: 4px;
}
.order__info-header-item-img {
  margin-right: 8px;
}
.order__documents {
  display: flex;
  flex-direction: column;
}
.order__documents-wrap {
  margin-top: 16px;
}
.order__btn {
  display: flex;
  align-items: center;
  margin-top: 16px;
  color: #868786;
  cursor: pointer;
}
.order__btn:first-child {
  margin-top: 0;
}
.order__btn-icon {
  margin-right: 10px;
}
.order__btn-text {
  height: 100%;
}
.order__btn-close {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.order__btn-close-top {
  position: absolute;
  width: 12px;
  border-top: 2px solid #868786;
  transform: rotate(45deg);
}
.order__btn-close-left {
  position: absolute;
  width: 12px;
  border-top: 2px solid #868786;
  transform: rotate(-45deg);
}
.order__btn--big {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 24px;
  margin-bottom: 24px;
  color: #fff;
  text-transform: uppercase;
  background: #ed7817;
}
@media (max-width: 767.98px) {
  .order__btn--big {
    width: 100%;
  }
}
.order__btn-img {
  margin-right: 16px;
}

.order-composition {
  margin-top: 40px;
}
@media (max-width: 767.98px) {
  .order-composition__main {
    margin-bottom: 32px;
  }
}
.order-composition__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  .order-composition__header {
    flex-direction: column;
    align-items: flex-start;
  }
}
.order-composition__header-item {
  line-height: 140%;
  color: #868786;
}
.order-composition__header-item--center {
  margin-left: 100px;
}
.order-composition__title {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 26px;
  font-weight: 500;
}
.order-composition__header-btn {
  padding: 11px 24px;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid #000;
}
@media (max-width: 767.98px) {
  .order-composition__header-btn {
    width: 100%;
    margin-top: 18px;
  }
}
.order-composition__row {
  display: grid;
  padding: 16px 0;
  grid-template-columns: 1fr 1fr 1fr;
  border-top: 1px solid #d9dada;
}
@media (max-width: 767.98px) {
  .order-composition__row {
    display: flex;
    flex-direction: column;
  }
}
.order-composition__row--small {
  padding: 8px 0;
}
@media (max-width: 767.98px) {
  .order-composition__row--small {
    display: none;
  }
}
.order-composition__row--without {
  padding-top: 0;
  border-width: 0;
}
@media (max-width: 767.98px) {
  .order-composition__row--without {
    padding-bottom: 0;
    margin-bottom: 16px;
    border-left: 1px solid #d9dada;
  }
}
.order-composition__row-price {
  font-size: 20px;
  font-weight: 500;
  text-align: end;
}
@media (max-width: 767.98px) {
  .order-composition__row-price {
    text-align: start;
  }
}
@media (max-width: 767.98px) {
  .order-composition__row-price--mod {
    padding-left: 24px;
  }
}
.order-composition__row-size {
  margin-left: 100px;
  font-weight: 500;
  line-height: 140%;
}
@media (max-width: 767.98px) {
  .order-composition__row-size {
    margin-bottom: 12px;
    margin-left: 0;
  }
}
.order-composition__row-size--spacer {
  margin-bottom: 0;
}
.order-composition__row-name {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 1px solid #fab82e;
}
.order-composition__row-name--border {
  border-width: 0;
}
.order-composition__row-name--bold {
  font-size: 20px;
  font-weight: 500;
}
.order-composition__row-item--border {
  padding-left: 24px;
  border-left: 1px solid #d9dada;
}
@media (max-width: 767.98px) {
  .order-composition__row-item--border {
    border-left: 0 solid #d9dada;
  }
}
@media (max-width: 767.98px) {
  .order-composition__row-item {
    margin-bottom: 12px;
  }
}
.order-composition__row-info {
  margin-top: 4px;
  font-size: 14px;
  color: #565655;
}
.order-composition__icon {
  font-size: 32px;
  color: #000;
  border-width: 0;
}
.order-composition__documents {
  display: flex;
  flex-direction: column;
}
.order-composition__documents-wrap {
  margin-top: 16px;
}
.order-composition__document {
  display: flex;
  text-decoration: none;
}
.order-composition__document-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 20px;
}

.payment-methods {
  padding-right: 22px;
  margin-top: -15px;
}
.payment-methods__title {
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: 500;
}
.payment-methods__item {
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 24px;
  cursor: pointer;
  border: 1px solid #d9dada;
}
.payment-methods__item--active {
  border: 1px solid #ed7817;
}
.payment-methods__icon {
  margin-right: 8px;
}
.payment-methods__text-info {
  font-size: 14px;
  color: #565655;
}
.payment-methods__btn {
  padding: 11px 24px;
  margin-right: 8px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 1023.98px) {
  .payment-methods__btn {
    width: 50%;
    height: auto;
  }
}
@media (max-width: 767.98px) {
  .payment-methods__btn {
    width: 100%;
    height: auto;
  }
}
.payment-methods__btn:last-child {
  margin-right: 0;
}
.payment-methods__btn--dark {
  border: 2px solid #000;
}
@media (max-width: 767.98px) {
  .payment-methods__btn--dark {
    margin-top: 24px;
  }
}
.payment-methods__btn--orange {
  color: #fff;
  background: #ed7817;
  border: 2px solid #ed7817;
}
.payment-methods__btn-wrap {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .payment-methods__btn-wrap {
    flex-direction: column-reverse;
  }
}

.load-proxy__title {
  margin-bottom: 24px;
  font-size: 26px;
  font-weight: 500;
}
.load-proxy__drop-area {
  margin-bottom: 8px;
}
.load-proxy__info {
  color: #565655;
}
.load-proxy__btn {
  padding: 11px 24px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: #ed7817;
  border: 2px solid #ed7817;
}
@media (max-width: 1023.98px) {
  .load-proxy__btn {
    width: 100%;
    height: auto;
  }
}
.load-proxy__btn-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
@media (max-width: 767.98px) {
  .load-proxy__btn-wrap {
    flex-direction: column-reverse;
  }
}

.repeat-order {
  min-width: 450px;
}
@media (max-width: 767.98px) {
  .repeat-order {
    min-width: initial;
  }
}
.repeat-order__title {
  font-size: 26px;
  font-weight: 500;
}
.repeat-order__title-wrap {
  display: flex;
  margin-bottom: 8px;
}
.repeat-order__title-icon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  color: #fff;
  background: #ed7817;
  border-radius: 9px;
}
.repeat-order__btn {
  padding: 11px 24px;
  margin-top: 24px;
  margin-right: 8px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 1023.98px) {
  .repeat-order__btn {
    width: 50%;
    height: auto;
  }
}
@media (max-width: 767.98px) {
  .repeat-order__btn {
    width: 100%;
    height: auto;
  }
}
.repeat-order__btn:last-child {
  margin-right: 0;
}
.repeat-order__btn--dark {
  border: 2px solid #000;
}
.repeat-order__btn--orange {
  color: #fff;
  background: #ed7817;
  border: 2px solid #ed7817;
}
.repeat-order__btn-wrap {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .repeat-order__btn-wrap {
    flex-direction: column-reverse;
  }
}
.repeat-order__info {
  margin-left: 42px;
}

.cancel-order {
  min-width: 450px;
}
@media (max-width: 767.98px) {
  .cancel-order {
    min-width: initial;
  }
}
.cancel-order__header {
  display: flex;
  margin-bottom: 24px;
}
.cancel-order__icon {
  width: 10px;
  height: 10px;
  margin-top: 1px;
  margin-left: 1px;
  background-color: #fff;
  -webkit-clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}
.cancel-order__icon-wrap {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  margin-right: 16px;
  background-color: #e32112;
  border-radius: 9px;
}
.cancel-order__btn {
  padding: 11px 24px;
  margin-top: 24px;
  margin-right: 8px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 1023.98px) {
  .cancel-order__btn {
    width: 50%;
    height: auto;
  }
}
@media (max-width: 767.98px) {
  .cancel-order__btn {
    width: 100%;
    height: auto;
  }
}
.cancel-order__btn:last-child {
  margin-right: 0;
}
.cancel-order__btn--dark {
  border: 2px solid #000;
}
.cancel-order__btn--red {
  color: #fff;
  background: #e32112;
  border: 2px solid #e32112;
}
.cancel-order__btn-wrap {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .cancel-order__btn-wrap {
    flex-direction: column-reverse;
  }
}
.cancel-order__title {
  margin-bottom: 8px;
  font-size: 26px;
  font-weight: 500;
}
.cancel-order__select {
  margin-bottom: 24px;
}

.drop-area {
  padding: 16px 24px;
  cursor: pointer;
  border: 1px dashed #b1b2b2;
}
.drop-area__input {
  display: none;
}
.drop-area__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.drop-area__icon {
  margin-bottom: 10px;
}
.drop-area__file {
  margin-top: 10px;
  color: #ed7817;
}
.drop-area__file--hidden {
  display: none;
}

.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: none;
  justify-content: center;
  font-weight: initial;
  text-transform: initial;
  cursor: initial;
}
.popup--active {
  display: flex;
}
.popup__content {
  position: relative;
  z-index: 2;
  padding: 35px 38px;
  margin: auto;
  overflow-y: auto;
  color: #000;
  background-color: #fff;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3), 0 1px 7px 2px rgba(0, 0, 0, 0.03);
}
@media (max-width: 1023.98px) {
  .popup__content {
    width: 100%;
    height: 100%;
    padding: 35px 32px;
    margin: auto 0;
  }
}
.popup__wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: #fff;
  opacity: 0.7;
}
.popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.custom-select__filter {
  width: 100%;
  height: auto;
  position: absolute;
  display: none;
  padding: 0;
  list-style: none;
  outline: #fff;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, 0.3), 0 1px 7px 2px rgba(0, 0, 0, 0.03);
  /* stylelint-disable */
  /* stylelint-enable */
}
.custom-select__filter--active {
  z-index: 200;
}
.custom-select__filter--active .custom-select__selected-elem {
  border: 1px solid #ed7817;
}
.custom-select__filter--active .custom-select__filter {
  display: block;
}
.custom-select__filter--active .custom-select__arrow {
  top: 15px;
  transform: rotate(135deg);
}
.custom-select__filter-wrap {
  position: relative;
}
.custom-select__filter-item {
  padding: 9px;
  text-align: initial;
  background: #fff;
}
.custom-select__filter-item:hover {
  cursor: pointer;
  background: #fef4dd;
}
.custom-select__filter:focus {
  border: 1px solid #ed7817;
}
.custom-select__filter-title {
  margin-bottom: 8px;
  font-weight: 500;
}
.custom-select__selected-elem {
  width: 100%;
  height: auto;
  position: relative;
  padding: 10px;
  text-align: initial;
  cursor: pointer;
  border: 1px solid #d9dada;
}
.custom-select__selected-elem--template {
  display: none;
}
.custom-select__selected-elem--default {
  color: #868786;
  opacity: 0.7;
}
.custom-select__arrow {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 12px;
  right: 20px;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  transition-duration: 0.2s;
  transform: rotate(-45deg);
}

.radio-button {
  margin-bottom: 16px;
}
.radio-button__input {
  display: none;
}
.radio-button__input:checked + label::before {
  border: 6px solid #ed7817;
}
.radio-button__label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  margin-right: 0;
  line-height: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-feature-settings: "tnum" on, "lnum" on, "ss04" on;
}
.radio-button__label::before {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  content: "";
  background: #fff;
  border: 1px solid #b1b2b2;
  border-radius: 9px;
}

.order-payment {
  max-width: 880px;
  margin: 0 auto 60px;
}
@media (max-width: 1023.98px) {
  .order-payment {
    margin: 0 0 60px;
  }
}
.order-payment__title {
  margin-bottom: 24px;
  font-size: 26px;
  font-weight: 500;
}
.order-payment__span {
  color: #565655;
}
.order-payment__btn {
  padding: 11px 24px;
  margin-right: 24px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .order-payment__btn {
    width: 100%;
    margin-bottom: 16px;
  }
  .order-payment__btn:last-child {
    margin-bottom: 0;
  }
}
.order-payment__btn:last-child {
  margin-right: 0;
}
.order-payment__btn-wrap {
  display: flex;
  margin-top: 8px;
}
@media (max-width: 767.98px) {
  .order-payment__btn-wrap {
    flex-direction: column;
  }
}
.order-payment__btn--dark {
  border: 2px solid #000;
}
.order-payment__btn--orange {
  color: #fff;
  background: #ed7817;
  border: 2px solid #ed7817;
}
.order-payment__info {
  padding: 32px;
  margin-top: 28px;
  background: #f3f2f2;
}
@media (max-width: 767.98px) {
  .order-payment__info {
    padding: 32px 16px;
  }
}
.order-payment__info-title {
  font-size: 26px;
  font-weight: 500;
}
.order-payment__info-container {
  display: flex;
}
@media (max-width: 767.98px) {
  .order-payment__info-container {
    flex-direction: column;
  }
}
.order-payment__info-content {
  display: flex;
  flex-basis: 55%;
  padding-right: 30px;
  margin-top: 24px;
}
.order-payment__info-content:first-child {
  flex-basis: 45%;
}
@media (max-width: 767.98px) {
  .order-payment__info-content {
    padding-right: 0;
  }
}
.order-payment__info-icon {
  margin-right: 24px;
}
.order-payment__info-light {
  margin-bottom: 4px;
  color: #565655;
}
.order-payment__info-bold {
  margin-bottom: 4px;
  font-weight: 500;
}
.order-payment__info-normal {
  margin-bottom: 4px;
}
.order-payment__info-normal--mod {
  font-feature-settings: "tnum" on;
}

.product {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
}
.product__image {
  width: 100%;
}
.product__title {
  margin: 10px 0;
  font-size: 26px;
  font-weight: 500;
  color: #000;
}
.product__code {
  margin: 10px 0;
  color: #565655;
}
.product__units {
  font-size: 1rem;
}
.product__controls .btn-close {
  position: relative;
  float: right;
  font-size: 12px;
  background-size: 12px;
}
@media (min-width: 768px) {
  .product {
    width: 60%;
    font-size: 1rem;
  }
}
@media (min-width: 1024px) {
  .product {
    width: 100%;
    font-size: 1rem;
  }
}

.operations-history__filter {
  display: flex;
  flex-direction: column;
}
.operations-history__filter .input-group {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .operations-history__filter {
    flex-direction: row;
    justify-content: space-between;
  }
  .operations-history__filter .input-group {
    max-width: 312px;
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.operations-history__list .cabinet-section__item:first-child {
  padding-top: 16px;
  border-top: 1px solid #d9dada;
}
.operations-history__list .cabinet-section__item:last-child {
  border-bottom: 1px solid #d9dada;
}

.list-picker {
  display: flex;
}
.list-picker-elem {
  margin-bottom: 16px;
  cursor: pointer;
}
.list-picker-elem--active {
  color: #ed7817;
}

.appeal__popup-content {
  min-width: 450px;
}
@media (max-width: 767.98px) {
  .appeal__popup-content {
    min-width: initial;
  }
}
.appeal__arrow {
  top: 10px;
}
.appeal__title {
  margin-bottom: 24px;
  font-size: 26px;
  font-weight: 500;
  text-align: initial;
}
.appeal__select {
  margin-bottom: 24px;
}
.appeal__textarea {
  margin-bottom: 24px;
}
.appeal__textarea--mod {
  margin-bottom: 0;
}
.appeal__info {
  margin-top: 8px;
  font-size: 14px;
  color: #565655;
}
.appeal__btn {
  padding: 11px 24px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: #ed7817;
  border: 2px solid #ed7817;
}
@media (max-width: 1023.98px) {
  .appeal__btn {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 1023.98px) {
  .appeal__btn--mod {
    margin-top: 10px;
  }
}
.appeal__btn-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
.appeal__btn-wrap--mod {
  justify-content: space-between;
}
@media (max-width: 1023.98px) {
  .appeal__btn-wrap--mod {
    flex-direction: column;
  }
}
.appeal__bold {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  text-align: start;
}
.appeal__light {
  margin-top: 8px;
  font-size: 14px;
  font-weight: initial;
  color: #868786;
  text-align: start;
}
@media (max-width: 767.98px) {
  .appeal-page__title {
    padding: 0 10px;
  }
}
.appeal-page__btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 11px 24px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: #ed7817;
  border: 2px solid #ed7817;
}
@media (max-width: 1023.98px) {
  .appeal-page__btn {
    width: 100%;
    height: auto;
  }
}
.appeal-page__btn-wrap {
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  .appeal-page__btn-wrap {
    padding: 0 10px;
  }
}
.appeal-page__appeals {
  border-bottom: 1px solid #d9dada;
}
.appeal-page__container {
  margin-bottom: 60px;
}
@media (max-width: 767.98px) {
  .appeal-page__wrap {
    padding: 0;
  }
}
.appeal__load-file {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}
.appeal__load-file-img {
  margin-right: 8px;
}
.appeal__load-file-text {
  border-bottom: 1px dashed #000;
}

.single-appeal {
  display: flex;
  padding: 12px 20px;
  border-top: 1px solid #d9dada;
}
.single-appeal__icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 28px;
  background: #fab82e;
  border-radius: 24px;
}
@media (max-width: 767.98px) {
  .single-appeal__icon-wrap {
    display: none;
  }
}
.single-appeal__icon--green {
  background: #4a8f40;
}
.single-appeal__main-light {
  margin-bottom: 4px;
  font-size: 14px;
  color: #868786;
}
.single-appeal__main-title {
  margin-bottom: 4px;
  font-weight: 500;
}
.single-appeal__main-text {
  margin-bottom: 4px;
}
.single-appeal__main-info-title {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 15px;
  color: #ed7817;
  cursor: pointer;
}
.single-appeal__main-info-content {
  padding-left: 16px;
  margin-top: 8px;
  border-left: 1px solid #b1b2b2;
}
.single-appeal__info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.single-appeal__info-text {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 8px;
  color: #4a8f40;
  text-align: center;
  background: #e7f0e6;
  border: 1px solid #b5d5b0;
}
@media (max-width: 767.98px) {
  .single-appeal__info-text {
    width: 100%;
  }
}
.single-appeal__info-file {
  display: flex;
  margin-top: 30px;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .single-appeal__info-file {
    margin-top: 10px;
  }
}
.single-appeal__info-file-icon {
  margin-right: 5px;
}
.single-appeal__info-file-name {
  line-height: 140%;
  color: #b1b2b2;
}
@media (max-width: 767.98px) {
  .single-appeal__info {
    align-items: flex-start;
    margin-top: 10px;
  }
}
.single-appeal__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 767.98px) {
  .single-appeal__content {
    flex-direction: column;
  }
}
.single-appeal__arrow {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 5px;
  right: 0;
  border-bottom: 2px solid #ed7817;
  border-left: 2px solid #ed7817;
  transition-duration: 0.2s;
  transform: rotate(-45deg);
}
.single-appeal--green {
  background: #e7f0e6;
}

.city-search {
  -moz-column-count: 3;
  column-count: 3;
}
.city-search__city {
  display: flex;
  margin-bottom: 10px;
  font-size: 14px;
}
.city-search__city-letter {
  width: 12px;
  margin-right: 24px;
  font-weight: 500;
  color: #868786;
}
.city-search__city-letter--hide {
  opacity: 0;
}
.city-search__template {
  display: none;
}
@media (max-width: 1023.98px) {
  .city-search {
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 767.98px) {
  .city-search {
    -moz-column-count: 1;
    column-count: 1;
  }
}

.ripple-block {
  background-color: #ed7817 !important;
  background-image: linear-gradient(90deg, #ed7817, #f4ae74);
}

.ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 100px;
  opacity: 0;
  -webkit-animation: ripple 2s 1;
  animation: ripple 2s 1;
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes ripple {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.ripple:nth-child(2) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.ripple:nth-child(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.animated {
  -webkit-animation: mymove 2s;
  animation: mymove 2s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-slow {
  -webkit-animation: mymove 3s;
  animation: mymove 3s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.rubber-band {
  -webkit-animation-name: rubber-band;
  animation-name: rubber-band;
}

@-webkit-keyframes rubber-band {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubber-band {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}
.swing {
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes wobble {
  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    transform: none;
  }
}

@keyframes wobble {
  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    transform: none;
  }
}
.fade-in-left {
  -webkit-animation-name: fade-in-left;
  animation-name: fade-in-left;
}

@-webkit-keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fade-in-right {
  -webkit-animation-name: fade-in-right;
  animation-name: fade-in-right;
}

@-webkit-keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fade-in-down {
  -webkit-animation-name: fade-in-down;
  animation-name: fade-in-down;
}

@-webkit-keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fade-in-up {
  -webkit-animation-name: fade-in-up;
  animation-name: fade-in-up;
}

@-webkit-keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.light-speed-in {
  -webkit-animation-name: light-speed-in;
  animation-name: light-speed-in;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes light-speed-in {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0) skewX(-30deg);
  }
  60% {
    opacity: 1;
    transform: skewX(20deg);
  }
  80% {
    opacity: 1;
    transform: skewX(-5deg);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes light-speed-in {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0) skewX(-30deg);
  }
  60% {
    opacity: 1;
    transform: skewX(20deg);
  }
  80% {
    opacity: 1;
    transform: skewX(-5deg);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.zoom-in {
  -webkit-animation-name: zoom-in;
  animation-name: zoom-in;
}

@-webkit-keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.zoom-out {
  -webkit-animation-name: zoom-out;
  animation-name: zoom-out;
}

@-webkit-keyframes zoom-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}

@keyframes zoom-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #fab82e;
}
.link-primary:hover, .link-primary:focus {
  color: #fbc658;
}

.link-secondary {
  color: #ed7817;
}
.link-secondary:hover, .link-secondary:focus {
  color: #be6012;
}

.link-success {
  color: #4a8f40;
}
.link-success:hover, .link-success:focus {
  color: #3b7233;
}

.link-info {
  color: #2070df;
}
.link-info:hover, .link-info:focus {
  color: #1a5ab2;
}

.link-warning {
  color: #fab82e;
}
.link-warning:hover, .link-warning:focus {
  color: #fbc658;
}

.link-danger {
  color: #e32112;
}
.link-danger:hover, .link-danger:focus {
  color: #b61a0e;
}

.link-light {
  color: #f3f2f2;
}
.link-light:hover, .link-light:focus {
  color: whitesmoke;
}

.link-gray {
  color: #d9dada;
}
.link-gray:hover, .link-gray:focus {
  color: #e1e1e1;
}

.link-dark {
  color: #000;
}
.link-dark:hover, .link-dark:focus {
  color: black;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 320px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1024px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1280px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1440px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
}
.text-overflow > span {
  position: relative;
  display: inline-block;
  padding-right: 20px;
}
.text-overflow > span:hover {
  -webkit-animation: move 3s;
  animation: move 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.text-overflow::after {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  display: inline-block;
  width: 40px;
  height: 12px;
  content: "";
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), white);
}

@-webkit-keyframes move {
  0% {
    left: 0%;
    transform: translateX(0%);
  }
  100% {
    left: 100%;
    transform: translateX(-100%);
  }
}

@keyframes move {
  0% {
    left: 0%;
    transform: translateX(0%);
  }
  100% {
    left: 100%;
    transform: translateX(-100%);
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #d9dada !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #d9dada !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #d9dada !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #d9dada !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #d9dada !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #fab82e !important;
}

.border-secondary {
  border-color: #ed7817 !important;
}

.border-success {
  border-color: #4a8f40 !important;
}

.border-info {
  border-color: #2070df !important;
}

.border-warning {
  border-color: #fab82e !important;
}

.border-danger {
  border-color: #e32112 !important;
}

.border-light {
  border-color: #f3f2f2 !important;
}

.border-gray {
  border-color: #d9dada !important;
}

.border-dark {
  border-color: #000 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-10 {
  width: 10% !important;
}

.w-25 {
  width: 25% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-400px {
  max-width: 400px !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-basis-33 {
  flex-basis: 33.3333% !important;
}

.flex-basis-25 {
  flex-basis: 25% !important;
}

.flex-basis-40 {
  flex-basis: 40% !important;
}

.flex-basis-50 {
  flex-basis: 50% !important;
}

.flex-basis-60 {
  flex-basis: 60% !important;
}

.flex-basis-75 {
  flex-basis: 75% !important;
}

.flex-basis-100 {
  flex-basis: 100% !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-c {
  gap: 0.75rem !important;
}

.gap-32 {
  gap: 2rem !important;
}

.gap-40 {
  gap: 2.5rem !important;
}

.gap-50 {
  gap: 3.125rem !important;
}

.gap-64 {
  gap: 4rem !important;
}

.gap-80 {
  gap: 5rem !important;
}

.gap-96 {
  gap: 6rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-c {
  margin: 0.75rem !important;
}

.m-32 {
  margin: 2rem !important;
}

.m-40 {
  margin: 2.5rem !important;
}

.m-50 {
  margin: 3.125rem !important;
}

.m-64 {
  margin: 4rem !important;
}

.m-80 {
  margin: 5rem !important;
}

.m-96 {
  margin: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-c {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-32 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-40 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-50 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important;
}

.mx-64 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-80 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-96 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-c {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-32 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-40 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-50 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.my-64 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-80 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-96 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-c {
  margin-top: 0.75rem !important;
}

.mt-32 {
  margin-top: 2rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.mt-64 {
  margin-top: 4rem !important;
}

.mt-80 {
  margin-top: 5rem !important;
}

.mt-96 {
  margin-top: 6rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-c {
  margin-right: 0.75rem !important;
}

.me-32 {
  margin-right: 2rem !important;
}

.me-40 {
  margin-right: 2.5rem !important;
}

.me-50 {
  margin-right: 3.125rem !important;
}

.me-64 {
  margin-right: 4rem !important;
}

.me-80 {
  margin-right: 5rem !important;
}

.me-96 {
  margin-right: 6rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-c {
  margin-bottom: 0.75rem !important;
}

.mb-32 {
  margin-bottom: 2rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.mb-64 {
  margin-bottom: 4rem !important;
}

.mb-80 {
  margin-bottom: 5rem !important;
}

.mb-96 {
  margin-bottom: 6rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-c {
  margin-left: 0.75rem !important;
}

.ms-32 {
  margin-left: 2rem !important;
}

.ms-40 {
  margin-left: 2.5rem !important;
}

.ms-50 {
  margin-left: 3.125rem !important;
}

.ms-64 {
  margin-left: 4rem !important;
}

.ms-80 {
  margin-left: 5rem !important;
}

.ms-96 {
  margin-left: 6rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-c {
  padding: 0.75rem !important;
}

.p-32 {
  padding: 2rem !important;
}

.p-40 {
  padding: 2.5rem !important;
}

.p-50 {
  padding: 3.125rem !important;
}

.p-64 {
  padding: 4rem !important;
}

.p-80 {
  padding: 5rem !important;
}

.p-96 {
  padding: 6rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-c {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-32 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-40 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-50 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important;
}

.px-64 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-80 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-96 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-c {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-32 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-50 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.py-64 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-96 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-c {
  padding-top: 0.75rem !important;
}

.pt-32 {
  padding-top: 2rem !important;
}

.pt-40 {
  padding-top: 2.5rem !important;
}

.pt-50 {
  padding-top: 3.125rem !important;
}

.pt-64 {
  padding-top: 4rem !important;
}

.pt-80 {
  padding-top: 5rem !important;
}

.pt-96 {
  padding-top: 6rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-c {
  padding-right: 0.75rem !important;
}

.pe-32 {
  padding-right: 2rem !important;
}

.pe-40 {
  padding-right: 2.5rem !important;
}

.pe-50 {
  padding-right: 3.125rem !important;
}

.pe-64 {
  padding-right: 4rem !important;
}

.pe-80 {
  padding-right: 5rem !important;
}

.pe-96 {
  padding-right: 6rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-c {
  padding-bottom: 0.75rem !important;
}

.pb-32 {
  padding-bottom: 2rem !important;
}

.pb-40 {
  padding-bottom: 2.5rem !important;
}

.pb-50 {
  padding-bottom: 3.125rem !important;
}

.pb-64 {
  padding-bottom: 4rem !important;
}

.pb-80 {
  padding-bottom: 5rem !important;
}

.pb-96 {
  padding-bottom: 6rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-c {
  padding-left: 0.75rem !important;
}

.ps-32 {
  padding-left: 2rem !important;
}

.ps-40 {
  padding-left: 2.5rem !important;
}

.ps-50 {
  padding-left: 3.125rem !important;
}

.ps-64 {
  padding-left: 4rem !important;
}

.ps-80 {
  padding-left: 5rem !important;
}

.ps-96 {
  padding-left: 6rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-s {
  font-size: 0.75rem !important;
}

.fs-m {
  font-size: 0.875rem !important;
}

.fs-l {
  font-size: 1rem !important;
}

.fs-xl {
  font-size: 1.125rem !important;
}

.fs-xxl {
  font-size: 1.25rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-md {
  line-height: 1.5 !important;
}

.lh-base {
  line-height: 1.3 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #fab82e !important;
}

.text-secondary {
  color: #ed7817 !important;
}

.text-success {
  color: #4a8f40 !important;
}

.text-info {
  color: #2070df !important;
}

.text-warning {
  color: #fab82e !important;
}

.text-danger {
  color: #e32112 !important;
}

.text-light {
  color: #f3f2f2 !important;
}

.text-gray {
  color: #d9dada !important;
}

.text-dark {
  color: #000 !important;
}

.text-gray-600 {
  color: #868786 !important;
}

.text-gray-700 {
  color: #565655 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #000 !important;
}

.text-muted {
  color: #868786 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #fab82e !important;
}

.bg-secondary {
  background-color: #ed7817 !important;
}

.bg-success {
  background-color: #4a8f40 !important;
}

.bg-info {
  background-color: #2070df !important;
}

.bg-warning {
  background-color: #fab82e !important;
}

.bg-danger {
  background-color: #e32112 !important;
}

.bg-light {
  background-color: #f3f2f2 !important;
}

.bg-gray {
  background-color: #d9dada !important;
}

.bg-dark {
  background-color: #000 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0 !important;
}

.rounded-2 {
  border-radius: 0 !important;
}

.rounded-3 {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-end {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 320px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .w-sm-10 {
    width: 10% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-45 {
    width: 45% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-basis-33 {
    flex-basis: 33.3333% !important;
  }

  .flex-sm-basis-25 {
    flex-basis: 25% !important;
  }

  .flex-sm-basis-40 {
    flex-basis: 40% !important;
  }

  .flex-sm-basis-50 {
    flex-basis: 50% !important;
  }

  .flex-sm-basis-60 {
    flex-basis: 60% !important;
  }

  .flex-sm-basis-75 {
    flex-basis: 75% !important;
  }

  .flex-sm-basis-100 {
    flex-basis: 100% !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-c {
    gap: 0.75rem !important;
  }

  .gap-sm-32 {
    gap: 2rem !important;
  }

  .gap-sm-40 {
    gap: 2.5rem !important;
  }

  .gap-sm-50 {
    gap: 3.125rem !important;
  }

  .gap-sm-64 {
    gap: 4rem !important;
  }

  .gap-sm-80 {
    gap: 5rem !important;
  }

  .gap-sm-96 {
    gap: 6rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-c {
    margin: 0.75rem !important;
  }

  .m-sm-32 {
    margin: 2rem !important;
  }

  .m-sm-40 {
    margin: 2.5rem !important;
  }

  .m-sm-50 {
    margin: 3.125rem !important;
  }

  .m-sm-64 {
    margin: 4rem !important;
  }

  .m-sm-80 {
    margin: 5rem !important;
  }

  .m-sm-96 {
    margin: 6rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-c {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-32 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }

  .mx-sm-64 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-96 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-c {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-32 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }

  .my-sm-64 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-96 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-c {
    margin-top: 0.75rem !important;
  }

  .mt-sm-32 {
    margin-top: 2rem !important;
  }

  .mt-sm-40 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-50 {
    margin-top: 3.125rem !important;
  }

  .mt-sm-64 {
    margin-top: 4rem !important;
  }

  .mt-sm-80 {
    margin-top: 5rem !important;
  }

  .mt-sm-96 {
    margin-top: 6rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-c {
    margin-right: 0.75rem !important;
  }

  .me-sm-32 {
    margin-right: 2rem !important;
  }

  .me-sm-40 {
    margin-right: 2.5rem !important;
  }

  .me-sm-50 {
    margin-right: 3.125rem !important;
  }

  .me-sm-64 {
    margin-right: 4rem !important;
  }

  .me-sm-80 {
    margin-right: 5rem !important;
  }

  .me-sm-96 {
    margin-right: 6rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-c {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-32 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-40 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-50 {
    margin-bottom: 3.125rem !important;
  }

  .mb-sm-64 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-80 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-96 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-c {
    margin-left: 0.75rem !important;
  }

  .ms-sm-32 {
    margin-left: 2rem !important;
  }

  .ms-sm-40 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-50 {
    margin-left: 3.125rem !important;
  }

  .ms-sm-64 {
    margin-left: 4rem !important;
  }

  .ms-sm-80 {
    margin-left: 5rem !important;
  }

  .ms-sm-96 {
    margin-left: 6rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-c {
    padding: 0.75rem !important;
  }

  .p-sm-32 {
    padding: 2rem !important;
  }

  .p-sm-40 {
    padding: 2.5rem !important;
  }

  .p-sm-50 {
    padding: 3.125rem !important;
  }

  .p-sm-64 {
    padding: 4rem !important;
  }

  .p-sm-80 {
    padding: 5rem !important;
  }

  .p-sm-96 {
    padding: 6rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-c {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-32 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }

  .px-sm-64 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-sm-96 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-c {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }

  .py-sm-64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-96 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-c {
    padding-top: 0.75rem !important;
  }

  .pt-sm-32 {
    padding-top: 2rem !important;
  }

  .pt-sm-40 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-50 {
    padding-top: 3.125rem !important;
  }

  .pt-sm-64 {
    padding-top: 4rem !important;
  }

  .pt-sm-80 {
    padding-top: 5rem !important;
  }

  .pt-sm-96 {
    padding-top: 6rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-c {
    padding-right: 0.75rem !important;
  }

  .pe-sm-32 {
    padding-right: 2rem !important;
  }

  .pe-sm-40 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-50 {
    padding-right: 3.125rem !important;
  }

  .pe-sm-64 {
    padding-right: 4rem !important;
  }

  .pe-sm-80 {
    padding-right: 5rem !important;
  }

  .pe-sm-96 {
    padding-right: 6rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-c {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-32 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-40 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-50 {
    padding-bottom: 3.125rem !important;
  }

  .pb-sm-64 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-80 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-96 {
    padding-bottom: 6rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-c {
    padding-left: 0.75rem !important;
  }

  .ps-sm-32 {
    padding-left: 2rem !important;
  }

  .ps-sm-40 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-50 {
    padding-left: 3.125rem !important;
  }

  .ps-sm-64 {
    padding-left: 4rem !important;
  }

  .ps-sm-80 {
    padding-left: 5rem !important;
  }

  .ps-sm-96 {
    padding-left: 6rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .opacity-sm-0 {
    opacity: 0 !important;
  }

  .opacity-sm-1 {
    opacity: 1 !important;
  }

  .opacity-sm-50 {
    opacity: 0.5 !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .w-md-10 {
    width: 10% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-45 {
    width: 45% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-basis-33 {
    flex-basis: 33.3333% !important;
  }

  .flex-md-basis-25 {
    flex-basis: 25% !important;
  }

  .flex-md-basis-40 {
    flex-basis: 40% !important;
  }

  .flex-md-basis-50 {
    flex-basis: 50% !important;
  }

  .flex-md-basis-60 {
    flex-basis: 60% !important;
  }

  .flex-md-basis-75 {
    flex-basis: 75% !important;
  }

  .flex-md-basis-100 {
    flex-basis: 100% !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-c {
    gap: 0.75rem !important;
  }

  .gap-md-32 {
    gap: 2rem !important;
  }

  .gap-md-40 {
    gap: 2.5rem !important;
  }

  .gap-md-50 {
    gap: 3.125rem !important;
  }

  .gap-md-64 {
    gap: 4rem !important;
  }

  .gap-md-80 {
    gap: 5rem !important;
  }

  .gap-md-96 {
    gap: 6rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-c {
    margin: 0.75rem !important;
  }

  .m-md-32 {
    margin: 2rem !important;
  }

  .m-md-40 {
    margin: 2.5rem !important;
  }

  .m-md-50 {
    margin: 3.125rem !important;
  }

  .m-md-64 {
    margin: 4rem !important;
  }

  .m-md-80 {
    margin: 5rem !important;
  }

  .m-md-96 {
    margin: 6rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-c {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-32 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }

  .mx-md-64 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-96 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-c {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-32 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }

  .my-md-64 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-96 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-c {
    margin-top: 0.75rem !important;
  }

  .mt-md-32 {
    margin-top: 2rem !important;
  }

  .mt-md-40 {
    margin-top: 2.5rem !important;
  }

  .mt-md-50 {
    margin-top: 3.125rem !important;
  }

  .mt-md-64 {
    margin-top: 4rem !important;
  }

  .mt-md-80 {
    margin-top: 5rem !important;
  }

  .mt-md-96 {
    margin-top: 6rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-c {
    margin-right: 0.75rem !important;
  }

  .me-md-32 {
    margin-right: 2rem !important;
  }

  .me-md-40 {
    margin-right: 2.5rem !important;
  }

  .me-md-50 {
    margin-right: 3.125rem !important;
  }

  .me-md-64 {
    margin-right: 4rem !important;
  }

  .me-md-80 {
    margin-right: 5rem !important;
  }

  .me-md-96 {
    margin-right: 6rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-c {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-32 {
    margin-bottom: 2rem !important;
  }

  .mb-md-40 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-50 {
    margin-bottom: 3.125rem !important;
  }

  .mb-md-64 {
    margin-bottom: 4rem !important;
  }

  .mb-md-80 {
    margin-bottom: 5rem !important;
  }

  .mb-md-96 {
    margin-bottom: 6rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-c {
    margin-left: 0.75rem !important;
  }

  .ms-md-32 {
    margin-left: 2rem !important;
  }

  .ms-md-40 {
    margin-left: 2.5rem !important;
  }

  .ms-md-50 {
    margin-left: 3.125rem !important;
  }

  .ms-md-64 {
    margin-left: 4rem !important;
  }

  .ms-md-80 {
    margin-left: 5rem !important;
  }

  .ms-md-96 {
    margin-left: 6rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-c {
    padding: 0.75rem !important;
  }

  .p-md-32 {
    padding: 2rem !important;
  }

  .p-md-40 {
    padding: 2.5rem !important;
  }

  .p-md-50 {
    padding: 3.125rem !important;
  }

  .p-md-64 {
    padding: 4rem !important;
  }

  .p-md-80 {
    padding: 5rem !important;
  }

  .p-md-96 {
    padding: 6rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-c {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-32 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }

  .px-md-64 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-md-96 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-c {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }

  .py-md-64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-96 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-c {
    padding-top: 0.75rem !important;
  }

  .pt-md-32 {
    padding-top: 2rem !important;
  }

  .pt-md-40 {
    padding-top: 2.5rem !important;
  }

  .pt-md-50 {
    padding-top: 3.125rem !important;
  }

  .pt-md-64 {
    padding-top: 4rem !important;
  }

  .pt-md-80 {
    padding-top: 5rem !important;
  }

  .pt-md-96 {
    padding-top: 6rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-c {
    padding-right: 0.75rem !important;
  }

  .pe-md-32 {
    padding-right: 2rem !important;
  }

  .pe-md-40 {
    padding-right: 2.5rem !important;
  }

  .pe-md-50 {
    padding-right: 3.125rem !important;
  }

  .pe-md-64 {
    padding-right: 4rem !important;
  }

  .pe-md-80 {
    padding-right: 5rem !important;
  }

  .pe-md-96 {
    padding-right: 6rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-c {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-32 {
    padding-bottom: 2rem !important;
  }

  .pb-md-40 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-50 {
    padding-bottom: 3.125rem !important;
  }

  .pb-md-64 {
    padding-bottom: 4rem !important;
  }

  .pb-md-80 {
    padding-bottom: 5rem !important;
  }

  .pb-md-96 {
    padding-bottom: 6rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-c {
    padding-left: 0.75rem !important;
  }

  .ps-md-32 {
    padding-left: 2rem !important;
  }

  .ps-md-40 {
    padding-left: 2.5rem !important;
  }

  .ps-md-50 {
    padding-left: 3.125rem !important;
  }

  .ps-md-64 {
    padding-left: 4rem !important;
  }

  .ps-md-80 {
    padding-left: 5rem !important;
  }

  .ps-md-96 {
    padding-left: 6rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .opacity-md-0 {
    opacity: 0 !important;
  }

  .opacity-md-1 {
    opacity: 1 !important;
  }

  .opacity-md-50 {
    opacity: 0.5 !important;
  }
}
@media (min-width: 1024px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-45 {
    width: 45% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-basis-33 {
    flex-basis: 33.3333% !important;
  }

  .flex-lg-basis-25 {
    flex-basis: 25% !important;
  }

  .flex-lg-basis-40 {
    flex-basis: 40% !important;
  }

  .flex-lg-basis-50 {
    flex-basis: 50% !important;
  }

  .flex-lg-basis-60 {
    flex-basis: 60% !important;
  }

  .flex-lg-basis-75 {
    flex-basis: 75% !important;
  }

  .flex-lg-basis-100 {
    flex-basis: 100% !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-c {
    gap: 0.75rem !important;
  }

  .gap-lg-32 {
    gap: 2rem !important;
  }

  .gap-lg-40 {
    gap: 2.5rem !important;
  }

  .gap-lg-50 {
    gap: 3.125rem !important;
  }

  .gap-lg-64 {
    gap: 4rem !important;
  }

  .gap-lg-80 {
    gap: 5rem !important;
  }

  .gap-lg-96 {
    gap: 6rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-c {
    margin: 0.75rem !important;
  }

  .m-lg-32 {
    margin: 2rem !important;
  }

  .m-lg-40 {
    margin: 2.5rem !important;
  }

  .m-lg-50 {
    margin: 3.125rem !important;
  }

  .m-lg-64 {
    margin: 4rem !important;
  }

  .m-lg-80 {
    margin: 5rem !important;
  }

  .m-lg-96 {
    margin: 6rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-c {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-32 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }

  .mx-lg-64 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-96 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-c {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-32 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }

  .my-lg-64 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-96 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-c {
    margin-top: 0.75rem !important;
  }

  .mt-lg-32 {
    margin-top: 2rem !important;
  }

  .mt-lg-40 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-50 {
    margin-top: 3.125rem !important;
  }

  .mt-lg-64 {
    margin-top: 4rem !important;
  }

  .mt-lg-80 {
    margin-top: 5rem !important;
  }

  .mt-lg-96 {
    margin-top: 6rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-c {
    margin-right: 0.75rem !important;
  }

  .me-lg-32 {
    margin-right: 2rem !important;
  }

  .me-lg-40 {
    margin-right: 2.5rem !important;
  }

  .me-lg-50 {
    margin-right: 3.125rem !important;
  }

  .me-lg-64 {
    margin-right: 4rem !important;
  }

  .me-lg-80 {
    margin-right: 5rem !important;
  }

  .me-lg-96 {
    margin-right: 6rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-c {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-32 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-40 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-50 {
    margin-bottom: 3.125rem !important;
  }

  .mb-lg-64 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-80 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-96 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-c {
    margin-left: 0.75rem !important;
  }

  .ms-lg-32 {
    margin-left: 2rem !important;
  }

  .ms-lg-40 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-50 {
    margin-left: 3.125rem !important;
  }

  .ms-lg-64 {
    margin-left: 4rem !important;
  }

  .ms-lg-80 {
    margin-left: 5rem !important;
  }

  .ms-lg-96 {
    margin-left: 6rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-c {
    padding: 0.75rem !important;
  }

  .p-lg-32 {
    padding: 2rem !important;
  }

  .p-lg-40 {
    padding: 2.5rem !important;
  }

  .p-lg-50 {
    padding: 3.125rem !important;
  }

  .p-lg-64 {
    padding: 4rem !important;
  }

  .p-lg-80 {
    padding: 5rem !important;
  }

  .p-lg-96 {
    padding: 6rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-c {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-32 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }

  .px-lg-64 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-lg-96 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-c {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }

  .py-lg-64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-96 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-c {
    padding-top: 0.75rem !important;
  }

  .pt-lg-32 {
    padding-top: 2rem !important;
  }

  .pt-lg-40 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-50 {
    padding-top: 3.125rem !important;
  }

  .pt-lg-64 {
    padding-top: 4rem !important;
  }

  .pt-lg-80 {
    padding-top: 5rem !important;
  }

  .pt-lg-96 {
    padding-top: 6rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-c {
    padding-right: 0.75rem !important;
  }

  .pe-lg-32 {
    padding-right: 2rem !important;
  }

  .pe-lg-40 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-50 {
    padding-right: 3.125rem !important;
  }

  .pe-lg-64 {
    padding-right: 4rem !important;
  }

  .pe-lg-80 {
    padding-right: 5rem !important;
  }

  .pe-lg-96 {
    padding-right: 6rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-c {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-32 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-40 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-50 {
    padding-bottom: 3.125rem !important;
  }

  .pb-lg-64 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-80 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-96 {
    padding-bottom: 6rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-c {
    padding-left: 0.75rem !important;
  }

  .ps-lg-32 {
    padding-left: 2rem !important;
  }

  .ps-lg-40 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-50 {
    padding-left: 3.125rem !important;
  }

  .ps-lg-64 {
    padding-left: 4rem !important;
  }

  .ps-lg-80 {
    padding-left: 5rem !important;
  }

  .ps-lg-96 {
    padding-left: 6rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .opacity-lg-0 {
    opacity: 0 !important;
  }

  .opacity-lg-1 {
    opacity: 1 !important;
  }

  .opacity-lg-50 {
    opacity: 0.5 !important;
  }
}
@media (min-width: 1280px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .w-xl-10 {
    width: 10% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-45 {
    width: 45% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-basis-33 {
    flex-basis: 33.3333% !important;
  }

  .flex-xl-basis-25 {
    flex-basis: 25% !important;
  }

  .flex-xl-basis-40 {
    flex-basis: 40% !important;
  }

  .flex-xl-basis-50 {
    flex-basis: 50% !important;
  }

  .flex-xl-basis-60 {
    flex-basis: 60% !important;
  }

  .flex-xl-basis-75 {
    flex-basis: 75% !important;
  }

  .flex-xl-basis-100 {
    flex-basis: 100% !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-c {
    gap: 0.75rem !important;
  }

  .gap-xl-32 {
    gap: 2rem !important;
  }

  .gap-xl-40 {
    gap: 2.5rem !important;
  }

  .gap-xl-50 {
    gap: 3.125rem !important;
  }

  .gap-xl-64 {
    gap: 4rem !important;
  }

  .gap-xl-80 {
    gap: 5rem !important;
  }

  .gap-xl-96 {
    gap: 6rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-c {
    margin: 0.75rem !important;
  }

  .m-xl-32 {
    margin: 2rem !important;
  }

  .m-xl-40 {
    margin: 2.5rem !important;
  }

  .m-xl-50 {
    margin: 3.125rem !important;
  }

  .m-xl-64 {
    margin: 4rem !important;
  }

  .m-xl-80 {
    margin: 5rem !important;
  }

  .m-xl-96 {
    margin: 6rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-c {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-32 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }

  .mx-xl-64 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-96 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-c {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-32 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }

  .my-xl-64 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-96 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-c {
    margin-top: 0.75rem !important;
  }

  .mt-xl-32 {
    margin-top: 2rem !important;
  }

  .mt-xl-40 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-50 {
    margin-top: 3.125rem !important;
  }

  .mt-xl-64 {
    margin-top: 4rem !important;
  }

  .mt-xl-80 {
    margin-top: 5rem !important;
  }

  .mt-xl-96 {
    margin-top: 6rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-c {
    margin-right: 0.75rem !important;
  }

  .me-xl-32 {
    margin-right: 2rem !important;
  }

  .me-xl-40 {
    margin-right: 2.5rem !important;
  }

  .me-xl-50 {
    margin-right: 3.125rem !important;
  }

  .me-xl-64 {
    margin-right: 4rem !important;
  }

  .me-xl-80 {
    margin-right: 5rem !important;
  }

  .me-xl-96 {
    margin-right: 6rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-c {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-32 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-40 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-50 {
    margin-bottom: 3.125rem !important;
  }

  .mb-xl-64 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-80 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-96 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-c {
    margin-left: 0.75rem !important;
  }

  .ms-xl-32 {
    margin-left: 2rem !important;
  }

  .ms-xl-40 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-50 {
    margin-left: 3.125rem !important;
  }

  .ms-xl-64 {
    margin-left: 4rem !important;
  }

  .ms-xl-80 {
    margin-left: 5rem !important;
  }

  .ms-xl-96 {
    margin-left: 6rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-c {
    padding: 0.75rem !important;
  }

  .p-xl-32 {
    padding: 2rem !important;
  }

  .p-xl-40 {
    padding: 2.5rem !important;
  }

  .p-xl-50 {
    padding: 3.125rem !important;
  }

  .p-xl-64 {
    padding: 4rem !important;
  }

  .p-xl-80 {
    padding: 5rem !important;
  }

  .p-xl-96 {
    padding: 6rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-c {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-32 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }

  .px-xl-64 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xl-96 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-c {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }

  .py-xl-64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-96 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-c {
    padding-top: 0.75rem !important;
  }

  .pt-xl-32 {
    padding-top: 2rem !important;
  }

  .pt-xl-40 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-50 {
    padding-top: 3.125rem !important;
  }

  .pt-xl-64 {
    padding-top: 4rem !important;
  }

  .pt-xl-80 {
    padding-top: 5rem !important;
  }

  .pt-xl-96 {
    padding-top: 6rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-c {
    padding-right: 0.75rem !important;
  }

  .pe-xl-32 {
    padding-right: 2rem !important;
  }

  .pe-xl-40 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-50 {
    padding-right: 3.125rem !important;
  }

  .pe-xl-64 {
    padding-right: 4rem !important;
  }

  .pe-xl-80 {
    padding-right: 5rem !important;
  }

  .pe-xl-96 {
    padding-right: 6rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-c {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-32 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-40 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-50 {
    padding-bottom: 3.125rem !important;
  }

  .pb-xl-64 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-80 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-96 {
    padding-bottom: 6rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-c {
    padding-left: 0.75rem !important;
  }

  .ps-xl-32 {
    padding-left: 2rem !important;
  }

  .ps-xl-40 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-50 {
    padding-left: 3.125rem !important;
  }

  .ps-xl-64 {
    padding-left: 4rem !important;
  }

  .ps-xl-80 {
    padding-left: 5rem !important;
  }

  .ps-xl-96 {
    padding-left: 6rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .opacity-xl-0 {
    opacity: 0 !important;
  }

  .opacity-xl-1 {
    opacity: 1 !important;
  }

  .opacity-xl-50 {
    opacity: 0.5 !important;
  }
}
@media (min-width: 1440px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .w-xxl-10 {
    width: 10% !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-40 {
    width: 40% !important;
  }

  .w-xxl-45 {
    width: 45% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-60 {
    width: 60% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-basis-33 {
    flex-basis: 33.3333% !important;
  }

  .flex-xxl-basis-25 {
    flex-basis: 25% !important;
  }

  .flex-xxl-basis-40 {
    flex-basis: 40% !important;
  }

  .flex-xxl-basis-50 {
    flex-basis: 50% !important;
  }

  .flex-xxl-basis-60 {
    flex-basis: 60% !important;
  }

  .flex-xxl-basis-75 {
    flex-basis: 75% !important;
  }

  .flex-xxl-basis-100 {
    flex-basis: 100% !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .gap-xxl-c {
    gap: 0.75rem !important;
  }

  .gap-xxl-32 {
    gap: 2rem !important;
  }

  .gap-xxl-40 {
    gap: 2.5rem !important;
  }

  .gap-xxl-50 {
    gap: 3.125rem !important;
  }

  .gap-xxl-64 {
    gap: 4rem !important;
  }

  .gap-xxl-80 {
    gap: 5rem !important;
  }

  .gap-xxl-96 {
    gap: 6rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-c {
    margin: 0.75rem !important;
  }

  .m-xxl-32 {
    margin: 2rem !important;
  }

  .m-xxl-40 {
    margin: 2.5rem !important;
  }

  .m-xxl-50 {
    margin: 3.125rem !important;
  }

  .m-xxl-64 {
    margin: 4rem !important;
  }

  .m-xxl-80 {
    margin: 5rem !important;
  }

  .m-xxl-96 {
    margin: 6rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-c {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-32 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }

  .mx-xxl-64 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-96 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-c {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-32 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }

  .my-xxl-64 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-96 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-c {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-32 {
    margin-top: 2rem !important;
  }

  .mt-xxl-40 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-50 {
    margin-top: 3.125rem !important;
  }

  .mt-xxl-64 {
    margin-top: 4rem !important;
  }

  .mt-xxl-80 {
    margin-top: 5rem !important;
  }

  .mt-xxl-96 {
    margin-top: 6rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-c {
    margin-right: 0.75rem !important;
  }

  .me-xxl-32 {
    margin-right: 2rem !important;
  }

  .me-xxl-40 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-50 {
    margin-right: 3.125rem !important;
  }

  .me-xxl-64 {
    margin-right: 4rem !important;
  }

  .me-xxl-80 {
    margin-right: 5rem !important;
  }

  .me-xxl-96 {
    margin-right: 6rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-c {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-32 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-40 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-50 {
    margin-bottom: 3.125rem !important;
  }

  .mb-xxl-64 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-80 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-96 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-c {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-32 {
    margin-left: 2rem !important;
  }

  .ms-xxl-40 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-50 {
    margin-left: 3.125rem !important;
  }

  .ms-xxl-64 {
    margin-left: 4rem !important;
  }

  .ms-xxl-80 {
    margin-left: 5rem !important;
  }

  .ms-xxl-96 {
    margin-left: 6rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .p-xxl-c {
    padding: 0.75rem !important;
  }

  .p-xxl-32 {
    padding: 2rem !important;
  }

  .p-xxl-40 {
    padding: 2.5rem !important;
  }

  .p-xxl-50 {
    padding: 3.125rem !important;
  }

  .p-xxl-64 {
    padding: 4rem !important;
  }

  .p-xxl-80 {
    padding: 5rem !important;
  }

  .p-xxl-96 {
    padding: 6rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-c {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-32 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }

  .px-xxl-64 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xxl-96 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-c {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }

  .py-xxl-64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-96 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-c {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-32 {
    padding-top: 2rem !important;
  }

  .pt-xxl-40 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-50 {
    padding-top: 3.125rem !important;
  }

  .pt-xxl-64 {
    padding-top: 4rem !important;
  }

  .pt-xxl-80 {
    padding-top: 5rem !important;
  }

  .pt-xxl-96 {
    padding-top: 6rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pe-xxl-c {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-32 {
    padding-right: 2rem !important;
  }

  .pe-xxl-40 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-50 {
    padding-right: 3.125rem !important;
  }

  .pe-xxl-64 {
    padding-right: 4rem !important;
  }

  .pe-xxl-80 {
    padding-right: 5rem !important;
  }

  .pe-xxl-96 {
    padding-right: 6rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-c {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-32 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-40 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-50 {
    padding-bottom: 3.125rem !important;
  }

  .pb-xxl-64 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-80 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-96 {
    padding-bottom: 6rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .ps-xxl-c {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-32 {
    padding-left: 2rem !important;
  }

  .ps-xxl-40 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-50 {
    padding-left: 3.125rem !important;
  }

  .ps-xxl-64 {
    padding-left: 4rem !important;
  }

  .ps-xxl-80 {
    padding-left: 5rem !important;
  }

  .ps-xxl-96 {
    padding-left: 6rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .opacity-xxl-0 {
    opacity: 0 !important;
  }

  .opacity-xxl-1 {
    opacity: 1 !important;
  }

  .opacity-xxl-50 {
    opacity: 0.5 !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

/*# sourceMappingURL=evrazui.css.map */