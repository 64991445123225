.error-page {
  padding: 3rem 0;

  @include media-breakpoint-up(md) {
    padding: 5rem 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 150px 0;
  }


  // Brand art
  .brand-art {
    &-left {
      display: flex;

      &__item:first-child {
        @include size(35px, 16px);
        position: relative;
        background-color: $primary;

        @include media-breakpoint-up(md) {
          @include size(70px, 32px);
        }

        @include media-breakpoint-up(xl) {
          @include size(140px, 64px);
        }
      }

      &__item:last-child {
        @include size(8px, 17px);
        position: relative;
        top: 16px;
        background-color: $secondary;

        @include media-breakpoint-up(md) {
          @include size(16px, 34px);
          top: 32px;
        }

        @include media-breakpoint-up(xl) {
          @include size(32px, 68px);
          top: 64px;
        }
      }
    }

    &-right {
      align-self: flex-end;

      &__item {
        @include size(43px, 4px);
        position: relative;
        background-color: $danger;

        @include media-breakpoint-up(md) {
          @include size(86px, 8px);
        }

        @include media-breakpoint-up(xl) {
          @include size(172px, 16px);
        }
      }
    }
  }


  &__wrap {
    display: flex;
  }


  // Main
  &-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      margin: 0 1.5rem;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 52px;
    }


    &__title {
      flex: 1 1 50%;
      font-size: 100px;
      font-weight: 500;

      @include media-breakpoint-up(lg) {
        font-size: 180px;
        line-height: .75;
      }
    }

    &__wrap {
      flex: 1 1 50%;
    }

    &__subtitle {
      margin-bottom: 1.5rem;

      @include media-breakpoint-down(md) {
        font-size: 1.2rem;
      }
    }
  }


  // Error page links
  &-link {
    display: flex;

    &__wrap {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex: 0 1 50%;
      }

      &:first-child {
        margin-right: 1rem;
      }
    }

    &__item {
      font-size: $fs-s;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        font-size: $fs-l;
      }

      &:first-child {
        margin-bottom: 1.5rem;
      }
    }
  }
}
