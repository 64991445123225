.service-branches {
  &__item {
    display: grid;
    grid-template-columns: 36px 1fr;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    &-wrap {
      &:first-child {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
          margin-right: 16px;
          margin-bottom: 0;
        }
      }
    }

    &-letter {
      font-weight: $font-weight-medium;
      color: $gray-600;
    }

    &-column {
      display: grid;
      grid-auto-flow: row;
      row-gap: 32px;
    }

    &-row {
      display: flex;
      flex-direction: column;

      a:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &-title {
      margin-bottom: 16px;
      font-size: $fs-xl;
      font-weight: $font-weight-medium;
    }
  }

  &-map {
    width: 100%;
    height: 486px;
  }
}
