.radio-button {
  margin-bottom: 16px;

  &__input {
    display: none;

    &:checked + label::before {
      border: 6px solid $orange;
    }
  }

  &__label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    margin-right: 0;
    line-height: 18px;
    cursor: pointer;
    user-select: none;
    font-feature-settings: "tnum" on, "lnum" on, "ss04" on;

    &::before {
      @include size(20px);
      position: absolute;
      bottom: 0;
      left: 0;
      display: inline-block;
      content: "";
      background: $white;
      border: 1px solid $gray-400;
      border-radius: 9px;
    }
  }
}
