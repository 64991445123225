.count-selector {
  @include size (135px, 40px);
  display: flex;
  justify-content: space-between;

  &__decrement,
  &__increment {
    @include size (33px, 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: $gray-600;
    cursor: pointer;
    user-select: none;
    background-color: $white;
    border: 1px solid $gray-300;

    &.disabled {
      color: $gray-200;
      cursor: default;
    }
  }

  &__value {
    @include size (67px, 100%);
    flex-grow: 1;

    input {
      @include size(100%);

      text-align: center;
      border: 1px solid $gray-300;
      border-right: none;
      border-left: none;
      outline: 0;

      &:focus {
        border: 2px solid $primary;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    @include size(147px, 48px);
  }
}
