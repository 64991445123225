.popup-address-edit {

  &.show {
    // stylelint-disable-next-line declaration-no-important
    display: flex !important;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(lg) {
      // stylelint-disable-next-line declaration-no-important
      display: block !important;
    }
  }


  &__title {
    margin-bottom: 4px;
    font-size: $fs-xxl;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(lg) {
      font-size: 26px;
    }
  }

  &__subtitle {
    margin-bottom: 24px;
    color: $gray-600;
  }

  &__nav {
    margin-bottom: 24px;
  }

  &__map{
    height: calc(100vh - 154px);

    @include media-breakpoint-up(lg) {
      height: auto;
      // stylelint-disable-next-line declaration-no-important
      opacity: 1 !important;
    }
    img {
      @include size(100%);
      object-fit: cover;
    }
  }

}

.popup-phone-edit {
  .modal-dialog {
    @include media-breakpoint-up(lg) {
      width: 540px;
    }
  }
}
