.orders {
  margin-bottom: 60px;

  &__filters {
    display: none;

    &--active {
      display: flex;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        margin-top: 10px;
      }
    }

    &-wrap {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      min-height: 40px;
      margin-bottom: 24px;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      margin-left: 12px;
      color: $orange;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        margin-left: 0;
      }

      &-text {
        margin-left: 8px;
      }
    }
  }

  &__search {
    &-wrap {
      position: relative;
      margin-right: 12px;

      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }

    &-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      font-size: 20px;
      color: #868786;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: $font-weight-medium;

    &-wrap {
      display: flex;
      align-items: flex-end;
      margin-bottom: 32px;
    }
  }

  &__count {
    @include size(fit-content);
    margin-bottom: 5px;
    margin-left: 20px;
    font-size: 14px;
    color: #868786;
  }

  &__list {
    padding: 0;
    list-style: none;
  }

  &__item {
    margin-bottom: 24px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, .05);

    /* stylelint-disable */
    &--canceled {
      .orders__header {
        opacity: 0.6;
      }

      .orders__main {
        opacity: 0.6;
      }
    }

    /* stylelint-enable */

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: $gray-100;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &-info {
      display: flex;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    &-buttons {
      display: flex;
      margin-top: 8px;
    }

    &-btn {
      @include size(fit-content);
      padding: 2px 8px;
      margin-left: 8px;
      font-size: 14px;
      cursor: pointer;
      border: 1px solid $gray-300;

      &:first-child {
        margin-left: 0;
      }

      &--accept {
        color: $green;
        background: #e7f0e6;
        border: 1px solid $green-400;
      }

      &--warning {
        color: $orange;
        background: $yellow-200;
        border: 1px solid $yellow-400;
      }

      &--danger {
        color: $danger;
        background: $red-200;
        border: 1px solid $red-400;
      }
    }

    &-price {
      font-size: 18px;
      font-weight: 500;
    }

    &-weight {
      margin-top: 8px;
      font-size: 14px;
      color: #565655;
      text-align: end;

      @include media-breakpoint-down(md) {
        text-align: start;
      }
    }

    &-content {
      @include media-breakpoint-down(md) {
        margin-top: 16px;
      }
    }
  }

  &__main {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__order {
    &-date {
      margin-left: 16px;
      font-size: 14px;

      @include media-breakpoint-down(md) {
        margin-top: 4px;
        margin-left: 0;
      }
    }

    &-number {
      font-weight: $font-weight-medium;
      color: $orange;
      text-transform: uppercase;
    }

    &-part {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px;
      border-bottom: 1px solid #d9d9d9;

      &-container {
        display: flex;
        align-items: center;
      }
    }

    &-items {
      @include size(fit-content);
      display: flex;
      margin-top: 3px;
      margin-left: 16px;
    }

    &-item {
      @include size(fit-content);
      margin-left: 16px;
      border-bottom: 1px solid $yellow;

      &:first-child {
        margin-left: 0;
      }
    }

    &-stock {
      color: #565655;
      text-align: end;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &-left {
      flex-basis: 60%;

      @include media-breakpoint-down(lg) {
        flex-basis: 40%;
      }
    }

    &-right {
      @include size(fit-content);
      display: flex;
      align-items: center;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        width: 100%;
      }
    }

    &-btn {
      @include size(fit-content);
      margin-left: 16px;

      &:first-child {
        margin-left: 0;

        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }

      &--dark {
        color: #868786;
      }

      &--light {
        color: $orange;
      }

      &--big {
        padding: 11px 24px;
        font-size: 13px;
        font-weight: $font-weight-medium;
        color: $white;
        text-transform: uppercase;
        background: $orange;

        @include media-breakpoint-down(md) {
          width: 100%;
          text-align: center;
        }
      }

      @include media-breakpoint-down(lg) {
        margin-top: 16px;
      }

      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }

    &-info {
      display: flex;
      align-items: flex-start;

      @include media-breakpoint-down(lg) {
        margin-bottom: 16px;
      }

      &-icon {
        @include size(16px);
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 16px;
        color: $white;
        background: $orange;
        border-radius: 8px;

        @include media-breakpoint-down(lg) {
          margin-top: 3px;
        }
      }

      &-text {
        margin-left: 8px;
      }
    }
  }

  &__more {
    @include size(fit-content);
    padding: 11px 24px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    color: $black;
    text-transform: uppercase;
    border: 2px solid $black;

    @include media-breakpoint-down(lg) {
      width: 100%;
      text-align: center;
    }
  }
}
