.nav-menu {
  width: 100%;
}

.nav-menu-item {
  padding: 0 25px;

  i {
    position: absolute;
    font-size: 32px;
  }

  & + & {
    margin-top: 35px;
    line-height: 2;
  }

  &_grey {
    width: fit-content;
    padding-top: 24px;
    padding-right: 62px;
    padding-bottom: 24px;
    background-color: $gray-100;

    a {
      color: $black;
      text-decoration: none;
    }
  }

  &__counter {
    @include size(16px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: $font-weight-medium;
    color: $white;
    background: $orange;
    border-radius: 8px;
  }
}

.nav-menu-header {
  padding-left: 47px;
  font-size: $fs-m;
  font-weight: 500;
  text-transform: uppercase;

  &:hover {
    color: $orange;
    cursor: pointer;

    &:not(.collapsed) {
      cursor: initial;
    }
  }

  &:not(.collapsed) {
    color: $orange;
  }
}

.nav-menu-content {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 47px;

  &.collapsed {
    display: none;
  }
}

.nav-menu-content-item {
  font-size: $fs-m;
  line-height: 2.3;
  color: $gray-900;
  text-decoration: none;

  &:hover {
    color: $orange;
    cursor: pointer;
  }

  &.active {
    font-weight: 500;
    color: $orange;
    cursor: initial;
  }
}

.nav-menu-secondary {
  padding: 0;
  margin: 0;
  list-style: none;

  .nav-menu {
    &__item {
      display: flex;
      align-items: center;
      padding: 16px 16px 16px 20px;
      cursor: pointer;

      &:hover {
        color: $orange;
      }

      &-text {
        margin-right: 8px;
        font-size: $fs-s;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
      }

      &.active {
        padding: 16px;
        color: $orange;
        border-left: 4px solid $orange;
      }
    }
  }
}
