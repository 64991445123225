.title-block {
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
  }

  &__brand-art {
    @include size(81px, 4px);
    display: inline-flex;
    justify-content: space-between;
    min-width: 24px;
    background-color: $secondary;

    &::before {
      @include size(43px, 4px);
      display: block;
      min-width: 43px;
      content: "";
      background-color: $primary;
    }

    &::after {
      @include size(14px, 4px);
      display: block;
      min-width: 14px;
      content: "";
      background-color: $red;
    }
  }
}
