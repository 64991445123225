.operations-history {
  &__filter {
    display: flex;
    flex-direction: column;

    .input-group {
      margin-bottom: 16px;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;

      .input-group {
        max-width: 312px;
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
  }

  &__list {
    .cabinet-section {
      &__item {
        &:first-child {
          padding-top: 16px;
          border-top: 1px solid $border-color;
        }

        &:last-child {
          border-bottom: 1px solid $border-color;
        }
      }
    }
  }
}

