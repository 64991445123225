.checkout-success {
  margin: 0 auto 40px;

  @include media-breakpoint-up(lg) {
    max-width: 594px;
    margin-bottom: 72px;
  }

  @include media-breakpoint-up(xl) {
    max-width: none;
    margin: 0 155px 40px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 870px;
    margin: 0 auto 40px;
  }

  h1 {
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }
  }

  h3 {
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      margin-bottom: 24px;
    }
  }

  &__wrapper {
    position: relative;

    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }

  &__text-wrapper {
    padding: 0 12px;

    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }

  &__text {
    font-size: rem(20px);
  }

  .info {
    padding: 32px 16px 40px;
    margin: 24px 0;
    line-height: 1.4;
    background-color: $gray-100;

    @include media-breakpoint-up(md) {
      padding: 32px 32px 40px;
      margin: 32px 12px;
    }

    @include media-breakpoint-up(lg) {
      margin: 32px 0;
    }

    &__item-wrapper {
      padding-left: 36px;
    }

    &__item-title {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      i {
        font-size: 20px;
      }

      span {
        padding-left: 16px;
        color: $gray-700;
      }
    }

    p + p {
      margin-top: 4px;
    }
  }

  .order {
    margin: 0 12px;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }

    &__title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 16px;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 24px;
      }
    }

    &__header {
      display: none;
      padding: 8px 0;
      color: $gray-600;
      border-top: 1px solid $gray-300;
      border-bottom: 1px solid $gray-300;

      @include media-breakpoint-up(md) {
        display: flex;
      }

      &-left {
        flex-basis: 60%;
      }

      &-right {
        flex-basis: 40%;
      }
    }
  }

  .product {
    margin-bottom: 1.5rem;
    border-top: 1px solid $border-color;

    @include media-breakpoint-up(md) {
      border: 0;
    }

    &-item {
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }

      &:first-child {
        margin-top: 1rem;
      }

      margin-bottom: 1rem;
    }

    &-info {
      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }

      &__title {
        display: flex;
        flex-direction: column;
        margin-bottom: .75rem;

        @include media-breakpoint-up(md) {
          flex-basis: calc(60% - 20px);
          margin-right: 20px;
        }
      }

      &__data {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-basis: 40%;
          flex-direction: row;
          justify-content: space-between;
        }

        &-text {
          margin-bottom: .75rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }
      }

      &__total,
      &__subtitle {
        font-size: $fs-m;
        color: $gray-700;
      }

      &__subtitle {
        padding-top: 4px;
        line-height: 1.6;
      }
    }

    &-service {
      &__title {
        display: flex;
        flex-direction: column;
        margin-bottom: .75rem;

        @include media-breakpoint-up(md) {
          flex-basis: 60%;
          margin-bottom: 0;
        }
      }

      &__info {
        padding-left: .75rem;
        margin-bottom: 1rem;
        border-left: 1px solid $border-color;
      }
    }
  }
}

.success-brand-art {
  position: absolute;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &.yellow {
    background-color: $yellow;

    @include media-breakpoint-up(lg) {
      @include size(99px, 64px);
      top: 368px;
      left: 0;
    }

    @include media-breakpoint-up(xxl) {
      @include size(140px, 64px);
    }
  }

  &.orange {
    background-color: $orange;

    @include media-breakpoint-up(lg) {
      @include size(32px, 68px);
      top: 432px;
      left: 99px;
    }

    @include media-breakpoint-up(xxl) {
      left: 140px;
    }
  }

  &.red {
    background-color: $red;

    @include media-breakpoint-up(lg) {
      @include size(131px, 16px);
      top: 530px;
      right: 0;
    }

    @include media-breakpoint-up(xxl) {
      @include size(172px, 16px);
    }
  }
}
