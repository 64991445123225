.filter {
  margin-bottom: 1rem;

  .dropdown-toggle {
    padding: 9px 10px;
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: 1.5rem;
    .dropdown {
      display: none;

      &-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1rem;
        border: none;

        span {
          margin-right: auto;
          margin-left: .25rem;
        }

        .icn-chevron-down {
          padding: 0;
          transform: rotate(-90deg);
        }
      }

      &-menu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 6;
        width: 100vw;
        height: 100vh;
        background-color: $white;
      }
    }
  }

  > button {
    display: none;
  }

  &-mobile {
    box-sizing: initial;
    margin-bottom: 0;
    @include media-breakpoint-down(lg) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100vh;
      padding: 2.75rem 2rem;
      background-color: $white;
    }

    @include media-breakpoint-down(md) {
      padding: 1.5rem 1rem .85rem;
    }

    .dropdown {
      display: flex;
    }
    .filter-button {
      align-items: center;
      margin-bottom: 1.5rem;
      font-size: 20px;
      font-weight: $font-weight-medium;

      &__icon {

        &:first-child {
          display: none;
        }

        &:last-child {
          display: inline;
        }
      }

      .dropdown {
        display: flex;
      }

      @include media-breakpoint-up(md) {
        font-size: 26px;
      }
    }

    > button {
      position: absolute;
      bottom: 24px;
      left: 50%;
      display: flex;
      width: 85%;
      margin: 0 auto;
      transform: translateX(-50%);

      @include media-breakpoint-up(md) {
        position: static;
        width: 100%;
        margin-top: 2.25rem;
        transform: translateX(0);
      }
    }
  }

  &-button {
    color: $dark;
    &__icon {
      margin-right: .5rem;
      font-size: 20px;

      &:first-child {
        display: inline;
      }
      &:last-child {
        display: none;
      }
    }

  }

  &-clean {
    display: none;
    color: $gray-600;
    i {
      padding-right: .3rem;
    }
  }
}
