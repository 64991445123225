.gallery {
  @include size(100%, auto);

  &__items {
    display: flex;
    max-width: 270px;
    padding: 0;
    margin-top: 15px;
    overflow-x: auto;
    list-style: none;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  &__image-wrap {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__image {
    @include size(100%, 270px);
    max-width: 270px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__item {
    @include size(33px);
    min-width: 33px;
    margin: 0 3px;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      @include size(180px);
      min-width: 180px;
      margin: 0 8px;
    }

    @include media-breakpoint-down(sm) {
      @include size(120px);
      min-width: 120px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--active div {
      border: 2px solid $yellow;

      @include media-breakpoint-down(md) {
        border-width: 0;
      }
    }
  }

  &__item-image {
    @include size(100%);
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid $light-gray;

    @include media-breakpoint-down(md) {
      border-width: 0;
    }
  }

  &-popup {
    @include size(100%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    display: none;
    justify-content: center;

    &--active {
      display: flex;
    }

    &__close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    &__items {
      @include size(fit-content,auto);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 50px auto 0;
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &__item {
      @include size(60px);
      margin: 0 3px;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &-image {
        @include size(100%);
        background-repeat: no-repeat;
        background-size: cover;
        border: 2px solid $light-gray;

        &--active {
          border: 2px solid $yellow;
        }
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__content {
      position: relative;
      z-index: 2;
      padding: 50px;
      margin: auto;
      background-color: $white;
      box-shadow: 0 10px 15px -8px rgba(0, 0, 0, .3), 0 1px 7px 2px rgba(0, 0, 0, .03);

      @include media-breakpoint-down(md) {
        @include size(100%, auto);
        padding: 135px 16px 50px;
        margin: auto 0;
      }
    }

    &__wrapper {
      @include size(100%);
      position: absolute;
      z-index: 1;
      background: $white;
      opacity: .7;
    }

    &__image-wrap {
      @include size(600px);
      margin: auto;

      @include media-breakpoint-down(md) {
        @include size(280px);
      }
    }

    &__image {
      @include size(100%);
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__arrow {
      @include size(10px);
      position: absolute;
      cursor: pointer;
      border-bottom: 2px solid $black;
      border-left: 2px solid $black;

      &--left {
        left: -10px;
        transform: rotate(45deg);

        @include media-breakpoint-down(md) {
          left: -20px;
        }
      }

      &--right {
        right: -10px;
        transform: rotate(-135deg);

        @include media-breakpoint-down(md) {
          right: -20px;
        }
      }
    }

    &__pagination {
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
        font-family: $font-family-base;
        font-size: $fs-l;
        font-weight: 500;
      }
    }
  }
}

