.city-search {
  column-count: 3;

  &__city {
    display: flex;
    margin-bottom: 10px;
    font-size: 14px;

    &-letter {
      width: 12px;
      margin-right: 24px;
      font-weight: $font-weight-medium;
      color: #868786;

      &--hide {
        opacity: 0;
      }
    }
  }

  &__template {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    column-count: 2;
  }

  @include media-breakpoint-down(md) {
    column-count: 1;
  }
}
