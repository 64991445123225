.act {
  &__title {
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  &-filter {
    padding: 16px;
    background-color: $gray-100;

    &__title {
      margin-bottom: 16px;
    }

    &__wrap {
      display: flex;
      flex-direction: column;

      .input-group,
      .select {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
        background-color: $white;
      }

      .input-group > input::placeholder,
      .select > span {
        color: $gray-700;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .input-group,
        .select {
          width: 314px;
          margin-right: 12px;
          margin-bottom: 0;
        }
      }
    }

    &__btn {
      margin-bottom: 16px;

      @include media-breakpoint-up(lg) {
        margin-right: 12px;
        margin-bottom: 0;
      }
    }

    &__clear {
      white-space: nowrap;

      i {
        vertical-align: text-top;
      }
    }
  }

  &-list {
    margin-bottom: 24px;

    tr:first-child td {
      border-top: 1px solid $border-color;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  &__info {
    font-size: $fs-l;
    font-weight: $font-weight-normal;
  }

  /* stylelint-disable */

  & .text-primary {
    color: $orange !important;
  }

  /* stylelint-enable */

  &__icn-close {
    font-size: 20px;
  }
}
