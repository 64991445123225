.custom-select {
  &__filter {
    @include size(100%, auto);
    position: absolute;
    display: none;
    padding: 0;
    list-style: none;
    outline: $white;
    box-shadow: 0 10px 15px -8px rgba(0, 0, 0, .3), 0 1px 7px 2px rgba(0, 0, 0, .03);

    /* stylelint-disable */
    &--active {
      z-index: 200;
      .custom-select__selected-elem {
        border: 1px solid $orange;
      }

      .custom-select__filter {
        display: block;
      }

      .custom-select__arrow {
        top: 15px;
        transform: rotate(135deg);
      }
    }

    /* stylelint-enable */

    &-wrap {
      position: relative;
    }

    &-item {
      padding: 9px;
      text-align: initial;
      background: $white;

      &:hover {
        cursor: pointer;
        background: $yellow-200;
      }
    }

    &:focus {
      border: 1px solid $orange;
    }

    &-title {
      margin-bottom: 8px;
      font-weight: $font-weight-medium;
    }
  }

  &__selected-elem {
    @include size(100%, auto);
    position: relative;
    padding: 10px;
    text-align: initial;
    cursor: pointer;
    border: 1px solid $gray-300;

    &--template {
      display: none;
    }

    &--default {
      color: #868786;
      opacity: .7;
    }
  }

  &__arrow {
    @include size(12px);
    position: absolute;
    top: 12px;
    right: 20px;
    border-bottom: 2px solid $black;
    border-left: 2px solid $black;
    transition-duration: .2s;
    transform: rotate(-45deg);
  }
}
