.order {
  margin-bottom: 64px;

  &__popup-content {
    max-width: 450px;

    @include media-breakpoint-down(lg) {
      max-width: initial;
    }
  }

  &__container {
    display: flex;

    @include media-breakpoint-down(xl) {
      flex-direction: column;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: row;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__left {
    flex-basis: 50%;
    margin-right: 50px;
  }

  &__right {
    @include media-breakpoint-down(xl) {
      margin-top: 24px;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }

    @include media-breakpoint-down(md) {
      margin-top: 24px;
    }
  }

  &__status{
    padding: 2px 8px;
    margin-left: 8px;
    text-align: center;
    background: $gray-100;
    border: 1px solid $gray-300;

    &:first-child {
      margin-left: 0;
    }

    &-block {
      display: flex;
      margin-bottom: 32px;
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 18px;
    font-weight: $font-weight-medium;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: $font-weight-medium;

    @include media-breakpoint-down(xl) {
      font-size: 36px;
    }

    @include media-breakpoint-down(lg) {
      font-size: 30px;
    }

    @include media-breakpoint-down(md) {
      font-size: 26px;
    }

    &-date {
      margin-bottom: 5px;
      margin-left: 10px;
      font-size: 26px;

      @include media-breakpoint-down(xl) {
        font-size: 23px;
      }

      @include media-breakpoint-down(lg) {
        font-size: 22px;
      }

      @include media-breakpoint-down(md) {
        margin-left: 0;
        font-size: 16px;
      }
    }
  }

  &__info {
    display: flex;
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }

    &-text {
      margin-top: 4px;

      &--email {
        font-feature-settings: "tnum" on;
      }
    }

    &-title {
      margin-top: 4px;
      font-weight: $font-weight-medium;
    }

    &-icon {
      margin-top: -3px;
      margin-right: 18px;
    }

    &-header {
      display: flex;

      &-item {
        margin-left: 16px;

        &:first-child {
          margin-left: 0;
        }

        &--gray {
          color: #868786;
        }

        &--orange {
          display: flex;
          align-items: center;
          color: $orange;
          cursor: pointer;
        }

        &--margin {
          margin-top: 4px;
        }

        &-img {
          margin-right: 8px;
        }
      }
    }
  }

  &__documents {
    display: flex;
    flex-direction: column;

    &-wrap {
      margin-top: 16px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    margin-top: 16px;
    color: #868786;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }

    &-icon {
      margin-right: 10px;
    }

    &-text {
      height: 100%;
    }

    &-close {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 8px;

      &-top {
        position: absolute;
        width: 12px;
        border-top: 2px solid $gray-600;
        transform: rotate(45deg);
      }

      &-left {
        position: absolute;
        width: 12px;
        border-top: 2px solid $gray-600;
        transform: rotate(-45deg);
      }
    }

    &--big {
      @include size(fit-content, 40px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 11px 24px;
      margin-bottom: 24px;
      color: $white;
      text-transform: uppercase;
      background: $orange;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    &-img {
      margin-right: 16px;
    }
  }
}

.order-composition {
  margin-top: 40px;

  &__main {
    @include media-breakpoint-down(md) {
      margin-bottom: 32px;
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-item {
      line-height: 140%;
      color: #868786;

      &--center {
        margin-left: 100px;
      }
    }
  }

  &__title {
    height: fit-content;
    font-size: 26px;
    font-weight: $font-weight-medium;
  }

  &__header-btn {
    padding: 11px 24px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    color: $black;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    border: 2px solid $black;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-top: 18px;
    }
  }

  &__row {
    display: grid;
    padding: 16px 0;
    grid-template-columns: 1fr 1fr 1fr;
    border-top: 1px solid $gray-300;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }

    &--small {
      padding: 8px 0;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &--without {
      padding-top: 0;
      border-width: 0;

      @include media-breakpoint-down(md) {
        padding-bottom: 0;
        margin-bottom: 16px;
        border-left: 1px solid $gray-300;
      }
    }

    &-price {
      font-size: 20px;
      font-weight: $font-weight-medium;
      text-align: end;

      @include media-breakpoint-down(md) {
        text-align: start;
      }

      &--mod {
        @include media-breakpoint-down(md) {
          padding-left: 24px;
        }
      }
    }

    &-size {
      margin-left: 100px;
      font-weight: $font-weight-medium;
      line-height: 140%;

      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
        margin-left: 0;
      }

      &--spacer {
        margin-bottom: 0;
      }
    }

    &-name {
      width: fit-content;
      border-bottom: 1px solid $yellow;

      &--border {
        border-width: 0;
      }

      &--bold {
        font-size: 20px;
        font-weight: $font-weight-medium;
      }
    }

    &-item {
      &--border {
        padding-left: 24px;
        border-left: 1px solid $gray-300;

        @include media-breakpoint-down(md) {
          border-left: 0 solid $gray-300;
        }
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
      }
    }

    &-info {
      margin-top: 4px;
      font-size: 14px;
      color: #565655;
    }
  }

  &__icon {
    font-size: 32px;
    color: $black;
    border-width: 0;
  }

  &__documents {
    display: flex;
    flex-direction: column;

    &-wrap {
      margin-top: 16px;
    }
  }

  &__document {
    display: flex;
    text-decoration: none;

    &-info {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      margin-left: 20px;
    }
  }
}


.payment-methods {
  padding-right: 22px;
  margin-top: -15px;

  &__title {
    margin-bottom: 32px;
    font-size: 20px;
    font-weight: $font-weight-medium;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 24px;
    cursor: pointer;
    border: 1px solid $gray-300;

    &--active {
      border: 1px solid $orange;
    }
  }

  &__icon {
    margin-right: 8px;
  }

  &__text{
    &-info {
      font-size: 14px;
      color: #565655;
    }
  }

  &__btn {
    padding: 11px 24px;
    margin-right: 8px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      @include size(50%, auto);
    }

    @include media-breakpoint-down(md) {
      @include size(100%, auto);
    }

    &:last-child {
      margin-right: 0;
    }


    &--dark {
      border: 2px solid $black;

      @include media-breakpoint-down(md) {
        margin-top: 24px;
      }
    }

    &--orange {
      color: $white;
      background: $orange;
      border: 2px solid $orange;
    }

    &-wrap{
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }
    }
  }
}

.load-proxy {
  &__title {
    margin-bottom: 24px;
    font-size: 26px;
    font-weight: $font-weight-medium;
  }

  &__drop-area {
    margin-bottom: 8px;
  }

  &__info {
    color: #565655;
  }

  &__btn {
    padding: 11px 24px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    background: $orange;
    border: 2px solid $orange;

    @include media-breakpoint-down(lg) {
      @include size(100%, auto);
    }

    &-wrap {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }
    }
  }

}

.repeat-order {
  min-width: 450px;

  @include media-breakpoint-down(md) {
    min-width: initial;
  }

  &__title {
    font-size: 26px;
    font-weight: $font-weight-medium;

    &-wrap {
      display: flex;
      margin-bottom: 8px;
    }

    &-icon {
      @include size(18px, 18px);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      color: $white;
      background: $orange;
      border-radius: 9px;
    }
  }

  &__btn {
    padding: 11px 24px;
    margin-top: 24px;
    margin-right: 8px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      @include size(50%, auto);
    }

    @include media-breakpoint-down(md) {
      @include size(100%, auto);
    }

    &:last-child {
      margin-right: 0;
    }

    &--dark {
      border: 2px solid $black;
    }

    &--orange {
      color: $white;
      background: $orange;
      border: 2px solid $orange;
    }

    &-wrap{
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }
    }
  }

  &__info {
    margin-left: 42px;
  }
}

.cancel-order {
  min-width: 450px;

  @include media-breakpoint-down(md) {
    min-width: initial;
  }

  &__header {
    display: flex;
    margin-bottom: 24px;
  }

  &__icon {
    @include size(10px, 10px);
    margin-top: 1px;
    margin-left: 1px;
    background-color: $white;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);

    &-wrap {
      @include size(18px, 18px);
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 18px;
      margin-right: 16px;
      background-color: $red;
      border-radius: 9px;
    }
  }

  &__btn {
    padding: 11px 24px;
    margin-top: 24px;
    margin-right: 8px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      @include size(50%, auto);
    }

    @include media-breakpoint-down(md) {
      @include size(100%, auto);
    }

    &:last-child {
      margin-right: 0;
    }

    &--dark {
      border: 2px solid $black;
    }

    &--red {
      color: $white;
      background: $red;
      border: 2px solid $red;
    }

    &-wrap{
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }
    }
  }

  &__title {
    margin-bottom: 8px;
    font-size: 26px;
    font-weight: $font-weight-medium;
  }

  &__select {
    margin-bottom: 24px;
  }
}
