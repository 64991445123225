.footer {
  display: flex;
  width: 100%;
  padding: 30px 0 20px;
  background-color: $gray-100;
}

.footer-contacts {
  &__phone,
  &__email {
    display: flex;
    flex-direction: column;
    font-family: $font-family-base;

    a:hover {
      color: $orange;
    }
  }

  &__phone {
    margin-top: 24px;
  }

  &__email {
    margin-top: 16px;
  }

  &__label {
    font-size: 14px;
    color: $gray-700;
  }

  &__value {
    margin-top: 3px;
    font-size: 20px;
    font-weight: 500;
    color: $black;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $black;
    }
  }
}

.footer-menu {
  ul {
    padding: 0;
    margin-top: 32px;
    list-style: none;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  li {
    display: block;
    margin-bottom: 10px;

    a {
      font-size: 16px;
      color: $black;
      text-decoration: none;

      &:hover {
        color: $orange;
      }

      &:focus {
        border: 1px solid $orange-200;
      }
    }
  }
}

.footer-logos {
  height: 90px;
  margin-bottom: 20px;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.footer-brand-art {
  display: flex;
  width: 100%;
  margin: 40px 0;

  &__item:nth-child(1) {
    @include size(16px);
    position: relative;
    top: 4px;
    min-width: 16px;
    background-color: $yellow;
  }

  &__item:nth-child(2) {
    width: 100%;
    height: 4px;
    background-color: $orange;
  }

  &__item:nth-child(3) {
    @include size(88px, 8px);
    position: relative;
    bottom: 8px;
    min-width: 88px;
    background-color: $red;
  }
}

.footer-bottom {
  ul {
    padding: 0;
    list-style: none;
  }

  li {
    margin-top: 10px;

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        color: $orange;
      }

      &:focus {
        border: 1px solid $orange-200;
      }
    }
  }

  a,
  span {
    font-size: 14px;
  }

  @include media-breakpoint-up(md) {
    ul {
      display: flex;
      justify-content: flex-end;
    }

    ul li {
      margin-top: 0;

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
}

.footer-x-light {
  font-size: $fs-m;
  background-color: $gray-100;

  &__wrap {
    padding-top: 32px;
    padding-bottom: 32px;
    border-top: 1px solid $gray-300;
  }
}

.footer-light {
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  line-height: 1.5em;
  background-color: $gray-100;

  &__title {
    font-size: 24px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: 32px;
      line-height: 1.2em;
    }
  }

  &__figure {
    position: relative;

    img {
      position: absolute;
      top: -61px;
      left: 0;
      z-index: 1;
      width: 165px;

      @include media-breakpoint-up(lg) {
        top: -82px;
        width: 220px;
      }
    }
  }

  &__input {
    margin-top: 10px;
    margin-top: 28px;

    @include media-breakpoint-up(md) {
      min-width: 258px;
      margin-top: 0;
    }
  }

  &__button {
    width: 100%;
    margin-top: 20px;
    color: $white;

    &:hover {
      color: $white;
    }

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  &__form {
    @include media-breakpoint-up(md) {
      box-sizing: content-box;
      display: flex;
      height: 40px;

      margin-top: 40px;
    }
  }

  &__form-wrapper {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    height: 220px;

    .container,
    .row {
      height: 100%;
    }
  }
}

.footer-brand-art-2 {
  display: flex;
  margin-top: 80px;

  &__item:nth-child(1) {
    @include size(100%, 47px);
    position: relative;
    top: 4px;
    width: 100%;
    height: 42px;
    background-color: $yellow;

    @include media-breakpoint-up(md) {
      @include size(100%, 57px);
    }
  }

  &__item:nth-child(2) {
    @include size(30px, 28px);
    position: relative;
    top: -24px;
    min-width: 30px;
    background-color: $orange;

    @include media-breakpoint-up(md) {
      @include size(40px);
      top: -35px;
      min-width: 40px;
    }
  }

  &__item:nth-child(3) {
    @include size(43px, 13x);
    position: relative;
    top: -36px;
    min-width: 43px;
    background-color: $red;

    @include media-breakpoint-up(md) {
      @include size(59px, 18px);
      top: -53px;
      min-width: 59px;
    }
  }
}
