.service-page {
  .nav-menu{

    &-item:not(:first-child) {
      margin-top: 32px;
    }

    &-header {
      max-width: 200px;
      padding-left: 0;
    }

    &-content {
      padding-top: 5px;
      padding-left: 0;
    }
  }

  .title-block {
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      margin-bottom: 32px;
    }
  }

  .service-main-row {
    justify-content: space-between;
    margin-bottom: 12px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 56px;
    }
  }

  .gallery {
    @include size(100%);
    margin-top: 16px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    img {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 200px;
      }
    }
  }

  .cart {
    margin-top: 24px;

    @include media-breakpoint-up(lg) {
      max-width: 535px;
      margin-top: 0;
      margin-left: 24px;
    }

    &__actual-price {
      font-size: 26px;
      font-weight: $font-weight-medium;

      span {
        font-size: 20px;
      }
    }

    &__old-price {
      padding-left: 16px;
      font-size: $fs-m;
      color: $orange;
      text-decoration-line: line-through;
    }

    &__button {
      margin-top: 16px;

      .btn {
        width: 100%;

        @include media-breakpoint-up(md) {
          @include size(100%, 56px);
        }

        @include media-breakpoint-up(lg) {
          @include size(176px, 56px);
        }
      }
    }

    &__note {
      max-width: 230px;
      margin-top: 16px;
      font-size: $fs-m;
      line-height: 20px;
      color: $gray-700;

      @include media-breakpoint-up(md) {
        max-width: unset;
      }

      @include media-breakpoint-up(lg) {
        font-size: $fs-l;
      }
    }

    &__warning {
      margin-top: 16px;
      background-color: $yellow-200;
      border: 1px solid $yellow-400;

      @include media-breakpoint-up(lg) {
        max-width: 535px;
      }

      &-inner {
        display: flex;
        padding: 10px;

        @include media-breakpoint-up(lg) {
          padding-left: 16px;
        }

        i {
          font-size: 20px;
        }

        p {
          padding-top: 3px;
          margin-left: 8px;
          font-size: $fs-m;
          line-height: 1.2;

          @include media-breakpoint-up(lg) {
            margin-left: 12px;
            font-size: $fs-l;
          }
        }
      }
    }

  }

  .popup-service {
    @include media-breakpoint-up(lg) {
      .modal-dialog {
        width: 568px;
      }

      .modal-content {
        padding: 36px 34px;
      }

      .modal-close {
        top: 16px;
        right: 16px;
      }
    }

    &__description {
      width: calc(100% - 4px);
      margin-left: 2px;

      &:focus {
        box-shadow: 0 0 0 2px $orange-200;
      }
    }

    &__file {
      margin-left: 2px;
    }

    h3 {
      padding-left: 2px;
      margin-bottom: 16px;
    }

    label {
      padding-left: 2px;
    }

    input,
    textarea {
      width: calc(100% - 4px);
      margin-left: 2px;

      &:focus {
        box-shadow: 0 0 0 2px $orange-200;
      }
    }

    .modal-footer {
      padding: 0 16px 8px;
      margin: 0;

      @include media-breakpoint-up(md) {
        padding: 0;
      }

      @include media-breakpoint-up(lg) {
        position: relative;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-top: 24px;
      }

      .buttons {
        width: 100%;

        @include media-breakpoint-up(lg) {
          justify-content: flex-end;
        }
      }

      button {
        margin: 0 0 96px;

        @include media-breakpoint-up(md) {
          margin-top: 24px;
        }

        @include media-breakpoint-up(lg) {
          @include size(auto, 40px);
          margin: 0 0 0 16px;
        }
      }
    }
  }

  .service-tabs {
    .service-main-tabs {
      margin-top: 32px;

      @include media-breakpoint-up(lg) {
        margin-top: 44px;

        .nav-link {
          font-size: $font-size-base;
        }
      }
    }

    .tab-pane {
      margin-top: 40px;

      @include media-breakpoint-up(lg) {
        margin-top: 44px;
      }

      p {
        padding-top: 8px;
        padding-right: 22px;

        @include media-breakpoint-up(lg) {
          padding-right: 0;
          line-height: 1.4;
        }
      }
    }

    .service {
      &__props {
        margin-top: 16px;

        &:first-of-type {
          p {
            padding-top: 0;
          }
        }

        &-title {
          font-weight: $font-weight-medium;
          line-height: 1.4;
        }

        &-list {
          position: relative;
          display: flex;
          flex-direction: column;
        }
      }

      &__prop {
        display: flex;
        justify-content: space-between;
        padding-top: 12px;
        font-size: $font-size-sm;
        text-align: left;

        span:first-child {
          max-width: 132px;

          @include media-breakpoint-up(md) {
            max-width: unset;
          }
        }
      }

      &__prop-separator {
        position: relative;
        bottom: -12px;
        flex-grow: 1;
        height: 2px;
        margin: 0 4px;
        border-bottom: 1px dotted $gray-400;
      }
    }

    .warehouses-tabs {
      margin-top: 16px;

      .nav-item:first-child {
        margin-right: 20px;
      }

      .nav-link {
        align-items: center;
        justify-content: space-around;
        height: 40px;
        padding: 0 20px;
        font-size: 13px;
        background-color: transparent;
        border: 1px solid $gray-300;

        &:focus {
          box-shadow: none;
        }
      }

      .active {
        border: 2px solid $orange;
      }
    }

    .warehouses-mobile {

      td {
        padding: 24px 0;
      }

      tr:first-child {
        // stylelint-disable-next-line
        td {
          padding-top: 0;
        }
      }

      tr:last-child {
        // stylelint-disable-next-line
        td {
          border-bottom: none;
        }
      }

      .warehouse {
        display: flex;
        flex-direction: column;
        padding-bottom: 24px;
        border-bottom: 1px solid $gray-300;

        &:first-child {
          // stylelint-disable-next-line
          .warehouse__name {
            margin-top: 40px;
          }
        }

        // stylelint-disable-next-line
        span {
          font-size: $fs-l;
        }

        &__name {
          margin-top: 24px;
        }

        &__name,
        &__phone {
          font-weight: $font-weight-medium;
        }

        &__phone {
          margin-bottom: 6px;
        }

        &__label {
          margin-top: 20px;
          margin-bottom: 10px;
          color: $gray-600;
        }
      }
    }

    .warehouses-table {
      margin-top: 24px;

      td {
        width: 25%;
      }

      &__phone {
        display: inline-block;
        margin-bottom: 4px;
        font-weight: $font-weight-medium;
      }
    }
  }
}
