.base-wrapper {

  margin: 65px 0;

  h2 {
    font-family: $font-family-base;
  }

  p {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    font-size: 16px;
  }

  .content {
    line-height: 1.375;
  }

}
