.ts-type-radio {
  @include media-breakpoint-up(xxl) {
    max-width: 640px;
  }

  &__cheapest-msg {
    display: flex;
    justify-content: flex-end;

    span {
      padding: 2px 8px;
      font-size: $fs-s;
      font-weight: 500;
      background-color: $yellow;
    }
  }

  &__item-wrapper {
    position: relative;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    width: 100%;
    padding: 16px 16px 16px 52px;
    box-shadow: inset 0 0 0 1px $gray-300;

    &-title,
    &-price {
      margin-bottom: 4px;
      font-size: $fs-l;
      font-weight: 500;
    }

    &-price {
      text-align: right;
      white-space: nowrap;
    }

    &-warning {
      display: flex;
      margin-top: 16px;

      i {
        font-size: 20px;
      }

      span {
        padding-left: 6px;
        font-size: $fs-m;
      }
    }

    &-main {
      font-size: $fs-m;

      @include media-breakpoint-up(md) {
        flex-basis: 60%;
      }

      @include media-breakpoint-between(lg, xxl) {
        flex-basis: 50%;
      }

      @include media-breakpoint-up(xxl) {
        flex-basis: 60%;
      }
    }

    &-additional {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 16px;
      font-size: $fs-m;

      @include media-breakpoint-up(md) {
        flex-basis: 40%;
        margin-top: 0;
      }

      @include media-breakpoint-between(lg, xxl) {
        flex-basis: 50%;
      }

      @include media-breakpoint-up(xxl) {
        flex-basis: 40%;
      }

      i {
        margin-right: 8px;
        font-size: 30px;

        @include media-breakpoint-up(md) {
          margin: 0;
        }
      }
    }
  }

  .form-check-input {
    position: absolute;
    top: 16px;
    left: 16px;

    &:checked {
      // stylelint-disable selector-class-pattern
      ~ .ts-type-radio__item {
        box-shadow: inset 0 0 0 2px $orange;
      }
    }

    &[disabled] {
      background-color: $white;
      border-color: $gray-400;
      opacity: 1;

      ~ .ts-type-radio__item {
        color: $gray-600;
        user-select: none;
        background-color: $gray-100;
        box-shadow: inset 0 0 0 1px $gray-300;
      }
    }
  }
}
