.subscription {
  display: flex;
  padding: 2rem 0;
  background-color: $gray-100;
  border-bottom: 1px solid $gray-300;

  &__title {
    display: flex;

    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-up(xl) {
      display: flex;
      flex-basis: 33.3333%;
    }
  }

  &__title-text {
    display: flex;
    flex-direction: column;
  }

  &__form {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-basis: 50%;
    }

    @include media-breakpoint-up(xl) {
      flex-basis: 33.3333%;
      flex-direction: row;
      align-items: center;
    }
  }

  &__form-title {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &__form-control {
    width: 100%;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-right: -2px;
      margin-bottom: 0;
    }

    .form-control-invalid {
      margin-top: 5px;
    }
  }

  .form {
    margin-top: 16px;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    @include media-breakpoint-up(xl) {
      width: 100%;
      margin-top: 0;
    }
  }

  button {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
      max-width: 144px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 134px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 150px;
    }
  }

  &__brand {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
      flex-basis: 50%;
      justify-content: flex-start;
    }

    @include media-breakpoint-up(xl) {
      flex: 1 0 auto;
      justify-content: center;
    }

    @include media-breakpoint-up(xxl) {
      justify-content: flex-start;
    }
  }

  &-brand-art {
    position: relative;
    width: 287px;

    &__main {
      width: 205px;

      @include media-breakpoint-up(xxl) {
        width: 290px;
      }

      img {
        @include size(100px, 107px);
        position: relative;
        z-index: 2;
        margin: 0 0 -8px 48px;

        @include media-breakpoint-up(xxl) {
          @include size(145px, 140px);
          margin-bottom: -4px;
          margin-left: 68px;
        }
      }

      div:last-child {
        @include size(205px, 42px);
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $primary;

        @include media-breakpoint-up(xxl) {
          @include size(290px, 57px);
        }
      }
    }

    &__item {
      &:nth-child(2) {
        @include size(30px);
        position: absolute;
        bottom: 42px;
        left: 205px;
        background-color: $secondary;

        @include media-breakpoint-up(xxl) {
          @include size(48px);
          bottom: 57px;
          left: 290px;
        }
      }

      &:last-child {
        @include size(43px, 13px);
        position: absolute;
        bottom: 72px;
        left: 235px;
        background-color: $danger;

        @include media-breakpoint-up(xxl) {
          @include size(60px, 18px);
          bottom: 105px;
          left: 338px;
        }
      }
    }
  }
}
