.popup {
  @include size(100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: none;
  justify-content: center;
  font-weight: initial;
  text-transform: initial;
  cursor: initial;

  &--active {
    display: flex;
  }

  &__content {
    position: relative;
    z-index: 2;
    padding: 35px 38px;
    margin: auto;
    overflow-y: auto;
    color: $black;
    background-color: $white;
    box-shadow: 0 10px 15px -8px rgba(0, 0, 0, .3), 0 1px 7px 2px rgba(0, 0, 0, .03);

    @include media-breakpoint-down(lg) {
      @include size(100%);
      padding: 35px 32px;
      margin: auto 0;
    }
  }

  &__wrapper {
    @include size(100%);
    position: absolute;
    z-index: 1;
    background: $white;
    opacity: .7;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}
