.lk-template {
  display: flex;

  &__left {
    flex-basis: 25%;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__right {
    flex-basis: 75%;
    width: 100%;
    margin-left: 24px;

    @include media-breakpoint-down(lg) {
      flex-basis: 100%;
      margin-left: 0;
    }

    .nav-item {
      &:first-child {
        .nav-link {
          padding-left: 0;
        }
      }

      &:last-child {
        .nav-link {
          padding-right: 0;
        }
      }
    }
  }

  &__list {
    list-style: none;
  }

  &__title {
    @include size(auto,48px);
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    line-height: 140%;
    text-transform: uppercase;
    border-left: 4px solid $white;

    &--active {
      color: $orange;
      border-left: 4px solid $orange;
    }

    &:hover {
      color: $orange;
    }
  }

  &__item {
    cursor: pointer;

    &-wrap {
      display: flex;
      align-items: center;
    }
  }

  &__info {
    @include size(16px,16px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    font-size: 12px;
    font-weight: $font-weight-medium;
    color: $white;
    background: $orange;
    border-radius: 8px;
  }

  th,
  td {
    width: 25%;
    @include media-breakpoint-down(md) {
      width: 50%;
    }
  }
}
