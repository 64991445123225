.list-picker {
  display: flex;

  &-elem {
    margin-bottom: 16px;
    cursor: pointer;

    &--active {
      color: $orange;
    }
  }
}
