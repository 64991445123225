
.service-block {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  &--small-card {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);

    &--small-card {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;
    }
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);

    &--small-card {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: repeat(4, 1fr);

    &--small-card {
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 24px;
    }
  }
}

.service-card {
  padding-bottom: 32px;
  margin-top: 16px;

  &__img {
    width: 100%;
    height: 120px;
    margin-bottom: 24px;
    overflow: hidden;

    img {
      min-width: 100%;
      min-height: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 24px 32px;
    margin: 0 -1px -1px 0;
    border: 1px solid $border-color;

    &--small {
      padding: 0;
      border: none;
    }
  }

  h3 {
    margin: 0;
    font-size: 20px;
  }

  .prices-list {
    ul {
      padding-left: 0;
      margin: 0;

      li:last-child {
        padding-bottom: 0;
      }
    }

    margin-top: 16px;
  }

  .prices-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;

    @include media-breakpoint-up(md) {
      align-items: initial;
    }

    a {
      margin-right: 5px;
    }

    &__price {
      margin-left: 5px;
      font-weight: $font-weight-medium;
      white-space: nowrap;

      i {
        position: relative;
        top: 2px;
        font-size: 20px;
      }
    }
  }

  .price-separator {
    position: relative;
    flex-grow: 1;
    height: 16px;
    border-bottom: 1px dotted $gray-400;
  }
}
