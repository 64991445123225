.vacancy {
  margin-top: 32px;
  margin-bottom: 80px;

  &__content {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &__left {
      flex-basis: 80%;
    }

    &__right {
      flex-basis: 20%;
    }
  }

  &__profile {
    @include size(auto, fit-content);
    padding: 24px;
    background: $gray-100;

    &-img {
      @include size(96px, 96px);
      background-position: center;
      background-size: contain;
      border-radius: 48px;
    }

    &-title {
      margin-bottom: 25px;
      font-weight: $font-weight-medium;
    }
  }

  &__info {
    &-wrap {
      margin-top: 24px;
    }

    &-description {
      margin-top: 8px;
    }

    &-name {
      line-height: 140%;
      color: $gray-600;
    }
  }

  &__back {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;

    &-arrow {
      @include size(8px, 8px);
      position: absolute;
      border-top: 2px solid $gray-900;
      border-left: 2px solid $gray-900;
      transform: rotate(-45deg);

      &-horizontal {
        @include size(15px, auto);
        position: absolute;
        border-top: 2px solid $gray-900;
      }

      &-wrap {
        display: flex;
        align-items: center;
      }
    }

    &-link {
      margin-left: 25px;
      color: $gray-700;
      text-decoration: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    &-separator {
      @include size(auto, 25px);
      margin: 0 10px;
      color: $gray-600;
    }
  }

  &__organization {
    color: $gray-600;
  }

  &__city {
    color: $gray-600;
  }

  &__title {
    margin: 8px 0;
    font-size: 32px;
    font-weight: $font-weight-medium;

    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }

  &__subtitle {
    font-weight: $font-weight-medium;
  }

  &__btn {
    @include size(fit-content);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 24px;
    margin: 32px 0;
    font-weight: $font-weight-medium;
    color: $white;
    text-transform: uppercase;
    cursor: pointer;
    background: $orange;

    &--mod {
      align-self: flex-end;
      margin: 0;
      font-size: 13px;

      @include media-breakpoint-down(lg) {
        @include size(100%, auto);
        margin-top: 15px;
      }
    }
  }

  &__list {
    &-wrap {
      margin-top: 32px;
    }

    &-name {
      margin-bottom: 16px;
      font-size: 26px;
      font-weight: $font-weight-medium;

      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }
  }

  &__item {
    line-height: 140%;
  }

  &__popup {
    @include size(100%, 100%);
    position: fixed;
    top: 0;
    z-index: 110;
    display: none;
    align-items: center;
    justify-content: center;

    &--active {
      display: flex;
    }

    &-background {
      @include size(100%, 100%);
      background: rgba(28, 28, 28, .8);
    }

    &-content {
      position: absolute;
      padding: 36px 32px;
      background: $white;

      @include media-breakpoint-down(lg) {
        @include size(100%, 100%);
      }
    }

    &-input {
      @include size(100%, 40px);
      padding: 5px 12px;
      border: 1px solid $gray-300;
      outline: $white;

      &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
      }

      &-title {
        margin-bottom: 8px;
        font-size: $fs-l;
        font-weight: $font-weight-medium;
        color: $gray-900;
      }

      &-wrap {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 24px;

        &--right {
          margin-right: 10px;
        }

        &--left {
          margin-left: 10px;

          @include media-breakpoint-down(lg) {
            margin-left: 0;
          }
        }
      }

      &-container {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(lg) {
          flex-direction: column;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }

    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    &-title {
      margin-bottom: 24px;
      font-size: 26px;
      font-weight: $font-weight-medium;
    }
  }

  &__uploaded {
    &-template {
      display: none;
    }

    &-info {
      font-size: $fs-m;
      color: $gray-600;
      text-transform: uppercase;
    }

    &-container {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 20px;
    }
  }
}
