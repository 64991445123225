//
// Base styles
//

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 40px;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  color: $gray-900;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  text-transform: uppercase;
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
  @include transition($btn-transition);

  &:hover {
    color: $body-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  }
  .btn-check:focus + &,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }
}

//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $btn-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $btn-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
    border-width: 2px;
  }
}

.btn-black-orange {
  color: #000;
  border-color: #000;
  border-width: 2px;

  &:hover {
    color: #fff;
    background: #ed7817;
    border-color: #ed7817;
  }
}
// scss-docs-end btn-variant-loops


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $btn-link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $btn-link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}

.filter-btn {
  @include size(275px, 56px);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px $gray-300;

  &.active {
    box-shadow: inset 0 0 0 2px $orange;
  }

  .badge {
    margin-left: 4px;
    font-weight: $font-weight-medium;
  }

  & + & {
    margin-left: 12px;
  }
}
