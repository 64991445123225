//
// Check/radio
//

.form-check {
  display: block;
  width: max-content;
  min-height: $form-check-min-height;
  padding-left: $form-check-padding-start;
  margin-bottom: $form-check-margin-bottom;

  &:focus {
    box-shadow: 0 0 0 2px $orange-200;
  }

  .form-check-input {
    float: left;
    padding-left: .25em;
    margin-left: $form-check-padding-start * -1;
  }

  .form-check-warning {
    display: none;
    margin: 8px 0 0 8px;

    i {
      padding-right: 6px;
      font-size: 20px;
    }

    span {
      font-size: $fs-m;
    }
  }
}

.form-check-input {
  width: $form-check-input-width;
  height: $form-check-input-width;
  vertical-align: top;
  background-color: $form-check-input-bg;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: $form-check-input-border;
  appearance: none;
  color-adjust: exact; // Keep themed appearance for print
  @include transition($form-check-transition);

  &[type="checkbox"] {
    @include border-radius($form-check-input-border-radius);
    &:hover {
      border-color: $gray-600;
    }
  }

  &[type="radio"] {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: $form-check-radio-border-radius;
    &:hover {
      border-color: $orange-hover;
    }

    &:checked {
      ~ .form-check-warning {
        display: flex;
      }
    }
  }

  &:active {
    filter: $form-check-input-active-filter;
  }

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;

    &:hover {
      background-color: $orange-hover;
    }

    &[type="checkbox"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-input-checked-bg-image), var(--#{$variable-prefix}gradient);
      } @else {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }
    }

    &[type="radio"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-radio-checked-bg-image), var(--#{$variable-prefix}gradient);
      } @else {
        background-image: escape-svg($form-check-radio-checked-bg-image);
      }
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    border-color: $form-check-input-indeterminate-border-color;

    @if $enable-gradients {
      background-image: escape-svg($form-check-input-indeterminate-bg-image), var(--#{$variable-prefix}gradient);
    } @else {
      background-image: escape-svg($form-check-input-indeterminate-bg-image);
    }
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: $form-check-input-disabled-opacity;
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      opacity: $form-check-label-disabled-opacity;
    }
  }
}

.form-check-label {
  padding-left: .5em;
  color: $form-check-label-color;
  cursor: $form-check-label-cursor;
}

//
// Switch
//

.form-switch {
  box-sizing: content-box;
  height: 24px;
  padding-left: 0;
  margin: 0;
  margin-bottom: 10px;
  cursor: pointer;

  .form-check-label {
    padding-left: 8px;
  }

  .form-check-input {
    position: relative;
    box-sizing: content-box;
    width: $form-switch-width;
    height: $form-switch-height;
    margin: 0;
    margin-right: 0;
    cursor: pointer;
    background-color: $form-switch-bg-color;
    border: none;
    @include border-radius($form-switch-border-radius);
    @include transition($form-switch-transition);


    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 20px;
      height: 20px;
      margin-top: 2px;
      margin-left: 2px;
      content: "";
      background-color: $form-switch-color;
      border-radius: 10px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, .2), 0 1px 5px rgba(0, 0, 0, .2);
      transition: $form-switch-transition;
    }

    &:hover {
      background-color: $form-switch-hover-bg-color;
    }

    &:focus {
      box-shadow: none;


      &::before {
        position: absolute;
        top: -2px;
        left: -2px;
        display: block;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        padding: 4px;
        content: "";
        border: 2px solid $orange-200;
      }
    }

    &:checked {
      box-sizing: content-box;
      background-color: $form-switch-checked-bg-color;
      background-image: none;

      &::after {
        left: 30px;
        margin-right: 2px;
        margin-left: 0;
      }
    }

    &:disabled {
      background-color: $form-switch-disabled-bg-color;
      border-color: $form-switch-disabled-border-color;
      border-style: solid;
      border-width: 1px;
      opacity: 1;

      &::after {
        background-color: $form-switch-disabled-color;
      }
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: $form-check-inline-margin-end;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &[disabled],
  &:disabled {
    + .btn {
      pointer-events: none;
      filter: none;
      opacity: $form-check-btn-check-disabled-opacity;
    }
  }
}
