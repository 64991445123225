.cart-popup {
  padding: 40px;

  .popup-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: $font-weight-medium;
  }

  .popup-wrapper {
    margin-bottom: 30px;
  }
}

.popup-cart {
  @include media-breakpoint-up(lg) {
    .modal-dialog {
      width: 864px;
    }
  }

  .modal-content {
    padding: 24px 16px 16px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .modal-title {
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      padding: 40px 24px 24px 40px;
      margin: 0;
      border-bottom: 1px solid $border-color;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-grow: 1;
    }
  }

  &__params {
    overflow-y: auto;

    @include media-breakpoint-up(md) {
      width: calc(100% - 301px);
      border-right: 1px solid $border-color;
    }

    @include media-breakpoint-up(lg) {
      width: calc(100% - 279px);
    }
  }

  &__info {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px 16px 16px;
    background-color: $white;

    @include media-breakpoint-up(md) {
      position: relative;
      width: 301px;
      padding: 26px 55px 40px 39px;
    }

    @include media-breakpoint-up(lg) {
      width: 279px;
      padding: 26px 39px 40px 33px;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      margin-bottom: 16px;
    }
  }

  &__main-price {
    font-size: 26px;
    font-weight: $font-weight-medium;

    span {
      font-size: 20px;
    }
  }

  &__discount-price {
    font-size: $fs-m;
  }

  &__discount-condition {
    color: $gray-600;
  }

  &__subtitle {
    font-size: $fs-m;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(md) {
      font-size: $fs-l;
    }
  }

  &__buy-click {
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 20px;
    }

    span {
      margin-left: 14px;
      font-size: $fs-m;
      border-bottom: 1px dotted $gray-900;
    }
  }

  &__feature {
    margin-top: 16px;

    @include media-breakpoint-up(md) {
      margin: 33px 40px 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
  }

  &__feature-icon {
    position: relative;
    top: 4px;
    display: none;
    font-size: 20px;
    color: $gray-400;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }

  &__feature-values {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4px;

    @include media-breakpoint-up(md) {
      margin-top: 10px;
    }
  }

  &__feature-item {
    min-width: 72px;
    height: 32px;
    margin-right: 8px;
    margin-bottom: 10px;

    &_label {
      @include size(100%);

      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 7px;
      cursor: pointer;
      box-shadow: inset 0 0 0 1px $gray-300;

      &:hover {
        box-shadow: inset 0 0 0 2.01px $primary;
      }
    }

    &_input {
      position: absolute;
      opacity: 0;

      &:checked {
        // stylelint-disable-next-line selector-class-pattern
        ~ .popup-cart__feature-item_label {
          box-shadow: inset 0 0 0 2.01px $primary;
        }
      }
    }
  }

  &__feature-btn {
    width: 100%;
    margin-top: 24px;

    @include media-breakpoint-up(md) {
      width: auto;
      margin-top: 115px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 102px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    margin-top: 12px;

    @include media-breakpoint-up(md) {
      margin-top: 16px;
    }

    &-control {
      width: 100%;

      @include media-breakpoint-up(md) {
        height: 56px;
        margin-bottom: 16px;
      }

      i {
        margin-right: 8px;
      }
    }

    &-favorite {
      padding: 0 16px;
      font-size: 20px;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .count-selector {
    @include media-breakpoint-up(md) {
      @include size(auto, 48px);
    }
  }

  &__calculator {
    display: flex;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: column;
    }
  }

  &__total-inner {
    display: flex;
    margin-top: 3px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  &__amount {
    margin-bottom: 16px;
  }

  &__amount-title {
    display: inline-block;
    margin-bottom: 3px;
    font-size: $fs-m;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(md) {
      margin-bottom: 6px;
      font-size: $fs-l;
    }
  }

  &__total {
    margin-left: 16px;

    @include media-breakpoint-down(md) {
      span {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
  }

  &__total-price {
    display: flex;
    align-items: center;
    height: 40px;
    padding-right: 12px;
    font-size: $fs-xl;
    font-weight: $font-weight-medium;
    border-right: 1px solid $border-color;
  }

  &__total-product {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    margin-top: 3px;
    font-size: $fs-m;
    color: $gray-600;

    @include media-breakpoint-up(md) {
      margin-top: -7px;
    }

    i {
      position: relative;
      top: 2px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
