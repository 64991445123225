// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  top: 0;
  left: 100%;
  align-items: center;
  justify-content: space-around;
  min-width: $badge-height;
  height: $badge-height;
  padding: $badge-padding-y $badge-padding-x;
  font-weight: $badge-font-weight;
  line-height: $badge-line-height;
  color: $badge-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: $secondary;
  @include font-size($badge-font-size);
  @include border-radius($badge-border-radius);

  &.square {
    border-radius: 0;
  }

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }

  //test badge for buttons with "sale"-mark
  .bg-sale {
    position: relative;
    overflow: hidden;
    color: $badge-color;
    background-color: $yellow;
    transform: rotate (-45deg);
  }

  &-xl{
    padding: 2px 8px;
    margin-right: 4px;
    font-size: $fs-m;
    color: $gray-900;
    background: $gray-100;
    border: 1px solid $gray-300;
    border-radius: 0;

    &-success{
      color: #4a8f40;
      background: #e7f0e6;
      border: 1px solid #b5d5b0;
    }
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}
