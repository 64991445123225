.catalog-page {

  .title-block {
    &__content {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        .price-update {
          width: auto;
        }
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .price-update {
          margin-top: 0;
        }
      }

      &_left {
        display: flex;
        align-items: baseline;

        span {
          display: none;

          @include media-breakpoint-up(md) {
            display: inline-block;
            margin-left: 1rem;
            font-size: $fs-m;
            color: $gray-600;
          }
        }
      }
    }

    .price-update {
      margin-top: 8px;
      font-size: $fs-m;
      color: $gray-900;

      time {
        font-weight: $font-weight-medium;
      }
    }
  }

  // Warning
  .auth-warning {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: $yellow-200;
    border: 1px solid $yellow-400;

    @include media-breakpoint-down(md) {
      margin-top: 16px;
      margin-bottom: 0;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0;
    }

    .container {
      @include media-breakpoint-down(md) {
        padding-right: 0;
        padding-left: 0;
      }
    }

    a,
    span {
      font-size: $fs-m;

      @include media-breakpoint-up(md) {
        font-size: $fs-l;
      }
    }

    .icn-user-bold {
      position: relative;
      top: 2px;
      margin-right: 10px;
      font-size: 20px;
    }

    .icn-close {
      font-size: 20px;
      cursor: pointer;
    }

    a {
      color: $orange;
      text-decoration: none;
    }
  }

  .promo {
    padding: 14px 10px;
    margin-bottom: 1.5rem;
    background-color: $yellow-200;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-around;

      i {
        margin-right: .5rem;
        font-size: 30px;
      }

      &__text {
        font-size: 14px;
        span {
          white-space: nowrap;
        }
      }

      &:last-child {
        white-space: nowrap;
      }
    }
  }

  .icn-question-circle {
    color: $gray-700;
  }

  .table {
    margin-bottom: 1.75rem;

    th,
    td {
      width: 25%;
      @include media-breakpoint-down(md) {
        width: 50%;
      }
    }

    tr {
      vertical-align: top;
    }

    th > a {
      color: $dark;
    }

    .catalog-switch {
      justify-content: flex-end;
      @include media-breakpoint-up(md) {
        justify-content: center;
      }
      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }
      color: $dark;
      .form-check {
        margin-bottom: 0;

        &-input {
          margin-right: .5rem;
          margin-left: .5rem;
        }
      }
    }

    .catalog-item {
      vertical-align: middle;

      &__price {
        font-weight: $font-weight-medium;

        @include media-breakpoint-up(md) {
          text-align: center;
        }

        @include media-breakpoint-up(lg) {
          text-align: right;
        }
      }

      &__icon {
        padding: 10px;
        margin-right: 0;
        color: $white;
        background-color: $secondary;

        @include media-breakpoint-up(md) {
          padding: .6rem 1rem;
        }
      }

      &-checked {
        position: relative;

        &::after {
          @include size(16px);
          position: absolute;
          top: 3px;
          right: 4px;
          content: "";
          background-color: $white;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.75 7.33329L0 3.99996L1.25 2.88885L3.75 5.11107L8.75 0.666626L10 1.77774L3.75 7.33329Z' fill='%23000'/%3e%3c/svg%3e ");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 80%;
          border-radius: 100%;
        }
      }
    }

    &-more {
      width: 100%;
      padding: .75rem 1.5rem;
      margin-bottom: 2.5rem;

      @include media-breakpoint-up(md) {
        width: fit-content;
      }
    }
  }

  .info {
    h2 {
      font-size: 32px;
    }
    p {
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }
    }
  }

  .filter-modal {
    .modal {
      &-header {
        padding: 0;
        margin-bottom: 24px;
        border-bottom: none;

        h5 {
          margin: 0;
        }

        span {
          color: $gray-600;
        }

        i {
          padding-right: 6px;
          vertical-align: text-top;
        }
      }

      &-list {
        padding: 0;
        margin: 0;

        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 0;

          > span:last-child {
            color: $gray-600;
          }

          a {
            color: $gray-900;
            text-decoration: none;
          }
        }
      }
    }
  }

  .sorting {
    padding: 0;

    ul {
      margin: .25rem 0 0;

      .dropdown-item {
        padding: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        width: 100%;
        padding: 17px 25px;
        color: $gray-900;
        text-decoration: none;

        &:hover,
        &:active {
          color: $orange-hover;
          background-color: $gray-100;
        }
      }
    }
  }
}

