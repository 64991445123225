.product {
  display: flex;
  flex-direction: column;
  font-size: $fs-m;

  &__image {
    width: 100%;
  }

  &__title {
    margin: 10px 0;
    font-size: 26px;
    font-weight: $font-weight-medium;
    color: $black;
  }

  &__code {
    margin: 10px 0;
    color: $gray-700;
  }

  &__units {
    font-size: $fs-l;
  }

  &__controls {

    .btn-close {
      position: relative;
      float: right;
      font-size: 12px;
      background-size: 12px;
    }
  }

  @include media-breakpoint-up(md) {
    width: 60%;
    font-size: $fs-l;
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    font-size: $fs-l;
  }
}
