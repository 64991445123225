.cabinet {
  &__subtitle {
    margin-bottom: 16px;
  }

  &-section {
    &:not(:last-child) {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid $border-color;

      @include media-breakpoint-up(lg) {
        padding-bottom: 48px;
        margin-bottom: 32px;
      }
    }

    &__wrap {
      display: flex;
      flex-direction: column;
    }

    &__item {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      span:not(:last-child) {
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }

      &-name {
        color: $gray-600;

        @include media-breakpoint-up(lg) {
          flex-basis: 30%;
        }

        @include media-breakpoint-up(xl) {
          flex-basis: 20%;
        }
      }

      &-action {
        color: $orange;
        cursor: pointer;

        i {
          margin-right: 4px;
        }

        span:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    &__text {
      &-light {
        color: $gray-600;
      }
    }

    // Recent operations
    &.recent-operations {
      .cabinet-section {
        &__item {
          padding-bottom: 16px;

          &:not(:last-child) {
            margin-bottom: 16px;
            border-bottom: 1px solid $border-color;
          }

          &-img {
            @include size(48px);
            position: relative;
            padding-top: 9px;
            padding-left: 8px;
            margin-right: 28px;
            font-size: 30px;
            color: $white;
            background-color: $gray-600;
            border-radius: 50%;

            &::after {
              @include size(20px);
              position: absolute;
              top: -3px;
              right: -4px;
              font-family: $font-family-icon;
              font-size: $fs-m;
              color: $gray-600;
              text-align: center;
              content: "\e920";
              background-color: $white;
              border-radius: 50%;
            }
          }

          &-info {
            font-weight: $font-weight-medium;
          }

          &.green {
            // stylelint-disable selector-max-class
            .cabinet-section {
              &__item {
                &-img {
                  background-color: $green;

                  &::after {
                    content: "\e91e";
                  }
                }

                &-price {
                  color: $green;
                }
              }
            }
          }
        }
      }
    }
  }

  //  Manager
  .manager {
    max-width: 280px;
    padding: 16px 20px;
    border: 1px solid $border-color;

    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid $border-color;

      &__img {
        margin-bottom: 8px;
      }

      &__name {
        text-align: center;
      }

      &__position {
        font-size: $fs-m;
        color: $gray-600;
      }
    }

    &-contacts {
      display: flex;
      flex-direction: column;
      font-size: $fs-m;

      &__phone {
        color: $black;
        text-decoration: none;
      }

      &__email {
        margin-bottom: 12px;
        color: $orange;
        text-decoration: none;
      }
    }
  }

  //  Balance
  .balance {
    padding: 16px;
    margin-bottom: 24px;
    background-color: $gray-100;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }

    &__quantity {
      margin-left: 10px;
      font-size: 22px;
      font-weight: $font-weight-medium;
    }

    &__icn {
      font-size: 20px;
      vertical-align: text-bottom;
    }
  }

  //  Banner
  .banner {
    width: 100vw;
    padding-top: 30px;
    margin-top: 48px;
    margin-left: -16px;
    background-color: $gray-100;

    @include media-breakpoint-up(md) {
      width: 100%;
      margin-left: 0;
    }

    &__wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      &::after {
        @include size(100%, 159px);
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background-image: url("/docs/5.0/assets/img/evraz/personal-cabinet/box.svg");
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: contain;

        @include media-breakpoint-up(md) {
          height: 191px;
        }
      }
    }

    &-brand-art {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 147px;
      margin-top: 8px;
      margin-bottom: 40px;

      @include media-breakpoint-up(md) {
        width: 78%;
        margin-top: 40px;
      }

      &__item:nth-child(1) {
        position: relative;
        top: 4px;
        flex-grow: 1;
        min-width: 235px;
        height: 52px;
        background-color: $yellow;

        @include media-breakpoint-up(md) {
          @include size(629px, 48px);
        }
      }

      &__item:nth-child(2) {
        @include size(29px, 60px);
        position: relative;
        top: -44px;
        background-color: $orange;
      }

      &__item:nth-child(3) {
        @include size(24px, 10px);
        position: relative;
        top: -134px;
        background-color: $red;

        @include media-breakpoint-up(md) {
          @include size(105px, 9px);
        }
      }
    }

    &__info {
      padding: 0 16px 24px;
      text-align: center;

      @include media-breakpoint-up(md) {
        padding: 0 64px 24px;
      }
    }
  }

  // Edit page
  &__back-link {
    margin-bottom: 16px;
    color: $gray-600;

    i {
      vertical-align: text-top;
    }
  }

  &-input-group {
    &__input {
      border-right: none;
    }

    &__icon {
      color: $gray-600;
      background-color: transparent;
      border-left: none;
    }
  }
}
