//
// Text truncation
//

.text-truncate {
  @include text-truncate();
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;

  > span {
    position: relative;
    display: inline-block;
    padding-right: 20px;

    &:hover {
      animation: move 3s;
      animation-fill-mode: forwards;
    }
  }

  &::after {
    position: sticky;
    top: 0;
    right: 0;
    display: inline-block;
    width: 40px;
    height: 12px;
    content: "";
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
}

@keyframes move {
  0% {
    left: 0%;
    transform: translateX(0%);
  }
  100% {
    left: 100%;
    transform: translateX(-100%);
  }
}
