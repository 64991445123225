//
// Headings
//
.h1 {
  @extend h1;
}

.h2 {
  @extend h2;
}

.h3 {
  @extend h3;
}

.h4 {
  @extend h4;
}

.h5 {
  @extend h5;
}

.h6 {
  @extend h6;
}


.lead {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
}

// Type display classes
@each $display, $font-size in $display-font-sizes {
  .display-#{$display} {
    @include font-size($font-size);
    font-weight: $display-font-weight;
    line-height: $display-line-height;
  }
}

//
// Emphasis
//
.small {
  @extend small;
}

.mark {
  @extend mark;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled();
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  @include font-size($initialism-font-size);
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $blockquote-margin-y;
  @include font-size($blockquote-font-size);

  > :last-child {
    margin-bottom: 0;
  }
}

.blockquote-footer {
  margin-top: -$blockquote-margin-y;
  margin-bottom: $blockquote-margin-y;
  @include font-size($blockquote-footer-font-size);
  color: $blockquote-footer-color;

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}

.page-content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.underline-stroke-link {
  display: inline;
  width: fit-content;
  padding-bottom: 0;
  color: $black;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: $primary;

  span {
    // stylelint-disable-next-line declaration-no-important
    font-weight: 400 !important;
    background-image: linear-gradient(180deg, transparent 0%, $yellow 0%);
    background-repeat: no-repeat;
    background-size: 0 100%;
    border-bottom: none;
    transition: .3s cubic-bezier(.5, .61, .355, 1) all;
  }

  &:hover {
    span {
      color: $black;
      background-size: 100% 100%;
      transition: .1s cubic-bezier(.5, .61, .355, 1) all;
    }
  }
}

.underline-hover-link {
  color: $orange;
  text-decoration: none;

  &:hover {
    color: $orange;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}

.underline-default-link {
  color: $orange;
  text-decoration: none;
  border-bottom: 1px dotted $orange;

  &:hover {
    color: $orange-hover;
    text-decoration: none;
  }
}

.underline-secondary-link {
  color: $black;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-offset: 2px;

  &:hover {
    color: $orange-hover;
  }
}
