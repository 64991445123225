.news-page {
  margin-bottom: 40px;

  @include media-breakpoint-up(md) {
    margin-bottom: 80px;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 32px 24px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__item {
    color: unset;
    text-decoration: none;

    &:hover {
      color: $orange;
      text-decoration: none;
    }

    img {
      @include size (100%, 170px);
      object-fit: cover;
    }
  }

  &__item-date {
    padding: 16px 0 10px;
    font-size: $fs-m;
    color: $gray-700;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}
