.accordion-item {
  font-size: $fs-l;

  .accordion-gray & {
    background-color: $gray-100;
  }
}

.accordion-body {
  padding: 15px 0 10px;

  .accordion-gray & {
    padding: 10px 20px;
  }
}

.accordion-collapse {
  &.show {
    border-bottom: 1px solid $gray-300;
  }
}

.accordion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  font-family: $font-family-base;
  font-weight: 500;
  background-color: $white;
  border: 0;
  overflow-anchor: none;


  .accordion-gray & {
    padding: 10px 16px;
    background-color: $gray-100;
  }

  &:not(.collapsed) {
    i {
      transform: $accordion-icon-transform;
    }
  }

  &.collapsed {
    border-bottom: 1px solid $gray-300;
  }

  i {
    font-size: rem(20px);
    @include transition($accordion-icon-transition);
  }

  &:hover {
    background-color: $gray-200;
    border-bottom: none;

    .accordion-gray &.collapsed {
      border-bottom: 1px solid $gray-300;
    }
  }

  &:focus {
    z-index: 3;
    border: none;
    outline: 0;
    box-shadow: $accordion-button-focus-box-shadow;

    .accordion-gray & {
      background-color: $gray-100;
    }
  }
}
