.popup-add-service {
  @include media-breakpoint-up(lg) {
    .modal-dialog {
      width: 568px;
    }
  }

  .modal-footer {

    .btn-primary {
      &:disabled,
      &.disabled {
        color: $gray-600;
        background-color: $gray-300;
        border: $gray-300;
      }
    }

    @include media-breakpoint-down(md) {
      button {
        width: 50%;
      }
    }
    @include media-breakpoint-down(lg) {
      button {
        width: 48.85%;
      }
    }
  }

  &.cart {
    .modal-body {
      padding: 24px 16px 0;

      @include media-breakpoint-up(md) {
        padding: 36px 32px 0;
      }
    }

    .modal-content {
      padding: 0;
    }

    .modal-footer {
      padding: 0 16px 16px;

      @include media-breakpoint-up(md) {
        padding: 0 32px 40px;
      }
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
    border-bottom: 1px solid $border-color;

    i {
      padding-bottom: 5px;
      font-size: 60px;
    }
  }

  &__heading {
    display: flex;
    justify-content: center;
    padding: 24px 0;
  }

  &__title {
    font-weight: 500;
  }

  &__parts {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    &-item {
      margin-top: 16px;

      @include media-breakpoint-up(md) {
        margin-top: 0;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }

      input {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 157.3px;
        }
      }
    }
  }

  &__item {
    @include size(100%, 112px);

    position: relative;
    min-width: 155px;

    & + & {
      margin-left: 8px;
    }
  }

  &__item-price {
    margin-top: 4px;
    font-weight: 500;
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 12px 16px 16px;
    cursor: pointer;
    box-shadow: inset 0 0 0 1px $gray-300;

    &:hover {
      box-shadow: inset 0 0 0 2.01px $primary;
    }

    i {
      font-size: 32px;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;

    &:checked {
      // stylelint-disable-next-line selector-class-pattern
      ~ .popup-add-service__label {
        box-shadow: inset 0 0 0 2.01px $primary;
      }
    }
  }

  &__warning {
    display: flex;
    width: 100%;
    padding: 16px 17px;
    font-size: $fs-m;
    background-color: $yellow-200;
    border: 1px solid $yellow-400;

    i {
      margin-right: 8px;
      font-size: 18px;
    }
  }

  &__description {
    height: 96px;
  }

  &__file {
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      margin-right: 6px;
      font-size: 20px;
    }
  }

  &__file-drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    cursor: pointer;
    border: 1px dotted $gray-400;

    i {
      padding-bottom: 5px;
      font-size: 20px;
      color: $gray-600;
    }

    span {
      font-size: $fs-m;
    }
  }

  &__file-text {
    border-bottom: 1px dotted $gray-900;
  }

  &__file-hint {
    margin-top: 8px;
    font-size: $fs-m;
    color: $gray-700;
  }

  &__message {
    display: flex;
    align-items: center;
    font-size: $fs-m;

    i {
      margin-right: 24px;
      font-size: 32px;
    }
  }

  &__discount {
    position: absolute;
    top: 13px;
    left: -20px;
    height: 0;
    font-size: $fs-m;
    font-weight: 500;
    text-transform: uppercase;
    border-right: 25px solid transparent;
    border-bottom: 18px solid $yellow;
    border-left: 18px solid transparent;
    transform: rotate(-47deg);
  }

  &__amount-title {
    margin-bottom: 8px;
    font-weight: 500;
  }

  &__total {
    margin-left: 24px;
    font-weight: 500;
  }

  &__total-title {
    margin-bottom: 8px;
  }

  &__total-price {
    margin-top: 12px;
    font-size: 26px;

    @include media-breakpoint-up(lg) {
      margin-top: 15px;
    }
  }

  &__load-file {
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;

    &-img {
      margin-right: 8px;
    }

    &-text {
      border-bottom: 1px dashed #000;
    }
  }

  &__info {
    display: flex;

    &-img {
      margin-right: 24px;
    }

    &-text {
      font-size: 14px;
    }
  }

  &__icon {
    @include size(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    font-size: 28px;
    text-align: center;
    border: 2px solid $black;
    border-radius: 16px;
  }
}
