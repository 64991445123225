.contacts-menu {
  padding: 24px 16px;

  @include media-breakpoint-up(md) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__info {
    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin: 0;
    }

    display: flex;
    margin: 0 -8px;

    &-text {
      display: flex;
      align-items: center;
    }

    &-success {
      color: $green;
    }

    &-blocks {
      @include media-breakpoint-down(md) {
        width: 100%;
        margin: 0;
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      margin: 0 8px;
    }

    &-phone {
      margin: 12px 0;
      font-size: 20px;
      font-weight: 500;
    }

    &-send-order {
      line-height: 1.4;
    }

    &-callback {
      @include media-breakpoint-down(md) {
        margin: 16px 0 24px;
      }
    }

    button {
      @include size(100%, 40px);
      margin-top: 23px;
      font-weight: 500;

      span {
        padding-left: 7px;
        font-size: $btn-font-size;
      }
    }

    i {
      font-size: 18px;
    }

    span {
      padding-left: 5px;
      font-size: $fs-l;
    }
  }

  &__hours {
    &-label {
      margin-bottom: 12px;
      font-size: $fs-l;
      font-weight: 500;
    }

    &-delimiter {
      position: relative;
      top: -3px;
      flex-grow: 1;
      margin: 0 2px;
      border-bottom: 1px dotted $gray-400;
    }

    &-slot {
      display: flex;

      &:first-of-type {
        margin-bottom: 12px;
      }
    }
  }

  &__manager {
    display: flex;

    &-ava {
      @include size(40px);
    }

    &-info {
      margin-left: 12px;
    }

    &-name {
      padding-top: 2px;
      font-weight: 500;
    }

    &-position {
      padding-top: 5px;
      color: $gray-600;
    }

    &-phone {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 500;
    }

    &-mail {
      margin-top: 10px;
    }

    &-mail_value {
      margin-top: 10px;
      font-size: $fs-l;
    }
  }

  .divider {
    margin: 24px 0;
    color: $gray-300;
  }
}

// stylelint-disable-next-line selector-class-pattern
.contacts-menu__hours-slot + .contacts-menu__hours-slot {
  margin-top: 12px;
}
