.product-page {

  time {
    font-weight: $font-weight-medium;
  }

  .title-block {
    .price-update {
      margin-top: 8px;
      font-size: $fs-m;
      color: $gray-900;
    }

    &__content {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .price-update {
          width: 120px;
          margin-top: 0;
        }
      }

      @include media-breakpoint-up(lg) {
        .price-update {
          width: auto;
        }
      }
    }
  }


  // Warning
  .auth-warning {
    margin-top: 20px;
    margin-bottom: 16px;
    background-color: $yellow-200;
    border: 1px solid $yellow-400;

    @include media-breakpoint-up(md) {
      margin-bottom: 32px;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0;
    }

    .container {
      @include media-breakpoint-down(md) {
        padding-right: 0;
        padding-left: 0;
      }
    }

    a,
    span {
      font-size: $fs-m;

      @include media-breakpoint-up(md) {
        font-size: $fs-l;
      }
    }

    .icn-user-bold {
      position: relative;
      top: 2px;
      margin-right: 10px;
      font-size: 20px;
    }

    .icn-close {
      font-size: 20px;
      cursor: pointer;
    }

    a {
      color: $orange;
      text-decoration: none;
    }
  }


  // Main row
  .product-main-row {
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      .product-main-col:nth-child(1) {
        width: calc(25% - 30px);
      }

      .product-main-col:nth-child(2) {
        width: calc(33.333333% + 30px);
      }
      .product-main-col:last-child {
        padding-right: 0;
      }
    }
  }


  // Gallery
  .gallery {
    @include size(100%);

    @include media-breakpoint-up(lg) {
      height: 221px;
    }
  }


  // Values
  .values {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    &__item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: calc(25% - (8px / 4 * 3));
      height: 32px;
      margin-right: 8px;
      margin-bottom: 10px;
      cursor: pointer;
      border: 1px solid $gray-300;

      &--selected {
        border-color: $orange;
        border-width: 2px;
      }

      &:hover {
        border-color: $orange;
        border-width: 2px;
      }

      &--more {
        border: none;

        span {
          border-bottom: 1px dotted $black;
        }
      }

      @include media-breakpoint-down(xl) {
        &:nth-child(4n + 4) {
          margin-right: 0;
        }
      }

      @include media-breakpoint-up(xl) {
        width: calc(16.666% - (8px / 6 * 5));
        margin-right: 8px;

        &:nth-child(6n + 6) {
          margin-right: 0;
        }
      }
    }
  }


  // Main info
  .main-info {
    &__title {
      display: inline-block;
      margin-bottom: 10px;
      font-size: $fs-m;
      font-weight: $font-weight-medium;

      @include media-breakpoint-up(lg) {
        font-size: $fs-l;
      }
    }

    &__question-icon {
      position: relative;
      top: 4px;
      font-size: 19px;
      color: $gray-400;
      cursor: pointer;
    }

    &__props {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &__prop-separator {
      position: relative;
      bottom: -12px;
      flex-grow: 1;
      height: 2px;
      margin: 0 10px;
      border-bottom: 1px dotted $gray-400;
    }

    &__prop {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      text-align: left;

      span {
        font-size: $fs-m;
      }

      span:last-child {
        width: 110px;

        @include media-breakpoint-up(lg) {
          width: 150px;
        }
      }
    }
  }


  // Cart
  .cart {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    .nav-item {
      a {
        padding-top: 13px;
        padding-right: 24px;
        padding-left: 24px;
        font-weight: $font-weight-medium;
      }
    }

    &__content {
      padding: 26px 16px 0;
      border: 1px solid $gray-200;

      @include media-breakpoint-up(md) {
        padding: 42px 16px 0;
      }

      @include media-breakpoint-up(lg) {
        padding: 17px 20px 0;
      }

      @include media-breakpoint-up(xxl) {
        padding: 17px 28px 0;
      }
    }

    &__title {
      display: inline-block;
      margin-bottom: 3px;
      font-size: $fs-m;
      font-weight: $font-weight-medium;
      white-space: nowrap;

      @include media-breakpoint-up(lg) {
        margin-bottom: 6px;
        font-size: $fs-l;
      }
    }

    &__price {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(xl) {
        flex-direction: row;

        // stylelint-disable selector-class-pattern
        .cart__main-price {
          width: 40%;
        }
        .cart__discount-price {
          width: 60%;
          margin-top: 10px;
        }

      }
    }

    &__main-price {
      margin-bottom: 4px;
      font-size: 26px;
      font-weight: $font-weight-medium;


      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }

      span {
        font-size: 20px;
      }
    }

    &__discount-price {
      font-size: $fs-m;

      span {
        font-weight: $font-weight-medium;
      }
    }

    &__discount-condition {
      color: $gray-600;
      white-space: nowrap;
    }

    &__availability {
      margin-top: 4px;
      font-size: $fs-m;
      color: $green;

      i {
        margin-right: 4px;
        font-size: $fs-l;
        vertical-align: middle;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 12px;
        font-size: $fs-m;
        font-weight: $font-weight-medium;
        text-transform: uppercase;

        i {
          margin-right: 11px;
        }
      }
    }

    &__calculator {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        margin-top: 20px;
      }
    }

    &__total-inner {
      display: flex;
      height: fit-content;


      @include media-breakpoint-up(lg) {
        flex-direction: column;
        justify-content: space-around;
      }

      @include media-breakpoint-up(xl) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &__amount {
      margin-bottom: 16px;

      @include media-breakpoint-up(lg) {
        width: 40%;
        padding-right: 16px;
        margin-bottom: 0;
      }

      .count-selector {
        width: 120px;
      }
    }

    &__total {
      @include media-breakpoint-down(md) {
        span {
          margin-bottom: 0;
        }
      }
      @include media-breakpoint-up(lg) {
        width: 60%;
        margin-left: 24px;
      }
      @include media-breakpoint-up(xl) {
        margin-left: 0;
      }
    }

    &__total-price {
      display: flex;
      align-items: center;
      height: 40px;
      padding-right: 12px;
      font-size: 20px;
      font-weight: $font-weight-medium;
      border-right: 1px solid $gray-300;

      @include media-breakpoint-up(lg) {
        align-items: flex-start;
        height: 25px;
        margin-bottom: 5px;
        border-right: none;
      }

      @include media-breakpoint-up(xl) {
        align-items: center;
        height: 40px;
        margin-bottom: 0;
        border-right: 1px solid $gray-300;
      }
    }

    &__total-product {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding-left: 10px;
      margin-top: -7px;
      font-size: $fs-m;
      color: $gray-600;

      i {
        position: relative;
        top: 2px;
        font-size: 19px;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        padding-left: 0;

        span:first-child {
          padding-right: 4px;
          margin-right: 4px;
          border-right: 1px solid $gray-300;
        }

        span:last-child {
          position: relative;
          top: 4px;
        }
      }

      @include media-breakpoint-up(xl) {
        flex-direction: column;
        padding-left: 12px;

        span:first-child {
          padding-right: 0;
          margin-right: 0;
          border-right: none;
        }

        span:last-child {
          position: relative;
          top: 0;
        }
      }
    }

    &__button {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      button {
        @include size(100%, 56px);

        i {
          margin-right: 10px;
          font-size: 20px;
        }
      }

      a {
        margin-top: 15px;
        font-size: $fs-m;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        height: 56px;
        margin-top: 16px;

        button {
          width: 147px;
        }

        a {
          display: flex;
          align-items: center;
          margin-top: 0;
          margin-left: 24px;
        }
      }

      @include media-breakpoint-up(xl) {
        button {
          width: 229px;
        }

        a {
          margin-left: 29px;
        }
      }

    }

    &__info {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      @include media-breakpoint-up(lg) {
        margin-top: 10px;
      }

      i {
        position: relative;
        top: 4px;
        left: 4px;
        font-size: 20px;
      }

      span {
        display: inline-block;
        margin-bottom: 10px;
        font-size: $fs-m;
        line-height: 140%;

        @include media-breakpoint-up(md) {
          margin-bottom: 6px;
        }

        @include media-breakpoint-up(lg) {
          margin-bottom: 10px;
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: 8px;
        }

        &:nth-child(2) {
          margin-bottom: 10px;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }

        &:last-child {
          margin-bottom: 11px;

          @include media-breakpoint-up(xl) {
            margin-bottom: 20px;
          }
        }
      }
    }

    &__bottom {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;

      &::before {
        position: absolute;
        top: 0;
        left: -10px;
        display: block;
        width: calc(100% + 20px);
        height: 1px;
        content: "";
        background-color: $gray-200;

        @include media-breakpoint-up(md) {
          left: -20px;
          width: calc(100% + 40px);
        }
      }

      span,
      a {
        font-size: $fs-m;
      }

      i {
        position: relative;
        top: 3px;
        margin-right: 4px;
        font-size: 20px;

        @include media-breakpoint-up(lg) {
          margin-right: 8px;
        }
      }

      a {
        margin-top: 6px;
      }
    }

  }

  // Tabs-block
  .product-tabs {
    margin-top: 36px;

    .product-main-tabs {
      &::-webkit-scrollbar {
        @include size(0);
        background: transparent;
      }
      .nav-link:not(.active) {
        border-bottom: none;
      }
    }

    .nav {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      width: auto;
      margin-bottom: 2rem;
      overflow-x: auto;
      border-bottom: 1px solid $gray-300;
      scrollbar-width: none;

      @include media-breakpoint-up(lg) {
        margin-bottom: 2.5rem;
      }

      @include media-breakpoint-down(xl) {
        justify-content: flex-start;
      }

      a {
        padding-right: 0;
        padding-left: 0;
        margin-right: 30px;
      }

      .nav-link {
        font-size: 13px;
        font-weight: $font-weight-medium;
        white-space: nowrap;

        &:focus {
          box-shadow: none;
        }
      }

      @include media-breakpoint-up(md) {
        .nav-link {
          font-size: $fs-l;
        }
      }
    }

    .tab-pane {
      margin-bottom: 2rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 2.5rem;
      }
    }

    i {
      font-size: 40px;

      @include media-breakpoint-up(md) {
        vertical-align: middle;
      }
    }

    .delivery-title {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
        margin-top: 25px;
      }

      &__title {
        margin-top: 12px;
        margin-bottom: 8px;
        font-size: $fs-xl;
        font-weight: $font-weight-medium;

        @include media-breakpoint-up(md) {
          margin: 0;
        }
      }

      &__text {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          margin-left: 20px;
        }

        span {
          font-weight: $font-weight-medium;
        }
      }

      h3 {
        margin-top: 10px;

        @include media-breakpoint-up(md) {
          margin-bottom: 4px;
        }
      }
    }


    .delivery {
      &-table__value {
        font-weight: $font-weight-medium;

        span {
          display: flex;
          font-size: 14px;
          font-weight: 400;
        }
      }

      &-table-title {
        display: inline-block;
        margin-top: 24px;
        margin-bottom: 8px;
        font-weight: $font-weight-medium;
      }

      .icn-small-truck {
        font-size: 24px;
      }

      .icn-medium-truck {
        font-size: 28px;
      }

      .icn-big-truck {
        font-size: 30px;
      }

      .icn-crane-truck {
        font-size: 37px;
      }
    }

    .delivery-calculator {
      margin-top: 20px;

      button {
        height: 40px;
        padding: 0 20px;
        font-size: 13px;
        font-weight: $font-weight-medium;
        color: $black;
      }

      i {
        position: relative;
        top: -1px;
        margin-right: 10px;
        font-size: $fs-l;
      }

    }

    .delivery-nearest-date {
      display: flex;
      align-items: center;
      padding: 10px;
      margin-top: 24px;
      background-color: $gray-100;

      span {
        font-size: $fs-l;
      }

      i {
        position: relative;
        top: -1px;
        margin-right: 10px;
        font-size: 22px;
      }
    }

    .warehouses-tabs {
      display: flex;
      width: fit-content;
      margin-top: 20px;
      margin-bottom: 0;
      border-bottom: 1px solid $gray-300;

      @include media-breakpoint-up(md) {
        display: inline-flex;
        width: auto;
        border-bottom: none;
      }

      .nav-item:first-child {
        margin-right: 20px;
      }

      .nav-link {
        align-items: center;
        justify-content: space-around;
        padding: 16px 0;
        font-size: 13px;
        background-color: transparent;
        border: none;

        @include media-breakpoint-up(md) {
          height: 40px;
          padding: 0 20px;
          border: 1px solid $gray-300;
        }
      }

      .active {
        border-bottom: 2px solid $orange;

        @include media-breakpoint-up(md) {
          border: 2px solid $orange;
        }
      }
    }

  }


  // Warehouse table
  .warehouse {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    border-bottom: 1px solid $gray-300;

    &:first-child {
      .warehouse__name {
        margin-top: 40px;
      }
    }

    span {
      font-size: $fs-l;
    }

    &__name {
      margin-top: 24px;
    }

    &__name,
    &__phone {
      font-weight: $font-weight-medium;
    }

    &__phone {
      margin-bottom: 6px;
    }

    &__label {
      margin-top: 20px;
      margin-bottom: 10px;
      color: $gray-600;
    }
  }

  .warehouses-table {
    margin-top: 24px;

    td {
      width: 25%;
    }

    &__phone {
      display: inline-block;
      margin-bottom: 4px;
      font-weight: $font-weight-medium;
    }
  }

}

.balloon-office {
  position: relative;
  max-width: 500px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, .3), 0 1px 7px 2px rgba(0, 0, 0, .03);

  &__title {
    display: block;
    padding-bottom: 5px;
    font-size: 26px;
  }

  &__route-btn {
    font-size: 16px;
    font-weight: $font-weight-medium;
    color: $orange;
    white-space: nowrap;
    cursor: pointer;
  }

  &__info {
    padding-top: 20px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 18px;
    color: $gray-700;

    i {
      // stylelint-disable-next-line declaration-no-important
      font-size: inherit !important;
    }
  }
}

.office-info {
  display: flex;
  padding-bottom: 10px;

  &__phone {
    padding-bottom: 5px;
    font-weight: $font-weight-medium;
    letter-spacing: .5px;
  }

  &__email,
  &__phone {
    display: block;
    color: $black;
    text-decoration: none;
  }

  &__label {
    width: 100px;
    color: $gray-700;
  }

  &__content {
    width: calc(100% - 100px);
    padding-left: 50px;
    font-weight: $font-weight-medium;
  }

  &__address {
    color: $black;
  }
}
