.delivery-address {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &--gray {
    background-color: $gray-100;
  }

  @include media-breakpoint-up(xl) {
    flex-direction: row;
    justify-content: space-between;

    &__form {
      width: 620px;
    }

    .link-button {
      margin-top: 90px;
    }
  }
}

.footnote {
  padding-top: 16px;
  color: $gray-700;

  ul {
    padding-left: 16px;
  }

  li {
    padding-bottom: 20px;
  }
}


.delivery-info {
  display: flex;

  &--top-offset {
    padding-top: 20px;
  }

  &__icon {
    padding-right: 12px;
  }

  i {
    font-size: 49px;
  }

  &__content {
    strong {
      padding-bottom: 10px;
    }
  }
}

.delivery-place {
  &__title {
    display: block;
    padding-bottom: 1rem;
  }

  &__city {
    padding-bottom: 1rem;

    span {
      border-bottom: 1px dashed $black;
    }
  }
}
