/* GT Eesti Pro Font */

$folder: "../../assets/fonts" !default;

@font-face {
  font-family: "GT Eesti Pro Text";
  font-style: normal;
  font-weight: 400;
  src: local("GTEestiProText-Book"), local("GT Eesti Pro Text Book"), url($folder + "/GTEestiProText-Book.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "GT Eesti Pro Text";
  font-style: normal;
  font-weight: 500;
  src: local("GTEestiProText-Medium"), local("GT Eesti Pro Text Medium"), url($folder + "/GTEestiProText-Medium.woff") format("woff");
  font-display: swap;
}


/* EvrazIcon Font */

$folder-icons: "../../assets/fonts/evraz-icon" !default;

@font-face {
  font-family: EvrazIcon;
  font-style: normal;
  font-weight: 400;
  src: url("#{$folder-icons}" + "EvrazIcon.eot?6hnzjm");
  src: url("#{$folder-icons}" + "EvrazIcon.eot?6hnzjm#iefix") format("embedded-opentype"), url("#{$folder-icons}" + "/EvrazIcon.ttf?6hnzjm") format("truetype"), url("#{$folder-icons}" + "/EvrazIcon.woff?6hnzjm") format("woff"), url("#{$folder-icons}" + "/EvrazIcon.svg?6hnzjm#EvrazIcon") format("svg");
  font-display: swap;
}
