.vacancies {
  margin-bottom: 80px;

  &__content {
    display: flex;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  &__left {
    flex-basis: 25%;
  }

  &__right {
    flex-basis: 75%;
    margin-left: 24px;

    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
  }

  &__subtitle {
    margin-bottom: 32px;
    font-size: 32px;
    font-weight: $font-weight-medium;
    line-height: 110%;

    @include media-breakpoint-down(lg) {
      margin-top: 32px;
      font-size: 24px;
    }
  }

  &__location {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    &-desc {
      margin-bottom: 8px;
      font-weight: $font-weight-medium;
    }

    &-name {
      @include size(fit-content);
      cursor: pointer;
      border-bottom: 1px dashed $black;

      &-img {
        margin-right: 8px;
      }
    }

    &-wrap {
      display: flex;
      cursor: pointer;

      &--template {
        display: none;
      }
    }
  }

  &__info {
    &-wrap {
      margin-left: 20px;

      @include media-breakpoint-down(lg) {
        margin-top: 16px;
        margin-left: 0;
      }
    }

    &-description {
      margin-top: 8px;
    }

    &-name {
      display: flex;
      align-items: center;
      min-height: 25px;
      line-height: 140%;
      color: $gray-600;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    &-separator {
      @include size(auto, 25px);
      margin: 0 10px;
      color: $gray-600;
    }
  }

  &__organization {
    color: $gray-600;
  }

  &__city {
    color: $gray-600;
  }

  &__selected-elem {
    @include size(100%, auto);
    position: relative;
    padding: 10px;
    cursor: pointer;
    border: 1px solid $gray-300;

    &--template {
      display: none;
    }
  }

  &__arrow {
    @include size(12px);
    position: absolute;
    top: 12px;
    right: 10px;
    border-bottom: 2px solid $black;
    border-left: 2px solid $black;
    transition-duration: .2s;
    transform: rotate(-45deg);
  }

  &__filter {
    @include size(100%, auto);
    position: absolute;
    display: none;
    padding: 0;
    list-style: none;
    outline: $white;
    box-shadow: 0 10px 15px -8px rgba(0, 0, 0, .3), 0 1px 7px 2px rgba(0, 0, 0, .03);

    /* stylelint-disable */
    &--active {
      .vacancies__selected-elem {
        border: 1px solid $orange;
      }

      .vacancies__filter {
        display: block;
      }

      .vacancies__arrow {
        top: 15px;
        transform: rotate(135deg);
      }
    }

    /* stylelint-enable */

    &-wrap {
      position: relative;
    }

    &-item {
      padding: 9px;
      background: $white;

      &:hover {
        cursor: pointer;
        background: $yellow-200;
      }
    }

    &:focus {
      border: 1px solid $orange;
    }

    &-title {
      margin-bottom: 8px;
      font-weight: $font-weight-medium;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border: 1px solid $gray-300;
    border-top: 0 solid $gray-300;

    &:first-child {
      border-top: 1px solid $gray-300;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      padding: 16px 0;
      border-right: 0 solid $gray-300;
      border-left: 0 solid $gray-300;
    }
  }

  &__position {
    display: block;
    margin-top: 8px;
    font-size: 20px;
    font-weight: $font-weight-medium;
    color: $gray-900;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $orange;
    }
  }

  &__price {
    font-weight: $font-weight-medium;

    @include media-breakpoint-down(lg) {
      margin-top: 16px;
    }
  }

  &__btn {
    @include size(fit-content);
    padding: 11px 24px;
    margin-top: 32px;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    border: 2px solid $black;

    @include media-breakpoint-down(lg) {
      @include size(100%, fit-content);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__popup {
    @include size(100%, 100%);
    position: fixed;
    top: 0;
    z-index: 110;
    display: none;
    align-items: center;
    justify-content: center;

    &--active {
      display: flex;
    }

    &-background {
      @include size(100%, 100%);
      background: rgba(28, 28, 28, .8);
    }

    &-content {
      @include size(760px, auto);
      position: absolute;
      top: 30px;
      padding: 36px 32px;
      background: $white;

      @include media-breakpoint-down(lg) {
        @include size(100%, 100%);
        overflow-y: scroll;
      }
    }

    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    &-title {
      margin-top: 20px;
      margin-bottom: 24px;
      font-size: 26px;
      font-weight: $font-weight-medium;
    }

    &-input {
      @include size(100%, 40px);
      max-width: 380px;
      padding: 5px 12px;
      border: 1px solid $gray-300;
      outline: $white;

      @include media-breakpoint-down(md) {
        max-width: inherit;
      }
    }

    &-list {
      @include size(auto, 480px);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0;
      margin-top: 12px;

      @include media-breakpoint-down(md) {
        @include size(auto);
      }
    }

    &-item {
      display: flex;
      margin-top: 12px;
    }

    &-cities {
      margin-left: 24px;
    }

    &-city {
      margin-top: 12px;
      font-size: $fs-m;
      line-height: 140%;
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }
    }

    &-letter {
      font-size: $fs-m;
      font-weight: $font-weight-medium;
      color: $gray-600;
    }

    &-template {
      display: none;
    }
  }
}
