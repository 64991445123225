.header-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  padding: 10px 0 8px;
  background-color: $white;
  border-top: 2px solid $gray-100;

  @supports (-webkit-touch-callout: none) {
    padding: 12px 0;
  }

  @include media-breakpoint-up(md) {
    padding: 16px 32px 4px;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    ~ .modal-backdrop {
      position: relative;
      height: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    font-size: $fs-s;
    color: $gray-700;

    i {
      padding-bottom: 5px;
      font-size: 22px;
    }

    &-name {
      @include text-truncate();
      max-width: 100%;
    }
  }
}

.popup-menu {
  .modal-content {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 24px 16px;
    }
  }
}

.mobile-menu {
  &__item {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  &__item-name {
    display: flex;
    align-items: center;
    padding-right: 16px;

    &.special {
      padding: 16px;
      margin-bottom: 16px;
      color: $black;
      text-decoration: none;
      background-color: $gray-100;

      &:hover {
        color: $black;
        text-decoration: none;
      }
    }

    i {
      font-size: 32px;
    }

    span {
      padding-left: 4px;
      font-size: $fs-l;
      color: $black;
    }
  }

  &__link {
    display: block;
    padding: 16px;
    color: $black;
    text-decoration: none;

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  &__sub {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background-color: $white;

    &-title {
      display: flex;
      align-items: center;
      padding: 24px 16px 16px;

      @include media-breakpoint-up(md) {
        padding: 24px 32px 16px;
      }

      i {
        padding-right: 8px;
        font-size: 20px;
      }
    }

    a {
      @include media-breakpoint-up(md) {
        padding: 16px 32px;
      }
    }
  }

  .icn-chevron-right {
    font-size: 20px;
    color: $gray-600;
  }
}

.user-menu {
  padding: 16px;

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    line-height: 1.25;
    color: $black;
    text-decoration: none;

    &:hover {
      color: $black;
      text-decoration: none;
    }

    &.logout {
      padding-top: 25px;
      color: $gray-600;

      i {
        font-size: 20px;
      }
    }
  }

  .badge {
    @include size(20px);
    line-height: 1.5;
  }
}
