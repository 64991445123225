.checkout-page {
  padding-bottom: 24px;
  background: $gray-100;

  @include media-breakpoint-up(xxl) {
    &__left {
      min-width: 898px;
    }

    &__right {
      max-width: 422px;
    }
  }

  hr {
    margin: 0;
    background-color: $gray-300;
  }

  h3 {
    margin-bottom: 24px;
  }

  .form-switch {
    margin-bottom: 4px;
  }

  .title-block {
    padding-top: 32px;
    background-color: $gray-100;
  }

  .nav-buttons-block {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: auto;
    background-color: $white;

    li {
      width: 100%;
    }

    li + li {
      margin-left: 16px;
    }

    &__item {
      padding: 10px;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;
      cursor: pointer;
      box-shadow: 0 0 0 2px $gray-300;

      &:hover,
      &.active {
        box-shadow: 0 0 0 2px $orange;
      }
    }

    &.delivery {
      margin: 0;

      // stylelint-disable selector-class-pattern
      .nav-buttons-block__item {
        padding: 17px 24px;
        font-size: $fs-l;
      }
    }

    &.customer {
      width: fit-content;
      padding: 2px;
    }
  }

  .delivery-customer {
    @include media-breakpoint-up(xxl) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      > div:last-child {
        width: 640px;
        margin-right: 24px;
      }
    }

    &__login {
      display: flex;
      margin-bottom: 24px;

      @include media-breakpoint-up(xxl) {
        display: block;
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        margin-left: 8px;

        @include media-breakpoint-up(xxl) {
          padding-top: 4px;
          margin-left: 0;
        }
      }
    }
  }

  .link-button {
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: $orange;
    white-space: nowrap;
    cursor: pointer;

    @include media-breakpoint-up(xxl) {
      margin-top: 0;
      margin-left: 24px;
    }

    i {
      padding-right: 6px;
      font-size: 20px;
    }

    &:hover {
      color: $orange-hover;
    }
  }

  .delivery-date {
    margin-top: 34px;

    @include media-breakpoint-up(md) {
      display: flex;

      &.pickup {
        display: block;
      }

      div + div {
        margin-left: 24px;
      }
    }

    &.pickup {
      @include media-breakpoint-up(lg) {
        .delivery-date__day {
          width: 225px;
        }
      }

      @include media-breakpoint-up(xxl) {
        .delivery-date__day {
          width: 248px;
        }
      }

      .delivery-date__interval {
        margin-top: 16px;
        margin-left: 0;
      }
    }

    &__day {
      width: 248px;
    }

    &__interval {
      margin-top: 24px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

      .select {
        width: 184px;
      }
    }
  }

  .transport-types-info {
    margin-top: 24px;

    span {
      font-size: $fs-l;
      color: $gray-700;
    }

    i {
      padding-right: 6px;
      font-size: 20px;
      color: $gray-900;
    }
  }

  .delivery-info-text {
    font-size: $fs-m;
    color: $gray-700;
  }

  .delivery-payment-info {
    font-size: $fs-l;
    color: $gray-700;
  }

  .warehouse-distance {
    margin-top: 16px;
    color: $gray-700;

    @include media-breakpoint-up(xxl) {
      margin-top: 12px;
    }

    i {
      position: relative;
      top: 2px;
      font-size: 20px;
    }
  }
}

.warehouse-block {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &__info-wrapper,
  &__map {
    width: 100%;
  }

  &__name {
    font-size: $fs-xl;
    font-weight: 500;
  }

  &__change {
    margin-left: 16px;
    font-size: $fs-xl;
    color: $orange;
    cursor: pointer;

    @include media-breakpoint-between(lg, xxl) {
      margin-top: 8px;
      margin-left: 0;
    }

    &:hover {
      color: $orange-hover;
    }
  }

  &__success-msg {
    display: flex;
    align-items: center;
    margin-top: 8px;

    span {
      padding-left: 6px;
      color: $green;
    }

    i {
      font-size: 20px;
    }
  }

  &__info-wrapper {
    display: flex;

    @include media-breakpoint-up(md) {
      padding-right: 24px;
    }

    .icn-location {
      margin-right: 10px;
      font-size: 22px;
    }
  }

  &__map {
    margin-top: 24px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    img {
      @include size(100%, 220px);

      object-fit: cover;

      @include media-breakpoint-up(xxl) {
        height: 310px;
      }
    }
  }
}

.content-block {
  width: 100%;
  padding: 32px 16px 40px;
  background-color: $white;

  @include media-breakpoint-up(md) {
    padding: 32px;
  }
}

.form-check {
  max-width: 100%;
}
