.search {
  &__res {
    margin-top: 6px;

    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 32px;
      z-index: 10;
      width: calc(100% - 32px);
      padding: 16px;
      margin-top: 0;
      background: $white;
      box-shadow: 0 20px 15px rgba(0, 21, 51, .05), 0 20px 32px -8px rgba(0, 21, 51, .1);
    }

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        color: $black;
        text-decoration: none;
      }
    }

    &-match {
      color: $orange;

      @include media-breakpoint-up(lg) {
        font-weight: $font-weight-medium;
        color: $black;
      }
    }

    &-item {
      display: flex;
      padding: 10px 0;
    }

    &-goods {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
    }

    &-title {
      padding: 10px 0;
      color: $gray-600;
    }

    &-category {
      display: flex;
      align-items: center;
      padding: 5px 0;

      i {
        padding-right: 4px;
        font-size: 32px;
      }
    }
  }

  &__cancel {
    display: none;
    padding-left: 16px;
    color: $gray-600;
    cursor: pointer;
  }

  &__popular {
    display: none;
    margin-top: 16px;
    color: $gray-600;

    &-item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      color: $black;
      text-decoration: none;

      &:hover {
        color: $black;
        text-decoration: none;
      }

      i {
        font-size: 20px;
        color: $gray-600;
      }

      span {
        padding-left: 4px;
        color: $black;
      }
    }
  }
}
