.select {
  position: relative;

  &.disabled .select-toggle {
    color: $dropdown-link-disabled-color;
    pointer-events: none;
    background-color: transparent;
  }
}

.select-toggle {
  display: flex;
  align-items: center;
  min-width: $dropdown-min-width;
  height: 40px;
  padding: 12px;
  cursor: pointer;
  background: transparent;
  border: 1px solid $gray-200;
  border-radius: 0;

  &::after {
    position: absolute;
    right: 10px;
    display: block;
    width: 16px;
    height: 10px;
    content: "";
    background-image: escape-svg($form-select-indicator);
    background-repeat: no-repeat;
    background-size: $form-select-bg-size;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: $orange;
    box-shadow: 0 0 0 .25rem $orange-200;
  }

  &.show {
    &::after {
      transform: rotate(180deg);
    }
  }
}

// The dropdown menu
.select-menu {
  position: absolute;
  // stylelint-disable-next-line
  top: -2px !important;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  min-width: 100%;
  padding-left: 0;
  margin: 0; // Override default margin of ul
  color: $dropdown-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  // stylelint-disable-next-line
  border: none;
  box-shadow: 0 10px 15px -8px rgba(0, 0, 0, .3), 0 1px 7px 2px rgba(0, 0, 0, .03);
  @include font-size($dropdown-font-size);


  li {
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;

    &:hover,
    &:focus,
    &:visited {
      color: $primary;
      background-color: $dropdown-bg;
    }
  }
}

// scss-docs-start responsive-breakpoints
// We deliberately hardcode the `bs-` prefix because we check
// this custom property in JS to determine Popper's positioning

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .select-menu#{$infix}-start {
      --bs-position: start;

      &[data-bs-popper] {
        right: auto;
        left: 0;
      }
    }

    .select-menu#{$infix}-end {
      --bs-position: end;

      &[data-bs-popper] {
        right: 0;
        left: auto;
      }
    }
  }
}
// scss-docs-end responsive-breakpoints

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .select-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  .select-toggle {
    @include caret(up);
  }
}

.dropend {
  .select-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-spacer;
  }

  .select-toggle {
    @include caret(end);
    &::after {
      vertical-align: 0;
    }
  }
}

.dropstart {
  .select-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .select-toggle {
    @include caret(start);
    &::before {
      vertical-align: 0;
    }
  }
}


// Dividers (basically an `<hr>`) within the dropdown
.select-divider {
  height: 0;
  margin: $dropdown-divider-margin-y 0;
  overflow: hidden;
  border-top: 1px solid $dropdown-divider-bg;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.select-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: $font-weight-normal;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  text-decoration: if($link-decoration == none, null, none);
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s

  // Prevent dropdown overflow if there's no padding
  // See https://github.com/twbs/bootstrap/pull/27703
  @if $dropdown-padding-y == 0 {
    &:first-child {
      @include border-top-radius($dropdown-inner-border-radius);
    }

    &:last-child {
      @include border-bottom-radius($dropdown-inner-border-radius);
    }
  }

  &:hover,
  &:focus {
    color: $dropdown-link-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    @include gradient-bg($dropdown-link-hover-bg);
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    @include gradient-bg($dropdown-link-active-bg);
  }
}

.select-menu.show {
  display: block;
}

// Dropdown section headers
.select-header {
  display: block;
  padding: $dropdown-header-padding;
  margin-bottom: 0; // for use with heading elements
  @include font-size($font-size-sm);
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Dropdown text
.select-item-text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}

// Dark dropdowns
.select-menu-dark {
  color: $dropdown-dark-color;
  background-color: $dropdown-dark-bg;
  border-color: $dropdown-dark-border-color;
  @include box-shadow($dropdown-dark-box-shadow);

  .select-item {
    color: $dropdown-dark-link-color;

    &:hover,
    &:focus {
      color: $dropdown-dark-link-hover-color;
      @include gradient-bg($dropdown-dark-link-hover-bg);
    }

    &.active,
    &:active {
      color: $dropdown-dark-link-active-color;
      @include gradient-bg($dropdown-dark-link-active-bg);
    }

    &.disabled,
    &:disabled {
      color: $dropdown-dark-link-disabled-color;
    }
  }

  .select-divider {
    border-color: $dropdown-dark-divider-bg;
  }

  .select-item-text {
    color: $dropdown-dark-link-color;
  }

  .select-header {
    color: $dropdown-dark-header-color;
  }
}
