.drop-area {
  padding: 16px 24px;
  cursor: pointer;
  border: 1px dashed $gray-400;

  &__input {
    display: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    margin-bottom: 10px;
  }

  &__file{
    margin-top: 10px;
    color: $orange;

    &--hidden {
      display: none;
    }
  }
}
