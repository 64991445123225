dl {
  dt,
  dd {
    padding: 8px 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: $gray-600;
  }
  dd {
    color: $gray-900;
  }
  // stylelint-disable selector-no-qualifying-type
  &.no-paddings {
    dt,
    dd {
      padding: 0;
    }
    dt {
      padding-top: 12px;
    }
    dd {
      padding-bottom: 12px;
    }
  }
  // stylelint-enable selector-no-qualifying-type
}
