.order-payment {
  max-width: 880px;
  margin: 0 auto 60px;

  @include media-breakpoint-down(lg) {
    margin: 0 0 60px;
  }

  &__title {
    margin-bottom: 24px;
    font-size: 26px;
    font-weight: $font-weight-medium;
  }

  &__span {
    color: #565655;
  }

  &__btn {
    padding: 11px 24px;
    margin-right: 24px;
    font-size: 13px;
    font-weight: $font-weight-medium;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &-wrap {
      display: flex;
      margin-top: 8px;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    &--dark {
      border: 2px solid $black;
    }

    &--orange {
      color: $white;
      background: $orange;
      border: 2px solid $orange;
    }
  }

  &__info {
    padding: 32px;
    margin-top: 28px;
    background: $gray-100;

    @include media-breakpoint-down(md) {
      padding: 32px 16px;
    }

    &-title {
      font-size: 26px;
      font-weight: $font-weight-medium;
    }

    &-container {
      display: flex;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    &-content {
      display: flex;
      flex-basis: 55%;
      padding-right: 30px;
      margin-top: 24px;

      &:first-child {
        flex-basis: 45%;
      }

      @include media-breakpoint-down(md) {
        padding-right: 0;
      }
    }

    &-icon {
      margin-right: 24px;
    }

    &-light {
      margin-bottom: 4px;
      color: #565655;
    }

    &-bold {
      margin-bottom: 4px;
      font-weight: $font-weight-medium;
    }

    &-normal {
      margin-bottom: 4px;

      &--mod {
        font-feature-settings: "tnum" on;
      }
    }
  }
}
