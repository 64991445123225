// stylelint-disable declaration-no-important
.ripple-block {
  background-color: $orange !important;
  background-image: linear-gradient(90deg, $orange, $orange-300);
}
// stylelint-enable declaration-no-important

.ripple{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 100px;
  opacity: 0;
  animation: ripple 2s 1;
}

@keyframes ripple{
  0%{
    opacity: 1;
    transform: scale(0);
  }
  100%{
    opacity: 0;
    transform: scale(1);
  }
}
.ripple:nth-child(2){
  animation-delay: .3s;
}
.ripple:nth-child(3){
  animation-delay: .6s;
}

.animated {
  animation: mymove 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated-slow {
  animation: mymove 3s;
  animation-duration: 2s;
  animation-fill-mode: both;
}

.rubber-band {
  animation-name: rubber-band;
}

@keyframes rubber-band{
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, .75, 1);
  }
  40% {
    transform: scale3d(.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, .85, 1);
  }
  65% {
    transform: scale3d(.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, .95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }

}

.pulse {
  animation-name: pulse;
}
@keyframes pulse{
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.shake {
  animation-name: shake;
}

@keyframes shake{
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.swing {
  transform-origin: top center;
  animation-name: swing;
}


@keyframes swing{
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.tada {
  animation-name: tada;
}


@keyframes tada{

  0% {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes wobble{

  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  100% {
    transform: none;
  }
}

.fade-in-left {
  animation-name: fade-in-left;
}

@keyframes fade-in-left{

  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-right {
  animation-name: fade-in-right;
}


@keyframes fade-in-right{

  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-down {
  animation-name: fade-in-down;
}

@keyframes fade-in-down{

  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-up {
  animation-name: fade-in-up;
}


@keyframes fade-in-up{

  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}


.light-speed-in {
  animation-name: light-speed-in;
  animation-timing-function: ease-out;
}

@keyframes light-speed-in{

  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0) skewX(-30deg);
  }
  60% {
    opacity: 1;
    transform: skewX(20deg);
  }
  80% {
    opacity: 1;
    transform: skewX(-5deg);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.zoom-in {
  animation-name: zoom-in;
}

@keyframes zoom-in{
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
  50% {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fade-in;
}

@keyframes fade-in{
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.zoom-out {
  animation-name: zoom-out;
}

@keyframes zoom-out{
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
  100% {
    opacity: 0;
  }
}
