.toast {
  position: relative;
  width: $toast-max-width;
  max-width: 100%;
  padding: $toast-padding-y $toast-padding-x;
  margin-bottom: $toast-margin-bottom;
  border: $toast-border-width solid $toast-border-color;
  @include border-radius($toast-border-radius);

  &:not(.showing):not(.show) {
    opacity: 0;
  }

  &.hide {
    display: none;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $stretched-link-z-index + 1;
    margin: $alert-padding-y $alert-padding-x;
  }
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;

  > :not(:last-child) {
    margin-bottom: $toast-spacing;
  }
}

.toast-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 .25rem;
  font-size: 1rem;
  font-weight: $font-weight-medium;
  color: inherit;
}


// scss-docs-start toast-modifiers
@each $state, $value in $toast-theme-colors {
  $alert-background: $value;
  $alert-border: $value;
  $alert-color: if($alert-background == $primary, $black, $white);

  .toast-#{$state} {
    @include alert-variant($alert-background, $alert-border, $alert-color);
    .toast-icon {
      fill: if($alert-background == $primary, $black, $white);
    }
    .btn-close {
      background-image: if($alert-background == $primary, escape-svg($btn-close-bg), escape-svg($btn-close-bg-white));

    }
  }
}
// scss-docs-end toast-modifiers
